import { EditTwoTone } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { selectCustomPageData, selectCustomPageLoading } from 'app/features/customPage/selectors';
import { customPageApi } from 'app/features/customPage/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect } from 'react';
import { ICustomPage } from 'app/features/customPage/types';
import { Link } from 'react-router-dom';

import ContentTop from 'components/ContentTop';
import { Text } from './styled';

const CustomPage = () => {
  //selectors
  const customPageData = useAppSelector(selectCustomPageData());
  const loading = useAppSelector(selectCustomPageLoading());
  // dispatch
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(customPageApi());
  }, [dispatch]);

  const columns: ColumnsType<ICustomPage> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Text',
      dataIndex: 'text',
      key: 'text',
      render: (text: string) => {
        return <Text>{text}</Text>;
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 111,
      key: 'Action',
      render: (_, record) => {
        return (
          <Link to={`${record.id}`}>
            <Button icon={<EditTwoTone />}>Edit</Button>
          </Link>
        );
      }
    }
  ];

  return (
    <>
      <ContentTop title={'Custom Page'} />
      <Table columns={columns} loading={loading} bordered dataSource={customPageData} pagination={false} rowKey="id" />
    </>
  );
};

export default CustomPage;
