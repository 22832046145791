import styled from 'styled-components';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
`;

export const EditableCellValueWrap = styled.div`
  padding: 5px 12px;
  cursor: pointer;

  &:hover {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
`;
