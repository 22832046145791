import { Form, Input } from 'antd';
import { postLogin } from 'app/features/auth/slice';
import { ILogin } from 'app/features/auth/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectLoginLoading } from 'app/features/auth/selectors';
import { useNavigate } from 'react-router-dom';

import { ButtonLogin, FormContainer, LoginContainer, Title } from './styled';

const Login = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = async (values: ILogin) => {
    dispatch(postLogin(values)).then(() => {
      navigate('/');
    });
  };

  const loading = useAppSelector(selectLoginLoading());

  return (
    <LoginContainer>
      <FormContainer>
        <Title level={1}>Welcome</Title>
        <Form
          scrollToFirstError={{
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
          }}
          form={form}
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!'
              },
              {
                required: true,
                message: 'Please input your E-mail!'
              }
            ]}
            label="Email"
            name="email"
          >
            <Input size="large" placeholder="Email"></Input>
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Please input your password!'
              }
            ]}
            label="Password"
            name="password"
          >
            <Input.Password style={{ borderRadius: '8px' }} size="large" placeholder="Password"></Input.Password>
          </Form.Item>
          <ButtonLogin loading={loading} htmlType="submit" type="primary">
            Log In
          </ButtonLogin>
        </Form>
      </FormContainer>
    </LoginContainer>
  );
};

export default Login;
