import { message, Modal, ModalFuncProps } from 'antd';
import { AxiosError } from 'axios';

export type ErrorType = {
  data: null;
  message: string;
  status: number;
};

export const showErrorMessage = (err: AxiosError<ErrorType>) => {
  console.error(err);
  message.error(err?.response?.data?.message || 'Something went wrong, check console for details');
};

export const showErrorUploadMessage = (err: AxiosError<ErrorType>) => {
  const errorProps: ModalFuncProps = {
    title: 'Error',
    content: err?.response?.data?.message || 'Something went wrong'
  };

  Modal.error(errorProps);
};

export const handleBackendValidationError = (errorMessage: string, error: unknown) => {
  message.error(`Backend validation error: ${errorMessage || (error as { message?: string })?.message || 'Unknown error'}: Check the console for more info.`, 3);

  console.log('Validation failed:', error);
};
