import { Button, Form, Image, Space, Table, Tag, UploadFile, UploadProps } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { selectServiceById, selectServiceLoadingAction, selectServiceLoadingByID } from 'app/features/services/selectors';
import { getServiceById, postService, serviceEdit } from 'app/features/services/slice';
import { IService } from 'app/features/services/types';
import { ColumnsType } from 'antd/es/table';
import { IProduct } from 'app/features/products/types';
import { getFileUrl } from 'helpers/file';

import ProductsListModal from 'components/ProductsListModal';
import Loading from 'components/Loading';
import { HeaderDivider, LeftPage, PageHeader, PageTitle, ProductTitle } from 'components/DetailsHead/styled';
import ServicePage1 from './components/ServicePage1';
import ContentTop from 'components/ContentTop';
import { FalseIcon, IconWrapper, ImgTableWrapper, TrueIcon } from 'pages/Products/styled';
import noImg from '../../assets/img/noimage.png';

const validateMessages = {
  required: "'${label}' is Required!"
};

const ServiceDetails = () => {
  //state
  const [showProductsListModal, setShowProductsListModal] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileListBody, setFileListBody] = useState<UploadFile[]>([]);
  //params
  const { id } = useParams();
  // navigation
  const navigate = useNavigate();
  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  const serviceByID = useAppSelector(selectServiceById());
  const loadingById = useAppSelector(selectServiceLoadingByID());
  const loadingAction = useAppSelector(selectServiceLoadingAction());
  // form
  const [form] = Form.useForm();

  //effects

  useEffect(() => {
    if (id) {
      dispatch(getServiceById(Number(id)));
    }
  }, [dispatch, id]);

  const onClose = () => {
    form.resetFields();
    navigate(-1);
  };

  useEffect(() => {
    if (id && serviceByID) {
      form.setFieldsValue({
        title_en: serviceByID?.title_en,
        body_text: serviceByID?.body_text,
        body_title: serviceByID?.body_title
      });
      const prodIds = serviceByID.products.map(item => item.id);

      setSelectedProducts(prodIds);
    }
  }, [serviceByID, form, id]);

  const onChange: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onChangeBodyImage: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
    setFileListBody(newFileList);
  };

  const columns: ColumnsType<IProduct> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Articul number',
      dataIndex: 'articul_number',
      key: 'articul_number'
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price'
    },
    {
      title: 'Availability',
      dataIndex: 'availability',
      width: 40,
      align: 'center',
      key: 'availability',
      render: value => {
        const color = value ? 'green' : 'red';
        const icon = value ? <TrueIcon style={{ fontSize: 20 }} /> : <FalseIcon style={{ fontSize: 20 }} />;

        return <IconWrapper style={{ width: 40, height: '100%' }} icon={icon} key={value} color={color}></IconWrapper>;
      }
    },
    {
      title: 'Image',
      dataIndex: 'Files',
      key: 'image',
      width: 100,
      render: (_, item) => {
        return (
          <ImgTableWrapper>
            {item.files?.length ? (
              <Image width={100} alt={item.files[0].name} src={getFileUrl(item.files[0]?.name, item.files[0]?.extension, 'product')} />
            ) : (
              <Image preview={false} width={70} src={noImg} alt="No Image" />
            )}
          </ImgTableWrapper>
        );
      }
    }
  ];

  const onFinish = async (values: IService) => {
    const files = fileList.map(item => item.response?.data?.data?.id || item.uid).filter(item => !!item);

    const bodyFiles = fileListBody.map(item => item.response?.data?.data?.id || item.uid).filter(item => !!item);

    const data = {
      title_en: values.title_en,
      products: selectedProducts,
      fileId: files[0],
      body: {
        title: values.body_title,
        text: values.body_text,
        image: bodyFiles
      }
    };

    if (id) {
      await dispatch(
        serviceEdit({
          id: id,
          ...data
        })
      ).then(() => onClose());
    } else {
      const res = (await dispatch(
        postService({
          ...data
        })
      )) as {
        meta: { requestStatus: string };
        payload: { message: string };
      };

      if (res.meta.requestStatus === 'rejected' && res.payload.message === 'Request failed with status code 409') {
        form.setFields([
          {
            name: 'id',
            errors: ['Such a key already exists. Please enter a different Articul Number.']
          }
        ]);
      } else {
        onClose();
      }
    }
  };

  return (
    <>
      {loadingById ? (
        <Loading size="large" />
      ) : (
        <>
          <Form
            validateMessages={validateMessages}
            size="large"
            form={form}
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError={{
              behavior: 'smooth',
              block: 'center',
              inline: 'center'
            }}
          >
            <PageHeader
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Space>
                <LeftPage onClick={onClose} />
                <PageTitle level={3}>{id ? `Edit Service` : `Add Service`}</PageTitle>
                {id && (
                  <Tag color="green">
                    <ProductTitle strong>{serviceByID?.title_en}</ProductTitle>
                  </Tag>
                )}
              </Space>

              <Button style={{ width: '200px' }} loading={loadingAction} type="primary" onClick={form.submit}>
                Save
              </Button>
            </PageHeader>
            <HeaderDivider />
            <ServicePage1
              serviceByID={serviceByID}
              fileList={fileList}
              onChange={onChange}
              fileListBody={fileListBody}
              onChangeBodyImage={onChangeBodyImage}
              setShowProductsListModal={setShowProductsListModal}
            />
          </Form>
          {id && (
            <>
              <ContentTop title="Service Products" />
              <Table loading={false} pagination={false} bordered columns={columns} dataSource={serviceByID?.products} rowKey="id" />
            </>
          )}
          <ProductsListModal
            visible={showProductsListModal}
            onCancel={() => {
              setShowProductsListModal(false);
            }}
            selected={selectedProducts}
            setSelected={setSelectedProducts}
            serviceByID={serviceByID}
          />
        </>
      )}
    </>
  );
};

export default ServiceDetails;
