import { selectSpecialistById, selectSpecialistLoadingAction, selectSpecialistLoadingById } from 'app/features/specialist/selectors';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getSpecialistById, postSpecialist, specialistEdit } from 'app/features/specialist/slice';
import { Button, Col, Form, Input, Row, Upload } from 'antd';
import { ISpecialistById } from 'app/features/specialist/types';
import { UploadOutlined } from '@ant-design/icons';
import { UploadFile, UploadProps } from 'antd/es/upload';
import { BASE_URL } from 'api/apiClient';
import useCustomRequest from 'Hook/useCustomRequest';

import Loading from 'components/Loading';
import DetailsHead from 'components/DetailsHead';

const validateMessages = {
  required: "'${label}' is Required!"
};

const { TextArea } = Input;
const { Dragger } = Upload;

const SpecialistDetails = () => {
  //state
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  //selectors
  const loadingById = useAppSelector(selectSpecialistLoadingById());
  const specialistById = useAppSelector(selectSpecialistById());
  const loadingAction = useAppSelector(selectSpecialistLoadingAction());

  //dispatch
  const dispatch = useAppDispatch();
  //navigate
  const navigate = useNavigate();
  const { id } = useParams();

  // form
  const [form] = Form.useForm();

  const onClose = () => {
    form.resetFields();
    navigate(-1);
  };

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  useEffect(() => {
    if (id) {
      dispatch(getSpecialistById(Number(id)));
    }
  }, [dispatch, id]);

  const onSave = async (values: ISpecialistById) => {
    const files = fileList.map(item => item.response?.data?.data?.id || item.uid).filter(item => !!item);

    const data = {
      fullName_en: values.fullName_en,
      description_en: values.description_en,
      // fullName_ru: values.fullName_ru,
      // description_ru: values.description_ru,
      // fullName_hy: values.fullName_hy,
      // description_hy: values.description_hy,
      email: values.email,
      address: values.address,
      linkedinUrl: values.linkedinUrl,
      profession_en: values.profession_en,
      // profession_ru: values.profession_ru,
      // profession_hy: values.profession_hy,
      phone: values.phone,
      files
    };

    if (id) {
      await dispatch(
        specialistEdit({
          id: Number(id),
          ...data
        })
      ).then(() => onClose());
    } else {
      await dispatch(postSpecialist(data)).then(() => onClose());
    }
  };

  useEffect(() => {
    if (id && specialistById) {
      form.setFieldsValue({
        fullName_en: specialistById.fullName_en,
        description_en: specialistById.description_en,
        // fullName_ru: specialistById.fullName_ru,
        // description_ru: specialistById.description_ru,
        // fullName_hy: specialistById.fullName_hy,
        // description_hy: specialistById.description_hy,
        email: specialistById.email,
        address: specialistById.address,
        linkedinUrl: specialistById.linkedinUrl,
        profession_en: specialistById.profession_en,
        // profession_ru: specialistById.profession_ru,
        // profession_hy: specialistById.profession_hy,
        phone: specialistById.phone
      });
      setFileList(
        specialistById.files?.map(item => {
          const url = `${BASE_URL}/uploads/${item.name}.${item.extension}`;

          return {
            uid: item.id,
            name: `${item.name}.${item.extension}`,
            status: 'done',
            url
          };
        }) || []
      );
    }
  }, [specialistById, form, id]);

  const customRequest = useCustomRequest({ url: 'file' });

  return (
    <>
      {loadingById ? (
        <Loading size="large" />
      ) : (
        <>
          <Form
            scrollToFirstError={{
              behavior: 'smooth',
              block: 'center',
              inline: 'center'
            }}
            onFinish={onSave}
            validateMessages={validateMessages}
            size="large"
            form={form}
            layout="vertical"
          >
            <DetailsHead titleItem={specialistById?.fullName_en} onClose={onClose} id={id} headTitle={'Specialist'} loadingAction={loadingAction} form={form} />
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <Form.Item rules={[{ required: true }]} label="English Full Name" name="fullName_en">
                  <Input placeholder="Enter Full Name in English" />
                </Form.Item>
              </Col>
              {/* <Col span={8}>
                <Form.Item
                  rules={[{ required: true }]}
                  label="Russian Full Name"
                  name="fullName_ru"
                >
                  <Input placeholder="Enter Full Name in Russian" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[{ required: true }]}
                  label="Armenian Full Name"
                  name="fullName_hy"
                >
                  <Input placeholder="Enter Full Name in Armenian" />
                </Form.Item>
              </Col> */}
              <Col span={12}>
                <Form.Item label="English Profession" rules={[{ required: true }]} name="profession_en">
                  <Input size="large" placeholder="Enter profession in English"></Input>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item rules={[{ required: true }]} label="English Description" name="description_en">
                  <TextArea style={{ height: '200px', resize: 'none' }} showCount placeholder="Enter Description in English" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!'
                    },
                    {
                      required: true,
                      message: 'Please input E-mail!'
                    }
                  ]}
                  label="Email"
                  name="email"
                >
                  <Input size="large" placeholder="Enter email"></Input>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Address" rules={[{ required: true }]} name="address">
                  <Input size="large" placeholder="Enter address"></Input>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Phone"
                  rules={[
                    {
                      // eslint-disable-next-line no-useless-escape
                      pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                      message: 'Enter a phone number'
                    },
                    {
                      required: true,
                      message: 'Please input phone number!'
                    }
                  ]}
                  name="phone"
                >
                  <Input size="large" placeholder="Enter phone"></Input>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  rules={[
                    {
                      // eslint-disable-next-line no-useless-escape
                      pattern: /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub|school|company|jobs)\/[a-zA-Z0-9-]{3,100}\/?$/,
                      message: 'The input is not valid Linkedin Url!'
                    },
                    {
                      required: true,
                      message: 'Please input Linkedin Url'
                    }
                  ]}
                  label="Linkedin Url"
                  name="linkedinUrl"
                >
                  <Input placeholder="Enter Linkedin Url" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="files">
                  <Dragger onChange={onChange} fileList={fileList} accept="image/*" customRequest={customRequest} listType="picture" maxCount={1}>
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Dragger>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </>
  );
};

export default SpecialistDetails;
