import { Button, Card, Drawer } from 'antd';
import styled from 'styled-components';

export const CardImg = styled(Card)`
  .ant-card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-top: 1px solid #f0f0f0;
    text-align: center;
  }
  .ant-card-actions > li {
    margin: 0;
  }
  .ant-card-cover {
    cursor: default;
  }
`;
export const ButtonDelete = styled(Button)`
  .ant-btn {
    border-radius: 0px 0px 6px 6px;
  }
`;

export const Img = styled.img`
  width: 100%;
  height: 98%;
  object-fit: contain;
`;

export const ImgWrraper = styled.div`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: fit-content;
    min-height: 180px;
    min-width: 180px;
    padding: 30px;
    img {
      width: 100%;
      height: fit-content;
    }
  }
`;

export const DrawerStyled = styled(Drawer)`
  .ant-drawer-close {
    margin-bottom: 4px;
  }
`;

export const UploadWrraper = styled.div`
  height: fit-content;
  margin-top: 20px;
  span {
    padding: 0 !important;
    .ant-upload-drag {
      border: none;
    }
  }
`;

export const ContentTopTitle = styled.div`
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
`;
export const ContentTopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledImagesContainer = styled.div`
  margin: 50px auto 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
`;
