import { useRef, useEffect } from 'react';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { RcFile } from 'antd/es/upload';
import { client } from 'api/apiClient';

type CustomRequestOptions = {
  url: string;
  name?: boolean;
};

const useCustomRequest = ({ url, name }: CustomRequestOptions) => {
  // refs
  const timer = useRef<NodeJS.Timeout | null>(null);
  const queue = useRef<UploadRequestOption<any>[]>([]);

  const customRequest = (e: UploadRequestOption<any>) => {
    queue.current.push(e);

    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(async () => {
      for (let i = 0; i < queue.current.length; i++) {
        const item = queue.current[i];

        try {
          const formData = new FormData();
          formData.append('file', item.file, (item.file as RcFile).name);
          name && formData.append('name', (item.file as RcFile).name);

          const res = await client.post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: item.onProgress
          });

          item.onSuccess?.(res);
        } catch (err: any) {
          item.onError?.(err);
        }
      }

      queue.current = [];
    }, 100);
  };

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  return customRequest;
};

export default useCustomRequest;
