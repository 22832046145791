import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api/apiClient';
import {
  selectUserLimit,
  selectUserOrdersLimit,
  selectUserOrdersPage,
  selectUserProductsLimit,
  selectUserProductsPage,
  selectUserProductsSearch,
  selectUsersPage,
  selectUsersSearch
} from 'app/features/users/selectors';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';

import { IUserEditValues, IUserPostValues, UsersState } from './types';
import { IOrderAddressEditValues, IOrderAddressPostValues } from 'pages/Users/UserDetails/types';

const initialState: UsersState = {
  users: [],
  loading: false,
  page: 1,
  search: '',
  limit: 10,
  count: 0,
  subscribeLimit: 10,
  subscribeCount: 0,
  subscribePage: 1,
  subscribeUsers: [],
  userById: null,
  userBasket: {
    total_margin_price: 0,
    total_price: 0,
    basketState: [],
    loading: false
  },
  userBasketQtyObj: null,
  userFavoriteProducts: [],
  orderSuccessState: false,
  orderLoadingState: false,
  userOrders: {
    orders: [],
    count: 0,
    page: 1,
    limit: 10,
    loading: false
  },
  userProducts: {
    products: [],
    loading: false,
    count: 0,
    page: 1,
    limit: 10,
    search: ''
  },
  userOrderAddresses: [],
  admins: {
    rows: [],
    count: 0,
    loading: false
  }
};

export const usersApi = createAsyncThunk('users/usersApi', async (_, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState;
    const page = selectUsersPage()(state);
    const search = selectUsersSearch()(state);
    const limit = selectUserLimit()(state);

    const res = await client.get(`/user`, {
      params: {
        search,
        page,
        limit
      }
    });

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const subscribeUserApi = createAsyncThunk('users/subscribeUserApi', async (_, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState;
    const page = selectUsersPage()(state);
    const limit = selectUserLimit()(state);

    const res = await client.get(`/user/subscribeUsers`, {
      params: {
        page,
        limit
      }
    });

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const userByIdApi = createAsyncThunk('users/userByIdApi', async (id: string, { rejectWithValue }) => {
  try {
    const res = await client.get(`/user/${id}`);

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const deleteUserApi = createAsyncThunk('users/deleteUserApi', async (id: string, { rejectWithValue }) => {
  try {
    const res = await client.delete(`/user/${id}`);

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const editUserInfoApi = createAsyncThunk('users/editUserInfoApi', async ({ id, data }: IUserEditValues, { rejectWithValue }) => {
  try {
    const res = await client.patch(`/user/${id}`, { ...data });

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const getUserBasketState = createAsyncThunk('users/getUserBasketState', async (user_id: string, { rejectWithValue }) => {
  try {
    const res = await client.post(`/shopping_cart/getAllAdmin`, { user_id });

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const getUserFavoriteProducts = createAsyncThunk('users/getUserFavoriteProducts', async (user_id: string, { rejectWithValue }) => {
  try {
    const res = await client.get(`/favorite_products/get/${user_id}`);

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const getUserOreders = createAsyncThunk('users/getUserOreders', async (user_id: string, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState;
    const page = selectUserOrdersPage()(state);
    const limit = selectUserOrdersLimit()(state);

    const res = await client.get(`order/getAllOneUserAdmin/${user_id}`, {
      params: {
        page: page,
        limit: limit
      }
    });

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const getUserProducts = createAsyncThunk('users/getUserProducts', async (user_id: string, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState;
    const page = selectUserProductsPage()(state);
    const limit = selectUserProductsLimit()(state);
    const search = selectUserProductsSearch()(state);
    const res = await client.get(`product/getAllOneUser/${user_id}`, {
      params: {
        search: search,
        page: page,
        limit: limit
      }
    });

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const createUserApi = createAsyncThunk('users/createUserApi', async ({ data }: IUserPostValues, { rejectWithValue }) => {
  try {
    const res = await client.post(`/user/createAdmin`, { ...data });

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const addBasketUserApi = createAsyncThunk('users/addBasketUserApi', async ({ ...data }: { id: number; count: number; user_id: string }, { dispatch, rejectWithValue }) => {
  try {
    const res = await client.post(`/shopping_cart/storeOne`, {
      ...data
    });

    dispatch(getUserBasketState(data.user_id));

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const deleteBasketUserApi = createAsyncThunk('users/deleteBasketUserApi', async ({ id, user_id }: { id: number; user_id: string }, { dispatch, rejectWithValue }) => {
  try {
    await client.delete(`/shopping_cart/deleteOne/${id}?user_id=${user_id}`);
    dispatch(getUserBasketState(user_id));
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const postOrderUserApi = createAsyncThunk('users/postOrderUserApi', async (id: string, { dispatch, rejectWithValue }) => {
  try {
    const res = await client.post(`/order?user_id=${id}`);

    if (res.data.status == 'SUCCESS') {
      dispatch(clearBasketState());
      dispatch(orderSuccess(true));
      dispatch(orderLoading(false));
    }
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const postUserOrderAddress = createAsyncThunk('users/postUserOrderAddress', async ({ id, data }: { id: string; data: IOrderAddressPostValues }, { dispatch, rejectWithValue }) => {
  try {
    await client.post(`/order_address`, { ...data });

    dispatch(getUserOrderAddress(id));
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});
export const getUserOrderAddress = createAsyncThunk('users/getUserOrderAddress', async (id: string, { rejectWithValue }) => {
  try {
    const res = await client.get(`/order_address?user_id=${id}`);

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const addressMakeDefault = createAsyncThunk('orderAddresses/addressMakeDefault', async ({ id, data }: { id: string; data: IOrderAddressEditValues }, { dispatch, rejectWithValue }) => {
  try {
    await client.patch(`/order_address/${id}`, { ...data });

    dispatch(getUserOrderAddress(data.user_id));
  } catch (err) {
    return rejectWithValue(err);
  }
});

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setSubscribePage: (state, action) => {
      state.subscribePage = action.payload;
    },
    setSubscribeLimit: (state, action) => {
      state.subscribeLimit = action.payload;
    },
    clickMenuUser: state => {
      (state.limit = 10), (state.page = 1), (state.search = '');
    },
    clearBasketState: state => {
      state.userBasket.basketState = [];
    },
    orderSuccess: (state, action) => {
      state.orderSuccessState = action.payload;
    },
    orderLoading: (state, action) => {
      state.orderLoadingState = action.payload;
    },
    clearUserOrderAddresses: state => {
      state.userOrderAddresses = [];
    },
    setUserOrderPage: (state, action) => {
      state.userOrders.page = action.payload;
    },
    setUserOrderLimit: (state, action) => {
      state.userOrders.limit = action.payload;
    },
    setUserProductSearch: (state, action) => {
      state.userProducts.search = action.payload;
    },
    setUserProductsPage: (state, action) => {
      state.userProducts.page = action.payload;
    },
    setUserProductsLimit: (state, action) => {
      state.userProducts.limit = action.payload;
    },
    clearUserByIdState: state => {
      state.userById = null;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(usersApi.pending, state => {
        state.loading = true;
      })
      .addCase(usersApi.fulfilled, (state, action) => {
        state.users = action.payload.rows;
        state.count = action.payload.count;
        state.loading = false;
      })
      .addCase(usersApi.rejected, state => {
        state.loading = false;
      })
      .addCase(subscribeUserApi.pending, state => {
        state.loading = true;
      })
      .addCase(subscribeUserApi.fulfilled, (state, action) => {
        state.subscribeUsers = action.payload.rows;
        state.subscribeCount = action.payload.count;
        state.loading = false;
      })
      .addCase(subscribeUserApi.rejected, state => {
        state.loading = false;
      })
      .addCase(userByIdApi.pending, state => {
        state.loading = true;
      })
      .addCase(userByIdApi.fulfilled, (state, action) => {
        state.userById = action.payload;
        state.loading = false;
      })
      .addCase(userByIdApi.rejected, state => {
        state.loading = false;
      })
      .addCase(getUserBasketState.pending, state => {
        state.userBasket.loading = true;
      })
      .addCase(addBasketUserApi.pending, state => {
        state.userProducts.loading = true;
      })
      .addCase(addBasketUserApi.fulfilled, state => {
        state.userProducts.loading = false;
      })
      .addCase(addBasketUserApi.rejected, state => {
        state.userProducts.loading = false;
      })
      .addCase(getUserBasketState.fulfilled, (state, action) => {
        state.userBasket.basketState = action.payload?.products?.products || [];
        state.userBasket.total_margin_price = action.payload.products?.all_total_margin_price || 0;
        state.userBasket.total_price = action.payload.products?.all_total_price || 0;
        state.userBasketQtyObj = action.payload.shoppingCart?.products?.products || [];
        state.userBasket.loading = false;
      })
      .addCase(getUserBasketState.rejected, state => {
        state.userBasket.loading = false;
      })
      .addCase(getUserFavoriteProducts.fulfilled, (state, action) => {
        state.userFavoriteProducts = action.payload;
      })
      .addCase(getUserOreders.pending, state => {
        state.userOrders.loading = true;
      })
      .addCase(getUserOreders.fulfilled, (state, action) => {
        state.userOrders.loading = false;
        state.userOrders.orders = action.payload.rows;
        state.userOrders.count = action.payload.count;
      })
      .addCase(getUserOreders.rejected, state => {
        state.userOrders.loading = false;
      })
      .addCase(getUserProducts.pending, state => {
        state.userProducts.loading = true;
      })
      .addCase(getUserProducts.fulfilled, (state, action) => {
        state.userProducts.loading = false;
        state.userProducts.products = action.payload.rows;
        state.userProducts.count = action.payload.count;
      })
      .addCase(getUserProducts.rejected, state => {
        state.userProducts.loading = false;
      })
      .addCase(getUserOrderAddress.fulfilled, (state, action) => {
        state.userOrderAddresses = action.payload;
      });
  }
});

export const {
  setSubscribePage,
  setSubscribeLimit,
  setPage,
  setSearch,
  setLimit,
  clickMenuUser,
  setUserOrderPage,
  setUserOrderLimit,
  setUserProductsPage,
  setUserProductsLimit,
  setUserProductSearch,
  clearBasketState,
  orderSuccess,
  orderLoading,
  clearUserOrderAddresses,
  clearUserByIdState
} = userSlice.actions;
export default userSlice.reducer;
