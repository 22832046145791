import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api/apiClient';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { AxiosError } from 'axios';

import { QuestionCreate, QuestionType, QuestionUpdate, QuestionsState } from './types';

const initialState: QuestionsState = {
  questions: [],
  questionById: null,
  loading: false,
  loadingById: false,
  loadingAction: false,
  count: 0,
  active: ''
};

export const allQuestion = createAsyncThunk('question/allQuestion', async (category: string, { dispatch, rejectWithValue }) => {
  try {
    const res = await client.get(`/question_answer/getByCategory/${category}`);

    dispatch(setActive(res.data.data.rows[0]?.id));

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const questionById = createAsyncThunk('question/questionById', async (id: string, { rejectWithValue }) => {
  try {
    const response = await client.get(`/question_answer/${id}`);

    return response.data.data;
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const questionUpdate = createAsyncThunk('question/questionUpdate', async ({ id, ...data }: QuestionUpdate, { dispatch, rejectWithValue }) => {
  try {
    await client.patch(`/question_answer/${id}`, {
      ...data
    });

    await dispatch(allQuestion(data.type));
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const createQuestion = createAsyncThunk('question/createQuestion', async (data: QuestionCreate, { dispatch, rejectWithValue }) => {
  try {
    await client.post('/question_answer', {
      ...data
    });
    await dispatch(allQuestion(data.types));
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const deleteQuestion = createAsyncThunk('question/deleteQuestion', async ({ id, type }: { id: string; type: QuestionType }, { dispatch, rejectWithValue }) => {
  try {
    await client.delete(`/question_answer/${id}`);

    await dispatch(allQuestion(type));
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});
const questionsSlice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    setActive: (state, action) => {
      state.active = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(allQuestion.pending, state => {
        state.loading = true;
      })
      .addCase(allQuestion.fulfilled, (state, action) => {
        state.loading = false;
        state.questions = action.payload.rows;
        state.count = action.payload.count;
      })
      .addCase(allQuestion.rejected, state => {
        state.loading = false;
      })
      .addCase(questionById.pending, state => {
        state.loadingById = true;
      })
      .addCase(questionById.fulfilled, (state, action) => {
        state.questionById = action.payload;
        state.loadingById = false;
      })
      .addCase(questionById.rejected, state => {
        state.loadingById = false;
      })
      .addCase(questionUpdate.pending, state => {
        state.loadingAction = true;
      })
      .addCase(questionUpdate.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(questionUpdate.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(createQuestion.pending, state => {
        state.loadingAction = true;
      })
      .addCase(createQuestion.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(createQuestion.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(deleteQuestion.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteQuestion.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteQuestion.rejected, state => {
        state.loadingAction = false;
      });
  }
});

export const { setActive } = questionsSlice.actions;
export default questionsSlice.reducer;
