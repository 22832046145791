import { Button, Input, Form, Upload, UploadFile, UploadProps, Table, Switch, Col, Row, Tag, Space, Tooltip, Modal } from 'antd';
import { getCategoryById, getDiscountByCategory, deleteCategoryDiscount } from 'app/features/category/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectCategoryById, selectDiscountByCategory } from 'app/features/category/selectors';
import { useEffect, useState } from 'react';
import { EditOutlined, PlusOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import useCustomRequest from 'Hook/useCustomRequest';
import { client } from 'api/apiClient';
import { ColumnsType } from 'antd/es/table';
import { useNavigate, useParams } from 'react-router-dom';
import { selectUserInfo } from 'app/features/auth/selectors';
import { ProductDiscountType } from 'app/features/products/types';
import Paragraph from 'antd/es/typography/Paragraph';
import { getPermission } from 'helpers/userParmissions';

import { LeftPage, PageHeader, PageTitle, ProductTitle } from 'components/DetailsHead/styled';
import { CategoryModal } from './CategoryModal';
import CategoryDiscountModal from './CategoryDiscountModal';
import DeleteModal from 'components/DeleteModal';

const { Dragger } = Upload;

export type ProductDiscountWithIdType = ProductDiscountType & { id: string };

const CategoryDetails = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [showCategoryModal, setShowCategoryModal] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [is_parent, setIsParent] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectRecordId, setSelectRecordId] = useState<string>('');
  const [showDiscountModal, setShowDiscountModal] = useState<boolean>(false);
  const [editValue, setEditValue] = useState<[boolean, string]>([false, '']);
  const discountByCategory = useAppSelector(selectDiscountByCategory()) as ProductDiscountWithIdType[];

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const [form] = Form.useForm();

  const dispatch = useAppDispatch();
  const categoryById = useAppSelector(selectCategoryById());
  const userInfo = useAppSelector(selectUserInfo());

  const customRequest = useCustomRequest({ url: 'advertisement_upload' });

  const handleBack = () => {
    navigate(-1);
    setFileList([]);
  };

  useEffect(() => {
    if (categoryById) {
      form.setFieldsValue({
        title_en: categoryById.title_en,
        key: categoryById.key
      });

      dispatch(getDiscountByCategory(params.id));
    } else {
      dispatch(getCategoryById(params.id));
    }
  }, [dispatch, categoryById, form]);

  const onSave = () => {
    handleBack();
  };

  const clickDiscount = (title: string) => {
    setShowDiscountModal(true);
    setEditValue([true, title]);
  };

  const deleteHandler = (id: string) => {
    setOpenDeleteModal(true);
    dispatch(deleteCategoryDiscount(id))
      .then(() => {
        dispatch(getDiscountByCategory(categoryById?.id));
        setOpenDeleteModal(false);
      })
      .catch(error => {
        console.error('Error deleting discount:', error);
      });
    setSelectRecordId(id);
  };

  const handleSelectParentDiscount = () => {
    Modal.confirm({
      title: 'Will assign it the discount of the parent of this category?',
      content: <Paragraph>Are you sure</Paragraph>,
      onOk: () => {
        client.patch(`/category/${params.id}`, {
          updated: false
        });
        setTimeout(() => {
          dispatch(getCategoryById(params.id));
        }, 1000);
      }
    });
  };

  const DiscountsColumns: ColumnsType<ProductDiscountWithIdType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Percent 1',
      dataIndex: 'percent1',
      key: 'percent1'
    },
    {
      title: 'Percent 2',
      dataIndex: 'percent2',
      key: 'percent2'
    },
    {
      title: 'Percent 3',
      dataIndex: 'percent3',
      key: 'percent3'
    },
    {
      title: 'Percent 4',
      dataIndex: 'percent4',
      key: 'percent4'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 120,
      key: 'Action',
      align: 'center',
      render: (_, record) => {
        return (
          <>
            <Button icon={<EditOutlined />} onClick={() => clickDiscount(record.name)}></Button>
            <Button
              style={{ marginLeft: '3px' }}
              icon={<DeleteOutlined />}
              onClick={() => {
                setOpenDeleteModal(true);
                setSelectRecordId(record.id);
              }}
            ></Button>
          </>
        );
      }
    }
  ];

  return (
    <>
      <PageHeader
        style={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center'
        }}
      >
        <Space>
          <LeftPage onClick={() => handleBack()} />
          <PageTitle level={3}>{params.id ? `Edit Category` : `Add Category`}</PageTitle>
          {params.id && (
            <Tag color="green">
              <ProductTitle strong>{categoryById?.title_en}</ProductTitle>
            </Tag>
          )}
        </Space>
        <Button size="large" type="primary" onClick={form.submit} key="Save">
          Save
        </Button>
      </PageHeader>
      <br />
      <br />
      <br />
      <Form
        scrollToFirstError={{
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        }}
        form={form}
        onFinish={onSave}
        layout="vertical"
        size="large"
      >
        {!params.id && (
          <Form.Item name="is_parent" label="Is Parent">
            <Switch checked={is_parent} onChange={() => setIsParent(!is_parent)} />
          </Form.Item>
        )}
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Form.Item name="title_en" label="English Title" rules={[{ required: true }]}>
              <Input disabled placeholder="Enter title in English" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="key" label="Category Key" rules={[{ required: true }]}>
              <Input disabled placeholder="Enter Category Key" />
            </Form.Item>
          </Col>
        </Row>
        <br />
        <br />
        {getPermission(userInfo?.permision_menu, 'DISCOUNTS') && (
          <>
            <p
              style={{
                fontSize: '24px',
                fontWeight: 500,
                marginBottom: '20px',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              Discounts
              {discountByCategory?.length < 4 && (
                <Button icon={<PlusOutlined />} color="primary" onClick={() => setShowDiscountModal(true)}>
                  Add Discount
                </Button>
              )}
              {categoryById?.updated && !categoryById.is_parent && (
                <Tooltip placement="topLeft" title="Will assign it the discount of the parent of this category">
                  <Button icon={<PlusOutlined />} color="primary" onClick={handleSelectParentDiscount}>
                    Remove Discount
                  </Button>
                </Tooltip>
              )}
            </p>
            <Table loading={false} bordered rowKey="id" pagination={false} columns={DiscountsColumns} dataSource={discountByCategory} />
          </>
        )}
        <br />
        {getPermission(userInfo?.permision_menu, 'CATEGORIES') && (
          <>
            <p
              style={{
                fontSize: '24px',
                fontWeight: 500,
                marginBottom: '20px'
              }}
            >
              Advertisement
            </p>
            <Form.Item name="files">
              <Dragger onChange={onChange} fileList={fileList} accept="image/*" customRequest={customRequest} listType="picture" disabled maxCount={1}>
                <Button style={{ cursor: 'not-allowed' }} icon={<UploadOutlined />}>
                  Upload Advertisement
                </Button>
              </Dragger>
            </Form.Item>
          </>
        )}
      </Form>
      <CategoryModal isOpen={showCategoryModal} onClose={() => setShowCategoryModal(false)} />
      <CategoryDiscountModal
        editValue={editValue}
        clearEditValue={() => setEditValue([false, ''])}
        isOpen={showDiscountModal}
        onClose={() => setShowDiscountModal(false)}
        categoryId={categoryById?.id}
      />
      <DeleteModal
        open={openDeleteModal}
        confirmLoading={false}
        onOk={() => deleteHandler(selectRecordId)}
        onCancel={() => {
          setOpenDeleteModal(false);
          setSelectRecordId('');
        }}
      />
    </>
  );
};

export default CategoryDetails;
