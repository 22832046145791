import { Modal, Button, Input, Form, Upload, UploadFile, UploadProps } from 'antd';
import { postCategory } from 'app/features/category/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectCategoryActionLoading } from 'app/features/category/selectors';
import { FC, memo, useState } from 'react';
import { CategoryParams } from 'app/features/category/types';
import { UploadOutlined } from '@ant-design/icons';
import useCustomRequest from 'Hook/useCustomRequest';
import { useNavigate, useParams } from 'react-router-dom';

import { ICategoryModalProps } from '../types';

const { Dragger } = Upload;

const CategoryModal: FC<ICategoryModalProps> = ({ isOpen, onClose }) => {
  //state
  const navigate = useNavigate();
  const params = useParams();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  // form
  const [form] = Form.useForm();
  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  const loadingAction = useAppSelector(selectCategoryActionLoading());

  const customRequest = useCustomRequest({ url: 'advertisement_upload' });

  const handleCloseModal = () => {
    form.resetFields();
    onClose();
    setFileList([]);
  };

  const onSave = async (values: CategoryParams) => {
    const file = fileList.map(item => item.response?.data?.data?.id || item.uid).filter(item => !!item);

    const data = {
      title_en: values.title_en,
      key: values.key,
      file
    };

    if (params.id) {
      const res = (await dispatch(
        postCategory({
          ...data,
          key: values.key,
          parentId: +params.id,
          is_parent: false
        })
      )) as { meta: { requestStatus: string }; payload: { message: string } };

      if (res.meta.requestStatus === 'rejected' && res.payload.message === 'Request failed with status code 409') {
        form.setFields([
          {
            name: 'key',
            errors: ['Such a key already exists. Please enter a different key.']
          }
        ]);
      } else {
        handleCloseModal();
        navigate(-1);
      }
    }
  };

  return (
    <Modal
      title={'Add Category'}
      open={isOpen}
      onCancel={handleCloseModal}
      footer={[
        <Button onClick={handleCloseModal} key="Cancel">
          Cancel
        </Button>,
        <Button type="primary" onClick={form.submit} loading={loadingAction} key="Save">
          Save
        </Button>
      ]}
    >
      <Form
        scrollToFirstError={{
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        }}
        form={form}
        onFinish={onSave}
        layout="vertical"
        size="large"
      >
        <Form.Item name="title_en" label="English Title" rules={[{ required: true }]}>
          <Input placeholder="Enter title in English" />
        </Form.Item>

        <Form.Item name="key" label="Category Key" rules={[{ required: true }]}>
          <Input placeholder="Enter Category Key" />
        </Form.Item>
        <Form.Item name="files">
          <Dragger onChange={onChange} fileList={fileList} accept="image/*" customRequest={customRequest} listType="picture" maxCount={1}>
            <Button icon={<UploadOutlined />}>Upload Advertisement</Button>
          </Dragger>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default memo(CategoryModal);
