import { selectPartnerById, selectPartnerLoadingAction, selectPartnerLoadingById } from 'app/features/partner/selectors';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getPartnerById, partnerEdit, postPartner } from 'app/features/partner/slice';
import { Button, Col, Form, Input, Row, Select, Switch, Upload, UploadFile, UploadProps } from 'antd';
import { IFile, IPartner } from 'app/features/partner/types';
import { BASE_URL } from 'api/apiClient';
import { UploadOutlined } from '@ant-design/icons';
import useCustomRequest from 'Hook/useCustomRequest';
import { ICountry } from 'app/features/country/types';
import { getCountryData } from 'assets/countries';

import DetailsHead from 'components/DetailsHead';
import Loading from 'components/Loading';

const validateMessages = {
  required: "'${label}' is Required!",
  vat: 'Must be a numeric value and can include up to three decimal places (e.g., 123 or 123.456).'
};

const { Dragger } = Upload;

const PartnerDetails = () => {
  const [visible, setIsVisible] = useState<boolean>(true);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const partnerById = useAppSelector(selectPartnerById());
  const loadingAction = useAppSelector(selectPartnerLoadingAction());
  const countries = getCountryData();
  const loadingById = useAppSelector(selectPartnerLoadingById());

  const [form] = Form.useForm();

  const onClose = () => {
    form.resetFields();
    navigate(-1);
  };

  useEffect(() => {
    if (id) dispatch(getPartnerById(Number(id)));
  }, [dispatch, id]);

  const customRequest = useCustomRequest({ url: 'file' });

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onChangeVisible = () => {
    setIsVisible(!visible);
  };

  const [selectedCountry, setSelectedCountry] = useState<ICountry | null>(null);

  const onSave = async (values: IPartner) => {
    const files = fileList.map(item => item.response?.data?.data?.id || item.uid).filter(item => !!item);

    const data: any = {
      name: values.name,
      description: values.description,
      url: values.url,
      visible,
      email: values.email,
      vat: values.vat,
      country_id: selectedCountry?.id
    };

    if (files.length > 0) {
      data.files = files;
    }

    if (id) {
      await dispatch(
        partnerEdit({
          id: Number(id),
          ...data
        })
      ).then(() => onClose());
    } else {
      await dispatch(postPartner(data)).then(() => onClose());
    }
  };

  useEffect(() => {
    if (id && partnerById) {
      form.setFieldsValue({
        name: partnerById.name,
        url: partnerById.url,
        email: partnerById.email,
        description: partnerById.description,
        country_id: partnerById.country.id,
        vat: partnerById.vat
      });
      setSelectedCountry(partnerById.country);
      setIsVisible(partnerById.visible);
      setFileList(
        partnerById.files?.map((item: IFile) => {
          const url = `${BASE_URL}/uploads/${item.name}.${item.extension}`;

          return {
            uid: item.id,
            name: `${item.name}.${item.extension}`,
            status: 'done',
            url
          };
        }) || []
      );
    }
  }, [partnerById, form, id]);

  if (loadingById) {
    return <Loading size="large" />;
  }

  return (
    <Form
      onFinish={onSave}
      validateMessages={validateMessages}
      size="large"
      form={form}
      layout="vertical"
      scrollToFirstError={{
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      }}
    >
      <DetailsHead titleItem={partnerById?.name} onClose={onClose} id={id} loadingAction={loadingAction} form={form} headTitle={'Partner'} />
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Form.Item name="visibility" label="Visibility">
            <Switch checked={visible} onChange={onChangeVisible} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item rules={[{ required: true }]} label="Name" name="name">
            <Input placeholder="Enter partner name" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item rules={[{ required: true }]} label="Country" name="country_id">
            <Select
              value={selectedCountry}
              onChange={e => {
                const country = countries.find(item => item.id === Number(e));

                if (country) setSelectedCountry(country);
              }}
              placeholder="Select Country"
              optionLabelProp="label"
              optionFilterProp="label"
              showSearch
              options={countries.map(item => {
                return {
                  value: item.id,
                  label: item.name
                };
              })}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item rules={[{ required: true }, { type: 'url' }]} label="Url" name="url">
            <Input placeholder="Enter partner url" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item rules={[{ required: true }, { type: 'email' }]} label="Email" name="email">
            <Input placeholder="Enter Partner Email" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item rules={[{ type: 'string' }, { pattern: /^\d+(\.\d{1,3})?$|^\d+$/, message: validateMessages.vat }]} label="VAT(%)" name="vat">
            <Input placeholder="Provide VAT" />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item name="files">
            <Dragger onChange={onChange} accept="image/*" fileList={fileList} customRequest={customRequest} listType="picture" maxCount={1}>
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Dragger>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item rules={[{ required: true }]} label="Description" name="description">
            <textarea
              style={{ width: '100%', padding: '10px 0 0 10px', resize: 'none', borderRadius: '8px', border: '1px solid gray', outline: 'none' }}
              rows={5}
              placeholder="Enter Partner Description"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default PartnerDetails;
