import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api/apiClient';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';

import { selectOrdersLimit, selectOrdersPage, selectOrdersSearch } from './selectors';
import { IDeleteOrder, OrderQtyEdit, OrderState, OrderStatus } from './types';

const initialState: OrderState = {
  orders: null,
  orderById: null,
  loading: false,
  loadingAction: false,
  loadingQuantity: false,
  loadingByID: false,
  page: 1,
  search: '',
  limit: 10,
  count: 0
};

export const ordersApi = createAsyncThunk('orders/ordersApi', async (_, { getState, dispatch, rejectWithValue }) => {
  try {
    const state = getState() as RootState;
    const page = selectOrdersPage()(state);
    const search = selectOrdersSearch()(state);
    const limit = selectOrdersLimit()(state);

    const res = await client.get(`/order/getAllAdmin`, {
      params: {
        search,
        page,
        limit
      }
    });

    return dispatch(setOrders(res.data.data));
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const ordersEdit = createAsyncThunk('orders/ordersEdit', async ({ id, ...data }: OrderStatus, { rejectWithValue }) => {
  try {
    await client.put(`/order/${id}`, {
      ...data
    });
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const ordersEditQty = createAsyncThunk('orders/ordersEditQty', async (data: OrderQtyEdit, { rejectWithValue }) => {
  try {
    await client.patch(`/order/${data.id}/${data.productId}`, {
      quantity: data.quantity
    });
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const getOrderById = createAsyncThunk('orders/getOrderById', async (id: number, { rejectWithValue }) => {
  try {
    const response = await client.get(`/order/getOne/${id}`);

    return response.data.data;
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const deleteOrder = createAsyncThunk('order/deleteOrder', async (id: number, { rejectWithValue }) => {
  try {
    await client.delete(`/order/${id}`);
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const deleteOrderProduct = createAsyncThunk('order/deleteOrderProduct', async (data: IDeleteOrder, { rejectWithValue }) => {
  try {
    await client.delete(`/order/${data.id}/${data.productId}`);
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setOrders: (state, action) => {
      state.orders = action.payload.rows;
      state.count = action.payload.count;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    clickMenuOrder: state => {
      (state.limit = 10), (state.page = 1), (state.search = '');
    }
  },
  extraReducers(builder) {
    builder
      .addCase(ordersApi.pending, state => {
        state.loading = true;
      })
      .addCase(ordersApi.fulfilled, state => {
        state.loading = false;
      })
      .addCase(ordersApi.rejected, state => {
        state.loading = false;
      })
      .addCase(ordersEdit.pending, state => {
        state.loadingAction = true;
      })
      .addCase(ordersEdit.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(ordersEdit.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(getOrderById.pending, state => {
        state.loadingByID = true;
      })
      .addCase(getOrderById.fulfilled, (state, action) => {
        state.orderById = action.payload;
        state.loadingByID = false;
      })
      .addCase(getOrderById.rejected, state => {
        state.loadingByID = false;
      })
      .addCase(deleteOrder.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteOrder.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteOrder.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(ordersEditQty.pending, state => {
        state.loadingQuantity = true;
      })
      .addCase(ordersEditQty.fulfilled, state => {
        state.loadingQuantity = false;
      })
      .addCase(ordersEditQty.rejected, state => {
        state.loadingQuantity = false;
      })
      .addCase(deleteOrderProduct.pending, state => {
        state.loadingQuantity = true;
      })
      .addCase(deleteOrderProduct.fulfilled, state => {
        state.loadingQuantity = false;
      })
      .addCase(deleteOrderProduct.rejected, state => {
        state.loadingQuantity = false;
      });
  }
});

export const { setPage, setSearch, setOrders, setLimit, clickMenuOrder } = ordersSlice.actions;
export default ordersSlice.reducer;
