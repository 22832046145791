import { Col, Form, Input, Row } from 'antd';
import { selectContactById, selectContactLoadingAction, selectContactLoadingById } from 'app/features/contact/selectors';
import { contactEdit, getContactById, postContact } from 'app/features/contact/slice';
import { IContactById } from 'app/features/contact/type';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import DetailsHead from 'components/DetailsHead';
import Loading from 'components/Loading';

const validateMessages = {
  required: "'${label}' is Required!"
};

const ContactDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  const contactById = useAppSelector(selectContactById());
  const loadingAction = useAppSelector(selectContactLoadingAction());
  const loadingById = useAppSelector(selectContactLoadingById());
  // form
  const [form] = Form.useForm();

  const onClose = () => {
    form.resetFields();
    navigate(-1);
  };

  useEffect(() => {
    if (id) {
      dispatch(getContactById(Number(id)));
    }
  }, [dispatch, id]);

  const onSave = async (values: IContactById) => {
    const data = {
      email: values.email,
      address_en: values.address_en,
      // address_ru: values.address_ru,
      // address_hy: values.address_hy,
      phone: values.phone,
      name_en: values.name_en
      // name_ru: values.name_ru,
      // name_hy: values.name_hy
    };

    if (id) {
      await dispatch(
        contactEdit({
          id: Number(id),
          ...data
        })
      ).then(() => onClose());
    } else {
      await dispatch(postContact(data)).then(() => onClose());
    }
  };

  useEffect(() => {
    if (id && contactById) {
      form.setFieldsValue({
        email: contactById.email,
        phone: contactById.phone,
        name_en: contactById.name_en,
        // name_ru: contactById.name_ru,
        // name_hy: contactById.name_hy,
        // address_hy: contactById.address_hy,
        address_en: contactById.address_en
        // address_ru: contactById.address_ru
      });
    }
  }, [contactById, form, id]);

  return (
    <>
      {loadingById ? (
        <Loading size="large" />
      ) : (
        <>
          <Form
            onFinish={onSave}
            validateMessages={validateMessages}
            size="large"
            form={form}
            layout="vertical"
            scrollToFirstError={{
              behavior: 'smooth',
              block: 'center',
              inline: 'center'
            }}
          >
            <DetailsHead titleItem={contactById?.name_en} onClose={onClose} id={id} headTitle={'Contact'} loadingAction={loadingAction} form={form} />
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <Form.Item rules={[{ required: true }]} label="English Country name" name="name_en">
                  <Input placeholder="Enter Country name" />
                </Form.Item>
              </Col>
              {/* <Col span={8}>
                <Form.Item
                  rules={[{ required: true }]}
                  label="Russian country name"
                  name="name_ru"
                >
                  <Input placeholder="Enter country name" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[{ required: true }]}
                  label="Armenian country name"
                  name="name_hy"
                >
                  <Input placeholder="Enter country name" />
                </Form.Item>
              </Col> */}
              <Col span={12}>
                <Form.Item rules={[{ required: true }]} label="English address" name="address_en">
                  <Input placeholder="Enter address" />
                </Form.Item>
              </Col>
              {/* <Col span={8}>
                <Form.Item
                  rules={[{ required: true }]}
                  label="Russian address"
                  name="address_ru"
                >
                  <Input placeholder="Enter address" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[{ required: true }]}
                  label="Armenian address"
                  name="address_hy"
                >
                  <Input placeholder="Enter address" />
                </Form.Item>
              </Col> */}
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!'
                    },
                    {
                      required: true,
                      message: 'Please input E-mail!'
                    }
                  ]}
                  label="Email"
                  name="email"
                >
                  <Input placeholder="Enter email"></Input>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      // eslint-disable-next-line no-useless-escape
                      pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                      message: 'Enter a phone number'
                    },
                    {
                      required: true,
                      message: 'Please input your phone number!'
                    }
                  ]}
                  label="Phone"
                  name="phone"
                >
                  <Input placeholder="Enter phone"></Input>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </>
  );
};

export default ContactDetails;
