import { RootState } from 'app/store';

export const selectOrdersLoading = () => (state: RootState) => state.orders.loading;

export const selectOrdersPage = () => (state: RootState) => state.orders.page;

export const selectOrdersCount = () => (state: RootState) => state.orders.count;

export const selectOrdersData = () => (state: RootState) => state.orders.orders;

export const selectOrdersSearch = () => (state: RootState) => state.orders.search;

export const selectOrdersLimit = () => (state: RootState) => state.orders.limit;

export const selectOrderById = () => (state: RootState) => state.orders.orderById;

export const selectOrderLoadingAction = () => (state: RootState) => state.orders.loadingAction;

export const selectOrderLoadingById = () => (state: RootState) => state.orders.loadingByID;

export const selectLoadingQuantity = () => (state: RootState) => state.orders.loadingQuantity;
