import { client } from 'api/apiClient';
import { ICategory } from 'app/features/category/types';
import { IProduct, IProductPrices } from 'app/features/products/types';
import { getCountryData } from 'assets/countries';

export type Option = {
  value: string | number;
  label: string;
  children?: Option[];
};

export const flattenCategories = (data: ICategory[]): Option[] =>
  data.map((element: ICategory) => ({
    label: `${element.title_en} (${element.key})`,
    value: element.id,
    children: element.children && flattenCategories(element.children)
  }));

export interface TransformedItem {
  [key: string]: any;
  percent1: number;
  percent2: number;
  percent3: number;
  percent4: number;
  margin: number;
  productId: number;
  createdAt: string;
  id: string;
  name: string;
}

export const transformArrayProducts = (arr: any[], dataIndex?: number): TransformedItem[] => {
  const transformedArr: TransformedItem[] = [];

  for (let i = 0; i < arr.length; i++) {
    const currentItem = arr[i];
    const currentType = currentItem.type;
    const currentTypeNumber = currentItem.type_number;
    const currentItemSource = currentItem.source;
    const isItemSpecial = currentItem.special;
    const discountCategoryTitle = currentItem?.discount_panel?.category?.title_en;

    const existingItem = transformedArr.find(item => item.name === currentType);

    if (existingItem) {
      existingItem[`percent${currentTypeNumber}`] = currentItem.percent;
      existingItem[`navy_price${currentTypeNumber}`] = currentItem.navy_price;
      existingItem[`plane_price${currentTypeNumber}`] = currentItem.plane_price;
      // existingItem[`amount_min${currentType}`] = currentItem.amount_min;
    } else {
      const newItem: any = {
        createdAt: currentItem.createdAt,
        id: currentItem.id,
        name: currentItem.type,
        price: currentItem.price,
        margin: currentItem.margin,
        source: currentItemSource,
        special: isItemSpecial,
        discountCategory: discountCategoryTitle
      };

      newItem[`percent${currentTypeNumber}`] = currentItem.percent;
      newItem[`navy_price${currentTypeNumber}`] = currentItem.navy_price;
      newItem[`plane_price${currentTypeNumber}`] = currentItem.plane_price;
      if (!Number.isNaN(dataIndex) && typeof dataIndex === 'number') newItem.index = dataIndex;

      transformedArr.push(newItem);
    }
  }

  return transformedArr;
};

export const getProductPrices = async (products: IProduct[]) => {
  const ids = products.reduce((acc: number[], item) => {
    acc.push(item.id);

    return acc;
  }, []);

  if (ids.length > 0) {
    const pricesRes = await client.post(`/product/checkProductPrices?currency=USD`, {
      product_id_array: ids
    });

    const newData = products.map(item => {
      const prodPrice: IProductPrices = pricesRes.data.data.find((i: { id: number }) => i.id === item.id);

      return {
        ...item,
        price: prodPrice.price,
        margin_price: prodPrice.margin_price,
        margin: prodPrice.margin,
        self_cost: prodPrice.self_cost,
        percent: prodPrice.percent
      };
    });

    return newData;
  } else {
    return [];
  }
};

export function sortingArrayByKey(array: any[], key?: string) {
  array.sort((a, b) => {
    const itemA = key ? Number(a?.[key]) : a;
    const itemB = key ? Number(b?.[key]) : b;
    if (itemA < itemB) {
      return -1;
    } else if (itemA > itemB) {
      return 1;
    } else {
      return 0;
    }
  });

  return array;
}

export const findCountryCode = (countryId: number) => {
  return (countryId && getCountryData().find((country: { id: number }) => country.id === countryId)?.code3l) || '';
};
