import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api/apiClient';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { message } from 'antd';

import { selectCurrencyLimit, selectCurrencyPage, selectCurrencySearch } from './selectors';
import { CurrencyState, EditCurrency, PostCurrency } from './type';

const initialState: CurrencyState = {
  currency: [],
  currencyById: null,
  loading: false,
  loadingAction: false,
  loadingByID: false,
  search: '',
  page: 1,
  limit: 10,
  count: 0
};

export const currencyApi = createAsyncThunk('currency/currencyApi', async (manualLimit: number | undefined, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState;
    const search = selectCurrencySearch()(state);
    const page = selectCurrencyPage()(state);
    const limit = selectCurrencyLimit()(state);
    const res = await client.get('/currency', {
      params: {
        search,
        page,
        limit: manualLimit ? manualLimit : limit
      }
    });

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const getCurrencyById = createAsyncThunk('currency/getCurrencyById', async (id: string, { rejectWithValue }) => {
  try {
    const response = await client.get(`/currency/${id}`);

    return response.data;
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const deleteCurrency = createAsyncThunk('currency/deleteCurrency', async (currencyCode: string, { dispatch, rejectWithValue }) => {
  try {
    const response = await client.delete(`/currency/${currencyCode}`);

    if (response.status !== 200) {
      throw new Error(`Failed to delete currency: ${response.status}`);
    }

    message.success('Currency deleted successfully');
    dispatch(currencyApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const postCurrency = createAsyncThunk('currency/postCurrency', async ({ ...data }: PostCurrency, { dispatch, rejectWithValue }) => {
  try {
    const response = await client.post('/currency', {
      ...data
    });

    message.success('Currency Updated successfully');
    dispatch(currencyApi());

    return response.data.data;
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const editCurrency = createAsyncThunk('currency/editCurrency', async ({ currencyCode, ...data }: EditCurrency, { dispatch, rejectWithValue }) => {
  try {
    const response = await client.patch(`/currency/${currencyCode}`, {
      ...data
    });

    if (response.status !== 200) {
      throw new Error(`Failed to edit currency: ${response.status}`);
    }

    message.success('Currency Updated successfully');

    await dispatch(currencyApi());

    return response.data.data;
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(currencyApi.pending, state => {
        state.loading = true;
      })
      .addCase(currencyApi.fulfilled, (state, action) => {
        state.loading = false;
        state.currency = action.payload.rows;
        state.count = action.payload.count;
      })
      .addCase(currencyApi.rejected, state => {
        state.loading = false;
      })
      .addCase(deleteCurrency.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteCurrency.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteCurrency.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(getCurrencyById.pending, state => {
        state.loadingByID = true;
      })
      .addCase(getCurrencyById.fulfilled, (state, action) => {
        state.currencyById = action.payload;
        state.loadingByID = false;
      })
      .addCase(getCurrencyById.rejected, state => {
        state.loadingByID = false;
      })
      .addCase(postCurrency.pending, state => {
        state.loadingAction = true;
      })
      .addCase(postCurrency.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(postCurrency.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(editCurrency.pending, state => {
        state.loadingAction = true;
      })
      .addCase(editCurrency.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(editCurrency.rejected, state => {
        state.loadingAction = false;
      });
  }
});

export const { setPage, setSearch, setLimit } = currencySlice.actions;
export default currencySlice.reducer;
