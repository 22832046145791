import { createSlice, createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
const initialValue = localStorage.getItem('selectedCurrency') + '';

const initialState = {
  currencyCode: initialValue
};

const selectedcurrencySlice = createSlice({
  name: 'selectedcurrency',
  initialState,
  reducers: {
    setSelect: (state, action) => {
      state.currencyCode = action.payload;
    }
  }
});

export const selectSelectedCurrency = createSelector(
  (state: RootState) => state.selectedcurrencyslice.currencyCode,
  (currencyCode: string) => currencyCode
);

export const { setSelect } = selectedcurrencySlice.actions;
export default selectedcurrencySlice.reducer;
