import { RootState } from 'app/store';

import { IFile } from './types';
import { BASE_URL } from '../../../api/apiClient';

export const selectImagesData = () => (state: RootState) => state.images.images;

export const selectImages = () => (state: RootState) => {
  const imagesData = selectImagesData()(state);

  return (
    imagesData?.map((item: IFile) => {
      const url = `${BASE_URL}/uploads/page_image/${item.name}.${item.extension}`;

      return {
        uid: item.id,
        name: `${item.name}`,
        status: 'done',
        url
      };
    }) || []
  );
};

export const selectImagesLoading = () => (state: RootState) => state.images.loading;

export const selectImagesLoadingAction = () => (state: RootState) => state.images.loadingAction;

export const selectImagesSearch = () => (state: RootState) => state.images.search;

export const selectImagesPage = () => (state: RootState) => state.images.page;

export const selectImagesLimit = () => (state: RootState) => state.images.limit;

export const selectImagesCount = () => (state: RootState) => state.images.count;
