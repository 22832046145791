import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import styled from 'styled-components';

export const TrueIcon = styled(CheckCircleOutlined)`
  font-size: 20px;
  margin: 2px 0 0 2px;
`;

export const FalseIcon = styled(CloseCircleOutlined)`
  font-size: 20px;
  margin: 2px 0 0 2px;
`;

export const ContentCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconWrapper = styled(Tag)`
  margin: 8px 0 0 0;
  width: fit-content;

  span {
    margin: 0;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;

  .navyPriceWrapper {
    width: 100%;
    margin-bottom: 16px;
  }

  .planePriceWrapper {
    width: 100%;
  }

  .navyPriceWrapper,
  .planePriceWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
`;

export const ImgTableWrapper = styled.div`
  overflow: hidden;
  .ant-image,
  .ant-image-mask {
    border-radius: 6px;
  }

  .ant-image img {
    border-radius: 6px;
  }
`;

export const StyledPopupDiv = styled.div`
  text-align: center;
`;

export const CountryName = styled.h3`
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
  margin-left: 20px;
`;

export const StatCategory = styled.p`
  margin-bottom: 4px;
  font-weight: bold;
  color: #666;
  margin-left: 40px;
`;

export const StatValue = styled.p<{ zeroValue?: boolean }>`
  margin-bottom: 8px;
  color: ${props => (props.zeroValue ? 'red' : '#999')};
  margin-left: 60px;
`;

export const TableWrapper = styled.div``;

export const PricesWrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  gap: 8px;
`;

export const PricingInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 8px 0;

  .priceType {
    font-weight: 700;
  }
`;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;
