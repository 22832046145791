import { Button, Space, Tag } from 'antd';
import { FC } from 'react';

import { HeaderDivider, LeftPage, PageHeader, PageTitle, ProductTitle } from './styled';
import { IDetailsHeadProps } from './types';

const DetailsHead: FC<IDetailsHeadProps> = ({ titleItem, onClose, id, headTitle, loadingAction, form }) => {
  return (
    <>
      <PageHeader>
        <Space>
          <LeftPage onClick={onClose} />

          <PageTitle level={3}>{id ? `Edit ${headTitle}` : `Add ${headTitle}`}</PageTitle>
          {id && titleItem && (
            <Tag color="green">
              <ProductTitle strong>{titleItem}</ProductTitle>
            </Tag>
          )}
        </Space>
        <Button style={{ width: '200px' }} loading={loadingAction} type="primary" onClick={form.submit}>
          Save
        </Button>
      </PageHeader>

      <HeaderDivider />
    </>
  );
};

export default DetailsHead;
