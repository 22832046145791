import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api/apiClient';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';

import { AdminsState } from './types';
import { selectAdminsLimit, selectAdminsPage, selectAdminsSearch } from './selectors';

const initialState: AdminsState = {
  admins: [],
  loading: false,
  page: 1,
  search: '',
  limit: 10,
  count: 0
};

export const adminsApi = createAsyncThunk('users/adminsApi', async (_, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState;
    const page = selectAdminsPage()(state);
    const search = selectAdminsSearch()(state);
    const limit = selectAdminsLimit()(state);

    const res = await client.get(`/user/admins`, {
      params: {
        search,
        page,
        limit
      }
    });

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

const adminsSlice = createSlice({
  name: 'admins',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    clickMenuAdmin: state => {
      (state.limit = 10), (state.page = 1), (state.search = '');
    }
  },
  extraReducers(builder) {
    builder
      .addCase(adminsApi.pending, state => {
        state.loading = true;
      })
      .addCase(adminsApi.fulfilled, (state, action) => {
        state.admins = action.payload.rows;
        state.count = action.payload.count;
        state.loading = false;
      })
      .addCase(adminsApi.rejected, state => {
        state.loading = false;
      });
  }
});

export const { setPage, setSearch, setLimit, clickMenuAdmin } = adminsSlice.actions;
export default adminsSlice.reducer;
