/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Button, Form, FormInstance, Input, Modal, Select, Space, message } from 'antd';
import { EditOutlined, CheckOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { deleteCurrency, editCurrency, getCurrencyById, postCurrency } from 'app/features/currency/slice';
import { getCountryData } from 'assets/countries';
import { DefaultOptionType } from 'antd/es/select';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectCurrencyLoadingAction } from 'app/features/currency/selectors';
import { CUrrency, PostCurrency } from 'app/features/currency/type';

interface Props {
  open: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  form: FormInstance<any>;
  editMode: boolean;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const CurrencyModal: React.FC<Props> = ({ open, form, setEditMode, editMode, setIsOpen }) => {
  const [selectedCountry, setSelectedCountry] = useState<CUrrency | null>(null);

  const onClose = () => {
    setIsOpen(false);
    setSelectedCountry(null);
    form.resetFields();
  };

  const countryData = getCountryData();
  const loading = useAppSelector(selectCurrencyLoadingAction());

  const dispatch = useAppDispatch();

  const handleCurrencySelect = async (option: DefaultOptionType) => {
    const res = await dispatch(getCurrencyById(String(option.value)));

    if (res.payload.data) {
      setEditMode(true);
      setSelectedCountry(res.payload.data);
      form.setFieldsValue({
        price: res.payload.data.conversionRate
      });
    } else {
      setSelectedCountry(null);
      setEditMode(false);
      form.setFieldsValue({
        price: ''
      });
    }
  };

  const onFinish = async () => {
    const values: PostCurrency = form.getFieldsValue();
    const { currency, price } = values;
    if (!price || !currency) return message.error('Price or currency can not be empty');

    const dataProduct = {
      currencyCode: currency,
      conversionRate: price
    };

    try {
      if (editMode) {
        await dispatch(editCurrency({ ...dataProduct }));
      } else {
        const res = await dispatch(postCurrency(dataProduct));

        if (res.payload) {
          setEditMode(true);
        }
      }
    } catch (error) {
      message.error(`An error occurred: ${error}`, 2);
    }
  };

  return (
    <Modal title="Create Currency Rate" width={'70%'} open={open} onCancel={onClose} footer={null}>
      <Space.Compact style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Form.Item name="currency" rules={[{ required: false }]} style={{ width: '40%' }}>
          <Select showSearch loading={loading} onSelect={(_, option) => handleCurrencySelect(option)} placeholder="Select Currency" optionLabelProp="label" optionFilterProp="label">
            {countryData.map(country => {
              if (country.name && country.currency) {
                return (
                  <Select.Option key={country.id} value={country.currency} label={country.currency} country={country}>
                    {`${country.name}: ${country.currency}`}{' '}
                  </Select.Option>
                );
              }

              return null;
            })}
          </Select>
        </Form.Item>

        <Form.Item name="price" rules={[{ required: true }]}>
          <Input type="number" min={0.1} name="price" />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            loading={loading}
            onClick={() => {
              Modal.confirm({
                title: 'Confirm Currency Rate Change',
                content: 'Are you sure you want to change the Currency Rate?',
                onOk: () => {
                  onFinish();
                }
              });
            }}
            block
            icon={editMode ? <EditOutlined /> : <CheckOutlined />}
          >
            {editMode ? 'Update Rate' : 'Create Rate'}
          </Button>
          {!!editMode && (
            <MinusCircleOutlined
              style={{
                position: 'fixed',
                margin: '6px',
                color: 'red',
                fontSize: '19px'
              }}
              onClick={() => {
                Modal.confirm({
                  title: 'Confirm',
                  content: `Are you sure you want to remove selected Country Rate? `,
                  onOk: async () => {
                    if (!selectedCountry) return message.error('No country selected');
                    dispatch(deleteCurrency(selectedCountry.currencyCode));

                    form.setFieldsValue({
                      price: ''
                    });
                  }
                });
              }}
            />
          )}
        </Form.Item>
      </Space.Compact>
    </Modal>
  );
};

export default CurrencyModal;
