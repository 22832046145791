import { setSubscribeLimit, setSubscribePage, subscribeUserApi } from 'app/features/users/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { selectSubscribeCount, selectSubscribeData, selectSubscribeLimit, selectSubscribePage, selectUsersLoading } from 'app/features/users/selectors';
import { ColumnsType } from 'antd/es/table';
import { Table, message } from 'antd';
import { ISubscribeUser } from 'app/features/users/types';

import ContentTop from 'components/ContentTop';
import { CopyStyled, HeaderWrraper, LeftPage } from './styled';

const SubscribeUsers = () => {
  // dispatch
  const dispatch = useAppDispatch();

  //navigate
  const navigate = useNavigate();

  //selectors
  const loading = useAppSelector(selectUsersLoading());
  const page = useAppSelector(selectSubscribePage());
  const count = useAppSelector(selectSubscribeCount());
  const subscribeUsersData = useAppSelector(selectSubscribeData());
  const limit = useAppSelector(selectSubscribeLimit());

  useEffect(() => {
    dispatch(subscribeUserApi());
  }, [dispatch, page, limit]);

  const handleCopy = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, email: string) => {
    event.preventDefault();
    navigator.clipboard.writeText(email);
    message.success(`${email} copied`);
  };

  const columns: ColumnsType<ISubscribeUser> = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text: string) => {
        return (
          <>
            <a href={`mailto: ${text}`}>{text}</a>
          </>
        );
      }
    },
    {
      title: '',
      dataIndex: 'copy',
      key: 'copy',
      width: 50,
      render: (_, record) => {
        return <CopyStyled onClick={event => handleCopy(event, record.email)} />;
      }
    }
  ];

  return (
    <>
      <HeaderWrraper>
        <LeftPage onClick={() => navigate(-1)} />
        <ContentTop title="Subscribed Users" />
      </HeaderWrraper>
      <Table
        loading={loading}
        bordered
        columns={columns}
        dataSource={subscribeUsersData}
        rowKey="id"
        pagination={{
          current: page,
          onChange: (page, pageSize) => {
            dispatch(setSubscribePage(page));
            dispatch(setSubscribeLimit(pageSize));
          },
          total: count,
          pageSize: limit
        }}
      />
    </>
  );
};

export default SubscribeUsers;
