import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client, clientBIG } from 'api/apiClient';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';

import { CategoryState, CategoryEditParams, CategoryAddParams, AddChildsInCategoryParams, ICategory, PostCategoryDiscountValues } from './types';

const initialState: CategoryState = {
  category: [],
  filterCategoryData: [],
  categoryById: null,
  loading: false,
  discountByCategory: [],
  loadingAction: false,
  loadingById: false,
  count: 0,
  search: '',
  page: 1,
  limit: 10,
  discounAmountByType: []
};

export const categoryApi = createAsyncThunk('category/categoryApi', async (_, { rejectWithValue }) => {
  try {
    const res = await clientBIG.post(`/api/v1/category/explore`, {});

    return res.data.payload;
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const categoryProductApi = createAsyncThunk('category/categoryProductApi', async (_, { rejectWithValue }) => {
  try {
    const res = await client.get('/category');

    return res.data.data;
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const filterApi = createAsyncThunk('category/filterApi', async (_, { rejectWithValue }) => {
  try {
    const res = await client.get('/filter');

    return res.data.data;
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const categoryEdit = createAsyncThunk('category/categoryEdit', async ({ id, ...data }: CategoryEditParams, { dispatch, rejectWithValue }) => {
  try {
    await client.patch(`/category/${id}`, {
      ...data
    });

    return dispatch(categoryApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const addChildsInCategoryApi = createAsyncThunk('category/addChildsInCategory', async ({ id, childrens }: AddChildsInCategoryParams, { dispatch, rejectWithValue }) => {
  try {
    await client.post(`/category/storeParent/${id}`, {
      childrens
    });

    return dispatch(categoryApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const removeFromParentApi = createAsyncThunk('category/removeFromParentApi', async ({ ...data }: ICategory, { dispatch, rejectWithValue }) => {
  try {
    await client.patch(`/category/${data.id}`, {
      parentId: null
    });

    return dispatch(categoryApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const getCategoryById = createAsyncThunk('category/categoryById', async (id: any, { rejectWithValue }) => {
  try {
    const res = await clientBIG.get(`/api/v1/category/${id}`);

    return res.data.payload;
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const postCategory = createAsyncThunk('category/postCategory', async ({ parentId, ...data }: CategoryAddParams, { dispatch, rejectWithValue }) => {
  try {
    await client.post('/category', {
      ...data,
      parentId
    });

    return dispatch(categoryApi());
  } catch (err) {
    return rejectWithValue(err as AxiosError<ErrorType>);
  }
});

export const getDiscountByCategory = createAsyncThunk('category/getDiscountByCategory', async (id: any, { rejectWithValue }) => {
  try {
    const res = await client.get(`/discount_panel/category/${id}`);

    return res.data.data;
  } catch (err) {
    return rejectWithValue(err as AxiosError<ErrorType>);
  }
});
export const getDiscountAmountsByType = createAsyncThunk('discounts/getDiscountAmountsByType', async (name: string, { rejectWithValue }) => {
  try {
    const res = await client.get(`/discount_panel/amounts/${name}`);

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});
export const postDiscountByCategory = createAsyncThunk('category/postDiscountByCategory', async (data: PostCategoryDiscountValues, { dispatch, rejectWithValue }) => {
  try {
    const res = await client.post(`/discount_panel`, {
      ...data
    });

    dispatch(getDiscountByCategory(res.data.data.rows.categoryId));

    return res.data.data;
  } catch (err) {
    return rejectWithValue(err as AxiosError<ErrorType>);
  }
});

export const deleteCategory = createAsyncThunk('category/deleteCategory', async (id: number, { dispatch, rejectWithValue }) => {
  try {
    await client.delete(`/category/${id}`);

    return dispatch(categoryApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const deleteCategoryDiscount = createAsyncThunk('category/deleteCategoryDiscount', async (id: string, { rejectWithValue }) => {
  try {
    const res = await client.delete(`/discount_panel/${id}`);

    return res.data.data;
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    clickMenuCategory: state => {
      (state.limit = 10), (state.page = 1), (state.search = '');
    },
    clickMenuCalculator: state => {
      (state.limit = 10), (state.page = 1), (state.search = '');
    }
  },
  extraReducers(builder) {
    builder
      .addCase(categoryApi.pending, state => {
        state.loading = true;
      })
      .addCase(categoryApi.fulfilled, (state, action) => {
        state.category = action.payload?.rows;
        state.count = action.payload?.count;
        state.loading = false;
      })
      .addCase(categoryApi.rejected, state => {
        state.loading = false;
      })
      .addCase(categoryProductApi.fulfilled, (state, action) => {
        state.category = action.payload?.rows;
      })
      .addCase(getCategoryById.pending, state => {
        state.loadingById = true;
      })
      .addCase(getCategoryById.fulfilled, (state, action) => {
        state.categoryById = action.payload;
        state.loadingById = false;
      })
      .addCase(getCategoryById.rejected, state => {
        state.loadingById = false;
      })
      .addCase(categoryEdit.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(categoryEdit.pending, state => {
        state.loadingAction = true;
      })
      .addCase(categoryEdit.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(postCategory.pending, state => {
        state.loadingAction = true;
      })
      .addCase(postCategory.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(postCategory.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(deleteCategory.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteCategory.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteCategory.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(filterApi.fulfilled, (state, action) => {
        state.filterCategoryData = action.payload;
      })
      .addCase(getDiscountAmountsByType.fulfilled, (state, action) => {
        state.discounAmountByType = action.payload;
      })
      .addCase(getDiscountByCategory.pending, state => {
        state.loadingAction = true;
      })
      .addCase(getDiscountByCategory.fulfilled, (state, action) => {
        state.discountByCategory = action.payload;
      })
      .addCase(getDiscountByCategory.rejected, state => {
        state.loadingAction = false;
      });
  }
});

export const { setPage, setSearch, setLimit, clickMenuCategory, clickMenuCalculator } = categorySlice.actions;
export default categorySlice.reducer;
