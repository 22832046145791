import { RootState } from 'app/store';

export const selectAdminsPage = () => (state: RootState) => state.admins.page;

export const selectAdminsData = () => (state: RootState) => state.admins.admins;
export const selectAdminsCount = () => (state: RootState) => state.admins.count;
export const selectAdminsLoading = () => (state: RootState) => state.admins.loading;

export const selectAdminsSearch = () => (state: RootState) => state.admins.search;

export const selectAdminsLimit = () => (state: RootState) => state.admins.limit;
