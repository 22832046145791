import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api/apiClient';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage, showErrorUploadMessage } from 'helpers/errors';
import { Modal } from 'antd';

import { selectAttributeLimit, selectAttributePage, selectAttributeSearch } from './selectors';
import { AttributesState, IAttributeEditValues, IAttributePostValues } from './types';

const initialState: AttributesState = {
  attribute: [],
  attributeById: null,
  loading: false,
  loadingAction: false,
  loadingById: false,
  page: 1,
  limit: 10,
  search: '',
  count: 0
};

export const attributeApi = createAsyncThunk('attributes/attributeApi', async (_, { getState, dispatch, rejectWithValue }) => {
  const state = getState() as RootState;
  const page = selectAttributePage()(state);
  const search = selectAttributeSearch()(state);
  const limit = selectAttributeLimit()(state);

  try {
    const res = await client.get(`/attribute`, {
      params: {
        search,
        page,
        limit
      }
    });

    return dispatch(setAtribute(res.data.data));
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const attributeApiProduct = createAsyncThunk('attributes/attributeApiProduct', async (_, { rejectWithValue }) => {
  try {
    const res = await client.get('/attribute');

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const getAttributeById = createAsyncThunk('attribute/attributeById', async (id: number, { dispatch, rejectWithValue }) => {
  try {
    const response = await client.get(`/attribute/attribute/${id}`);

    return dispatch(setAtributeById(response.data.data));
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const postAttributes = createAsyncThunk('attribute/postAttributes', async (data: IAttributePostValues, { dispatch, rejectWithValue }) => {
  try {
    await client.post('/attribute', { ...data });

    return dispatch(attributeApi());
  } catch (err) {
    return rejectWithValue(err as AxiosError<ErrorType>);
  }
});

export const attributesEdit = createAsyncThunk('attribute/attributesEdit', async ({ id, ...data }: IAttributeEditValues, { dispatch, rejectWithValue }) => {
  try {
    await client.patch(`/attribute/${id}`, { ...data });

    await dispatch(attributeApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const deleteAttribute = createAsyncThunk('attribute/deleteAttribute', async (id: number, { dispatch, rejectWithValue }) => {
  try {
    await client.delete(`/attribute/${id}`);
    await dispatch(attributeApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const handleUploadAttr = createAsyncThunk('attribute/handleUploadAttr', async (file: File, { dispatch, rejectWithValue }) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    await client.post('attribute/excel', formData).then(() => {
      Modal.success({
        content: 'Excel file uploaded successfully!'
      });
    });
    await dispatch(attributeApi());
  } catch (err) {
    return rejectWithValue(showErrorUploadMessage(err as AxiosError<ErrorType>));
  }
});
const attributeSlice = createSlice({
  name: 'attribute',
  initialState,
  reducers: {
    setAtributeById: (state, action) => {
      state.attributeById = action.payload;
    },
    setAtribute: (state, action) => {
      state.attribute = action.payload.rows;
      state.count = action.payload.count;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    clickMenuAttributes: state => {
      (state.limit = 10), (state.page = 1), (state.search = '');
    }
  },
  extraReducers(builder) {
    builder
      .addCase(attributeApi.pending, state => {
        state.loading = true;
      })
      .addCase(attributeApi.fulfilled, state => {
        state.loading = false;
      })
      .addCase(attributeApi.rejected, state => {
        state.loading = false;
      })
      .addCase(getAttributeById.pending, state => {
        state.loadingById = true;
      })
      .addCase(getAttributeById.fulfilled, state => {
        state.loadingById = false;
      })
      .addCase(getAttributeById.rejected, state => {
        state.loadingById = false;
      })
      .addCase(attributesEdit.pending, state => {
        state.loadingAction = true;
      })
      .addCase(attributesEdit.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(attributesEdit.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(postAttributes.pending, state => {
        state.loadingAction = true;
      })
      .addCase(postAttributes.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(postAttributes.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(attributeApiProduct.fulfilled, (state, action) => {
        state.attribute = action.payload.rows;
      })
      .addCase(deleteAttribute.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteAttribute.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteAttribute.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(handleUploadAttr.pending, state => {
        state.loading = true;
      })
      .addCase(handleUploadAttr.fulfilled, state => {
        state.loading = false;
      })
      .addCase(handleUploadAttr.rejected, state => {
        state.loading = false;
      });
  }
});

export const { setAtributeById, setAtribute, setPage, setSearch, setLimit, clickMenuAttributes } = attributeSlice.actions;
export default attributeSlice.reducer;
