import { EditOutlined } from '@ant-design/icons';
import { Image, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { categoryApi, deleteCategory, getCategoryById, setLimit, setPage } from 'app/features/category/slice';
import { CategoryId, ICategory } from 'app/features/category/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectCategoryActionLoading,
  selectCategoryCount,
  selectCategoryData,
  selectCategoryLimit,
  selectCategoryLoading,
  selectCategoryPage,
  selectCategorySearch
} from 'app/features/category/selectors';
import { useEffect, useState } from 'react';
import { _debounce } from 'helpers/debounce';
import { Link } from 'react-router-dom';
import { getFileUrl } from 'helpers/file';

import ContentTop from 'components/ContentTop';
import { ButtonsWrapper, ImgTableWrraper } from './styled';
import DeleteModal from 'components/DeleteModal';
import noImg from '../../assets/img/noimage.png';

const debounce = _debounce();

const Categories = () => {
  // states
  const [categoryId, setCategoryId] = useState<CategoryId>(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  const categoryData = useAppSelector(selectCategoryData());
  const loading = useAppSelector(selectCategoryLoading());
  const page = useAppSelector(selectCategoryPage());
  const limit = useAppSelector(selectCategoryLimit());
  const count = useAppSelector(selectCategoryCount());
  const search = useAppSelector(selectCategorySearch());
  const loadingAction = useAppSelector(selectCategoryActionLoading());

  const onEditCategory = (id: CategoryId) => {
    dispatch(getCategoryById(id));
    setCategoryId(id);
  };

  useEffect(() => {
    debounce(() => dispatch(categoryApi()));
  }, [dispatch, search, page, limit]);

  const columns: ColumnsType<ICategory> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'Title',
      render: (_, record) => {
        return <p style={!record.parent_id ? { fontWeight: 'bold' } : {}}>{record.title_en}</p>;
      }
    },
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key'
    },
    {
      title: 'Advertisement',
      dataIndex: 'file',
      key: 'Advertisement',
      width: 100,
      render: (_, item) => {
        return (
          <ImgTableWrraper>
            {item.file?.length ? (
              <Image height={55} width={70} alt={item.file[0].name} src={getFileUrl(item.file[0]?.name, item.file[0]?.extension, 'category')} />
            ) : (
              <Image preview={false} width={70} src={noImg} alt="No Image" />
            )}
          </ImgTableWrraper>
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'Action',
      align: 'center',
      width: 150,
      render: (_, record) => {
        return (
          <ButtonsWrapper>
            <Link
              className="edit"
              to={`details/${record.id}`}
              onClick={() => {
                onEditCategory(record.id);
              }}
            >
              <EditOutlined />
              Edit
            </Link>
          </ButtonsWrapper>
        );
      }
    }
  ];

  return (
    <>
      <ContentTop title="Categories" placeholder="Search Category" onClickAdd={() => (location.pathname = 'categories/details')} />
      <Table
        bordered
        pagination={{
          current: page,
          onChange: (page, pageSize) => {
            dispatch(setPage(page));
            dispatch(setLimit(pageSize));
          },
          total: count,
          pageSize: limit
        }}
        loading={loading}
        columns={columns}
        dataSource={categoryData}
        rowKey="id"
      />

      <DeleteModal
        open={openDeleteModal}
        confirmLoading={loadingAction}
        onOk={() => {
          dispatch(deleteCategory(categoryId)).then(() => {
            setOpenDeleteModal(false);
            setCategoryId(0);
          });
        }}
        onCancel={() => {
          setOpenDeleteModal(false);
          setCategoryId(0);
        }}
      />
    </>
  );
};

export default Categories;
