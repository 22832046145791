import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AddProductContainer = styled.div`
  width: 100%;
  margin-bottom: 12px;
  display: flex;
  justify-content: flex-end;
`;
