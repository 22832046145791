import styled, { css } from 'styled-components';

export const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-block: 20px;
  margin-bottom: 20px;
`;
export const StyledContainer = styled.div`
  .journalTableWrapper {
    margin-top: 16px;
  }

  .journalTableTitle {
    margin: 8px 0;
    font-weight: 600;
    font-size: 14px;
  }

  .header {
    margin: 8px 0;
    border-radius: 8px;
    height: 55px;
    gap: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0 10px;
    //border-radius: 3px;
    //border: 1px solid #d1d3d4;
    background: rgba(209, 211, 212, 0.3);
    div {
      display: flex;
      gap: 10px;
    }
    .headPrice {
      display: flex;
      align-items: center;
      span {
        font-weight: 500;
        font-size: 16px;
      }
    }
    .arrow {
      transform: rotate(90deg);
    }
  }
`;

export const StyledCountryPriceDrop = styled.div<{ open: boolean }>`
  transform-origin: top;
  border-top: ${({ open }) => (open ? 'none' : '1px solid #d1d3d4')};
  //border: 1px solid #d1d3d4;
  justify-content: space-between;
  width: 100%;

  td {
    border: none;
  }

  .dropHeader {
    display: flex;
    justify-content: space-between;
    //border-bottom: 1px solid rgba(209, 211, 212, 0.5);
    padding: 10px 40px;
  }

  .dropBody {
    display: flex;
    justify-content: space-between;
    padding: 10px 40px;

    p {
      //border-right: 1px solid rgba(209, 211, 212, 0.5);
    }
  }

  .b {
    font-weight: 700;
  }

  ${({ open }) =>
    open
      ? css`
          transition: 0.5s;
          height: max-content;
          transform: scaleY(1);
        `
      : css`
          transition: 0.5s;
          height: 0;
          transform: scaleY(0);
        `}
`;
interface MarginInputsProps {
  addMargin?: boolean;
}

export const StyledMarginInputs = styled.div<MarginInputsProps>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;

  .flexRow {
    display: flex;
    align-items: center;
    margin-top: 16px;
  }

  .priceInfoCombineVertical {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
  }

  .priceInfoCombine {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0 4px;
  }

  .navyPriceWrapper,
  .planePriceWrapper {
    border: 1px solid rgba(5, 5, 5, 0.06);
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 8px 10px;
    display: block;
    box-sizing: border-box;
    text-align: left;
  }

  .textLeft {
    justify-content: flex-start;
    text-align: left;
  }

  span {
    margin-left: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex-shrink: 0;
    font-size: 12px;
  }

  input {
    text-overflow: ellipsis;
    border: 1px solid rgba(5, 5, 5, 0.06);
    padding: 10px 20px;
    width: 100%;
    margin-right: 5px;
    font-size: 12px;
  }

  div {
    display: flex;
    align-items: center;
    color: #19293b;
    text-align: center;
    font-weight: 500;
    justify-content: center;
    width: 100%;
    height: 38px;
    //border-radius: 0px 3px 3px 0px;
  }
`;

export const AvailabilityWrapper = styled.div<MarginInputsProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;

  > div {
    margin-top: 30px;
  }
`;
