import { Dispatch, FC, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectUserOrderAddresses } from 'app/features/users/selectors';
import { useParams } from 'react-router-dom';
import { Button, Modal } from 'antd';
import { addressMakeDefault, orderLoading, postOrderUserApi } from 'app/features/users/slice';

import { AddressesCart, AddressesCartContainer, StyledModal } from '../styled';

interface IProps {
  open: boolean;
  handleModal: Dispatch<React.SetStateAction<boolean>>;
  setShowAddressesModal: Dispatch<React.SetStateAction<boolean>>;
}

const MakeOrderModal: FC<IProps> = ({ open, handleModal, setShowAddressesModal }) => {
  const orderAddresses = useAppSelector(selectUserOrderAddresses());
  const [selectedAddressId, setSelectedAddressId] = useState<string>('');
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const modalRef = useRef<HTMLDivElement | null>(null);

  const [showDefaultAddressModal, setShowDefaultAddressModal] = useState<boolean>(false);

  const handleMakeDefault = () => {
    if (selectedAddressId && id) {
      dispatch(
        addressMakeDefault({
          id: selectedAddressId,
          data: {
            user_id: id,
            is_active: true
          }
        })
      );
      setShowDefaultAddressModal(false);
    }
  };

  const createOrderApi = () => {
    if (orderAddresses.length === 0) {
      setShowAddressesModal(true);
    } else {
      if (id) {
        dispatch(postOrderUserApi(id));
        dispatch(orderLoading(true));
        handleModal(false);
      }
    }
  };

  return (
    <StyledModal style={{ overflow: 'auto', maxHeight: '790px' }} ref={modalRef} active={open}>
      {orderAddresses.length > 0 && <p style={{ fontSize: '20px' }}>Order Addresses</p>}
      <span
        className="close"
        onClick={() => handleModal(false)}
        style={{
          position: 'absolute',
          top: '16px',
          right: '16px',
          fontSize: '20px',
          border: '1px solid black',
          borderRadius: '50%',
          padding: '5px',
          width: '30px',
          height: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer'
        }}
      >
        X
      </span>
      <AddressesCartContainer>
        <div
          style={{
            width: 'max-content',
            margin: '30px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <div
            className="addNewAddress"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
            onClick={() => setShowAddressesModal(true)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80ZM42.1429 42.1429H52.8571C54.0357 42.1429 55 41.1786 55 40C55 38.8214 54.0357 37.8571 52.8571 37.8571H42.1429V27.1429C42.1429 25.9643 41.1786 25 40 25C38.8214 25 37.8571 25.9643 37.8571 27.1429V37.8571H27.1429C25.9643 37.8571 25 38.8214 25 40C25 41.1786 25.9643 42.1429 27.1429 42.1429H37.8571V52.8571C37.8571 54.0357 38.8214 55 40 55C41.1786 55 42.1429 54.0357 42.1429 52.8571V42.1429Z"
                fill="#00909E"
              />
            </svg>
            <p className="newAddressText">Add new address</p>
          </div>
        </div>
        {orderAddresses?.map((address, index) => (
          <AddressesCart active={address.is_active} key={index}>
            {address.is_active && <p className="title">Default Address</p>}
            <p className="title">
              {address?.first_name} {address?.last_name}
            </p>
            <div className="address">
              <p>
                {address.city}, {address.country} {address.zip_code}
              </p>
              <p>{address.address}</p>
              <p>{address.phone_number}</p>
              <p>{address.email}</p>
            </div>
            {!address.is_active && (
              <>
                <p
                  className="makeDefBtn"
                  onClick={() => {
                    setSelectedAddressId(address.id);
                    setShowDefaultAddressModal(true);
                  }}
                >
                  Make Default
                </p>
              </>
            )}
          </AddressesCart>
        ))}
      </AddressesCartContainer>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={() => createOrderApi()} type="primary">
          Save
        </Button>
      </div>
      <Modal open={showDefaultAddressModal} title="Make Default?" onOk={handleMakeDefault} onCancel={() => setShowDefaultAddressModal(false)}></Modal>
    </StyledModal>
  );
};

export default MakeOrderModal;
