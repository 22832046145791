import styled from 'styled-components';

import rightArrow from '../../../../../assets/img/arrowRight.png';
import leftArrow from '../../../../../assets/img/arrowLeft.png';

export const ImgSlider = styled.div`
  border: 0.5px solid #d1d3d4;
  margin: auto;
  position: relative;

  .slick-slider {
    .slick-list {
      overflow: hidden;
      height: 438px;
      .slick-track {
        display: flex;
        height: 100%;
        .slick-current > div {
          height: 100%;
          & > div {
            height: 100%;
            & > div {
              height: 100% !important;
              img {
                object-fit: contain !important;
              }
              & > img {
                width: 100%;
                height: 100% !important;
                object-fit: contain !important;
              }
              div {
                width: 100% !important;
                height: 100% !important;
              }
            }
          }
        }
        .slick-slide {
          height: 100%;
          div {
            height: 100%;
            img {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }
  }
`;

export const ImgComponentSlider = styled.div`
  width: 100%;
  margin: 25px auto 0 auto;
  .slick-slider {
    .slick-prev {
      left: 0;
      width: 25px;
      height: 25px;
    }
    .slick-next {
      width: 25px;
      height: 25px;
      right: 0;
    }
    .slick-prev:before,
    .slick-next:before {
      opacity: 1;
    }
    .slick-prev.slick-disabled:before,
    .slick-next.slick-disabled:before {
      opacity: 0.25 !important;
    }
    .slick-list {
      width: calc(125px * 4);
      margin: auto;
      .slick-track {
        .slick-current {
          border: 1px solid var(--primary) !important;
        }
        div.slick-slide {
          margin: 0 7px;
          min-width: 110px;
          width: 110px !important;
          border: 1px solid var(--gray);
          height: 90px;

          div {
            height: 100%;
            img {
              height: 100%;
              width: 100%;
              object-fit: contain;
              cursor: pointer;
              border: 1px solid gray;
            }
          }
        }

        .slick-cloned {
          display: none;
        }
      }
    }
  }
  /* button {
    display: block !important;
  }
  button:nth-of-type(1) {
    &::before {
      content: url(${leftArrow});
    }
  }
  button:nth-of-type(2) {
    &::before {
      content: url(${rightArrow});
    }
  } */
`;
