import { memo, FC, useState } from 'react';
import { RightOutlined } from '@ant-design/icons';
import { setPage } from 'app/features/products/slice';
import { useAppDispatch } from 'app/hooks';
import { Button } from 'antd';

import { ICollapsePanelProps } from './types';
import { LiTitle, OpenDiv, StyledSpan } from './style';

const CollapsePanel: FC<ICollapsePanelProps> = ({ id, title_en, nodes, selectedCategory, setSelectedCategory, thisItem, setIsSelectCategoryOpen }) => {
  // state
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  return (
    <li key={`tree-data-item-${id}`}>
      <StyledSpan selected={selectedCategory?.id == thisItem.id}>
        <LiTitle>
          <Button
            type="ghost"
            onClick={() => {
              dispatch(setPage(1));
              selectedCategory?.id == thisItem.id ? setSelectedCategory(null) : setSelectedCategory(thisItem);
              setIsSelectCategoryOpen(prevState => !prevState);
            }}
          >
            {title_en}
          </Button>
        </LiTitle>
        {nodes && nodes?.length > 0 && (
          <OpenDiv
            onClick={e => {
              e.stopPropagation();
              setOpen(!open);
            }}
          >
            <RightOutlined style={open ? { rotate: '90deg' } : {}} />
          </OpenDiv>
        )}
      </StyledSpan>

      {open && !!nodes?.length && (
        <div style={{ paddingLeft: '15px' }}>
          {nodes?.map(item => {
            return (
              <div key={item.id}>
                <CollapsePanel
                  setIsSelectCategoryOpen={setIsSelectCategoryOpen}
                  thisItem={item}
                  setSelectedCategory={setSelectedCategory}
                  selectedCategory={selectedCategory}
                  key={item.id}
                  id={item.id}
                  title_en={item.title_en}
                  nodes={item.children}
                  parent_id={item.parent_id}
                />
              </div>
            );
          })}
        </div>
      )}
    </li>
  );
};

export default memo(CollapsePanel);
