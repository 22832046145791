import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api/apiClient';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';

import { CountryState } from './types';

const initialState: CountryState = {
  loading: false,
  country: [],
  countryById: null
};

export const countryApi = createAsyncThunk('country/countryApi', async (_, { rejectWithValue }) => {
  try {
    const res = await client.get('/country');

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const countryByIdApi = createAsyncThunk('country/countryByIdApi', async (id: number, { rejectWithValue }) => {
  try {
    const res = await client.get(`/country/${id}`);

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(countryApi.pending, state => {
        state.loading = true;
      })
      .addCase(countryApi.fulfilled, (state, action) => {
        state.loading = false;
        state.country = action.payload;
      })
      .addCase(countryApi.rejected, state => {
        state.loading = false;
      });
  }
});

export default countrySlice.reducer;
