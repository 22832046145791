import { RootState } from 'app/store';

export const selectCurrencyData = () => (state: RootState) => state.currency.currency;

export const selectCurrencyLoading = () => (state: RootState) => state.currency.loading;

export const selectCurrencySearch = () => (state: RootState) => state.currency.search;

export const selectCurrencyPage = () => (state: RootState) => state.currency.page;

export const selectCurrencyLimit = () => (state: RootState) => state.currency.limit;

export const selectCurrencyCount = () => (state: RootState) => state.currency.count;

export const selectCurrencyById = () => (state: RootState) => state.currency.currencyById;

export const selectCurrencyLoadingAction = () => (state: RootState) => state.currency.loadingAction;

export const selectCurrencyLoadingById = () => (state: RootState) => state.currency.loadingByID;
