import { Dispatch, FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { categoryApi } from 'app/features/category/slice';
import { selectCategoryData } from 'app/features/category/selectors';
import { ICategory } from 'app/features/category/types';
import { MenuUnfoldOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { LiTitle, OpenDiv, StyledAside, StyledDiv, StyledDropDown, StyledParentContainer, StyledSpan } from './style';
import CollapsePanel from './CollapsePanel';

interface IProps {
  selectedCategory: ICategory | null;
  setSelectedCategory: Dispatch<React.SetStateAction<ICategory | null>>;
}

const CategoryDrop: FC<IProps> = ({ selectedCategory, setSelectedCategory }) => {
  const [isSelectCategoryOpen, setIsSelectCategoryOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const categoryData = useAppSelector(selectCategoryData());

  useEffect(() => {
    dispatch(categoryApi());
  }, [dispatch]);

  return (
    <StyledDiv isSelectCategoryOpen={isSelectCategoryOpen}>
      <StyledAside isActive={isSelectCategoryOpen}>
        <StyledParentContainer>
          <StyledSpan
            onClick={() => {
              setIsSelectCategoryOpen(prevState => !prevState);
            }}
            selected={!!selectedCategory}
          >
            <LiTitle>
              <Button
                type="ghost"
                style={{ color: selectedCategory ? '#00909e' : 'inherit' }}
                onClick={event => {
                  event.stopPropagation();
                  selectedCategory ?? setIsSelectCategoryOpen(prevState => !prevState);
                  setSelectedCategory(null);
                }}
              >
                {selectedCategory ? <>{selectedCategory?.title_en}</> : 'Select Category'}
              </Button>
            </LiTitle>
            <OpenDiv onClick={event => event.stopPropagation()}>
              <Button
                onClick={() => {
                  setIsSelectCategoryOpen(prevState => !prevState);
                }}
                type="ghost"
                icon={<MenuUnfoldOutlined />}
              />
            </OpenDiv>
          </StyledSpan>
          <StyledDropDown isActive={isSelectCategoryOpen}>
            <div>
              {categoryData?.map(parent => (
                <CollapsePanel
                  setIsSelectCategoryOpen={setIsSelectCategoryOpen}
                  thisItem={parent}
                  setSelectedCategory={setSelectedCategory}
                  selectedCategory={selectedCategory}
                  key={parent.id}
                  id={parent.id}
                  nodes={parent.children}
                  title_en={parent.title_en}
                  parent_id={parent.parent_id}
                />
              ))}
            </div>
          </StyledDropDown>
        </StyledParentContainer>
      </StyledAside>
    </StyledDiv>
  );
};

export default CategoryDrop;
