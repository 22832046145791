export function EditIcon({ color = '#19293B' }: { color?: string }) {
  return (
    <svg color={color} xmlns="http://www.w3.org/2000/svg" width={42} height={37} xmlSpace="preserve" viewBox="0 0 48 60">
      <path
        fill={color}
        d="M39.498 37.714h-6.251V29.23l11.547-11.548.003-.001h.002l1.744-1.747a2.469 2.469 0 0 0 0-3.482l-1.851-1.849a2.441 2.441 0 0 0-1.742-.723c-.657 0-1.276.256-1.743.723l-1.742 1.743-.001.001-.004.002-6.213 6.216V4.07c0-.493-.4-.892-.894-.892H1.538a.892.892 0 0 0-.893.892v37.366a4.918 4.918 0 0 0 4.913 4.911H35.48a4.92 4.92 0 0 0 4.914-4.911v-2.83a.894.894 0 0 0-.896-.892zm2.975-25.849a.692.692 0 0 1 .957 0l1.849 1.849a.675.675 0 0 1 0 .958l-1.113 1.114-2.808-2.807 1.115-1.114zm-2.379 2.377 2.809 2.808-11.181 11.178v.002l-2.837 2.837-2.808-2.809 14.017-14.016zM27.269 31.976l-3.343 1.242 1.243-3.345 2.1 2.103zm-17.99 6.63v2.83a3.13 3.13 0 0 1-3.126 3.125h-.595a3.13 3.13 0 0 1-3.126-3.125V4.963h29.03v15.388l-7.278 7.276a1.047 1.047 0 0 0-.117.145l-.023.042c-.021.04-.044.08-.061.124-.001.003-.005.007-.005.01l-2.406 6.476a.894.894 0 0 0 1.15 1.147l6.476-2.405c.005-.001.007-.003.009-.004a.962.962 0 0 0 .133-.065c.009-.007.021-.011.032-.018a.914.914 0 0 0 .146-.117l1.944-1.943v6.696H10.619a.984.984 0 0 0-.224.031.89.89 0 0 0-1.116.86zm29.327 2.831a3.132 3.132 0 0 1-3.127 3.125H9.939a4.889 4.889 0 0 0 1.127-3.125V39.5h27.541v1.937z"
      />
      <path
        fill={color}
        d="M7.791 14.275h18.312a.894.894 0 0 0 0-1.787H7.791a.893.893 0 0 0 0 1.787zM7.791 19.631h18.312a.894.894 0 0 0 0-1.787H7.791a.894.894 0 0 0 0 1.787zM7.791 24.986h17.865a.895.895 0 0 0 0-1.787H7.791a.894.894 0 0 0 0 1.787zM7.791 30.342h13.398a.893.893 0 0 0 0-1.787H7.791a.893.893 0 0 0 0 1.787zM14.117 34.805a.893.893 0 0 0-.893-.894H7.791a.893.893 0 0 0 0 1.787h5.433a.895.895 0 0 0 .893-.893z"
      />
    </svg>
  );
}
