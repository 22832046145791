import { useAppSelector } from 'app/hooks';
import { selectProductById } from 'app/features/products/selectors';
import { getPDFUrl } from 'helpers/file';
import { message } from 'antd';

import { IGlobalFilesType } from 'pages/ProductDetails/components/ProductPage1/SyncSlider/types';
import SyncSlider from 'pages/ProductDetails/components/ProductPage1/SyncSlider';
import PDFIcon from '../../../../assets/img/PDFIcon.jpg';
import {
  AccordionDrop,
  AccordionWrapper,
  AttributeDescription,
  AttributesStyled,
  Availabil,
  Catalogue,
  Features,
  GeneralProduct,
  ProductFigcap,
  ProductFigure,
  ProductInfo,
  StyledSection,
  StyledVideosContainer
} from './style';
import Loading from 'components/Loading';

const ProductPage1 = () => {
  const productById = useAppSelector(selectProductById());

  const copyText = async (text: string) => {
    await navigator.clipboard.writeText(text);
    message.success(`Article number: ${text} copied!`);
  };

  if (!productById) return <Loading size="large" />;

  return (
    <StyledSection style={{ marginBottom: 40 }}>
      <ProductFigure>
        <GeneralProduct>
          <div>
            <div className="sliderCont">
              <div>
                <SyncSlider files={productById.files as IGlobalFilesType[]} folder="" BigFiles={true} />
              </div>
            </div>
            <ProductFigcap>
              <ProductInfo>
                <p className="title">{productById.title || productById.title_en}</p>
                {productById.description1_en && productById.description1_en.length > 0 && (
                  <div style={{ position: 'relative' }}>
                    <div
                      className="description3Line"
                      dangerouslySetInnerHTML={{
                        __html: productById.description1_en
                      }}
                    ></div>
                  </div>
                )}
                {/* <div>
                            <Rating
                              className="rating"
                              name="read-only"
                              value={4}
                              readOnly
                            />
                          </div> */}
                {productById.main_vendor && (
                  <div className="brand">
                    <span style={{ fontWeight: 700 }}>Brand:</span> <span>{productById.main_vendor?.name}</span>
                  </div>
                )}
                {productById.articul_number && (
                  <div className="brand">
                    <span style={{ fontWeight: 700 }}>Article: </span>
                    <span onClick={() => copyText(productById ? productById.articul_number : '')}>{productById.articul_number}</span>
                  </div>
                )}
                <Availabil>{productById.availability ? 'Is Available' : 'Not available'}</Availabil>
                {productById.unit && (
                  <div className="unit">
                    <span style={{ fontWeight: 700 }}>Unit: </span>
                    {productById.unit}
                  </div>
                )}
                {productById.code && (
                  <div>
                    <span style={{ fontWeight: 700 }}>Code: </span>
                    {productById.code}
                  </div>
                )}
                {productById.categories && (
                  <div className="categories">
                    <p style={{ fontWeight: 700 }}>Categories</p>
                    <div className="container">
                      {productById.categories.map(item => {
                        return (
                          <div key={item.id} className="item">
                            {item.title_en || item.title}
                            <div className="arrow">&gt;</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </ProductInfo>
            </ProductFigcap>
          </div>
        </GeneralProduct>
        <div className="producInfoSections">
          <div>
            <div className="generalTitle">Characteristics</div>
            <div id="characteristic">
              {!!productById.description1_en && productById.description1_en.length > 0 && (
                <div style={{ width: '95%', margin: 'auto' }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: productById?.description1_en
                    }}
                  ></div>
                  <br />
                </div>
              )}
              <br />
              {!!productById.attributes.rows && (
                <AccordionWrapper>
                  {
                    <AccordionDrop>
                      {typeof productById.attributes === 'object' ? (
                        productById.attributes.rows.map(itemValue => {
                          if (itemValue.type === 'enum') {
                            return (
                              <AttributesStyled key={itemValue.id}>
                                <AttributeDescription>{itemValue.description_en}</AttributeDescription>
                                <div>{itemValue?.products[0]?.product_attributes?.enum_value}</div>
                              </AttributesStyled>
                            );
                          } else if (itemValue.type === 'number') {
                            return (
                              <AttributesStyled key={itemValue.id}>
                                <AttributeDescription>{itemValue.description_en}</AttributeDescription>
                                <div>
                                  {
                                    <>
                                      <span>{itemValue?.products[0]?.product_attributes?.min}</span>
                                      ---
                                      <span>{itemValue?.products[0]?.product_attributes?.max}</span>
                                    </>
                                  }
                                </div>
                              </AttributesStyled>
                            );
                          }
                        })
                      ) : (
                        <Catalogue
                          dangerouslySetInnerHTML={{
                            __html: productById.attributes
                          }}
                        ></Catalogue>
                      )}
                    </AccordionDrop>
                  }
                </AccordionWrapper>
              )}
            </div>
          </div>

          {!!productById.html && productById.html?.length > 15 && (
            <div>
              <div id="features">
                <br />
                <br />
                <br />
                <p className="title">Features</p>
                <Features>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: productById.html
                    }}
                  ></div>
                </Features>
              </div>
            </div>
          )}
          {productById && productById.youtube_urls && productById.youtube_urls?.length > 0 && (
            <div>
              <div id="videos">
                <br />
                <br />
                <br />
                <p className="title">Videos</p>
                <StyledVideosContainer>
                  {productById.youtube_urls.map((item, index) => {
                    return (
                      <div
                        key={index}
                        dangerouslySetInnerHTML={{
                          __html: item.replace('<iframe', '<iframe style="width: 100%; height: 257px;"')
                        }}
                      ></div>
                    );
                  })}
                </StyledVideosContainer>
              </div>
            </div>
          )}
          <br />
          <br />

          {productById.connectivity_solution && productById.connectivity_solution.image.length > 0 && (
            <div style={{ paddingTop: '60px' }}>
              <p className="title">Connectivity Solution</p>
              <div
                style={{
                  border: '1px black dashed',
                  padding: '0 20px'
                }}
                id="connSolution"
              >
                <br />
                <br />
                <br />
                INCOMMING CONNECTIVITY SOLUTIONS
                {/* <ConnSolution connSolution={connSolution} /> */}
                <br />
                <br />
                <br />
              </div>
            </div>
          )}

          {productById && productById.datasheets.length > 0 && (
            <div>
              <p className="title">Datasheet</p>
              {productById.datasheets.map((item, index) => {
                return (
                  <div key={index} id="datasheets" style={{ display: 'inline-block' }}>
                    <a href={getPDFUrl(item.path)} target="_blank" rel="noreferrer">
                      <img src={PDFIcon} alt="" />
                      <p className="file_name">{`${item.file_name}.${item.extension}`}</p>
                    </a>
                  </div>
                );
              })}
            </div>
          )}

          {/* {recomendedProds.length > 0 && (
            <div ref={refRecPosition}>
              <div id="recomended" ref={refRec}>
                <br />
                <br />
                <br />
                <p className="title">
                  {t.recomended}
                  <button className="moreBtn">
                    <DoubleRightOutlined />
                    More Products
                  </button>
                </p>
                <ProductSlider similarId={null} isHome={true} />
              </div>
            </div>
          )} */}
        </div>
      </ProductFigure>
    </StyledSection>
  );
};

export default ProductPage1;
