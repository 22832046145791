import { Col, Form, Input, Row } from 'antd';
import { selectEducationalById, selectEducationalLoadingAction, selectEducationalLoadingById } from 'app/features/educational/selectors';
import { IEducationalByIdValues } from 'app/features/educational/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { educationalByIdApi, educationalEdit, postEducational } from 'app/features/educational/slice';

import Loading from 'components/Loading';
import DetailsHead from 'components/DetailsHead';

const validateMessages = {
  required: "'${label}' is Required!"
};

const { TextArea } = Input;

const EducationalDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  const educationalById = useAppSelector(selectEducationalById());
  const loadingAction = useAppSelector(selectEducationalLoadingAction());
  const loadingById = useAppSelector(selectEducationalLoadingById());
  // form
  const [form] = Form.useForm();

  const onClose = () => {
    form.resetFields();
    navigate(-1);
  };

  useEffect(() => {
    if (id) {
      dispatch(educationalByIdApi(Number(id)));
    }
  }, [dispatch, id]);

  const onSave = async (values: IEducationalByIdValues) => {
    const data = {
      title_en: values.title_en,
      description_en: values.description_en,
      url: values.url
    };

    if (id) {
      await dispatch(
        educationalEdit({
          id: Number(id),
          ...data
        })
      ).then(() => onClose());
    } else {
      await dispatch(postEducational(data)).then(() => onClose());
    }
  };

  useEffect(() => {
    if (id && educationalById) {
      form.setFieldsValue({
        title_en: educationalById.title_en,
        // title_ru: educationalById.title_ru,
        // title_hy: educationalById.title_hy,
        // description_hy: educationalById.description_hy,
        // description_ru: educationalById.description_ru,
        url: educationalById.url,
        description_en: educationalById.description_en
      });
    }
  }, [educationalById, form, id]);

  return (
    <>
      {loadingById ? (
        <Loading size="large" />
      ) : (
        <>
          <Form
            onFinish={onSave}
            validateMessages={validateMessages}
            size="large"
            form={form}
            layout="vertical"
            scrollToFirstError={{
              behavior: 'smooth',
              block: 'center',
              inline: 'center'
            }}
          >
            <DetailsHead titleItem={educationalById?.id} onClose={onClose} form={form} loadingAction={loadingAction} id={id} headTitle={'Educational'} />
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Form.Item rules={[{ required: true }]} label="Title" name="title_en">
                  <Input placeholder="Enter educational title" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="description_en" label="Description" rules={[{ required: true }]}>
                  <TextArea style={{ height: '150px' }} showCount placeholder="Enter description in English" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item rules={[{ required: true }, { type: 'url', warningOnly: true }]} label="Video Url" name="url">
                  <Input placeholder="Enter Video Url" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </>
  );
};

export default EducationalDetails;
