import { Button, Col, Form, Input, Row, Upload } from 'antd';
import { FC, useEffect, useState } from 'react';
import { PlusCircleFilled, UploadOutlined } from '@ant-design/icons';
import useCustomRequest from 'Hook/useCustomRequest';
import { getFileUrl } from 'helpers/file';
import { useParams } from 'react-router-dom';
import { IFile } from 'app/features/services/types';

import { IServicePage } from './types';
import { UploadWrraper } from 'components/SliderPage/styled';
import { TextArea } from 'pages/ProductDetails/styled';

const { Dragger } = Upload;

const ServicePage1: FC<IServicePage> = ({ serviceByID, fileList, onChange, fileListBody, onChangeBodyImage, setShowProductsListModal }) => {
  const customRequestLogo = useCustomRequest({ url: 'services/uploadFile' });
  const customRequestBody = useCustomRequest({
    url: 'services/uploadBodyFile'
  });

  const { id } = useParams();
  const [bodyImage, setBodyImage] = useState<IFile>();
  const [icon, setIcon] = useState<IFile>();
  useEffect(() => {
    serviceByID?.files.map(item => {
      if (item.file_type === 'body_image') {
        setBodyImage(item);
      } else {
        setIcon(item);
      }
    });
  }, [serviceByID?.files]);

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Form.Item label="English Title" style={{ minWidth: '400px' }} name="title_en" rules={[{ required: true }]}>
            <Input placeholder="Enter title in English" />
          </Form.Item>

          <Button style={{ marginTop: '20px' }} icon={<PlusCircleFilled style={{ fontSize: 18 }} />} onClick={() => setShowProductsListModal(true)}>
            Add Products
          </Button>
        </Col>
        <Col>
          {serviceByID !== null && id && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <p
                style={{
                  fontSize: '20px',
                  marginBottom: '10px',
                  fontWeight: '500'
                }}
              >
                Icon
              </p>
              {icon && <img src={getFileUrl(icon.id, icon.extension, 'services/logo')} alt="" />}
            </div>
          )}
          <UploadWrraper>
            <Dragger onChange={onChange} accept="image/*" multiple maxCount={1} listType="picture" fileList={fileList} customRequest={customRequestLogo}>
              <Button icon={<UploadOutlined />}>Upload {id ? 'New' : 'Service'} Icon</Button>
            </Dragger>
          </UploadWrraper>
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <Row gutter={[24, 24]} style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Col span={10}>
          <Form.Item name="body_title" rules={[{ required: true }]} label="Body Title">
            <Input placeholder="Enter title for Body" />
          </Form.Item>
        </Col>
        <Col>
          <UploadWrraper>
            <Dragger onChange={onChangeBodyImage} accept="image/*" multiple maxCount={1} listType="picture" fileList={fileListBody} customRequest={customRequestBody}>
              <Button icon={<UploadOutlined />}>Upload {id ? 'New' : 'Service'} Body Image</Button>
            </Dragger>
          </UploadWrraper>
        </Col>
        <Col span={10} style={{ maxWidth: '100%' }}>
          {serviceByID !== null && id && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <p
                style={{
                  fontSize: '20px',
                  marginBottom: '10px',
                  fontWeight: '500'
                }}
              >
                Body Image
              </p>
              {bodyImage && <img style={{ width: '100%' }} src={getFileUrl(bodyImage.id, bodyImage.extension, 'services/body')} alt="" />}
            </div>
          )}
        </Col>
        <Col span={24}>
          <Form.Item name="body_text" rules={[{ required: true }]} label="Body Text">
            <TextArea placeholder="Enter Body" rows={20} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default ServicePage1;
