import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectUserById,
  selectUserOrdersLimit,
  selectUserOrdersPage,
  selectUserProductsLimit,
  selectUserProductsPage,
  selectUserProductsSearch,
  selectUsersLoading
} from 'app/features/users/selectors';
import { useEffect, useState } from 'react';
import {
  clearBasketState,
  clearUserByIdState,
  clearUserOrderAddresses,
  createUserApi,
  editUserInfoApi,
  getUserBasketState,
  getUserFavoriteProducts,
  getUserOrderAddress,
  getUserOreders,
  getUserProducts,
  setUserProductSearch,
  setUserProductsLimit,
  setUserProductsPage,
  userByIdApi
} from 'app/features/users/slice';
import { Form, Tabs } from 'antd';
import { IUserById } from 'app/features/users/types';
import { selectUserInfo } from 'app/features/auth/selectors';
import { _debounce } from 'helpers/debounce';
import { getRoles } from 'app/features/roles/slice';
// import { menuListArr, requestsArr } from 'helpers/userParmissions';

import DetailsHead from 'components/DetailsHead';
import Loading from 'components/Loading';
import UserPage1 from './UserPage1';
import UserPage2 from './UserPage2';
// import UserPage3 from './UserPage3';
import UserPage4 from './UserPage4';
import UserPage5 from './UserPage5';
import AddressesModal from './UserPage2/addressesModal';

const debounce = _debounce();

const UserDetails = () => {
  const [tabs, setTabs] = useState('User page 1');
  const { id } = useParams();

  const accountTypes = [
    {
      title: 'BASIC'
    },
    {
      title: 'SILVER'
    },
    {
      title: 'GOLD'
    },
    {
      title: 'PREMIUM'
    }
  ];

  const discountTypes = [
    {
      type: 1
    },
    {
      type: 2
    },
    {
      type: 3
    },
    {
      type: 4
    }
  ];
  //state

  const [showAddressesModal, setShowAddressesModal] = useState<boolean>(false);

  //selectors
  const loading = useAppSelector(selectUsersLoading());
  const userById = useAppSelector(selectUserById());
  const userInfo = useAppSelector(selectUserInfo());
  const ordersLimit = useAppSelector(selectUserOrdersLimit());
  const ordersPage = useAppSelector(selectUserOrdersPage());

  const productsLimit = useAppSelector(selectUserProductsLimit());
  const productsPage = useAppSelector(selectUserProductsPage());
  const productsSearch = useAppSelector(selectUserProductsSearch());

  const isSalesAdmin = userInfo?.role == 'SALES_ADMIN';
  const [phone_number, setPhoneNumber] = useState<string | undefined>(undefined);

  // const [requestTypes, setRequestTypes] = useState<IPerimission[]>(requestsArr);
  // const [pagesList, setPagesList] = useState<IPerimission[]>(menuListArr);
  //dispatch
  const dispatch = useAppDispatch();
  //navigate
  const navigate = useNavigate();

  // form
  const [form] = Form.useForm();

  const onClose = () => {
    form.resetFields();
    navigate(-1);
  };

  const validateMessages = {
    required: "'${label}' is Required!"
  };

  useEffect(() => {
    return () => {
      dispatch(clearUserByIdState());
      dispatch(clearBasketState());
      dispatch(clearUserOrderAddresses());
    };
  }, [dispatch]);

  useEffect(() => {
    if (tabs === 'User page 1') {
      dispatch(getRoles());
    }

    if (id) {
      if (tabs === 'User page 1') {
        dispatch(userByIdApi(id));
      } else if (tabs === 'User page 2') {
        if (userById?.have_basket) {
          dispatch(getUserBasketState(id));
        }

        dispatch(getUserOrderAddress(id));
      } else if (tabs === 'User page 3') {
        dispatch(getUserFavoriteProducts(id));
      }
    }
  }, [dispatch, id, tabs, userById?.have_basket]);

  useEffect(() => {
    if (tabs === 'User page 4' && id) {
      dispatch(getUserOreders(id));
    }
  }, [dispatch, id, tabs, ordersLimit, ordersPage]);

  useEffect(() => {
    if (tabs === 'User page 5' && id) {
      if (productsSearch.length > 0) {
        debounce(() => {
          dispatch(getUserProducts(id));
        });
      } else {
        dispatch(getUserProducts(id));
      }
    } else {
      dispatch(setUserProductSearch(''));
      dispatch(setUserProductsPage(1));
      dispatch(setUserProductsLimit(10));
    }
  }, [dispatch, id, tabs, productsLimit, productsPage, productsSearch]);

  const onSave = async (values: IUserById) => {
    const data: any = {
      firstName: values.firstName,
      lastName: values.lastName,
      username: values.username,
      phone: phone_number || '',
      email: values.email,
      account_type: values.account_type,
      role: 'USER',
      countryId: values.countryId,
      password: values.password,
      discount_type_number: values.discount_type_number
    };

    if (!phone_number || phone_number.length <= 4 || phone_number == '+') {
      data.phone = ' ';
    }

    if (id) {
      await dispatch(
        editUserInfoApi({
          id,
          data
        })
      ).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          onClose();
        }
      });
    } else {
      await dispatch(createUserApi({ data })).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          onClose();
        }
      });
    }
  };

  useEffect(() => {
    if (id && userById) {
      form.setFieldsValue({
        firstName: userById.firstName,
        lastName: userById.lastName,
        username: userById.username,
        gender: userById.gender,
        email: userById.email,
        phone: userById.phone,
        point: +userById.point,
        account_type: userById.account_type,
        discount_type_number: userById.discount_type_number,
        countryId: userById.countryId,
        role: userById.role
      });
      setPhoneNumber(userById.phone);
    }
  }, [userById, form, id]);

  const tabItems = id
    ? [
        {
          label: 'User',
          key: 'User page 1',
          children: (
            <UserPage1
              id={id}
              isSalesAdmin={isSalesAdmin}
              accountTypes={accountTypes}
              discountTypes={discountTypes}
              setShowAddressesModal={setShowAddressesModal}
              phoneNumber={phone_number}
              setPhoneNumber={setPhoneNumber}
            />
          )
        },
        {
          label: 'User Basket',
          key: 'User page 2',
          children: <UserPage2 showAddressesModal={showAddressesModal} setShowAddressesModal={setShowAddressesModal} />
        },
        // {
        //   label: 'User Favorite Products',
        //   key: 'User page 3',
        //   children: <UserPage3 />
        // },
        {
          label: 'User Orders',
          key: 'User page 4',
          children: <UserPage4 />
        },
        {
          label: 'User Products',
          key: 'User page 5',
          children: <UserPage5 />
        }
      ]
    : [
        {
          label: 'User',
          key: 'User page 1',
          children: (
            <UserPage1
              id={id}
              isSalesAdmin={isSalesAdmin}
              accountTypes={accountTypes}
              phoneNumber={phone_number}
              setPhoneNumber={setPhoneNumber}
              discountTypes={discountTypes}
              setShowAddressesModal={setShowAddressesModal}
            />
          )
        }
      ];

  return (
    <>
      {loading ? (
        <Loading size="large" />
      ) : (
        <>
          <Form
            scrollToFirstError={{
              behavior: 'smooth',
              block: 'center',
              inline: 'center'
            }}
            onFinish={onSave}
            validateMessages={validateMessages}
            size="large"
            form={form}
            layout="vertical"
          >
            <DetailsHead titleItem={`${userById?.firstName}  ${userById?.lastName}`} onClose={onClose} id={id} headTitle={'User'} loadingAction={loading} form={form} />
            <Tabs activeKey={tabs} onChange={e => setTabs(e)} type="card" items={tabItems} />
          </Form>
        </>
      )}
      <AddressesModal open={showAddressesModal} handleModal={setShowAddressesModal} />
    </>
  );
};

export default UserDetails;
