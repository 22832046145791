import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: center;
  .edit {
    color: black;
    background-color: transparent;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 2px 7px;
    width: max-content;
    display: flex;
    gap: 5px;
    &:hover {
      color: #4096ff;
      border-color: #4096ff;
    }
  }
`;

export const ImgTableWrraper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
