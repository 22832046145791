import { RootState } from 'app/store';

export const selectServicePage = () => (state: RootState) => state.services.page;

export const selectServiceData = () => (state: RootState) => state.services.services;

export const selectServiceCount = () => (state: RootState) => state.services.count;

export const selectServiceLoading = () => (state: RootState) => state.services.loading;

export const selectServiceLoadingAction = () => (state: RootState) => state.services.loadingAction;

export const selectServiceLoadingByID = () => (state: RootState) => state.services.loadingByID;

export const selectServiceSearch = () => (state: RootState) => state.services.search;

export const selectServiceLimit = () => (state: RootState) => state.services.limit;

export const selectServiceById = () => (state: RootState) => state.services.serviceById;
