import { RootState } from 'app/store';

export const selectEducationalData = () => (state: RootState) => state.educational.educational;

export const selectEducationalLoading = () => (state: RootState) => state.educational.loading;

export const selectEducationalSearch = () => (state: RootState) => state.educational.search;

export const selectEducationalPage = () => (state: RootState) => state.educational.page;

export const selectEducationalLimit = () => (state: RootState) => state.educational.limit;

export const selectEducationalCount = () => (state: RootState) => state.educational.count;

export const selectEducationalLoadingAction = () => (state: RootState) => state.educational.loadingAction;

export const selectEducationalLoadingById = () => (state: RootState) => state.educational.loadingById;

export const selectEducationalById = () => (state: RootState) => state.educational.educationalById;
