import { Drawer } from 'antd';
import styled from 'styled-components';

export const DrawerStyled = styled(Drawer)`
  .ant-drawer-close {
    margin-bottom: 4px;
  }
`;

export const UploadWrraper = styled.div`
  height: fit-content;
  margin-top: 20px;
  span {
    padding: 0 !important;
    .ant-upload-drag {
      border: none;
    }
  }
`;

export const ContentTopTitle = styled.div`
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
`;
export const ContentTopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
