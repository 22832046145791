import { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { CloseOutlined, DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectQuestionActive, selectQuestionData, selectQuestionLoading, selectQuestionLoadingAction } from 'app/features/questions/selectors';
import { allQuestion, deleteQuestion, questionUpdate, setActive } from 'app/features/questions/slice';
import { QuestionType, QuestionUpdate } from 'app/features/questions/types';

import ContentTop from 'components/ContentTop';
import { ButtonsWrapper, CollapseStyled, QuestionItemAnswer, QuestionItemStyled, QuestionText, QuestionWrraper, TabsStyled } from './styled';
import { pageArr } from './constant';
import Loading from 'components/Loading';
import DeleteModal from 'components/DeleteModal';
import QuestionModal from './components/QuestionModal';

const { TextArea } = Input;

const Questions = () => {
  //selectors
  const questionData = useAppSelector(selectQuestionData());
  const loading = useAppSelector(selectQuestionLoading());
  const loadingAction = useAppSelector(selectQuestionLoadingAction());
  const activeQuestion = useAppSelector(selectQuestionActive());
  //state
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [tabs, setTabs] = useState(pageArr[0].id);

  const [editQuestion, setEditQuestion] = useState(false);
  const [showSectionModal, setShowSectionModal] = useState(false);
  //dispatch
  const dispatch = useAppDispatch();

  // form
  const [form] = Form.useForm();

  const onClose = () => {
    setShowSectionModal(false);
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
    setEditQuestion(false);
  };

  useEffect(() => {
    dispatch(allQuestion(tabs));
  }, [dispatch, tabs]);

  const onSave = async (values: QuestionUpdate) => {
    await dispatch(
      questionUpdate({
        id: activeQuestion,
        question: values.question,
        answer: values.answer,
        type: tabs as QuestionType
      })
    ).then(() => handleClose());
  };

  return (
    <>
      <ContentTop title={'Questions and Answer'} />
      <TabsStyled
        size="large"
        tabBarExtraContent={
          <Button
            onClick={() => {
              setShowSectionModal(true), setEditQuestion(false);
            }}
            size="large"
            type="primary"
          >
            {`Add ${pageArr.find(item => item.id === tabs)?.label} Question and Answer`}
          </Button>
        }
        activeKey={tabs}
        onChange={e => {
          setTabs(e), setEditQuestion(false);
        }}
        type="card"
        items={pageArr.map(item => ({
          key: item.id,
          label: item.label,
          children: loading ? (
            <Loading size="large" />
          ) : (
            <CollapseStyled>
              <Form form={form} onFinish={onSave} layout="vertical">
                <Row style={{ height: '100%', marginLeft: 0 }} gutter={[24, 24]}>
                  <QuestionWrraper span={13}>
                    {questionData?.map(itemData => {
                      return (
                        <QuestionItemStyled active={activeQuestion === itemData.id} key={itemData.id + item.label}>
                          {editQuestion && activeQuestion === itemData.id ? (
                            <QuestionText>
                              <Form.Item name="question">
                                <TextArea style={{ height: 100, fontSize: '16px' }} defaultValue={questionData.find(item => item.id === activeQuestion)?.question} />
                              </Form.Item>
                            </QuestionText>
                          ) : (
                            <QuestionText
                              onClick={() => {
                                dispatch(setActive(itemData.id)), setEditQuestion(false);
                              }}
                            >
                              {itemData.question}
                            </QuestionText>
                          )}

                          <ButtonsWrapper>
                            {editQuestion && activeQuestion === itemData.id ? (
                              <>
                                <Button onClick={form.submit} icon={<SaveOutlined />}></Button>
                                <Button onClick={() => setEditQuestion(false)} style={{ marginLeft: 7 }} icon={<CloseOutlined />}></Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  onClick={() => {
                                    setEditQuestion(true);
                                  }}
                                  disabled={activeQuestion !== itemData.id}
                                  icon={<EditOutlined />}
                                ></Button>
                                <Button
                                  onClick={() => {
                                    setOpenDeleteModal(true);
                                  }}
                                  disabled={activeQuestion !== itemData.id}
                                  style={{ marginLeft: 7 }}
                                  danger
                                  icon={<DeleteOutlined />}
                                ></Button>
                              </>
                            )}
                          </ButtonsWrapper>
                        </QuestionItemStyled>
                      );
                    })}
                  </QuestionWrraper>

                  <Col span={11}>
                    {editQuestion ? (
                      <QuestionItemAnswer>
                        <Form.Item name="answer">
                          <TextArea style={{ height: 430, fontSize: '16px' }} defaultValue={questionData.find(item => item.id === activeQuestion)?.answer} />
                        </Form.Item>
                      </QuestionItemAnswer>
                    ) : (
                      <QuestionItemAnswer>{questionData?.find(item => item.id === activeQuestion)?.answer}</QuestionItemAnswer>
                    )}
                  </Col>
                </Row>
              </Form>
            </CollapseStyled>
          )
        }))}
      />
      <QuestionModal tabs={tabs as QuestionType} isOpen={showSectionModal} onClose={onClose} />
      <DeleteModal
        open={openDeleteModal}
        confirmLoading={loadingAction}
        onOk={() => {
          dispatch(deleteQuestion({ id: activeQuestion, type: tabs as QuestionType })).then(() => {
            setOpenDeleteModal(false);
          });
        }}
        onCancel={() => {
          setOpenDeleteModal(false);
        }}
      />
    </>
  );
};

export default Questions;
