import { Modal, Button, Input, Form, Row, Col, Select } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FC, memo, useEffect, useState } from 'react';
import { selectDiscounts } from 'app/features/discounts/selectors';
import { PostDiscountValues } from 'app/features/discounts/types';
import { postDiscountsApi } from 'app/features/discounts/slice';
import { transformArray } from 'helpers/discount';

import { IDiscountModalProps } from '../../Categories/Details/types';

const DiscountModal: FC<IDiscountModalProps> = ({ accTypeName, clearAccTypeName, isOpen, onClose }) => {
  const accountTypes = [
    {
      title: 'BASIC'
    },
    {
      title: 'SILVER'
    },
    {
      title: 'GOLD'
    },
    {
      title: 'PREMIUM'
    }
  ];

  const discountState = useAppSelector(selectDiscounts());
  const selectedDiscount = transformArray(discountState)?.filter(item => item.name === accTypeName)[0];

  const [selectedAccType, setSelectedAccType] = useState<string>('');

  // form
  const [form] = Form.useForm();
  // dispatch
  const dispatch = useAppDispatch();
  // selectors

  const handleCloseModal = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    if (selectedAccType === 'BASIC') {
      form.setFieldValue('amount_min1', '0');
    }
  }, [selectedAccType]);

  useEffect(() => {
    if (selectedDiscount && accTypeName) {
      form.setFieldsValue({
        amount_min1: selectedDiscount.amount_min1,
        amount_max1: selectedDiscount.amount_max1,
        amount_min2: selectedDiscount.amount_min2,
        amount_max2: selectedDiscount.amount_max2,
        amount_min3: selectedDiscount.amount_min3,
        amount_max3: selectedDiscount.amount_max3,
        amount_min4: selectedDiscount.amount_min4,
        amount_max4: selectedDiscount.amount_max4
      });
    }

    if (!isOpen) {
      form.resetFields();
      setSelectedAccType('');
      clearAccTypeName();
    }
  }, [isOpen]);

  const onSave = async (values: PostDiscountValues) => {
    const data = {
      name: accTypeName ? accTypeName : selectedAccType,
      amount_min1: +values.amount_min1,
      amount_max1: +values.amount_max1,
      amount_min2: +values.amount_min2,
      amount_max2: +values.amount_max2,
      amount_min3: +values.amount_min3,
      amount_max3: +values.amount_max3,
      amount_min4: +values.amount_min4,
      amount_max4: +values.amount_max4
    };

    const res = (await dispatch(
      postDiscountsApi({
        ...data
      })
    )) as { meta: { requestStatus: string }; payload: { message: string } };

    if (res.meta.requestStatus === 'rejected' && res.payload.message === 'Request failed with status code 409') {
      form.setFields([
        {
          name: 'key',
          errors: ['Such a key already exists. Please enter a different key.']
        }
      ]);
    } else {
      handleCloseModal();
    }
  };

  return (
    <Modal
      width={900}
      title={accTypeName ? `Edit Discount: ${accTypeName}` : `Add Discount`}
      open={isOpen}
      onCancel={handleCloseModal}
      footer={[
        <Button onClick={handleCloseModal} key="Cancel">
          Cancel
        </Button>,
        <Button type="primary" onClick={form.submit} key="Save">
          Save
        </Button>
      ]}
    >
      <Form
        scrollToFirstError={{
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        }}
        initialValues={{
          amount_min1: selectedAccType === 'BASIC' ? 0 : null
        }}
        form={form}
        onFinish={onSave}
        layout="vertical"
        size="large"
      >
        <br />
        <br />
        {!accTypeName && (
          <Form.Item name="accountType" label="Account Type" rules={[{ required: true }]}>
            <Select
              value={selectedAccType.length > 0 ? selectedAccType : null}
              placeholder="Select Account Type"
              style={{ width: '200px' }}
              onChange={(e: any) => setSelectedAccType(e)}
              options={accountTypes.map(item => {
                return {
                  value: item.title,
                  label: item.title
                };
              })}
            />
          </Form.Item>
        )}
        <Row style={{ borderBottom: '1px solid lightgray' }} gutter={[24, 24]}>
          <Col span={12}>
            <Form.Item name="amount_min1" label="Min Price 1">
              <Input placeholder="Enter Minimal Price 1" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="amount_max1" label="Max Price 1" rules={[{ required: true }]}>
              <Input placeholder="Enter Maximum Price 1" type="number" onChange={e => form.setFieldValue('amount_min2', e.target.value)} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ borderBottom: '1px solid lightgray' }} gutter={[24, 24]}>
          <Col span={12}>
            <Form.Item name="amount_min2" label="Min Price 2" rules={[{ required: true }]}>
              <Input placeholder="Enter Minimal Price 2" type="number" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="amount_max2" label="Max Price 2" rules={[{ required: true }]}>
              <Input placeholder="Enter Maximum Price 2" type="number" onChange={e => form.setFieldValue('amount_min3', e.target.value)} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ borderBottom: '1px solid lightgray' }} gutter={[24, 24]}>
          <Col span={12}>
            <Form.Item name="amount_min3" label="Min Price 3" rules={[{ required: true }]}>
              <Input placeholder="Enter Minimal Price 3" type="number" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="amount_max3" label="Max Price 3" rules={[{ required: true }]}>
              <Input placeholder="Enter Maximum Price 3" onChange={e => form.setFieldValue('amount_min4', e.target.value)} type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Form.Item name="amount_min4" label="Min Price 4" rules={[{ required: true }]}>
              <Input placeholder="Enter Minimal Price 4" type="number" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="amount_max4" label="Max Price 4" rules={[{ required: true }]}>
              <Input placeholder="Enter Maximum Price 4" type="number" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default memo(DiscountModal);
