import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import { deleteSectionProduct, postSectionProduct } from 'app/features/sections/slice';
import { useAppDispatch } from 'app/hooks';
import { _debounce } from 'helpers/debounce';
// import { defaultSwitch } from 'helpers/section';
import { FC, useEffect, useState } from 'react';

import { ISectionSwitchProps } from '../SectionsAddProduct/types';

const debounce = _debounce();

const SectionSwitch: FC<ISectionSwitchProps> = ({ section_id, product_id, productsList }) => {
  //state
  const [switchState, setSwitchState] = useState<boolean>(false);
  useEffect(() => {
    if (productsList) {
      const finded = productsList.find(i => i.id === product_id);
      setSwitchState(!!finded);
    }
  }, [productsList]);

  //dispatch
  const dispatch = useAppDispatch();

  return (
    <Switch
      checked={switchState}
      onChange={e => {
        debounce(() => {
          if (e !== switchState && e) {
            dispatch(
              postSectionProduct({
                section_id,
                product_id
              })
            );
          } else if (e !== switchState && !e) {
            dispatch(
              deleteSectionProduct({
                section_id,
                product_id
              })
            );
          }

          setSwitchState(e);
        });
      }}
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
    />
  );
};

export default SectionSwitch;
