import { Layout } from 'antd';
import styled, { css } from 'styled-components';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

export const LayoutStyled = styled(Layout)`
  min-height: 100vh;
`;
export const MenuOutlined1 = styled(MenuFoldOutlined)`
  color: white;
  font-size: 25px;
`;
export const MenuOutlined2 = styled(MenuUnfoldOutlined)`
  color: white;
  font-size: 25px;
`;

export const LogoImgWrapper = styled.div`
  display: flex;
  align-items: start;
  max-width: 200px;
  height: 64px;
  padding: 10px 15px 0 15px;
  cursor: pointer;
  width: 100%;
`;
export const SiderTop = styled.div<{ collapsed: boolean }>`
  display: flex;
  width: 100%;
  justify-content: ${props => (props.collapsed ? 'center' : 'end')};
  padding: 10px;
  align-items: center;
  height: 64px;
`;
export const LogoImg = styled.img`
  width: 100%;
`;

export const ContentStyled = styled(Layout.Content)<{ bgcolor: string }>`
  &&& {
    margin: 24px;
    padding: 24px;
    border-radius: 15px;
    background: ${props => props.bgcolor};
    min-height: calc(100vh - 112px);
  }
`;
export const StyledUserInfo = styled.div<{ open: boolean }>`
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  .icon {
    font-size: 22px;
    border: 1px solid black;
    border-radius: 50%;
    padding: 5px;
    background-color: #d1d2d320;
  }
  .dropDown {
    background: white;
    border: 1px solid black;
    border-radius: 8px;
    padding: 10px;
    position: absolute;
    right: 0%;
    top: 100%;
    transform: scaleY(0);
    transition: 0.5s;
    transform-origin: top;
    .title {
      font-size: 18px;
      line-height: normal;
      width: max-content;
      margin-bottom: 8px;
      b {
        font-weight: 500;
      }
    }
    button {
      float: right;
    }
    ${({ open }) =>
      open
        ? css`
            transform-origin: top;
            transform: scaleY(1);
            transition: 0.5s;
          `
        : css``}
  }
`;
export const HeaderStyled = styled(Layout.Header)`
  && {
    display: flex;
    padding-inline: 24px;
    justify-content: space-between;
    align-items: center;
    background: hsla(0, 0%, 100%, 0.8);
    backdrop-filter: saturate(180%) blur(5px);
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 7;
  }
`;
export const SiderStyled = styled(Layout.Sider)`
  && {
    height: 100vh;
    position: fixed;
    left: 0;
    top: 64px;
  }
  .scroll {
    height: 80vh;
    overflow: auto;
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;
export const ContentWrapper = styled.div<{ collapsed: boolean }>`
  width: ${props => props.collapsed && 'calc(100% - 50px)'};
  margin-left: ${props => (props.collapsed ? '50px' : '220px')};
  transition: all 0.3s;
`;

export const MenuWrraper = styled.div`
  display: flex;
  gap: 25px;
`;

export const TopNavBarInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoItem = styled.div`
  margin-right: 20px;
`;
