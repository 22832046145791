import { FC, useState } from 'react';
import { selectSliderLoading, selectSliderLoadingAction } from 'app/features/slider/selectors';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { DeleteOutlined } from '@ant-design/icons';
import { getFileUrl } from 'helpers/file';
import { deleteFileApi } from 'app/features/customPage/slice';

import { ButtonDelete, CardImg, ImgWrraper, StyledImagesContainer } from './styled';
import DeleteModal from 'components/DeleteModal';
import Loading from 'components/Loading';
import { IFile } from './types';

interface IAboutUploaded {
  sliderData: IFile[];
}

const UploadedFiles: FC<IAboutUploaded> = ({ sliderData }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [imageId, setImageId] = useState<string>('');
  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  const loading = useAppSelector(selectSliderLoading());
  const loadingAction = useAppSelector(selectSliderLoadingAction());

  return (
    <>
      {loading ? (
        <Loading size="large" />
      ) : (
        <>
          <StyledImagesContainer>
            {sliderData?.map((item, index) => {
              return (
                <div key={index}>
                  <CardImg
                    hoverable
                    actions={[
                      <ButtonDelete
                        key="delete"
                        style={{ width: '100%', backgroundColor: 'white' }}
                        block
                        icon={<DeleteOutlined />}
                        danger
                        onClick={() => {
                          setImageId(item.id);
                          setOpenDeleteModal(true);
                        }}
                      >
                        Delete
                      </ButtonDelete>
                    ]}
                    cover={
                      <ImgWrraper>
                        <img src={getFileUrl(item.name, item.extension, 'about_us')} alt={item.name} />
                      </ImgWrraper>
                    }
                  ></CardImg>
                </div>
              );
            })}
          </StyledImagesContainer>
          <DeleteModal
            open={openDeleteModal}
            confirmLoading={loadingAction}
            onOk={() => {
              dispatch(deleteFileApi(imageId)).then(() => {
                setOpenDeleteModal(false);
                setImageId('');
              });
            }}
            onCancel={() => {
              setOpenDeleteModal(false);
              setImageId('');
            }}
          />
        </>
      )}
    </>
  );
};

export default UploadedFiles;
