import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api/apiClient';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { message } from 'antd';

import { IFile, PostProductDiscountValues, ProductState } from './types';
import { selectProductPage, selectProductSearch } from './selectors';
import { getQueryString } from '../../../helpers/object';

const initialState: ProductState = {
  products: [],
  productStock: null,
  productById: null,
  loadingAction: false,
  loadingByID: false,
  loading: false,
  page: 1,
  search: '',
  count: 0,
  productDiscounts: []
};

export const productApi = createAsyncThunk('products/productApi', async (currency: string, { getState, rejectWithValue }) => {
  const state = getState() as RootState;

  const page = selectProductPage()(state);
  const search = selectProductSearch()(state);

  const queryString = getQueryString({ search, page, currency, limit: 10 });

  try {
    const res = await client.get(`/product/getAdminProducts${queryString}`);

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});
export const productByCategoryApi = createAsyncThunk('products/productByCategoryApi', async ({ id, limit }: { id: number; limit: number }, { getState, rejectWithValue }) => {
  const state = getState() as RootState;
  const page = selectProductPage()(state);
  const search = selectProductSearch()(state);

  const queryString = getQueryString({ search, page, limit });

  try {
    const res = await client.get(`/category/${id}/product${queryString}`);

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const getProductById = createAsyncThunk('products/productById', async (opened_articul: string, { rejectWithValue }) => {
  try {
    const response = await client.get(`/product/getAdmin/${opened_articul}`);

    return response.data.data;
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const updateFileApi = createAsyncThunk('customPage/updateFileApi', async (file: IFile, { dispatch, getState, rejectWithValue }) => {
  const state = getState() as RootState;

  try {
    const response = await client.patch(`/file/${file.id}`, {
      favorite: true
    });

    if (state.products.productById) {
      dispatch(getProductById(state.products.productById.opened_articul));
    }

    return response.data.data;
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const getProductDiscount = createAsyncThunk('products/getProductDiscount', async ({ id, currencyCode }: { id?: number; currencyCode?: string }, { rejectWithValue }) => {
  try {
    const queryString = getQueryString({ currency: currencyCode });

    const res = await client.get(`/discount_panel/getProductsDiscounts/${id}${queryString}`);

    return res.data.data;
  } catch (err) {
    return rejectWithValue(err as AxiosError<ErrorType>);
  }
});

export const deleteProductDiscount = createAsyncThunk('product/deleteProductDiscount', async (id: string, { rejectWithValue }) => {
  try {
    const res = await client.delete(`/product/discount/${id}`);

    return res.data.data;
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const deleteProductDiscountBulk = createAsyncThunk('product/deleteProductDiscountBulk', async (ids: (string | undefined)[], { rejectWithValue }) => {
  try {
    const promises = [];

    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      const promise = client.delete(`/product/discount/${id}`);

      promises.push(promise);
    }

    return Promise.all(promises).then(results => {
      return results;
    });
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const postProductDiscountApi = createAsyncThunk('products/postProductDiscountApi', async (data: PostProductDiscountValues, { dispatch, rejectWithValue }) => {
  try {
    const res = await client.post('/discount_panel/createProductDiscount', {
      ...data
    });

    if (res.status === 201) dispatch(getProductDiscount({ id: data.product_id }));

    return res.data.data;
  } catch (err) {
    return rejectWithValue(err as AxiosError<ErrorType>);
  }
});

export const editProduct = createAsyncThunk('products/productsEdit', async ({ id, data }: { id: number; data: any }, { dispatch, rejectWithValue, getState }) => {
  try {
    const response = await client.patch(`/product/${id}`, data);
    const state = getState() as RootState;

    const currency = state.selectedcurrencyslice.currencyCode;

    if (response.status === 200) {
      message.success({
        content: `Successfull request, validating...`
      });

      await dispatch(productApi(currency));
    }

    return response.data.data;
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const syncHC = createAsyncThunk('products/syncHC', async (_, { rejectWithValue }) => {
  try {
    const response = await client.get(`/hc/update`);

    if (response.status === 200) {
      message.success('Successfull request working...', 1);
      response.data.data.length ? message.success('Successfull Response') : message.error('No data came from HC');
    }

    return response.data;
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const syncHCJournal = createAsyncThunk('products/syncHCJournal', async (_, { rejectWithValue }) => {
  try {
    const response = await client.get(`/hc/updatej`);

    if (response.status === 200) {
      message.success('Successfull request working...', 1);
      response.data.data.length ? message.success('Successfull Response') : message.error('No data came from HC Journal');
    }

    return response.data;
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setProduct: (state, action) => {
      state.products = action.payload.rows;
      state.count = action.payload.count;
    },
    clearProdById: state => {
      state.productById = null;
    },
    clickMenuProduct: state => {
      (state.page = 1), (state.search = '');
    }
  },
  extraReducers(builder) {
    builder
      .addCase(editProduct.pending, state => {
        state.loadingAction = true;
      })
      .addCase(editProduct.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(editProduct.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(syncHC.pending, state => {
        state.loadingAction = true;
      })
      .addCase(syncHC.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(syncHC.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(productApi.pending, state => {
        state.loading = true;
      })
      .addCase(productApi.fulfilled, (state, action) => {
        state.products = action.payload.rows;
        state.count = action.payload.count;
        state.loading = false;
      })
      .addCase(productApi.rejected, state => {
        state.loading = false;
      })
      .addCase(productByCategoryApi.pending, state => {
        state.loading = true;
      })
      .addCase(productByCategoryApi.fulfilled, (state, action) => {
        state.products = action.payload.rows;
        state.count = action.payload.count;
        state.loading = false;
      })
      .addCase(productByCategoryApi.rejected, state => {
        state.loading = false;
      })
      .addCase(getProductById.pending, state => {
        state.loadingByID = true;
      })
      .addCase(getProductById.fulfilled, (state, action) => {
        state.productById = action.payload;
        state.loadingByID = false;
      })
      .addCase(getProductById.rejected, state => {
        state.loadingByID = false;
      })
      // .addCase(handleUpload.pending, state => {
      //   state.loading = true;
      // })
      // .addCase(handleUpload.fulfilled, state => {
      //   state.loading = false;
      // })
      // .addCase(handleUpload.rejected, state => {
      //   state.loading = false;
      // })
      .addCase(getProductDiscount.pending, state => {
        state.loading = true;
      })
      .addCase(getProductDiscount.fulfilled, (state, action) => {
        state.productDiscounts = action.payload;
        state.loading = false;
      })
      .addCase(getProductDiscount.rejected, state => {
        state.loading = false;
      });
  }
});

export const { setPage, setSearch, setProduct, clickMenuProduct, clearProdById } = productsSlice.actions;
export default productsSlice.reducer;
