import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAppSelector } from 'app/hooks';
import { selectLoginIsLoggedIn } from 'app/features/auth/selectors';

import Login from 'pages/Login';
import PageLayout from 'components/PageLayout';
import Categories from 'pages/Categories';
import Products from 'pages/Products';
import Users from 'pages/Users';
import ProductDetails from 'pages/ProductDetails';
import Partners from 'pages/Partners';
import PartnerDetails from 'pages/PartnerDetails';
import Sections from 'pages/Sections';
import Attributes from 'pages/Attributes';
import AttributesDetails from 'pages/AttributesDetails';
import CustomPageId from 'pages/CustomPageId';
import SectionsAddProduct from 'pages/Sections/components/SectionsAddProduct';
import CustomPage from 'pages/CustomPage';
import BlogDetails from 'pages/BlogDetails';
import Specialist from 'pages/Specialist';
import SpecialistDetails from 'pages/SpecialistDetails';
import Orders from 'pages/Orders';
import OrderDetails from 'pages/OrderDetails';
import Contacts from 'pages/Contacts';
import ContactDetails from 'pages/ContactDetails';
import Blog from 'pages/Blog';
import Educational from 'pages/Educational';
import EducationalDetails from 'pages/EducationalDetails';
import Questions from 'pages/Questions';
import SubscribeUsers from 'pages/SubscribeUsers';
import CreateService from 'pages/AllServices';
import ServiceDetails from 'pages/ServiceDetails';
import UserDetails from 'pages/Users/UserDetails';
import CategoryDetails from 'pages/Categories/Details';
import Discounts from 'pages/Discounts';
import Roles from 'pages/Roles';
import RolesDetails from 'pages/Roles/details';
import Admins from 'pages/Admins';
import AdminDetails from 'pages/Admins/UserDetails';
import Currency from 'pages/Currency';

const App = () => {
  const isLoggedIn = useAppSelector(selectLoginIsLoggedIn());

  return (
    <Router>
      <Routes>
        {isLoggedIn ? (
          <Route path="/" element={<PageLayout />}>
            <Route path="categories">
              <Route path="" element={<Categories />} />
              <Route path="details/:id" element={<CategoryDetails />} />
              <Route path="details" element={<CategoryDetails />} />
            </Route>
            <Route path="products">
              <Route path="" element={<Products />} />
              <Route path="details/:opened_articul" element={<ProductDetails />} />
              <Route path="details" element={<ProductDetails />} />
            </Route>
            <Route path="discounts">
              <Route path="" element={<Discounts />} />
            </Route>
            <Route path="educational">
              <Route path="" element={<Educational />} />
              <Route path="details/:id" element={<EducationalDetails />} />
              <Route path="details" element={<EducationalDetails />} />
            </Route>
            <Route path="services">
              <Route path="" element={<CreateService />} />
              <Route path="details/:id" element={<ServiceDetails />} />
              <Route path="details" element={<ServiceDetails />} />
            </Route>
            <Route path="users">
              <Route path="" element={<Users />} />
              <Route path="details/:id" element={<UserDetails />} />
              <Route path="details" element={<UserDetails />} />
              <Route path="subscribe" element={<SubscribeUsers />} />
            </Route>
            <Route path="admins">
              <Route path="" element={<Admins />} />
              <Route path="details/:id" element={<AdminDetails />} />
              <Route path="details" element={<AdminDetails />} />
            </Route>
            <Route path="partners">
              <Route path="" element={<Partners />} />
              <Route path="details/:id" element={<PartnerDetails />} />
              <Route path="details" element={<PartnerDetails />} />
            </Route>
            <Route path="specialist">
              <Route path="" element={<Specialist />} />
              <Route path="details/:id" element={<SpecialistDetails />} />
              <Route path="details" element={<SpecialistDetails />} />
            </Route>
            <Route path="section">
              <Route path="" element={<Sections />} />
              <Route path="products" element={<SectionsAddProduct />} />
            </Route>
            <Route path="attributes">
              <Route path="" element={<Attributes />} />
              <Route path="details/:id" element={<AttributesDetails />} />
              <Route path="details" element={<AttributesDetails />} />
            </Route>
            <Route path="custom_page">
              <Route path="" element={<CustomPage />} />
              <Route path=":id" element={<CustomPageId />} />
            </Route>
            <Route path="" element={<Navigate to="/categories" />} />
            <Route path="blog">
              <Route path="" element={<Blog />} />
              <Route path="details/:id" element={<BlogDetails />} />
              <Route path="details" element={<BlogDetails />} />
            </Route>
            <Route path="contact">
              <Route path="" element={<Contacts />} />
              <Route path="details/:id" element={<ContactDetails />} />
              <Route path="details" element={<ContactDetails />} />
            </Route>
            <Route path="questions" element={<Questions />} />
            <Route path="currency" element={<Currency />} />
            <Route path="orders">
              <Route path="" element={<Orders />} />
              <Route path="details/:id" element={<OrderDetails />} />
            </Route>
            <Route path="roles">
              <Route path="" element={<Roles />} />
              <Route path="details" element={<RolesDetails />} />
              <Route path="details/:id" element={<RolesDetails />} />
            </Route>
          </Route>
        ) : (
          <Route path="">
            <Route path="login" element={<Login />} />
            <Route path="" element={<Navigate to="login" />} />
          </Route>
        )}
      </Routes>
    </Router>
  );
};

export default App;
