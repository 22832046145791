import { Col, Row } from 'antd';
import { selectUserProducts, selectUserProductsCount, selectUserProductsLimit, selectUserProductsPage, selectUserProductsSearch } from 'app/features/users/selectors';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { setUserProductSearch, setUserProductsLimit, setUserProductsPage } from 'app/features/users/slice';

import { SearchStyled } from 'components/ContentTop/styled';
import ProductTable from './ProductsTable';

const UserPage5 = () => {
  const userProductsData = useAppSelector(selectUserProducts());
  const limit = useAppSelector(selectUserProductsLimit());
  const page = useAppSelector(selectUserProductsPage());
  const count = useAppSelector(selectUserProductsCount());
  const search = useAppSelector(selectUserProductsSearch());

  const dispatch = useAppDispatch();

  function setCountInProducts() {
    const newData = userProductsData.map(elem => {
      return {
        ...elem,
        count: 1
      };
    });

    return newData;
  }

  return (
    <Row gutter={[24, 24]}>
      <Col span={24} style={{ textAlign: 'end' }}>
        <SearchStyled
          onChange={e => {
            dispatch(setUserProductSearch(e.target.value));
          }}
          size="large"
          value={search}
          placeholder={'Search Product'}
          enterButton
        />
      </Col>
      <Col span={24}>
        <ProductTable
          products={setCountInProducts()}
          pagination={{
            current: page,
            onChange: (page: number, pageSize: number) => {
              dispatch(setUserProductsPage(page));
              dispatch(setUserProductsLimit(pageSize));
            },
            pageSize: limit,
            total: count
          }}
        />
      </Col>
    </Row>
  );
};

export default UserPage5;
