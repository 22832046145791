import React, { useState, useEffect } from 'react';
import { FileImageOutlined, LogoutOutlined, SlidersOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Layout, Modal, Select, theme, Typography } from 'antd';
import { logOut, userInfoApi } from 'app/features/auth/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Outlet, useNavigate } from 'react-router-dom';
import { selectUserInfo } from 'app/features/auth/selectors';
import { selectPartnerData } from 'app/features/partner/selectors';
import { partnerApi } from 'app/features/partner/slice';
import { getCountryData } from 'assets/countries';
import { currencyApi } from 'app/features/currency/slice';
import { selectCurrencyData } from 'app/features/currency/selectors';
import { setSelect } from 'app/features/currency/selectedcurrencyslice';

import {
  LogoImgWrapper,
  SiderTop,
  LayoutStyled,
  MenuOutlined1,
  MenuOutlined2,
  LogoImg,
  ContentStyled,
  HeaderStyled,
  SiderStyled,
  ContentWrapper,
  MenuWrraper,
  StyledUserInfo,
  InfoItem,
  TopNavBarInfo
} from './styled';
import Logo from '../../assets/img/Logo.png';
import MenuSider from 'components/MenuSider';
import ImagePage from 'components/ImagePage';
import SliderPage from 'components/SliderPage';

const { Paragraph } = Typography;
type CountryAndCurrencyType = {
  [key: string]: string;
};

const CountryAndCurrencyList: CountryAndCurrencyType = {
  Georgia: 'GEL',
  Armenia: 'AMD',
  Russia: 'RUB'
};

const PageLayout: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSlider, setOpenSlider] = useState(false);

  const countries = getCountryData();

  const {
    token: { colorBgContainer }
  } = theme.useToken();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onClickOut = () => {
    Modal.confirm({
      title: 'LogOut',
      content: <Paragraph>Are you sure?</Paragraph>,
      onOk: () => {
        dispatch(logOut());
        navigate('/login');
      }
    });
  };

  useEffect(() => {
    dispatch(userInfoApi());

    dispatch(currencyApi(50));
  }, []);

  const userInfo = useAppSelector(selectUserInfo());
  const partners = useAppSelector(selectPartnerData());
  const currencyData = useAppSelector(selectCurrencyData());

  const [showUserInfo, setShowUserInfo] = useState<boolean>(false);

  useEffect(() => {
    if (partners.length === 0) {
      dispatch(partnerApi());
    }
  }, [partners]);

  const TopUserInfo = () => {
    const countryName = String(countries.find(item => item.id === userInfo?.country_id)?.name);
    const defaultCurrency = CountryAndCurrencyList[countryName] || 'USD';

    const savedCurrency = localStorage.getItem('selectedCurrency') || defaultCurrency;
    const [selectedCurrency, setSelectedCurrency] = useState<string>(savedCurrency);

    useEffect(() => {
      if (savedCurrency) {
        dispatch(setSelect(savedCurrency));
      } else {
        setSelectedCurrency(defaultCurrency);

        dispatch(setSelect(defaultCurrency));
      }
    }, []);

    useEffect(() => {
      if (selectedCurrency !== savedCurrency) {
        localStorage.setItem('selectedCurrency', selectedCurrency);
      }
    }, [selectedCurrency]);

    const handleCurrencyChange = (value: string) => {
      setSelectedCurrency(value);
      dispatch(setSelect(value));
    };

    return (
      <Typography>
        <TopNavBarInfo>
          {userInfo?.role && (
            <InfoItem>
              Role: <strong>{userInfo.role.replaceAll('_', '-').toLowerCase()}</strong>
            </InfoItem>
          )}
          {userInfo?.partner_id && !!partners.length && (
            <InfoItem>
              Partner: <strong>{partners.find(item => item.id === userInfo.partner_id)?.name}</strong>
            </InfoItem>
          )}
          {userInfo?.country_id && (
            <InfoItem>
              Country: <strong>{countries.find(item => item.id === userInfo?.country_id)?.name}</strong>
            </InfoItem>
          )}
          <Select
            defaultValue={selectedCurrency}
            style={{ margin: '10px', width: '200px' }}
            showSearch
            onSelect={handleCurrencyChange}
            placeholder="Select Currency"
            optionLabelProp="label"
            optionFilterProp="label"
          >
            {currencyData?.map(country => {
              return (
                <Select.Option key={country.currencyCode} value={country.currencyCode} label={country.currencyCode} country={country}>
                  {`${country.currencyCode}: ${country.conversionRate}`}{' '}
                </Select.Option>
              );
            })}
          </Select>
        </TopNavBarInfo>
      </Typography>
    );
  };

  return (
    <LayoutStyled>
      <ImagePage open={open} setOpen={setOpen} />
      <SliderPage openSlider={openSlider} setOpenSlider={setOpenSlider} />
      <SiderStyled trigger={null} collapsible collapsed={collapsed} collapsedWidth={60} width={220}>
        <SiderTop collapsed={collapsed}>{collapsed ? <MenuOutlined2 onClick={() => setCollapsed(!collapsed)} /> : <MenuOutlined1 onClick={() => setCollapsed(!collapsed)} />}</SiderTop>
        <MenuSider />
      </SiderStyled>
      <Layout>
        <HeaderStyled>
          <LogoImgWrapper onClick={() => navigate('/categories')}>
            <LogoImg src={Logo} alt="logo" />
          </LogoImgWrapper>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TopUserInfo />
            <MenuWrraper>
              <Button size="large" icon={<SlidersOutlined />} onClick={() => setOpenSlider(true)}>
                Slider
              </Button>
              <Button size="large" icon={<FileImageOutlined />} onClick={() => setOpen(true)}>
                Images
              </Button>
              <StyledUserInfo open={showUserInfo}>
                <UserOutlined className="icon" onClick={() => setShowUserInfo(!showUserInfo)} />
                <div className="dropDown">
                  <div className="title">
                    Name:{' '}
                    <b>
                      {userInfo?.firstName} {userInfo?.lastName}
                    </b>
                  </div>
                  <div className="title">
                    E-Mail: <b> {userInfo?.email}</b>
                  </div>
                  <div className="title">
                    Role: <b> {userInfo?.role}</b>
                  </div>
                  {userInfo?.partner_id && !!partners.length && (
                    <div className="title">
                      Partner: <b> {partners.find(item => item.id === userInfo.partner_id)?.name}</b>
                    </div>
                  )}
                  <Button size="large" onClick={onClickOut} icon={<LogoutOutlined />}>
                    LogOut
                  </Button>
                </div>
              </StyledUserInfo>
            </MenuWrraper>
          </div>
        </HeaderStyled>
        <ContentWrapper collapsed={collapsed}>
          <ContentStyled bgcolor={colorBgContainer}>
            <Outlet />
          </ContentStyled>
        </ContentWrapper>
      </Layout>
    </LayoutStyled>
  );
};

export default PageLayout;
