import { UploadOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { FC } from 'react';

import { AddButton, ContentTopTitle, SearchStyled, AddButtonWrapper, ContentTopWrapper, UploadDraggerFile } from './styled';
import { IContentTopProps } from './types';

const ContentTop: FC<IContentTopProps> = ({ title, upload, placeholder, onClickAdd, buttonText, onChange, searchComponent, search, onChangeUpload, add }) => {
  return (
    <ContentTopWrapper>
      <ContentTopTitle>
        <Typography.Title style={{ marginTop: '8px', width: 'max-content' }}>{title}</Typography.Title>
        {searchComponent && <SearchStyled onChange={onChange} size="large" value={search} placeholder={placeholder} enterButton />}
      </ContentTopTitle>
      {add && (
        <AddButtonWrapper>
          {upload && (
            <UploadDraggerFile
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .ods"
              maxCount={1}
              beforeUpload={() => false}
              onChange={onChangeUpload}
            >
              <Button size="large" icon={<UploadOutlined />}>
                Upload Excel File
              </Button>
            </UploadDraggerFile>
          )}
          <AddButton size="large" onClick={onClickAdd} type="primary">
            {buttonText}
          </AddButton>
        </AddButtonWrapper>
      )}
    </ContentTopWrapper>
  );
};

export default ContentTop;
