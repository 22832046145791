import { RootState } from 'app/store';

export const selectCustomPageLoading = () => (state: RootState) => state.customPage.loading;

export const selectCustomPageData = () => (state: RootState) => state.customPage.customPage;

export const selectCustomPageById = () => (state: RootState) => state.customPage.customPageById;

export const selectCustomPageLoadingById = () => (state: RootState) => state.customPage.loadingById;

export const selectCustomPageLoadingAction = () => (state: RootState) => state.customPage.loadingAction;

export const selectAboutFiles = () => (state: RootState) => state.customPage.aboutFiles;
