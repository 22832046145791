import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api/apiClient';
import { selectServiceLimit, selectServicePage, selectServiceSearch } from 'app/features/services/selectors';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage, showErrorUploadMessage } from 'helpers/errors';
import { Modal } from 'antd';

import { IServiceEditValues, IServicePostValues, ServicesState } from './types';

const initialState: ServicesState = {
  services: [],
  serviceById: null,
  loadingAction: false,
  loadingByID: false,
  loading: false,
  page: 1,
  limit: 10,
  search: '',
  count: 0
};

export const serviceApi = createAsyncThunk('services/serviceApi', async (_, { getState, dispatch, rejectWithValue }) => {
  const state = getState() as RootState;
  const page = selectServicePage()(state);
  const search = selectServiceSearch()(state);
  const limit = selectServiceLimit()(state);

  try {
    const res = await client.get(`/services`, {
      params: {
        search,
        page,
        limit
      }
    });

    return dispatch(setService(res.data.data));
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const getServiceById = createAsyncThunk('services/serviceById', async (id: number, { rejectWithValue }) => {
  try {
    const response = await client.get(`/services/${id}`);

    return response.data.data;
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const deleteService = createAsyncThunk('services/deleteService', async (id: number, { dispatch, rejectWithValue }) => {
  try {
    await client.delete(`/services/${id}`);
    await dispatch(serviceApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const postService = createAsyncThunk('services/postService', async (data: IServicePostValues, { dispatch, rejectWithValue }) => {
  try {
    await client.post('/services', {
      ...data
    });
    await dispatch(serviceApi());
  } catch (err) {
    return rejectWithValue(err as AxiosError<ErrorType>);
  }
});

export const handleUpload = createAsyncThunk('services/handleUpload', async (file: File, { dispatch, rejectWithValue }) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    await client.post('service/excel', formData).then(() => {
      Modal.success({
        content: 'Excel file uploaded successfully!'
      });
    });
    await dispatch(serviceApi());
  } catch (err) {
    return rejectWithValue(showErrorUploadMessage(err as AxiosError<ErrorType>));
  }
});

export const serviceEdit = createAsyncThunk('services/servicesEdit', async ({ id, ...data }: IServiceEditValues, { dispatch, rejectWithValue }) => {
  try {
    await client.patch(`/services/${id}`, {
      ...data
    });

    await dispatch(serviceApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setService: (state, action) => {
      state.services = action.payload.rows;
      state.count = action.payload.count;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    clickMenuCreateService: state => {
      (state.limit = 10), (state.page = 1), (state.search = '');
    }
  },
  extraReducers(builder) {
    builder
      .addCase(deleteService.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteService.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteService.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(serviceEdit.pending, state => {
        state.loadingAction = true;
      })
      .addCase(serviceEdit.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(serviceEdit.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(postService.pending, state => {
        state.loadingAction = true;
      })
      .addCase(postService.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(postService.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(serviceApi.pending, state => {
        state.loading = true;
      })
      .addCase(serviceApi.fulfilled, state => {
        state.loading = false;
      })
      .addCase(serviceApi.rejected, state => {
        state.loading = false;
      })
      .addCase(getServiceById.pending, state => {
        state.loadingByID = true;
      })
      .addCase(getServiceById.fulfilled, (state, action) => {
        state.serviceById = action.payload;
        state.loadingByID = false;
      })
      .addCase(getServiceById.rejected, state => {
        state.loadingByID = false;
      })
      .addCase(handleUpload.pending, state => {
        state.loading = true;
      })
      .addCase(handleUpload.fulfilled, state => {
        state.loading = false;
      })
      .addCase(handleUpload.rejected, state => {
        state.loading = false;
      });
  }
});

export const { setPage, setSearch, setService, setLimit, clickMenuCreateService } = servicesSlice.actions;
export default servicesSlice.reducer;
