import { AxiosError } from 'axios';

import { client } from '../apiClient';
import { ErrorType, showErrorMessage } from '../../helpers/errors';
import { ProductData, ProductDataUpdate, ProductJournal } from '../../pages/ProductDetails/components/ProductPrices/types';
import { getQueryString } from '../../helpers/object';

export const getProductPrices = async (productId: number, currency?: string): Promise<ProductData | null> => {
  try {
    const queryString = getQueryString({ currency });

    const response = await client.get(`/product/margins/${productId}${queryString}`);

    return response.data.data;
  } catch (error) {
    showErrorMessage(error as AxiosError<ErrorType>);

    return null;
  }
};

export const updateProductPrices = async (productData: ProductDataUpdate) => {
  try {
    const response = await client.patch(`/product/margins/${productData.productId}`, productData);

    return response.data.data;
  } catch (error) {
    return showErrorMessage(error as AxiosError<ErrorType>);
  }
};

export const calculateFinalPrice = async (data: { basePrice: number; margin: number | string; countryId: number }) => {
  try {
    const response = await client.post(`product/calculate`, data);

    return response.data.data as { result: number; resultNoVat: number };
  } catch (error) {
    return showErrorMessage(error as AxiosError<ErrorType>);
  }
};

export const getProductPriceJournals = async ({ productCode, countryIds }: { productCode: string | number; countryIds?: (number | null)[] }): Promise<ProductJournal[][]> => {
  if (!countryIds || countryIds.length === 0) {
    return [];
  }

  try {
    const promises = countryIds.map(countryId => {
      const queryString = getQueryString({ country: countryId });

      return client.get(`product_hc/getJournal/${productCode}${queryString}`);
    });

    const responses = await Promise.all(promises);

    return responses.map(response => response.data.data);
  } catch (error: any) {
    const errorResponseMessage = error?.response?.data?.message;

    if (errorResponseMessage === 'No journal entries found for the product code') {
      return [];
    }

    showErrorMessage(error as AxiosError<ErrorType>);

    return [];
  }
};
