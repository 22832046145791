import styled, { css } from 'styled-components';

interface ISolutionModal {
  open: boolean;
}

export const StyledSection = styled.section`
  padding: 0 24px;
  * {
    font-family: Montserrat !important;
  }
`;

export const ProductFigure = styled.figure`
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  .title {
    color: #19293b;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 30px;
  }
  .producInfoSections {
    width: 100%;
    margin: 0 auto;
    .generalTitle {
      font-size: 18px;
      color: #19293b;
      margin: 10px 0;
    }
    #description {
      div {
        p {
          color: #000;
          font-size: 14px;
          line-height: 26px;
          font-style: normal;
          letter-spacing: 0.72px;
        }
      }
    }
    #datasheets {
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 120px;
        flex-direction: column;
      }
      .file_name {
        color: #19293b;
        font-family: Montserrat;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
      }
    }
    #characteristic {
      span {
        line-height: 29px;
      }
    }
    .title {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:has(.moreBtn) {
        margin: 0 0 20px 0;
      }
      .moreBtn {
        position: relative;
        background-color: transparent;
        cursor: pointer;
        color: #19293b;
        font-family: Montserrat;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        width: 170px;
        border-radius: 20px;
        border: 1px solid #19293b;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        transition: 0.5s;
        span {
          position: absolute;
          left: 10px;
          font-size: 16px;
        }
        &:hover {
          background-color: #19293b;
          transition: 1s;
          color: white;
          span {
            left: 85%;
            transition: 0.5s;
          }
        }
      }
    }
  }
`;

export const ProductFigcap = styled.figcaption`
  height: 440px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ProductImg = styled.div`
  width: 350px;
  height: 350px;
  border: 0.5px solid var(--gray);

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const Price = styled.span`
  color: #19293b;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  .defaultPrice {
    color: #19293b;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 10px;
  }
  .saledPrice {
    color: #d1d3d4;
    font-family: Montserrat;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: line-through;
    margin-left: 10px;
    padding-bottom: 5px;
  }
`;

export const SalesBody = styled.div`
  transform-origin: top;
  transition: 0.5s ease;
  padding: 10px 0;
  transform: scaleY(0);
  position: absolute;
  > div {
    visibility: hidden;
  }
`;

export const SalesDrop = styled.div`
  width: 250px;
  border: 0.5px solid var(--primary);
  border-radius: 3px;
  padding: 6px 16px 6px 10px;
  transition: width 1s ease;
  label {
    p {
      color: #949798;
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  > input {
    visibility: hidden;
    display: none;

    :checked + label {
      > img {
        transform: rotate(90deg);
      }
    }

    :checked + label + div {
      transform: scaleY(1);
      transition: 0.5s ease;
      position: static;
      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 14px;
        visibility: visible;
        margin-top: 10px;
      }
    }
  }
`;

export const SalesTitle = styled.label`
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  p {
    color: #949798;
  }

  img {
    width: 6.5px;
    height: 12px;
    transition: 0.3s rotate;
    transition: transform 0.3s ease;
  }
`;

export const InProcessing = styled.div<{ percentage: number; color: string }>`
  width: 83px;
  height: 7px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  background: linear-gradient(to right, ${props => props.color} ${props => props.percentage}%, #f2eeee ${props => props.percentage}%);
`;
export const ProductCounterContainer = styled.div<{ length: number }>`
  position: relative;
  min-width: 78px;
  height: 22px;
  padding: 2px 5px;
  border-radius: 2px;
  border: 0.5px solid #19293b;
  background: #fff;
  .minus {
    position: absolute;
    left: 10px;
    top: 50%;
    color: #19293b;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .plus {
    cursor: pointer;
    position: absolute;
    right: 10px;
    color: #19293b;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    top: 50%;
    transform: translateY(-50%);
  }

  ${({ length }) =>
    length
      ? css`
          width: ${length * 16}px;
        `
      : css`
          width: 40px;
        `}
`;

export const Availabil = styled.span`
  display: flex;
  gap: 15px;
  color: #19293b;
  font-family: Montserrat arm;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  .unit {
    color: #19293b;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border-radius: 2px;
    border: 0.5px solid #19293b;
    background: #fff;
    height: 22px;
    min-width: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -8px;
  }
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  .title {
    color: #000;
    font-family: Roboto;
    font-size: 26px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 15px;
  }
  .saleInfo {
    color: #19293b;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: inline-block;
  }
  .description3Line {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    color: #000;
    font-family: Montserrat arm;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 29px; /* 207.143% */
    letter-spacing: 0.56px;
  }
  .brand {
    font-family: Montserrat arm;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #19293b;
    font-size: 14px;
    span {
      :nth-child(2) {
        color: #00909e;
        cursor: pointer;
      }
    }
  }
  .categories {
    .container {
      margin-top: 10px;
      display: flex;
      gap: 10px;
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        font-size: 16px;
        gap: 5px;
        padding: 5px;
        &:last-child {
          .arrow {
            display: none;
          }
        }
      }
    }
  }
`;

export const ProductDeliver = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-block: 10px 20px;

  svg {
    fill: black;
  }
`;

export const DeliveryIcons = styled.div`
  display: flex;
  gap: 16px;
  margin-left: 5px;

  div {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  input:checked + label > svg > path,
  input:checked + label {
    fill: var(--primary);
    color: var(--primary);
  }
  input {
    cursor: pointer;
    accent-color: var(--primary);
  }

  label {
    cursor: pointer;
    font-size: var(--text-10);
  }
`;

export const ProductColor = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  ul {
    display: flex;
    gap: 15px;

    input {
      cursor: pointer;
      margin-right: 5px;
      accent-color: var(--primary);
    }
    input:checked + label {
      color: var(--primary);
    }

    label {
      cursor: pointer;
      font-size: var(--text-10);
    }
  }
`;

export const ProductButtons = styled.div`
  position: absolute;
  bottom: 0;
  width: 48%;
  button {
    width: 100%;
    color: #fff;
    height: 45px;
    font-family: Montserrat arm;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 5px;
    background: #00909e;
    box-shadow: 2px 2px 2px 0px rgba(25, 41, 59, 0.2);
  }
`;

export const ProductAdvertising = styled.div<{ image: boolean }>`
  ${({ image }) =>
    image
      ? css`
          width: 100%;
          position: relative;
          margin-block: 52px 20px;
          height: 180px;
        `
      : css`
          margin-block: 20px;
        `}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ProductForm = styled.form`
  margin: 30px auto 0;
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  z-index: 2;
  position: relative;
  width: 70%;

  h1 {
    text-align: center;
  }

  button {
    font-size: var(--text-9);
  }

  @media only screen and (max-width: 884px) {
    width: 100%;
  }
`;

export const ProductInputs = styled.div`
  display: grid;
  gap: 10px;
  width: 100%;
  grid-template-columns: repeat(2, minmax(250px, 1fr));
`;
export const StyledBg = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const ProductIcons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ProductWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;
export const StyledConnSolutionWrapper = styled.div`
  position: relative;
  & > img {
    width: 100%;
  }
`;
export const StyledConnSolutionTrigger = styled.div`
  position: absolute;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.314);
  width: 32px;
  height: 32px;
  display: block;
  border-radius: 50%;
  .iconsCont {
    position: absolute;
    left: 50%;
    top: 50%;
    .icon {
      width: 20px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const StyledConnSolutionModal = styled.div`
  cursor: default;
  position: absolute;
  position: absolute;
  background-color: white;
  z-index: 2;
  padding: 30px 20px;
  transform: translate(-50%, 20%);
  border-radius: 5px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

  .close {
    position: absolute;
    top: 16px;
    right: 16px;
    line-height: 12px;
    font-size: 22px;
    cursor: pointer;
  }
  .modal-content {
    padding: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    img {
      width: 100px;
      height: 100px;
    }
    .title {
      width: max-content;
      margin-bottom: 0;
      a {
        font-size: 14px;
        cursor: pointer;
        line-height: 22px;
        font-weight: 500;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        scroll-margin-block-end: 40px;
        &:hover {
          text-decoration: underline;
        }
      }
      .prices {
        transform: translateY(100%);
      }
    }
    .prices {
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      line-height: 24px;
      .price {
        color: #00c11f;
      }
      .defaultPrice {
        color: #19191a;
        text-decoration: line-through;
      }
    }
  }
  .basketIcon {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 32px;
    height: 32px;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: 50%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #19191a;
    font-size: 16px;
    background: #fff;
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.3);
    right: 5px;
    bottom: 5px;
    cursor: pointer;
  }
`;

export const GeneralProduct = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 50px 0;

  div:has(${ProductFigcap}) {
    display: grid;
    width: 100%;
    grid-template-columns: minmax(300px, 1fr) minmax(300px, 1fr);
    gap: 20px;
  }
`;

export const Features = styled.div`
  /* width: 100%;
  margin-top: 30px;
  padding: 26px;
  box-shadow: 1px 1px 3px 3px rgba(25, 41, 59, 0.15);
  display: flex;
  flex-direction: column;
  gap: 20px;
  div {
    white-space: pre-wrap;
    
    word-wrap: break-word;
    width: 100%;
    table {
      border: none;
      border-collapse: collapse;
      empty-cells: show;
      margin-bottom: 1em;
      margin-top: 1em;
      max-width: 100%;
    }
    td {
      border: 1px solid #dadada;
      min-width: 2em;
      padding: 0.4em;
      -webkit-user-select: text;
      -moz-user-select: text;
      -ms-user-select: text;
      user-select: text;
      vertical-align: middle;
    }
    .ql-syntax {
      background-color: #f0f0f0;
      color: #333;
      padding: 10px;
      font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
      font-size: 14px;
      line-height: 1.4;
      overflow-x: auto;
      border-radius: 4px;
      margin-bottom: 10px;
    }
    .ql-align-justify {
      text-align: justify;
    }
    .ql-align-right {
      text-align: right;
    }
    .ql-align-center {
      text-align: center;
    }
    .ql-indent-1 {
      padding-left: 3em;
    }
    .ql-indent-2 {
      padding-left: 6em;
    }
    .ql-indent-3 {
      padding-left: 9em;
    }
    .ql-indent-4 {
      padding-left: 12em;
    }
    .ql-indent-5 {
      padding-left: 15em;
    }
    .ql-indent-6 {
      padding-left: 18em;
    }
    .ql-indent-7 {
      padding-left: 21em;
    }
    .ql-indent-8 {
      padding-left: 24em;
    }

    h1 {
      font-size: 48px;
    }
    h2 {
      font-size: 36px;
    }
    h3 {
      font-size: 24px;
    }
    h4 {
      font-size: 18px;
    }
    h5 {
      font-size: 16px;
    }
    h6 {
      font-size: 14px;
    }
    ul {
      list-style-type: disc;
      list-style-position: inside;
    }
    ol {
      list-style-type: decimal;
      list-style-position: inside;
    }
    ul ul,
    ol ul {
      list-style-type: circle;
      list-style-position: inside;
      margin-left: 15px;
    }
    ol ol,
    ul ol {
      list-style-type: lower-latin;
      list-style-position: inside;
      margin-left: 15px;
    }
    img {
      max-width: 100%;
      border-radius: 10px;
    }
  } */
  img {
    width: 100%;
    height: 100%;
  }
`;

export const Connectivity = styled.div`
  * {
    user-select: none;
  }
  position: relative;
  p {
    font-size: 20px;
    line-height: 30px;
    color: #19191a;
    font-weight: 600;
    padding: 20px 0;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .body {
    padding: 36px 28px 0;
    .scenario1 {
      position: relative;
      padding: 32px 90px !important;
      border-radius: 4px;
      border: 1px dashed #ccc;
      margin-bottom: 44px;
      .title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: #19191a;
        padding: 0 10px;
        position: absolute;
        z-index: 1;
        top: 0;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        background: #fff;
        left: 20px;
      }
      img {
        width: 100%;
      }
    }
  }
`;
export const Toggler = styled.div<{ open: boolean }>`
  background-color: #00000050;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  position: absolute;
  top: 56%;
  left: 31.5%;
  transform: translateY(-50%);
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  .tagIcon {
    position: relative;
    color: white;
    font-size: 20px;
    transform: scale(0);
    transition: 0s;
    width: 0;
  }
  .tag {
    transform: scale(0) rotate(0deg);
  }
  ${({ open }) =>
    open
      ? css`
          .close {
            transition: 0.3s;
            transform: scale(1) rotate(180deg);
            width: auto;
          }
        `
      : css`
          .tag {
            transition: 0s;
            transform: scale(1) rotate(270deg);
            width: auto;
          }
        `}
`;

export const ProductModal = styled.div<ISolutionModal>`
  width: 503px;
  position: absolute;
  inset: 200px 100px;
  margin: 0px;
  z-index: 5;
  display: block;
  transition: 1s;
  overflow: hidden;
  border-radius: 8px;
  height: fit-content;
  transform-origin: top;
  border: 1px solid gray;
  figure {
    border: none !important;
  }
  transform: ${({ open }) => (open ? 'scaleY(1)' : 'scaleY(0)')};
  opacity: ${({ open }) => (open ? '1' : '0')};
`;

export const Buttons = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    gap: 10px;
    cursor: pointer;

    :hover {
      opacity: 0.8;
    }

    img {
      width: 25px;
      height: 25px;
    }

    button {
      border: none;
      background-color: var(--white);
      font-weight: 700;
      font-size: 24px;
      cursor: pointer;
    }
  }
`;

export const StyledProductShortInfo = styled.div<{ show: boolean }>`
  transform: scaleY(0);
  position: relative;
  transform-origin: top;
  height: 128px;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  border: 1px solid #d1d3d4;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(25, 41, 59, 0.15);
  width: 100%;
  padding: 0;
  ${({ show }) =>
    !show
      ? css`
          transform: scaleY(1);
        `
      : null};
  .product {
    margin: 0 auto;
    height: 80%;
    display: flex;
    justify-content: space-between;
    max-width: 1440px;
    width: 85%;
    .imageContainer {
      margin-top: 20px;
      img {
        min-width: 60px;
        height: 60px;
      }
    }
    .title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      color: #000;
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
    ${Price} {
      font-size: 16px;
      span {
        font-size: 16px;
      }
    }
  }
  .price {
    display: flex;
    align-items: flex-end;
    height: 60px;
    p {
      color: #19293b;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.7px;
    }
    .qtyCont {
      display: flex;
      align-items: flex-end;
      gap: 10px;
      button {
        border-radius: 5px;
        background: #00909e;
        box-shadow: 2px 2px 2px 0px rgba(25, 41, 59, 0.2);
        color: #fff;
        font-family: Montserrat arm;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  .menu {
    width: 65%;
    margin: auto;
  }
`;

export const StyledVideoText = styled.p<{ more: boolean }>`
  position: relative;
  color: #000;
  font-family: Montserrat arm;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px; /* 208.333% */
  letter-spacing: 1.2px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  ${({ more }) => (more ? '-webkit-line-clamp:9999' : '-webkit-line-clamp:2')};
  overflow: hidden;

  .readMoreBtn {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: white;
    cursor: pointer;
    color: #19293b;
    font-family: Montserrat arm;
    font-size: 12px;
    font-style: normal;
    font-weight: 900;
    line-height: 25px;
    letter-spacing: 1.2px;
  }
`;

export const StyledVideosContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(250px, 390px));
  gap: 10px;
  .body {
    border: 1px solid #00909e;
    background: #fff;
    padding: 15px 20px;
    margin-top: 10px;
    .title {
      color: #000;
      font-family: Helvetica;
      font-size: 18px;
      margin-bottom: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 111.111% */
    }
    .more {
      -webkit-line-clamp: 999999;
    }
    iframe {
      width: 100% !important;
      height: 275px !important;
    }
  }
`;

export const AccordionWrapper = styled.div`
  border: none;
  width: 100%;
  border-radius: 3px;
`;

export const AccordionTitle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-inline: 26px 28px;
  justify-content: space-between;
  height: 70px;
  background-color: transparent;

  img {
    width: 8.5px;
    height: 16px;
  }
`;

export const AccordionDrop = styled.div`
  border: none;
  border-top: 1px solid #d1d3d4;
  white-space: pre-wrap;
  word-wrap: break-word;
  div {
    text-align: center;
  }
`;

export const AttributesStyled = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgba(209, 211, 212, 0.5);
  :nth-child(2n + 1) {
    background: rgba(209, 211, 212, 0.3);
  }
  div {
    max-width: 50%;
    width: 50%;
    word-break: normal;
    color: #19293b;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 43px; /* 238.889% */
    letter-spacing: 1.8px;
  }
`;

export const AttributeDescription = styled.div`
  text-align: center !important;
  border-right: 1px solid rgba(209, 211, 212, 0.5);
`;

export const Catalogue = styled.div`
  ul {
    li {
      display: flex;

      img {
        width: 300px;
      }
    }
  }
`;
