import { FC } from 'react';

import { WrapperInput, StyledInput, StyledLabel } from './styled';
import { IInputProps } from './types';

const Input: FC<IInputProps> = ({ value, name, label, ...rest }) => {
  return (
    <div>
      <WrapperInput filled={value ? value.length > 0 : false}>
        <StyledInput name={name} id={name} autoComplete="off" {...rest} />
        <StyledLabel htmlFor={name}>{label}</StyledLabel>
      </WrapperInput>
    </div>
  );
};

export default Input;
