import { Col, Form, Row, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectRoleById, selectRolesLoading } from 'app/features/roles/selectors';
import { clearRoleState, createRole, editRole, getRoleById } from 'app/features/roles/slice';
import Input from 'antd/es/input/Input';
import { menuListArr, requestsArr } from 'helpers/userParmissions';
import { IRoleEditValues } from 'app/features/roles/types';

import DetailsHead from 'components/DetailsHead';
import { Checkbox } from 'components/ProductsListModal/styled';
import { StyledListChild, StyledListContainer } from './styled';
import { IPerimission } from '../types';

const RolesDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [roleName, setRoleName] = useState<string>('');
  const [form] = Form.useForm();
  const loading = useAppSelector(selectRolesLoading());
  const role = useAppSelector(selectRoleById());

  const [requestTypes, setRequestTypes] = useState<IPerimission[]>(requestsArr);
  const [pagesList, setPagesList] = useState<IPerimission[]>(menuListArr);

  useEffect(() => {
    const updateListItems = (listToUpdate: IPerimission[], permissions: string[]) => {
      return listToUpdate.map(item => ({
        ...item,
        checked: permissions?.includes(item.key) ?? false
      }));
    };

    if (role?.permission_request || role?.permision_menu) {
      if (role.permission_request) {
        setRequestTypes(updateListItems(requestTypes, role.permission_request));
      }

      if (role.permision_menu) {
        setPagesList(updateListItems(pagesList, role.permision_menu));
      }
    }
  }, [role]);

  const onClose = () => {
    form.resetFields();
    navigate(-1);
  };

  useEffect(() => {
    if (id) {
      dispatch(getRoleById(id));
    }

    return () => {
      dispatch(clearRoleState());
    };
  }, [dispatch, id]);

  const handleCheckRequest = (item: IPerimission) => {
    const updatedRequestTypes = requestTypes.map(requestType => {
      if (requestType.key === item.key) {
        return {
          ...requestType,
          checked: !requestType.checked
        };
      }

      return requestType;
    });

    setRequestTypes(updatedRequestTypes);
  };

  const handleCheckMenuList = (item: IPerimission) => {
    const updatedMenuList = pagesList.map(menuItem => {
      if (item.key === menuItem.key) {
        return {
          ...menuItem,
          checked: !menuItem.checked
        };
      }

      return menuItem;
    });

    setPagesList(updatedMenuList);
  };

  useEffect(() => {
    if (id && role) {
      form.setFieldsValue({
        name: role.name
      });
    }
  }, [form, id, role]);

  const onSave = async (values: IRoleEditValues) => {
    const menu: string[] = [];
    const reqs: string[] = [];
    pagesList.map(item => {
      if (item.checked) {
        menu.push(item.key);
      }
    });
    requestTypes.map(item => {
      if (item.checked) {
        reqs.push(item.key);
      }
    });

    if (id && role) {
      if (menu.length == 0 || reqs.length == 0) {
        message.error('The role contains at least one permission type! In case you don’t need the role, please delete the role.');

        return;
      }

      const data = {
        name: role.name,
        permission_request: Object.values(reqs),
        permision_menu: Object.values(menu)
      };

      await dispatch(editRole({ id, data })).then(() => {
        onClose();
      });
    } else {
      const data = {
        name: values.name,
        permission_request: Object.values(reqs),
        permision_menu: Object.values(menu)
      };

      const res = (await dispatch(createRole(data))) as {
        meta: { requestStatus: string };
        payload: { message: string };
      };

      if (res.meta.requestStatus === 'rejected') {
        form.setFields([
          {
            name: 'name',
            errors: ['The role name is required and must consist of capital letters and underscores']
          }
        ]);
      } else {
        onClose();
      }
    }
  };

  return (
    <>
      <Form
        size="large"
        form={form}
        layout="vertical"
        onFinish={onSave}
        scrollToFirstError={{
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        }}
      >
        <DetailsHead onClose={onClose} id={id} form={form} loadingAction={loading} headTitle={'Role'} />
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Col span={12}>
              <Form.Item name="name" required label="Role Name">
                <Input
                  type="text"
                  value={roleName}
                  placeholder="Enter Role name"
                  onChange={e => {
                    setRoleName(e.target.value.toUpperCase());
                  }}
                />
              </Form.Item>
            </Col>
          </Col>
          <Col span={24}>
            <p
              style={{
                fontSize: '18px',
                display: 'block',
                marginBottom: '10px'
              }}
            >
              Available Requests
            </p>
            <StyledListContainer>
              {requestTypes.map((item, index) => {
                return (
                  <StyledListChild active={item.checked} key={index} onClick={() => handleCheckRequest(item)}>
                    <p>{item.key}</p>
                    <Checkbox readOnly checked={item.checked} />
                  </StyledListChild>
                );
              })}
            </StyledListContainer>
          </Col>
          <Col span={24}>
            <p
              style={{
                fontSize: '18px',
                display: 'block',
                marginBottom: '10px'
              }}
            >
              Available Pages
            </p>
            <StyledListContainer>
              {pagesList.map((item, index) => {
                return (
                  <StyledListChild active={item.checked} key={index} onClick={() => handleCheckMenuList(item)}>
                    <p style={{ textTransform: 'capitalize' }}>{item.key.toLowerCase()}</p>
                    <Checkbox readOnly checked={item.checked} />
                  </StyledListChild>
                );
              })}
            </StyledListContainer>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default RolesDetails;
