import { Button, Image, Table } from 'antd';
import { deletePartner, partnerApi, setLimit, setPage, setSearch } from 'app/features/partner/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectPartnerData,
  selectPartnerLoading,
  selectPartnerLoadingAction,
  selectPartnersCount,
  selectPartnersLimit,
  selectPartnersPage,
  selectPartnersSearch
} from 'app/features/partner/selectors';
import { useEffect, useState } from 'react';
import { _debounce } from 'helpers/debounce';
import { Link, useNavigate } from 'react-router-dom';
import { DeleteOutlined, EditTwoTone } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { IPartner } from 'app/features/partner/types';
import { getFileUrl } from 'helpers/file';

import ContentTop from 'components/ContentTop';
import { ButtonsWrapper, ImgTableWrraper } from './styled';
import DeleteModal from 'components/DeleteModal';
import noImg from '../../assets/img/noimage.png';

const debounce = _debounce();

const Partners = () => {
  //state
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [partnerId, setPartnerId] = useState<number>(0);
  const navigate = useNavigate();

  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  const partnerData = useAppSelector(selectPartnerData());
  const loading = useAppSelector(selectPartnerLoading());
  const page = useAppSelector(selectPartnersPage());
  const limit = useAppSelector(selectPartnersLimit());
  const count = useAppSelector(selectPartnersCount());
  const search = useAppSelector(selectPartnersSearch());
  const loadingAction = useAppSelector(selectPartnerLoadingAction());

  useEffect(() => {
    debounce(() => dispatch(partnerApi()));
  }, [dispatch, search, page, limit]);

  // useEffect(() => {
  //   if (!pathname.includes('/partners')) {
  //     return () => {
  //       dispatch(setPage(1));
  //       dispatch(setSearch(''));
  //       dispatch(setLimit(10));
  //     };
  //   }
  // }, [dispatch, pathname]);

  const columns: ColumnsType<IPartner> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Url',
      dataIndex: 'url',
      key: 'url',
      render: (text: string) => {
        return (
          <>
            <a href={text} target="_blank" rel="noreferrer">
              {text}
            </a>
          </>
        );
      }
    },
    {
      title: 'Image',
      dataIndex: 'Files',
      key: 'image',
      width: 100,
      render: (_, item) => {
        return (
          <ImgTableWrraper>
            {item.files?.length ? (
              <Image width={70} alt={item.files[0].name} src={getFileUrl(item.files[0]?.name, item.files[0]?.extension, '')} />
            ) : (
              <Image preview={false} width={70} src={noImg} alt="No Image" />
            )}
          </ImgTableWrraper>
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 220,
      key: 'Action',
      render: (_, record) => {
        return (
          <ButtonsWrapper>
            <Link to={`details/${record.id}`}>
              <Button icon={<EditTwoTone />}>Edit</Button>
            </Link>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                setPartnerId(record.id);
                setOpenDeleteModal(true);
              }}
            >
              Delete
            </Button>
          </ButtonsWrapper>
        );
      }
    }
  ];

  return (
    <>
      <ContentTop
        title="Partners"
        placeholder="Search Partner"
        buttonText="Add Partner"
        onClickAdd={() => navigate('details')}
        onChange={e => {
          dispatch(setSearch(e.target.value));
        }}
        searchComponent
        search={search}
        add
      />
      <Table
        bordered
        columns={columns}
        dataSource={partnerData}
        loading={loading}
        rowKey="id"
        pagination={{
          current: page,
          onChange: (page, pageSize) => {
            dispatch(setPage(page));
            dispatch(setLimit(pageSize));
          },
          pageSize: limit,
          total: count
        }}
      />
      <DeleteModal
        open={openDeleteModal}
        confirmLoading={loadingAction}
        onOk={() => {
          dispatch(deletePartner(partnerId)).then(() => {
            setOpenDeleteModal(false);
            setPartnerId(0);
          });
        }}
        onCancel={() => {
          setOpenDeleteModal(false);
          setPartnerId(0);
        }}
      />
    </>
  );
};

export default Partners;
