import { selectAboutFiles, selectCustomPageById, selectCustomPageLoadingAction, selectCustomPageLoadingById } from 'app/features/customPage/selectors';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';
import { customPageEdit, getCustomPageById } from 'app/features/customPage/slice';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Form, Input, Row, UploadFile, UploadProps } from 'antd';
import { ICustomPageById } from 'app/features/customPage/types';
import useCustomRequest from 'Hook/useCustomRequest';
import Dragger from 'antd/es/upload/Dragger';
import { UploadOutlined } from '@ant-design/icons';

import Loading from 'components/Loading';
import DetailsHead from 'components/DetailsHead';
import UploadedFiles from './components/uploadedFiles';
import { UploadWrraper } from './components/styled';

const CustomPageId = () => {
  //navigate
  const navigate = useNavigate();
  //dispatch
  const dispatch = useAppDispatch();
  //selectors
  const customPageById = useAppSelector(selectCustomPageById());
  const aboutFiles = useAppSelector(selectAboutFiles());
  const loadingAction = useAppSelector(selectCustomPageLoadingAction());
  const loadingById = useAppSelector(selectCustomPageLoadingById());
  //params
  const { id } = useParams();
  //form
  const [form] = Form.useForm();
  const validateMessages = {
    required: "'${label}' is Required!"
  };

  const onClose = () => {
    form.resetFields();
    navigate(-1);
  };

  useEffect(() => {
    dispatch(getCustomPageById(Number(id)));
  }, [dispatch, id]);

  useEffect(() => {
    if (id && customPageById) {
      form.setFieldsValue({
        title: customPageById.title,
        // title_ru: customPageById.title_ru,
        // title_hy: customPageById.title_hy,
        text: customPageById.text
        // text_ru: customPageById.text_ru,
        // text_hy: customPageById.text_hy
      });
    }
  }, [customPageById, form, id]);

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const onChange: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
    setFileList(newFileList);

    const isAllUploaded = newFileList.every(file => file.status === 'done');
    console.warn(isAllUploaded);

    // if (isAllUploaded) {
    //   setFileList([]);
    //   await message.success('Pictures uploaded successfully!', 2);
    //   await dispatch(sliderApi());
    // }
  };

  const onSave = async (values: ICustomPageById) => {
    const files = fileList.map(item => item.response?.data?.data?.id || item.uid).filter(item => !!item);

    if (id) {
      await dispatch(
        customPageEdit({
          id: Number(id),
          title: values.title,
          // title_ru: values.title_ru,
          // title_hy: values.title_hy,
          text: values.text,
          files
          // text_ru: values.text_ru,
          // text_hy: values.text_hy
        })
      ).then(() => onClose());
    }
  };

  const customRequest = useCustomRequest({
    url: '/file/uploadAboutUsFiles',
    name: true
  });

  return (
    <>
      {loadingById ? (
        <Loading size="large" />
      ) : (
        <>
          <Form
            validateMessages={validateMessages}
            size="large"
            form={form}
            layout="vertical"
            onFinish={onSave}
            scrollToFirstError={{
              behavior: 'smooth',
              block: 'center',
              inline: 'center'
            }}
          >
            <DetailsHead id={id} headTitle={customPageById?.title} onClose={onClose} form={form} loadingAction={loadingAction} />
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Form.Item label="English Title" name="title" rules={[{ required: true }]}>
                  <Input placeholder="Enter title in English" />
                </Form.Item>
                <Form.Item label="English Text" name="text" rules={[{ required: true }]}>
                  <Input.TextArea style={{ height: '300px' }} showCount placeholder="Enter text in English" />
                </Form.Item>
              </Col>
              {customPageById?.id == 1 && (
                <div style={{ textAlign: 'center' }}>
                  <UploadWrraper>
                    <Dragger onChange={onChange} accept="image/*" listType="picture" multiple fileList={fileList} customRequest={customRequest}>
                      <div style={{ textAlign: 'end' }}>
                        <Button icon={<UploadOutlined />}>Upload About Us Image</Button>
                      </div>
                    </Dragger>
                    <UploadedFiles sliderData={aboutFiles || []} />
                  </UploadWrraper>
                </div>
              )}
            </Row>
          </Form>
        </>
      )}
    </>
  );
};

export default CustomPageId;
