import axios from 'axios';

const token = localStorage.getItem('token') || window.localStorage.getItem('token');

export const BASE_URL = process.env.REACT_APP_API_URL;
export const BASE_URL_BIG = process.env.REACT_APP_BIG_DB_API_URL;

const UNAUTHORISED_STATUS = 'UNAUTHORIZED_ACCESS';

const createClient = () => {
  const client = axios.create({
    baseURL: BASE_URL,
    headers: {
      'Accept-language': 'en',
      'x-access-token': token || '' // Устанавливаем начальный токен
    }
  });

  let isRefreshing = false;
  const refreshSubscribers: any[] = [];

  client.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      const currentToken = localStorage.getItem('refresh_token');

      if (error.response && error.response.status === 401 && error.config.url !== '/auth') {
        const originalRequest = error.config;

        if (!currentToken || error.response.data.status === UNAUTHORISED_STATUS) {
          localStorage.removeItem('token');
          localStorage.removeItem('refresh_token');
          location.pathname = '/login';
        }

        if (!isRefreshing) {
          isRefreshing = true;

          try {
            const res = await client.post('/auth/refresh', {
              refresh_token: currentToken
            });

            if (res) {
              setClientToken(res.data.data.access_token);
              localStorage.setItem('token', res.data.data.access_token);
              localStorage.setItem('refresh_token', res.data.data.refresh_token);

              originalRequest.headers['x-access-token'] = res.data.data.access_token;

              if (refreshSubscribers.length > 0 && res.data.data.access_token) {
                onTokenRefreshed(res.data.data.access_token);
              }

              return client(originalRequest);
            } else {
              localStorage.removeItem('token');
              localStorage.removeItem('refresh_token');
              location.pathname = '/login';
            }
          } finally {
            isRefreshing = false;
          }
        } else {
          return new Promise(resolve => {
            refreshSubscribers.push(() => {
              resolve(client(originalRequest));
            });
          });
        }
      }

      return Promise.reject(error);
    }
  );

  function onTokenRefreshed(newToken: string) {
    refreshSubscribers.forEach(callback => callback(newToken));
    refreshSubscribers.pop();
  }

  return client;
};

export const client = createClient();

export const clientBIG = axios.create({
  baseURL: BASE_URL_BIG,
  headers: {
    Bussinessid: '2477062e-d1f1-4369-ac8e-c10045d9a72a'
  }
});

export const setClientToken = (token: string) => {
  client.defaults.headers.common['x-access-token'] = token;
  client.defaults.headers['x-access-token'] = token;
};
