import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { client } from 'api/apiClient';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { AxiosError } from 'axios';

import { EducationalState, IEducationalByIdValues } from './types';
import { selectEducationalLimit, selectEducationalPage, selectEducationalSearch } from './selectors';

const initialState: EducationalState = {
  educational: [],
  educationalById: null,
  loading: false,
  loadingById: false,
  page: 1,
  limit: 10,
  search: '',
  count: 0,
  loadingAction: false
};

export const educationalApi = createAsyncThunk('educational/educationalApi', async (_, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState;
    const page = selectEducationalPage()(state);
    const search = selectEducationalSearch()(state);
    const limit = selectEducationalLimit()(state);
    const res = await client.get('/educational', {
      params: {
        search,
        page,
        limit
      }
    });

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const educationalByIdApi = createAsyncThunk('educational/educationalByIdApi', async (id: number, { rejectWithValue }) => {
  try {
    const res = await client.get(`/educational/${id}`);

    return res.data.data;
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const deleteEducational = createAsyncThunk('educational/deleteEducational', async (id: number, { dispatch, rejectWithValue }) => {
  try {
    await client.delete(`/educational/${id}`);
    await dispatch(educationalApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const postEducational = createAsyncThunk('educational/postEducational', async (data: IEducationalByIdValues, { dispatch, rejectWithValue }) => {
  try {
    await client.post('/educational', {
      ...data
    });
    await dispatch(educationalApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const educationalEdit = createAsyncThunk('educational/educationalEdit', async ({ id, ...data }: IEducationalByIdValues, { dispatch, rejectWithValue }) => {
  try {
    await client.patch(`/educational/${id}`, {
      ...data
    });

    await dispatch(educationalApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});
const educationalSlice = createSlice({
  name: 'educational',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    clickMenuEducational: state => {
      (state.limit = 10), (state.page = 1), (state.search = '');
    }
  },
  extraReducers(builder) {
    builder
      .addCase(educationalApi.pending, state => {
        state.loading = true;
      })
      .addCase(educationalApi.fulfilled, (state, action) => {
        state.educational = action.payload.rows;
        state.count = action.payload.count;
        state.loading = false;
      })
      .addCase(educationalApi.rejected, state => {
        state.loading = false;
      })
      .addCase(educationalByIdApi.pending, state => {
        state.loadingById = true;
      })
      .addCase(educationalByIdApi.fulfilled, (state, action) => {
        state.educationalById = action.payload;
        state.loadingById = false;
      })
      .addCase(educationalByIdApi.rejected, state => {
        state.loadingById = false;
      })
      .addCase(deleteEducational.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteEducational.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteEducational.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(postEducational.pending, state => {
        state.loadingAction = true;
      })
      .addCase(postEducational.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(postEducational.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(educationalEdit.pending, state => {
        state.loadingAction = true;
      })
      .addCase(educationalEdit.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(educationalEdit.rejected, state => {
        state.loadingAction = false;
      });
  }
});

export const { setPage, setSearch, setLimit, clickMenuEducational } = educationalSlice.actions;
export default educationalSlice.reducer;
