import { RootState } from 'app/store';

export const selectSpecialistData = () => (state: RootState) => state.specialist.specialist;

export const selectSpecialistLoading = () => (state: RootState) => state.specialist.loading;

export const selectSpecialistSearch = () => (state: RootState) => state.specialist.search;

export const selectSpecialistPage = () => (state: RootState) => state.specialist.page;

export const selectSpecialistLimit = () => (state: RootState) => state.specialist.limit;

export const selectSpecialistCount = () => (state: RootState) => state.specialist.count;

export const selectSpecialistById = () => (state: RootState) => state.specialist.specialistById;

export const selectSpecialistLoadingAction = () => (state: RootState) => state.specialist.loadingAction;

export const selectSpecialistLoadingById = () => (state: RootState) => state.specialist.loadingByID;
