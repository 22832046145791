import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectUserById, selectUsersLoading } from 'app/features/users/selectors';
import { useEffect, useState } from 'react';
import { clearBasketState, clearUserByIdState, clearUserOrderAddresses, createUserApi, editUserInfoApi, userByIdApi } from 'app/features/users/slice';
import { Form, Tabs } from 'antd';
import { IUserById } from 'app/features/users/types';
import moment from 'moment';
import { selectUserInfo } from 'app/features/auth/selectors';
import { getRoles } from 'app/features/roles/slice';
import { selectAllRoles } from 'app/features/roles/selectors';
import { menuListArr, requestsArr } from 'helpers/userParmissions';
import { partnerApi } from 'app/features/partner/slice';
import { selectPartnerData } from 'app/features/partner/selectors';
import { getCountryData } from 'assets/countries';

import DetailsHead from 'components/DetailsHead';
import Loading from 'components/Loading';
import AdminPage1 from './AdminPage1';
import AddressesModal from './AdminPage2/addressesModal';
import { IPerimission } from 'pages/Roles/types';

const AdminDetails = () => {
  const [tabs, setTabs] = useState('Admin page 1');
  const { id } = useParams();

  const accountTypes = [
    {
      title: 'BASIC'
    },
    {
      title: 'SILVER'
    },
    {
      title: 'GOLD'
    },
    {
      title: 'PREMIUM'
    }
  ];

  const roles = useAppSelector(selectAllRoles());
  const adminRoles = roles.filter(item => item.name !== 'USER');
  const discountTypes = [
    {
      type: 1
    },
    {
      type: 2
    },
    {
      type: 3
    },
    {
      type: 4
    }
  ];

  const [showAddressesModal, setShowAddressesModal] = useState<boolean>(false);

  const loading = useAppSelector(selectUsersLoading());
  const userById = useAppSelector(selectUserById());
  const userInfo = useAppSelector(selectUserInfo());

  const countryData = getCountryData();
  const partners = useAppSelector(selectPartnerData());

  const isSalesAdmin = userInfo?.role == 'SALES_ADMIN';

  const [requestTypes, setRequestTypes] = useState<IPerimission[]>(requestsArr);
  const [pagesList, setPagesList] = useState<IPerimission[]>(menuListArr);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const onClose = () => {
    form.resetFields();
    navigate(-1);
  };

  const validateMessages = {
    required: "'${label}' is Required!"
  };

  useEffect(() => {
    return () => {
      dispatch(clearUserByIdState());
      dispatch(clearBasketState());
      dispatch(clearUserOrderAddresses());
    };
  }, []);

  useEffect(() => {
    if (tabs === 'Admin page 1') {
      dispatch(partnerApi());
      dispatch(getRoles());
    }

    if (id) {
      if (tabs === 'Admin page 1') {
        dispatch(userByIdApi(id));
      }
    }
  }, [id, tabs]);

  const [phone_number, setPhoneNumber] = useState<string | undefined>(undefined);

  const onSave = async (values: IUserById) => {
    const menu: string[] = [];
    const reqs: string[] = [];
    pagesList.map(item => {
      if (item.checked) {
        menu.push(item.key);
      }
    });
    requestTypes.map(item => {
      if (item.checked) {
        reqs.push(item.key);
      }
    });

    const data: any = {
      firstName: values.firstName,
      lastName: values.lastName,
      username: values.username,
      gender: values.gender,
      email: values.email,
      account_type: values.account_type,
      role: values.role,
      password: values.password,
      partner_id: values.partner_id,
      discount_type_number: values.discount_type_number,
      permision_menu: menu,
      permission_request: reqs,
      phone: phone_number || ''
    };

    if (values.partner_id) {
      const selectedPartner = partners.find(i => i.id === values.partner_id);

      if (selectedPartner) {
        const findedCountry = countryData.find(i => i.name === selectedPartner.country.name);

        data.countryId = findedCountry?.id;
      }
    } else {
      data.countryId = values.countryId;
      delete data.partner_id;
    }

    if (!phone_number || phone_number.length <= 4 || phone_number == '+') {
      data.phone = ' ';
    }

    if (id) {
      await dispatch(
        editUserInfoApi({
          id,
          data
        })
      ).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          onClose();
        }
      });
    } else {
      await dispatch(createUserApi({ data })).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          onClose();
        }
      });
    }
  };

  useEffect(() => {
    if (id && userById) {
      form.setFieldsValue({
        firstName: userById.firstName,
        lastName: userById.lastName,
        username: userById.username,
        gender: userById.gender,
        email: userById.email,
        phone: userById.phone,
        point: +userById.point,
        account_type: userById.account_type,
        partner_id: userById.partner_id,
        discount_type_number: userById.discount_type_number,
        countryId: userById.countryId,
        role: userById.role
      });
      setPhoneNumber(userById.phone);
    }
  }, [userById, form, id]);

  const tabItems = [
    {
      label: 'Admin',
      key: 'Admin page 1',
      children: (
        <AdminPage1
          id={id}
          setPhoneNumber={setPhoneNumber}
          phoneNumber={phone_number}
          isSalesAdmin={isSalesAdmin}
          userRoles={adminRoles}
          accountTypes={accountTypes}
          discountTypes={discountTypes}
          requestTypes={requestTypes}
          setRequestTypes={setRequestTypes}
          pagesList={pagesList}
          setPagesList={setPagesList}
          setShowAddressesModal={setShowAddressesModal}
        />
      )
    }
  ];

  return (
    <>
      {loading ? (
        <Loading size="large" />
      ) : (
        <>
          <Form
            scrollToFirstError={{
              behavior: 'smooth',
              block: 'center',
              inline: 'center'
            }}
            initialValues={{
              createdAt: moment(userById?.createdAt).format('DD/MMM/YYYY HH:mm')
            }}
            onFinish={onSave}
            validateMessages={validateMessages}
            size="large"
            form={form}
            layout="vertical"
          >
            <DetailsHead titleItem={`${userById?.firstName}  ${userById?.lastName}`} onClose={onClose} id={id} headTitle={'Admin'} loadingAction={loading} form={form} />
            <Tabs activeKey={tabs} onChange={e => setTabs(e)} type="card" items={tabItems} />
          </Form>
        </>
      )}
      <AddressesModal open={showAddressesModal} handleModal={setShowAddressesModal} />
    </>
  );
};

export default AdminDetails;
