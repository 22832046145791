import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client, setClientToken } from 'api/apiClient';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { AxiosError } from 'axios';

import { ILogin, AuthState } from './types';

const initialState: AuthState = {
  isLoggedIn: !!localStorage.getItem('token'),
  user: null,
  loading: false
};

export const postLogin = createAsyncThunk('auth/postLogin', async (values: ILogin, { rejectWithValue }) => {
  try {
    const res = await client.post('/auth', { remember: false, ...values });

    setClientToken(res.data.data.token.access_token);
    localStorage.setItem('token', res.data.data.token.access_token);
    localStorage.setItem('refresh_token', res.data.data.token.refresh_token);
    window.location.pathname = '/categories';
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const logOut = createAsyncThunk('auth/logOut', async () => {
  try {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    window.location.pathname = '/login';
  } catch (error) {
    return showErrorMessage(error as AxiosError<ErrorType>);
  }
});

export const userInfoApi = createAsyncThunk('userInfo/userInfoApi', async (_, { rejectWithValue }) => {
  try {
    const res = await client.get(`/user/getByToken`);

    return res.data.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postLogin.pending, state => {
        state.loading = true;
      })
      .addCase(postLogin.fulfilled, state => {
        state.loading = false;

        state.isLoggedIn = true;
      })
      .addCase(postLogin.rejected, state => {
        state.loading = false;
      })
      .addCase(logOut.fulfilled, state => {
        state.isLoggedIn = false;
      })
      .addCase(userInfoApi.pending, state => {
        state.loading = true;
      })
      .addCase(userInfoApi.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(userInfoApi.rejected, state => {
        state.loading = false;
      });
  }
});

export default authSlice.reducer;
