export interface TransformedItem {
  [key: string]: any;
  amount_max1: number;
  amount_min1: number;
  amount_max2: number;
  amount_min2: number;
  amount_max3: number;
  amount_min3: number;
  amount_min4: number;
  amount_max4: number;
  createdAt: string;
  id: string;
  name: string;
}

export const transformArray = (arr: any[]): TransformedItem[] => {
  const transformedArr: TransformedItem[] = [];

  for (let i = 0; i < arr.length; i++) {
    const currentItem = arr[i];
    const currentName = currentItem.name;
    const currentType = currentItem.type;

    const existingItem = transformedArr.find(item => item.name === currentName);

    if (existingItem) {
      existingItem[`amount_max${currentType}`] = currentItem.amount_max;
      existingItem[`amount_min${currentType}`] = currentItem.amount_min;
    } else {
      const newItem: any = {
        createdAt: currentItem.createdAt,
        id: currentItem.id,
        name: currentItem.name
      };

      newItem[`amount_max${currentType}`] = currentItem.amount_max;
      newItem[`amount_min${currentType}`] = currentItem.amount_min;
      transformedArr.push(newItem);
    }
  }

  return transformedArr;
};
