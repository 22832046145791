import { Col, Row } from 'antd';
import { selectSliderData, selectSliderLoading, selectSliderLoadingAction } from 'app/features/slider/selectors';
import { deleteSliderImage, sliderApi } from 'app/features/slider/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { getFileUrlSlider } from 'helpers/file';

import { ButtonDelete, CardImg, Img, ImgWrraper } from './styled';
import DeleteModal from 'components/DeleteModal';
import Loading from 'components/Loading';

const Slider = () => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [imageId, setImageId] = useState<number>(0);
  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  const sliderData = useAppSelector(selectSliderData());
  const loading = useAppSelector(selectSliderLoading());
  const loadingAction = useAppSelector(selectSliderLoadingAction());

  useEffect(() => {
    dispatch(sliderApi());
  }, [dispatch]);

  if (loading) {
    return <Loading size="large" />;
  }

  return (
    <>
      <Row gutter={[24, 24]}>
        {sliderData?.map((item, index) => {
          return (
            <Col key={index} span={8}>
              <CardImg
                hoverable
                actions={[
                  <ButtonDelete
                    key="delete"
                    style={{ width: '100%', backgroundColor: 'white' }}
                    block
                    icon={<DeleteOutlined />}
                    danger
                    onClick={() => {
                      setImageId(item.id);
                      setOpenDeleteModal(true);
                    }}
                  >
                    Delete
                  </ButtonDelete>
                ]}
                cover={
                  <ImgWrraper>
                    <Img src={getFileUrlSlider(item.file.name, item.file.extension)} />
                  </ImgWrraper>
                }
              ></CardImg>
            </Col>
          );
        })}
      </Row>
      <DeleteModal
        open={openDeleteModal}
        confirmLoading={loadingAction}
        onOk={() => {
          dispatch(deleteSliderImage(imageId)).then(() => {
            setOpenDeleteModal(false);
            setImageId(0);
          });
        }}
        onCancel={() => {
          setOpenDeleteModal(false);
          setImageId(0);
        }}
      />
    </>
  );
};

export default Slider;
