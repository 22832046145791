import { Button, Image, message, Modal, Popconfirm, Table, Tag, Tooltip } from 'antd';
import { getProductById, productApi, productByCategoryApi, setPage, setSearch, syncHC, syncHCJournal } from 'app/features/products/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectProductById,
  selectProductCount,
  selectProductData,
  selectProductLoading,
  selectProductLoadingAction,
  selectProductLoadingByID,
  selectProductSearch
} from 'app/features/products/selectors';
import { useEffect, useState } from 'react';
import { _debounce } from 'helpers/debounce';
import { ColumnsType } from 'antd/lib/table';
import { IProduct, PricingInfo } from 'app/features/products/types';
import { FileSyncOutlined, WarningFilled } from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getFileUrlBIG } from 'helpers/file';
import { ICategory } from 'app/features/category/types';
import { selectSelectedCurrency } from 'app/features/currency/selectedcurrencyslice';
import { getQueryString } from 'helpers/object';

import { productProcesses } from '../../helpers/product-processes';
import { ViewButton } from 'components/Button/styled';
import ContentTop from 'components/ContentTop';
import { ActionButtonsWrapper, IconWrapper, ImgTableWrapper, PricesWrapper, PricingInfoWrapper, TableWrapper } from './styled';
import noImg from '../../assets/img/noimage.png';
import CategoryDrop from './CategoryDrop';
import { hcSyncRes } from 'pages/ProductDetails/components/ProductPrices/types';
import HcModal from './HcModal';
import ProductPrices from '../ProductDetails/components/ProductPrices';
import { getCountryData } from '../../assets/countries';
import { selectUserInfo } from '../../app/features/auth/selectors';
import { ProductPriceIcon } from '../../components/icons/ProductPriceIcon';
import { EditIcon } from '../../components/icons/EditIcon';

const debounce = _debounce(500);

const PriceModalInitialState = { open: false, productId: null };

const countryList = getCountryData();

const PricesInfo = ({ pricingData, currency }: { pricingData: PricingInfo; currency: string }) => {
  return (
    <PricingInfoWrapper>
      <span>
        <span className="priceType">ST:</span> {pricingData.finalNavyPrice ?? 0} {currency}
      </span>
      <span>
        <span className="priceType">EX:</span> {pricingData.finalPlanePrice ?? 0} {currency}
      </span>
    </PricingInfoWrapper>
  );
};

const Products = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(null);
  const [hcMessage, setHcMessage] = useState<hcSyncRes>();

  const [isHCModalOpen, setIsHCModalOpen] = useState(false);
  const [isHCPopupOpen, setIsHCPopupOpen] = useState(false);

  const [priceModalData, setPriceModalData] = useState<{ open: boolean; productId: string | number | null }>(PriceModalInitialState);

  const productsData = useAppSelector(selectProductData());
  const count = useAppSelector(selectProductCount());
  const loading = useAppSelector(selectProductLoading());
  const loadingAction = useAppSelector(selectProductLoadingAction());
  const search = useAppSelector(selectProductSearch());
  const userInfo = useAppSelector(selectUserInfo());

  const selectedCurrencyCode = useAppSelector(selectSelectedCurrency);

  const selectedProductData = useAppSelector(selectProductById());
  const productDataLoading = useAppSelector(selectProductLoadingByID());
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');

  const productPriceModalTitle = productDataLoading ? (
    'Loading...'
  ) : (
    <>
      <Tag color="green" style={{ fontSize: '18px' }}>
        {selectedProductData?.title_en ?? selectedProductData?.title}
      </Tag>
      prices
    </>
  );

  const showHCPopup = () => setIsHCPopupOpen(true);

  const handleOk = async () => {
    const res = await dispatch(syncHC());
    if (res.payload.data.length) setHcMessage(res.payload.data);
    setIsHCPopupOpen(false);
  };

  const closePricesModal = () => {
    setPriceModalData(PriceModalInitialState);
  };

  const getProducts = (selectedCategory: ICategory | null) => {
    dispatch(setPage(page));

    if (selectedCategory) {
      debounce(() => dispatch(productByCategoryApi({ id: selectedCategory?.id, limit: 10 })));
    } else {
      debounce(() => dispatch(productApi(selectedCurrencyCode)));
    }
  };

  const productPricesSuccessUpdate = () => {
    closePricesModal();
    getProducts(selectedCategory);
  };

  useEffect(() => {
    if (hcMessage) setIsHCModalOpen(true);
  }, [hcMessage]);

  useEffect(() => {
    const validPage = page || 1;

    dispatch(setPage(validPage));
    setSearchParams(getQueryString({ page: validPage }));
  }, []);

  const handleCancel = () => {
    setIsHCPopupOpen(false);
  };

  useEffect(() => {
    dispatch(setPage(page));
    dispatch(setSearch(''));
  }, [navigate, dispatch]);

  useEffect(() => {
    const productId = String(priceModalData.productId);

    if (productId && priceModalData.open) dispatch(getProductById(productId));
  }, [dispatch, priceModalData]);

  useEffect(() => getProducts(selectedCategory), [dispatch, search, page, selectedCategory, selectedCurrencyCode]);

  const columns: ColumnsType<IProduct> = [
    {
      title: 'Availability',
      dataIndex: 'availability',
      width: 40,
      align: 'center',
      key: 'availability',
      onCell: record => ({
        onClick: () => navigate(`details/${record.opened_articul}`),
        style: { cursor: 'pointer' }
      }),
      render: (value, record) => {
        const color = value ? 'green' : 'red';
        const text = value ? 'Available' : 'Unavailable';

        return (
          <>
            <IconWrapper key={value} color={color}>
              {text}
            </IconWrapper>
            <br />
            {record?.process === productProcesses.discontinued.value && <Tag style={{ marginTop: '4px' }}>{productProcesses.discontinued.label}</Tag>}
          </>
        );
      }
    },
    {
      title: 'Title',
      dataIndex: 'title_en',
      align: 'left',
      key: 'title_en',
      width: 200,
      onCell: record => ({
        onClick: () => navigate(`details/${record.opened_articul}`),
        style: { cursor: 'pointer', fontSize: '14px', overflow: 'hidden', textOverflow: 'ellipsis' }
      })
    },
    {
      title: 'Image',
      dataIndex: 'files',
      align: 'left',
      key: 'image',
      width: 100,
      render: files => {
        const thumbnailImageFile = files?.length > 0 ? { ...files[0], path: getFileUrlBIG(files[0].path) } : null;

        return (
          <ImgTableWrapper>
            {thumbnailImageFile ? (
              <Image
                width={70}
                height={55}
                alt={thumbnailImageFile.name}
                src={thumbnailImageFile.path}
                preview={{
                  src: thumbnailImageFile.path.replace('108x88', '574x438')
                }}
              />
            ) : (
              <Image preview={false} width={70} src={noImg} alt="No Image" />
            )}
          </ImgTableWrapper>
        );
      }
    },
    {
      title: 'CRM Code',
      dataIndex: 'code',
      key: 'code',
      width: 60,
      align: 'center',
      render: value => {
        const handleCopy = async () => {
          await navigator.clipboard.writeText(value);
          message.success('CRM Code copied');
        };

        return (
          <Tooltip title="Copy CRM code" placement="bottom">
            <Button onClick={handleCopy}>{value}</Button>
          </Tooltip>
        );
      }
    },
    {
      title: 'Final Price',
      dataIndex: 'pricingInfo',
      key: 'price',
      align: 'left',
      width: 150,
      render: (_, record) => {
        const isSuper = userInfo?.role === 'SUPER_ADMIN';

        if (isSuper) {
          return (
            <PricesWrapper>
              {record.pricingInfo.map(pricingData => {
                const countryName = countryList.find(country => country.id === pricingData.countryId)?.name ?? '';

                return (
                  <Tooltip placement="bottom" title={<PricesInfo pricingData={pricingData} currency={selectedCurrencyCode} />} key={pricingData.countryId}>
                    <Button>{countryName}</Button>
                  </Tooltip>
                );
              })}
            </PricesWrapper>
          );
        }

        return <PricesInfo pricingData={record.pricingInfo[0]} currency={selectedCurrencyCode} />;
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 150,
      key: 'Action',
      align: 'center',
      render: (_, record) => {
        return (
          <ActionButtonsWrapper>
            <Tooltip title="Open product prices" placement="bottom">
              <ViewButton onClick={() => setPriceModalData({ open: true, productId: record?.opened_articul })}>
                <ProductPriceIcon />
              </ViewButton>
            </Tooltip>

            <Tooltip title="Open product" placement="bottom">
              <ViewButton onClick={() => navigate(`details/${record.opened_articul}`)}>
                <EditIcon />
              </ViewButton>
            </Tooltip>
          </ActionButtonsWrapper>
        );
      }
    }
  ];

  const handleJournalSync = async () => {
    try {
      dispatch(syncHCJournal());
    } catch (error) {
      message.error('Error syncing with HC Journal');
    }
  };

  return (
    <>
      <ContentTop
        title="Products"
        placeholder="Search Product"
        onChange={e => {
          dispatch(setSearch(e.target.value));
        }}
        searchComponent
        search={search}
      />
      <Popconfirm
        icon={<WarningFilled />}
        title="Caution!"
        description="Confirm Product Synchronization with HC"
        open={isHCPopupOpen}
        onConfirm={handleOk}
        okButtonProps={{ loading: loadingAction }}
        onCancel={handleCancel}
      >
        <Button loading={loadingAction} icon={<FileSyncOutlined />} size="large" type="primary" onClick={showHCPopup} style={{ marginBottom: '1rem', float: 'right' }}>
          Sync Products with HC
        </Button>
      </Popconfirm>
      <Button loading={loadingAction} icon={<FileSyncOutlined />} size="large" type="primary" onClick={handleJournalSync} style={{ marginBottom: '1rem', float: 'right', marginRight: '10px' }}>
        Sync Products with HC Journal
      </Button>
      <HcModal open={isHCModalOpen} onClose={() => setIsHCModalOpen(false)} hcRes={hcMessage} />
      <Modal destroyOnClose title={productPriceModalTitle} width={1400} open={priceModalData.open} footer={null} onCancel={closePricesModal}>
        <ProductPrices onSuccess={productPricesSuccessUpdate} />
      </Modal>

      <CategoryDrop selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />

      <TableWrapper>
        <Table
          rowClassName="vertical-center"
          loading={loading}
          pagination={{
            current: Number(page),
            total: count,
            pageSize: 10,
            showPrevNextJumpers: true,
            showSizeChanger: false,
            onChange: page => {
              setSearchParams(getQueryString({ page }));
              dispatch(setPage(page));
            }
          }}
          bordered
          columns={columns}
          dataSource={productsData}
          rowKey="id"
        />
      </TableWrapper>
    </>
  );
};

export default Products;
