import { Col, Form, Input, Row, Select } from 'antd';
import { FC, useState, Dispatch } from 'react';
import { CountryById } from 'app/features/country/types';
import { useAppSelector } from 'app/hooks';
import { selectUserById } from 'app/features/users/selectors';
import moment from 'moment';
import MuiPhoneNumber from 'material-ui-phone-number-2';
import { getCountryData } from 'assets/countries';

import { StyledMobileNumber } from '../styled';

interface IUserPage1 {
  id?: string;
  isSalesAdmin: boolean;
  accountTypes: { title: string }[];
  discountTypes: { type: number }[];
  setShowAddressesModal: Dispatch<React.SetStateAction<boolean>>;
  phoneNumber: string | undefined;
  setPhoneNumber: Dispatch<React.SetStateAction<string | undefined>>;
}

const UserPage1: FC<IUserPage1> = ({ id, isSalesAdmin, accountTypes, discountTypes, phoneNumber, setPhoneNumber }) => {
  const [userCountry] = useState<CountryById>();
  const countryData = getCountryData();
  const userById = useAppSelector(selectUserById());

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <Form.Item rules={[{ required: true }]} label="First Name" name="firstName">
            <Input placeholder="Enter First Name" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item rules={[{ required: true }]} label="Last Name" name="lastName">
            <Input placeholder="Enter Last Name" />
          </Form.Item>
        </Col>
        {!id && (
          <Col span={8}>
            <Form.Item rules={[{ required: true }]} label="Password" name="password">
              <Input placeholder="Enter Password" />
            </Form.Item>
          </Col>
        )}
        <Col span={12}>
          <Form.Item
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!'
              },
              {
                required: true,
                message: 'Please input E-mail!'
              }
            ]}
            label="Email"
            name="email"
          >
            <Input size="large" disabled={isSalesAdmin} placeholder="Enter email"></Input>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item rules={[{ required: true }]} label="Account Type" name="account_type">
            <Select
              disabled={isSalesAdmin}
              placeholder="Select Account type"
              options={accountTypes.map(item => {
                return {
                  value: item.title,
                  label: item.title
                };
              })}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item rules={[{ required: true }]} label="Discount" name="discount_type_number">
            <Select
              placeholder="Select Discount"
              options={discountTypes.map(item => {
                return {
                  value: item.type,
                  label: item.type
                };
              })}
            />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label="Country" name="countryId">
            <Select
              disabled={isSalesAdmin}
              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
              filterSort={(optionA, optionB) => optionA?.label.toLowerCase().localeCompare(optionB?.label.toLowerCase())}
              showSearch
              value={userCountry}
              placeholder="Country"
              options={countryData.map(country => {
                return {
                  value: country.id,
                  label: country.name
                };
              })}
            />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label="Phone" name="phone">
            <StyledMobileNumber>
              <MuiPhoneNumber
                disableAreaCodes={true}
                className="mobileNumberSelect"
                sx={{ '& svg': { height: '1em' } }}
                value={phoneNumber}
                inputClass="phoneNumberInput"
                defaultCountry={'am'}
                variant="outlined"
                onChange={(e: any) => setPhoneNumber(e)}
              />
            </StyledMobileNumber>
          </Form.Item>
        </Col>
        {id && (
          <Col span={7} style={{ marginTop: '12px' }}>
            <p style={{ marginBottom: '15px' }}>Created at</p>
            <Input readOnly value={userById ? moment(userById?.createdAt).format('DD/MMM/YYYY HH:mm') : ''} />
          </Col>
        )}
      </Row>
    </>
  );
};

export default UserPage1;
