export const pageArr = [
  {
    id: 'GENEREAL',
    label: 'General'
  },
  {
    id: 'PRODUCT',
    label: 'Product'
  },
  {
    id: 'SHIPPING',
    label: 'Shipping'
  },
  {
    id: 'PAYMENT',
    label: 'Payment'
  }
];
