import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api/apiClient';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';

import { IRoleEditValues, RolesState } from './types';

const initialState: RolesState = {
  roles: [],
  roleById: null,
  loading: false
};

export const getRoles = createAsyncThunk('roles/getRoles', async (_, { rejectWithValue }) => {
  try {
    const res = await client.get(`/roles`);

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});
export const getRoleById = createAsyncThunk('roles/getRoleById', async (id: string, { rejectWithValue }) => {
  try {
    const res = await client.get(`/roles/${id}`);

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const createRole = createAsyncThunk('roles/createRole', async (data: IRoleEditValues, { dispatch, rejectWithValue }) => {
  try {
    await client.post(`/roles/create`, { ...data });

    return dispatch(getRoles());
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const editRole = createAsyncThunk('roles/editRole', async ({ id, data }: { id: string; data: IRoleEditValues }, { dispatch, rejectWithValue }) => {
  try {
    await client.patch(`/roles/${id}`, { ...data });

    dispatch(getRoles());
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const deleteRole = createAsyncThunk('roles/deleteRole', async (id: string, { dispatch, rejectWithValue }) => {
  try {
    await client.delete(`/roles/${id}`);

    dispatch(getRoles());
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    clearRoleState: state => {
      state.roleById = null;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getRoles.pending, state => {
        state.loading = true;
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.roles = action.payload;
      })
      .addCase(getRoles.rejected, state => {
        state.loading = false;
      })
      .addCase(getRoleById.pending, state => {
        state.loading = true;
      })
      .addCase(getRoleById.fulfilled, (state, action) => {
        state.loading = false;
        state.roleById = action.payload;
      })
      .addCase(getRoleById.rejected, state => {
        state.loading = false;
      });
  }
});

export const { clearRoleState } = rolesSlice.actions;
export default rolesSlice.reducer;
