import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { selectDiscounts } from 'app/features/discounts/selectors';
import { PostDiscountValues } from 'app/features/discounts/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';
import { deleteDiscount, getDiscountsApi } from 'app/features/discounts/slice';
import { transformArray } from 'helpers/discount';
import { selectCurrencyData } from 'app/features/currency/selectors';
import { selectSelectedCurrency } from 'app/features/currency/selectedcurrencyslice';

import { Text } from 'pages/CustomPage/styled';
import DiscountModal from './DiscountModal';
import DeleteModal from 'components/DeleteModal';

const Discounts = () => {
  const discountState = useAppSelector(selectDiscounts());
  const dispatch = useAppDispatch();
  const [discounts, setDiscounts] = useState<PostDiscountValues[]>();
  const currencyDataList = useAppSelector(selectCurrencyData());
  const selectedCurrency = useAppSelector(selectSelectedCurrency);
  const SelectedCurrencyObj = currencyDataList.find(item => item.currencyCode == selectedCurrency);
  const conversion = Number(SelectedCurrencyObj?.conversionRate);

  useEffect(() => {
    setDiscounts(transformArray(discountState));
  }, [discountState]);

  const [showDiscountModal, setShowDiscountModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedAccType, setSelectedAccType] = useState<string>('');

  useEffect(() => {
    dispatch(getDiscountsApi());
  }, []);

  const clickDiscount = (title: string) => {
    setShowDiscountModal(true);
    setSelectedAccType(title);
  };

  const clickDiscountDelete = (title: string) => {
    setShowDeleteModal(true);
    setSelectedAccType(title);
  };

  const DiscountsColumns: ColumnsType<PostDiscountValues> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Amount 1',
      dataIndex: 'amount_1',
      key: 'amount_1',
      render: (_, record) => {
        return (
          <Text>
            {record.amount_min1 * conversion} - {record.amount_max1 * conversion}
          </Text>
        );
      }
    },
    {
      title: 'Amount 2',
      dataIndex: 'amount_2',
      key: 'amount_2',
      render: (_, record) => {
        return (
          <Text>
            {record.amount_min2 * conversion} - {record.amount_max2 * conversion}
          </Text>
        );
      }
    },
    {
      title: 'Amount 3',
      dataIndex: 'amount_3',
      key: 'amount_3',
      render: (_, record) => {
        return (
          <Text>
            {record.amount_min3 * conversion} - {record.amount_max3 * conversion}
          </Text>
        );
      }
    },
    {
      title: 'Amount 4',
      dataIndex: 'amount_4',
      key: 'amount_4',
      render: (_, record) => {
        return (
          <Text>
            {record.amount_min4 * conversion} - {record.amount_max4 * conversion}
          </Text>
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 60,
      key: 'Action',
      align: 'center',
      render: (_, record) => {
        return (
          <div style={{ display: 'flex', gap: '8px' }}>
            <Button icon={<EditOutlined />} onClick={() => clickDiscount(record.name)}>
              Edit
            </Button>
            <Button danger icon={<DeleteOutlined />} onClick={() => clickDiscountDelete(record.name)}>
              Delete
            </Button>
          </div>
        );
      }
    }
  ];

  return (
    <>
      {discounts && discounts?.length < 4 && (
        <div style={{ textAlign: 'end', marginBottom: '30px' }}>
          <Button icon={<PlusOutlined />} color="primary" onClick={() => setShowDiscountModal(true)}>
            Add Discount
          </Button>
        </div>
      )}
      <Table loading={false} bordered rowKey="id" pagination={false} columns={DiscountsColumns} dataSource={discounts} />
      <DiscountModal accTypeName={selectedAccType} clearAccTypeName={() => setSelectedAccType('')} isOpen={showDiscountModal} onClose={() => setShowDiscountModal(false)} />
      <DeleteModal
        open={showDeleteModal}
        confirmLoading={false}
        onOk={() => {
          dispatch(deleteDiscount(selectedAccType)).then(() => {
            setShowDeleteModal(false);
          });
        }}
        onCancel={() => {
          setShowDeleteModal(false);
        }}
      />
    </>
  );
};

export default Discounts;
