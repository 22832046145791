import { CheckOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Col, FloatButton, Form, Input, List, Modal, Popconfirm, message } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { currencyApi, deleteCurrency, editCurrency, setLimit, setPage, setSearch } from 'app/features/currency/slice';
import { selectCurrencyCount, selectCurrencyData, selectCurrencyLimit, selectCurrencyLoading, selectCurrencyPage } from 'app/features/currency/selectors';
import { CUrrency } from 'app/features/currency/type';
import { selectUserInfo } from 'app/features/auth/selectors';

import CurrencyModal from './currencyModal';
import ContentTop from 'components/ContentTop';
import { EditableCellValueWrap } from './styled';
const validateMessages = {
  required: "'${label}' is Required!"
};

const Currency = () => {
  const currencyData = useAppSelector(selectCurrencyData());
  const page = useAppSelector(selectCurrencyPage());
  const limit = useAppSelector(selectCurrencyLimit());
  const count = useAppSelector(selectCurrencyCount());
  const loading = useAppSelector(selectCurrencyLoading());

  const [editMode, setEditMode] = useState(false);
  const userInfo = useAppSelector(selectUserInfo());
  const isDisabled = userInfo?.role === 'SUPER_ADMIN' ? false : true;
  const [selectedCountry, setSelectedCountry] = useState<CUrrency | null>(null);
  const [isCurrencyModalOpen, setIsCurrencyModalOpen] = useState(false);

  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(currencyApi());
  }, [dispatch, navigate, page, limit]);

  useEffect(() => {
    return () => {
      dispatch(setSearch(''));
      dispatch(setLimit(10));
    };
  }, [dispatch, navigate]);

  const edit = (item: CUrrency) => {
    form.setFieldsValue({
      conversionRate: item.conversionRate
    });
    setSelectedCountry(item);
  };

  const update = async (item: CUrrency) => {
    const priceItem = form.getFieldValue('conversionRate');

    const dataProduct = {
      currencyCode: item.currencyCode,
      conversionRate: priceItem
    };

    const res = await dispatch(editCurrency({ ...dataProduct }));

    if (res.payload) {
      setSelectedCountry(null);
    }
  };

  const handleOutsideClick = (e: any) => {
    if (!e.target.closest('.ant-list-item')) {
      setSelectedCountry(null);
    }
  };

  return (
    <Form
      validateMessages={validateMessages}
      size="large"
      form={form}
      name="formLogistics"
      layout="vertical"
      // onFinish={onFinish}
      scrollToFirstError={{
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      }}
    >
      <ContentTop add title="Currency" buttonText="Add Currency Rate" onClickAdd={() => setIsCurrencyModalOpen(true)} />

      <CurrencyModal open={isCurrencyModalOpen} setIsOpen={setIsCurrencyModalOpen} form={form} setEditMode={setEditMode} editMode={editMode} />

      <div
        onClick={handleOutsideClick}
        style={{
          height: '90%',
          overflow: 'auto',
          padding: '0 16px',
          border: '1px solid rgba(140, 140, 140, 0.35)'
        }}
      >
        <Col span={12} offset={6}>
          <List
            loading={loading}
            dataSource={currencyData}
            pagination={{
              align: 'center',
              style: { margin: 8 },
              current: page,
              onChange: (newPage, newPageSize) => {
                dispatch(setPage(newPage));
                dispatch(setLimit(newPageSize));
              },
              pageSize: limit,
              total: count
              // showSizeChanger: true
            }}
            renderItem={item =>
              item && (
                <List.Item key={item.currencyCode}>
                  <List.Item.Meta title={item.currencyCode} />
                  <div>
                    {selectedCountry && selectedCountry.currencyCode === item.currencyCode ? (
                      <Form.Item name="conversionRate" style={{ margin: 0 }}>
                        <Input
                          type="number"
                          suffix={
                            <Popconfirm title="Do you want to update currency rate?" onConfirm={() => update(item)}>
                              <a>Update</a> {''}
                              <CheckOutlined />
                            </Popconfirm>
                          }
                          onPressEnter={() => update(item)}
                        />
                      </Form.Item>
                    ) : (
                      <EditableCellValueWrap onClick={() => edit(item)}>{item.conversionRate}</EditableCellValueWrap>
                    )}
                  </div>

                  <MinusCircleOutlined
                    style={{
                      marginLeft: 20,
                      color: isDisabled ? 'grey' : 'red',
                      fontSize: '19px',
                      cursor: isDisabled ? 'not-allowed' : 'pointer'
                    }}
                    onClick={e => {
                      if (isDisabled) {
                        e.preventDefault();
                        e.stopPropagation();

                        return;
                      }

                      Modal.confirm({
                        title: 'Confirm',
                        content: `Are you sure you want to remove "${item?.currencyCode}" Price? `,
                        onOk: async () => {
                          if (!item?.currencyCode) return message.error('No country selected');

                          dispatch(deleteCurrency(item.currencyCode));
                        }
                      });
                    }}
                  />
                </List.Item>
              )
            }
          />
        </Col>
      </div>
      <FloatButton.BackTop />
    </Form>
  );
};

export default Currency;
