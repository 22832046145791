import { ProductData, ProductJournal, ProductPriceState, ProductPricingCountry } from './types';

export type Action = 'updatePLaneData' | 'updateNavyData' | 'updateData' | 'setData';

interface ActionTypeBase {
  type: Action;
  data: { [key: string]: any };
}

interface ActionWithCountry extends ActionTypeBase {
  type: 'updatePLaneData' | 'updateNavyData' | 'updateData';
  countryId: number;
}

interface ActionSetData {
  type: 'setData';
  countryId?: never;
  data: { productPrice: ProductData | null; productJournals?: ProductJournal[][] | null };
}

interface ActionWithLoading {
  type: 'setLoading';
  data: { isLoading: boolean };
  countryId?: never;
}

interface ActionWithClear {
  type: 'clearData';
  countryId?: never;
}

export type ActionType = ActionWithCountry | ActionSetData | ActionWithLoading | ActionWithClear;

export const ProductPriceInitialData: ProductPriceState = {
  productPrice: null,
  changeableProductPrice: null,
  productJournals: null,
  isLoading: true
};

const updateCountryData = (countries: ProductPricingCountry[], countryId: number, update: { [key: string]: any }): ProductPricingCountry[] => {
  return countries.map(country => (country.countryId === countryId ? { ...country, ...update } : country));
};

export const pricingReducer = (state: ProductPriceState, action: ActionType): ProductPriceState => {
  const changeableProductPrice = state.changeableProductPrice as ProductData;
  const { countries = [] } = changeableProductPrice ?? {};

  const countryData = countries?.find(country => country.countryId === action?.countryId);

  switch (action.type) {
    case 'updateData':
      return {
        ...state,
        changeableProductPrice: {
          ...changeableProductPrice,
          countries: updateCountryData(countries, action?.countryId, {
            ...action.data
          })
        }
      };

    case 'setLoading': {
      return {
        ...state,
        isLoading: action.data.isLoading
      };
    }

    case 'setData': {
      return {
        changeableProductPrice: action.data.productPrice,
        productPrice: action.data.productPrice,
        productJournals: action.data.productJournals ?? state.productJournals,
        isLoading: false
      };
    }

    case 'updateNavyData':
      if (countryData) {
        return {
          ...state,
          changeableProductPrice: {
            ...changeableProductPrice,
            countries: updateCountryData(countries, action?.countryId, {
              pricing: {
                ...countryData.pricing,
                navy: {
                  ...countryData.pricing?.navy,
                  ...action.data
                }
              }
            })
          }
        };
      }

      return state;

    case 'clearData':
      return { ...ProductPriceInitialData };
    case 'updatePLaneData':
      if (countryData) {
        return {
          ...state,
          changeableProductPrice: {
            ...changeableProductPrice,
            countries: updateCountryData(countries, action.countryId, {
              pricing: {
                ...countryData.pricing,
                plane: {
                  ...countryData.pricing?.plane,
                  ...action.data
                }
              }
            })
          }
        };
      }

      return state;
    default:
      return state;
  }
};
