import { Button, Card, Col, Form, InputNumber, Row, Select, Typography } from 'antd';
import { selectLoadingQuantity, selectOrderById, selectOrderLoadingAction, selectOrderLoadingById } from 'app/features/order/selectors';
import { deleteOrderProduct, getOrderById, ordersEdit, ordersEditQty } from 'app/features/order/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { OrderStatus } from 'app/features/order/types';
import { CloseOutlined, DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { getFileUrlBIG } from 'helpers/file';
import Meta from 'antd/es/card/Meta';

import { status, statusFilter } from '../Orders/constant';
import Loading from 'components/Loading';
import {
  CardContentWrraper,
  DescriptionItemWrraper,
  GridStyle,
  HeaderDivider,
  HeaderStyled,
  Img,
  ImgWrraper,
  LeftPage,
  PageHeader,
  PageTitle,
  Price,
  ProductContainer,
  Total,
  TotalPriceWrraper
} from './styled';
import noImg from '../../assets/img/noimage.png';
import CancelOrderModal from 'components/CancelOrderModal';

const { Text } = Typography;

const OrderDetails = () => {
  //state
  const [openCancelOrderModal, setOpenCancelOrderModal] = useState(false);
  const [quantity, setQuantity] = useState<number>(0);
  const [editQty, setEditQty] = useState(0);
  const [message, setMessage] = useState<string>('');
  //navigate
  const navigate = useNavigate();
  //dispatch
  const dispatch = useAppDispatch();
  //selectors
  const orderById = useAppSelector(selectOrderById());
  const loadingAction = useAppSelector(selectOrderLoadingAction());
  const loadingById = useAppSelector(selectOrderLoadingById());
  const loadingQuantity = useAppSelector(selectLoadingQuantity());
  //params
  const { id } = useParams();
  //form
  const [form] = Form.useForm();

  const onClose = () => {
    form.resetFields();
    navigate(-1);
  };

  useEffect(() => {
    dispatch(getOrderById(Number(id)));
  }, [dispatch, id]);

  useEffect(() => {
    if (orderById) {
      form.setFieldsValue({
        status: statusFilter(orderById.order.status)
      });
    }
  }, [orderById, form]);

  const onSave = async (values: OrderStatus) => {
    await dispatch(
      ordersEdit({
        ...values,
        id: Number(id)
      })
    );
  };

  const handleSubmit = (e: string) => {
    if (e === 'CANCELED') {
      setOpenCancelOrderModal(true);
    } else {
      form.submit();
      setTimeout(() => {
        dispatch(getOrderById(Number(id)));
      }, 1000);
    }
  };

  return (
    <>
      {loadingById ? (
        <Loading size="large" />
      ) : (
        <>
          <PageHeader>
            <HeaderStyled>
              <LeftPage onClick={onClose} />
              <PageTitle level={3}>Order </PageTitle>
              <Typography.Text style={{ margin: 'auto' }}>{orderById && statusFilter(orderById?.order?.status)}</Typography.Text>
            </HeaderStyled>
            {orderById && statusFilter(orderById?.order?.status) != 'CANCELED' && (
              <Button onClick={() => setOpenCancelOrderModal(true)} danger icon={<DeleteOutlined />}>
                Cancel Order
              </Button>
            )}
          </PageHeader>
          <HeaderDivider />
          <Form size="large" form={form} layout="vertical" onFinish={onSave}>
            <Row style={{ marginBottom: 40 }} gutter={[24, 24]}>
              <Col span={12}>
                <Form.Item name="status" label="Status">
                  <Select
                    onChange={handleSubmit}
                    loading={loadingAction}
                    options={status.map(item => {
                      return {
                        value: item.title,
                        label: item.title
                      };
                    })}
                  />
                </Form.Item>
                {orderById?.order.status === 5 && (
                  <div>
                    <h5>Cancel Message</h5>
                    <br />
                    <p
                      style={{
                        border: '1px solid gray',
                        borderRadius: '5px',
                        padding: '5px 10px'
                      }}
                    >
                      {orderById?.order.message}
                    </p>
                  </div>
                )}
              </Col>
              <Col span={12}>
                <TotalPriceWrraper>
                  <Total>Total Price</Total>
                  <Price>-- {orderById?.order.totalPrice} AMD</Price>
                </TotalPriceWrraper>
              </Col>
            </Row>
          </Form>
          <Card title="Order Content">
            <CardContentWrraper>
              <GridStyle>{`Full Name --- ${orderById?.order.name} ${orderById?.order.surname}`}</GridStyle>
              <GridStyle>{`Payment --- ${orderById?.order.payment}`}</GridStyle>
              <GridStyle>{`Phone --- ${orderById?.order.phone}`}</GridStyle>
              <GridStyle>{`Email --- ${orderById?.order.email}`}</GridStyle>
              <GridStyle>{`Address --- ${orderById?.order.address}`}</GridStyle>
              <GridStyle>{`CreatedAt --- ${!!orderById && new Date(orderById?.order.createdAt).toLocaleDateString('en-GB')} - ${
                !!orderById &&
                new Date(orderById?.order.createdAt).toLocaleTimeString('it-IT', {
                  hour: '2-digit',
                  minute: '2-digit'
                })
              }`}</GridStyle>
              <GridStyle>{`UpdatedAt ---  ${!!orderById && new Date(orderById?.order.updatedAt).toLocaleDateString('en-GB')} - ${
                !!orderById &&
                new Date(orderById?.order.updatedAt).toLocaleTimeString('it-IT', {
                  hour: '2-digit',
                  minute: '2-digit'
                })
              }`}</GridStyle>
              <GridStyle>{`Transport --- ${orderById?.order.transport || null}`}</GridStyle>
            </CardContentWrraper>
          </Card>
          <Row gutter={[24, 24]} style={{ marginTop: 50 }}>
            {orderById?.products.map((item, index) => {
              const qty = orderById.order.products.products.find(i => i.id === item.id)?.count;

              return (
                <Col key={index} span={8}>
                  <ProductContainer
                    loading={loadingQuantity}
                    style={{ cursor: 'default' }}
                    hoverable
                    cover={<ImgWrraper>{item.files?.length ? <Img alt={item.files[0].name} src={getFileUrlBIG(item.files[0]?.path)} /> : <Img src={noImg} alt="No Image" />}</ImgWrraper>}
                    actions={[
                      editQty === item.id ? (
                        <SaveOutlined
                          style={{ fontSize: '20px' }}
                          key="saveEdit"
                          onClick={() => {
                            dispatch(
                              ordersEditQty({
                                id: Number(id),
                                productId: item.id,
                                quantity: quantity
                              })
                            ).then(() => {
                              setQuantity(0), setEditQty(0);
                              dispatch(getOrderById(Number(id)));
                            });
                          }}
                        />
                      ) : (
                        <EditOutlined
                          style={{ fontSize: '20px' }}
                          // onClick={() => {
                          //   setEditQty(item.id), setQuantity(item.count);
                          // }}
                          key="showEdit"
                        />
                      ),
                      <DeleteOutlined
                        style={{ fontSize: '20px' }}
                        onClick={() =>
                          item.id &&
                          dispatch(
                            deleteOrderProduct({
                              id: Number(id),
                              productId: item.id
                            })
                          ).then(() => {
                            dispatch(getOrderById(Number(id)));
                          })
                        }
                        key="ellipsis"
                      />
                    ]}
                  >
                    <Meta
                      title={item.title_en}
                      description={
                        <div style={{ lineHeight: 2 }}>
                          <DescriptionItemWrraper>
                            <Text>Articul number</Text>
                            <Text>{item.articul_number}</Text>
                          </DescriptionItemWrraper>
                          <DescriptionItemWrraper>
                            <Text>Country</Text>
                            <Text>{item.country?.name}</Text>
                          </DescriptionItemWrraper>
                          <DescriptionItemWrraper>
                            <Text>Partner</Text>
                            <Text>{item.partner?.name}</Text>
                          </DescriptionItemWrraper>
                          <DescriptionItemWrraper>
                            <Text>Quantity</Text>
                            {editQty === item.id ? (
                              <InputNumber
                                onChange={e => {
                                  setQuantity(e || 0);
                                }}
                                defaultValue={qty}
                                value={quantity}
                                style={{ width: '150px', height: '42px' }}
                                addonBefore={
                                  <CloseOutlined
                                    onClick={() => {
                                      setEditQty(0);
                                      setQuantity(0);
                                    }}
                                  />
                                }
                                size="small"
                              />
                            ) : (
                              <Text>{qty}</Text>
                            )}
                          </DescriptionItemWrraper>
                          <DescriptionItemWrraper>
                            <Text>Price</Text>
                            <Text>{item.price}</Text>
                          </DescriptionItemWrraper>
                          <DescriptionItemWrraper>
                            <Text>Total Amount</Text>
                            {/* <Text>{item.totalAmount}</Text> */}
                          </DescriptionItemWrraper>
                        </div>
                      }
                    />
                  </ProductContainer>
                </Col>
              );
            })}
          </Row>
          <CancelOrderModal
            setMessage={setMessage}
            open={openCancelOrderModal}
            confirmLoading={loadingAction}
            onOk={() => {
              dispatch(
                ordersEdit({
                  status: 'CANCELED',
                  id: Number(id),
                  message: message
                })
              ).then(() => {
                navigate(-1);
                setOpenCancelOrderModal(false);
              });
            }}
            onCancel={() => {
              setOpenCancelOrderModal(false);
            }}
          />
        </>
      )}
    </>
  );
};

export default OrderDetails;
