import { Button, Typography } from 'antd';
import { deleteUserApi, setLimit, setPage, setSearch, usersApi } from 'app/features/users/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectUserLimit, selectUsersCount, selectUsersData, selectUsersLoading, selectUsersPage, selectUsersSearch } from 'app/features/users/selectors';
import { useEffect, useState } from 'react';
import { _debounce } from 'helpers/debounce';
import { DeleteOutlined, EditTwoTone } from '@ant-design/icons';
import Table, { ColumnsType } from 'antd/es/table';
import { IUser } from 'app/features/users/types';
import { Link, useNavigate } from 'react-router-dom';
import { selectUserInfo } from 'app/features/auth/selectors';

import ContentTop from 'components/ContentTop';
import { SubscribeUsersButton } from './styled';
import DeleteModal from 'components/DeleteModal';

const debounce = _debounce();

const Users = () => {
  // dispatch
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // selectors
  const page = useAppSelector(selectUsersPage());
  const search = useAppSelector(selectUsersSearch());
  const limit = useAppSelector(selectUserLimit());
  const userInfo = useAppSelector(selectUserInfo());
  const isSalesAdmin = userInfo?.role == 'SALES_ADMIN';

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectRecordId, setSelectRecordId] = useState<string>('');

  const loading = useAppSelector(selectUsersLoading());
  const count = useAppSelector(selectUsersCount());
  const usersData = useAppSelector(selectUsersData());

  useEffect(() => {
    debounce(() => dispatch(usersApi()));
  }, [dispatch, search, page, limit]);

  const deleteHandler = (id: string) => {
    dispatch(deleteUserApi(id));
    setTimeout(() => {
      setOpenDeleteModal(false);
      dispatch(usersApi());
    }, 1000);
  };

  const columns: ColumnsType<IUser> = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (_, record) => {
        return <Typography.Text>{`${record.firstName}  ${record.lastName}`}</Typography.Text>;
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text: string) => {
        return (
          <>
            <a href={`mailto: ${text}`}>{text}</a>
          </>
        );
      }
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role'
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => {
        return (
          <div style={{ display: 'flex', gap: '10px' }}>
            <Link to={`details/${record.id}`}>
              <Button icon={<EditTwoTone />}></Button>
            </Link>
            <Button
              icon={<DeleteOutlined />}
              onClick={() => {
                setSelectRecordId(record.id);
                setOpenDeleteModal(true);
              }}
            ></Button>
          </div>
        );
      }
    }
  ];

  return (
    <>
      <ContentTop
        title="Users"
        placeholder="Search User"
        buttonText="Add User"
        onClickAdd={() => navigate(`details`)}
        onChange={e => {
          dispatch(setSearch(e.target.value));
        }}
        searchComponent
        search={search}
        add={!isSalesAdmin}
      />
      <SubscribeUsersButton>
        <Link to="subscribe">
          <Button size="large">Subscribed Users</Button>
        </Link>
      </SubscribeUsersButton>
      <Table
        loading={loading}
        bordered
        columns={columns}
        dataSource={usersData}
        rowKey="id"
        pagination={{
          current: page,
          onChange: (page, pageSize) => {
            dispatch(setPage(page));
            dispatch(setLimit(pageSize));
          },
          total: count,
          pageSize: limit
        }}
      />

      <DeleteModal
        open={openDeleteModal}
        confirmLoading={false}
        onOk={() => deleteHandler(selectRecordId)}
        onCancel={() => {
          setOpenDeleteModal(false);
          setSelectRecordId('');
        }}
      />
    </>
  );
};

export default Users;
