import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api/apiClient';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';

import { selectSectionLimit, selectSectionPage, selectSectionSearch } from './selectors';
import { IPostSectionProduct, ISectionById, SectionState } from './types';

const initialState: SectionState = {
  section: [],
  sectionById: null,
  loading: false,
  loadingById: false,
  loadingAction: false,
  page: 1,
  limit: 10,
  search: '',
  count: 0
};

export const sectionApi = createAsyncThunk('section/sectionApi', async (_, { getState, dispatch, rejectWithValue }) => {
  const state = getState() as RootState;
  const page = selectSectionPage()(state);
  const search = selectSectionSearch()(state);
  const limit = selectSectionLimit()(state);

  try {
    const res = await client.get(`/section`, {
      params: {
        search,
        page,
        limit
      }
    });

    return dispatch(setSection(res.data.data));
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const deleteSection = createAsyncThunk('section/deleteSection', async (id: number, { dispatch, rejectWithValue }) => {
  try {
    await client.delete(`/section/${id}`);

    await dispatch(sectionApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const deleteSectionProduct = createAsyncThunk('section/deleteSectionProduct', async ({ section_id, product_id }: { section_id?: number; product_id?: number }, { rejectWithValue }) => {
  try {
    await client.delete(`/section/product/${section_id}/${product_id}`);
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const sectionEdit = createAsyncThunk('section/sectionEdit', async ({ id, ...data }: ISectionById, { dispatch, rejectWithValue }) => {
  try {
    await client.patch(`/section/${id}`, {
      ...data
    });

    await dispatch(sectionApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const getSectionById = createAsyncThunk('section/getSectionById', async (id: number, { rejectWithValue, dispatch }) => {
  try {
    const response = await client.get(`/section/section/${id}`);

    return dispatch(setSectionById(response.data.data));
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const postSection = createAsyncThunk('section/postSection', async ({ ...data }: ISectionById, { dispatch, rejectWithValue }) => {
  try {
    await client.post('/section', {
      ...data
    });
    await dispatch(sectionApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});
export const postSectionProduct = createAsyncThunk('section/postSectionProduct', async (values: IPostSectionProduct, { rejectWithValue }) => {
  try {
    await client.post(`/section/product`, values);
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

const sectionSlice = createSlice({
  name: 'section',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setSection: (state, action) => {
      state.section = action.payload.rows;
      state.count = action.payload.count;
    },
    setSectionById: (state, action) => {
      state.sectionById = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    clickMenuSection: state => {
      (state.limit = 10), (state.page = 1), (state.search = '');
    }
  },
  extraReducers(builder) {
    builder
      .addCase(sectionApi.pending, state => {
        state.loading = true;
      })
      .addCase(sectionApi.fulfilled, state => {
        state.loading = false;
      })
      .addCase(sectionApi.rejected, state => {
        state.loading = false;
      })
      .addCase(getSectionById.pending, state => {
        state.loadingById = true;
      })
      .addCase(getSectionById.fulfilled, state => {
        state.loadingById = false;
      })
      .addCase(getSectionById.rejected, state => {
        state.loadingById = true;
      })
      .addCase(postSection.pending, state => {
        state.loadingAction = true;
      })
      .addCase(postSection.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(postSection.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(deleteSection.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteSection.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteSection.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(sectionEdit.pending, state => {
        state.loadingAction = true;
      })
      .addCase(sectionEdit.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(sectionEdit.rejected, state => {
        state.loadingAction = false;
      });
  }
});

export const { setPage, setSectionById, setSearch, setSection, setLimit, clickMenuSection } = sectionSlice.actions;
export default sectionSlice.reducer;
