import styled from 'styled-components';

export const StyledOrderLink = styled.div`
  .orderCount {
    position: absolute;
    top: -50%;
    right: -60%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: red;
    color: white;
  }
`;
