import styled, { css } from 'styled-components';

import { NumberInputType } from './types';

export const StyledDiv = styled.div``;

export const StyledForm = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  width: 100%;
  .numberInputCountainer {
    input {
      ::-webkit-outer-spin-button,
      ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
`;
export const SubForm = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 20px;
  width: 90%;
  label {
    color: #19293b;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
  }
`;

export const StyledCountrySelect = styled.div<{
  value?: string;
  error?: boolean;
  open?: boolean;
}>`
  position: relative;
  select {
    width: 100%;
    height: 100%;
    border: 1px solid gray;
    padding-inline: 11px;
    background-color: white;
    outline: none;
    font-size: var(--text-8);
    border-radius: 5px;
    color: black;
    background-color: white;
    appearance: none;
    -moz-appearance: none;
    background-image: url('/assets/icon/inputs/arrow.png');
    background-position: right 16px center;
    background-repeat: no-repeat;
    padding-right: 20px;
    :focus-visible {
      border: 1px solid #00909e;
    }

    :focus-visible + label {
      transform: translateY(-120%);
      color: #00909e;
    }
    ::-moz-focus-inner {
      border: 0;
      padding: 0;
    }
  }
  label {
    position: absolute;
    transition: transform 0.2s cubic-bezier(0.59, 0.63, 1, 0.96);
    cursor: text;
    top: 30%;
    left: 11px;
    background-color: white;
    padding-inline: 3px;
    letter-spacing: 0.06em;
    font-style: normal;
    font-weight: 600;
    font-family: Roboto;
    font-size: 14px;
    line-height: 20px;
    color: #19293b;
    ${({ value, open, error }) => css`
      transform: ${open ? 'translateY(-120%)' : 'translateY(0%)'};
      color: ${open ? '#00909E' : error ? 'ff0000' : '#19293b'};
      :focus-visible {
        transform: translateY(-120%);
        color: ${value ? '#00909E' : error ? 'ff0000' : '#19293b'};
      }
    `}
  }
`;

export const StyledMobileNumber = styled.div`
  .mobileNumberSelect {
    width: 100%;
    height: 45px;
    border-radius: 5px;
    outline: none;
    button {
      display: none;
    }
    & > div {
      outline: none;
      border: none;
      display: flex;
      align-items: center;
      height: 100%;
      padding: 10px;
    }
    fieldset {
      border-color: gray;
      height: 57px;
      top: -12px;
      legend {
        width: max-content;
      }
    }
  }
`;

export const SignFooter = styled.div`
  width: 90%;
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;

  > p {
    width: 100%;
    text-align: left;
  }

  button {
    margin-top: 60px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    font-family: Montserrat;
    line-height: 22px;
    color: #ffffff;
    box-sizing: border-box;
    background: #00909e;
    border: 1px solid #00909e;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  > p {
    color: gray;
    word-spacing: 0.1rem;
    a {
      color: var(--primary);
      text-decoration: underline;
    }

    input {
      margin-right: 5px;
    }
  }
`;

export const StyledModal = styled.div<{ active: boolean }>`
  position: fixed;
  z-index: 3;
  top: 50%;
  left: 50%;
  width: 1076px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transform: translate(-50%, -50%) scale(0);
  transform-origin: center;
  padding: 24px 80px;
  transition: 0.5s;
  ${({ active }) =>
    active
      ? css`
          transform: translate(-50%, -50%) scale(1);
          transition: 0.5s;
        `
      : null}
  .title {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    color: #19293b;
    font-family: Helvetica home;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.2px;
    .closeIcon {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 33px;
      height: 33px;
      cursor: pointer;
      svg {
        /* &:nth-child(1) {
          position: absolute;
          top: 0;
          right: 0;
        } */
        &:nth-child(2) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .imageContainer {
    margin-left: 10px;
    position: relative;
    width: 120px;
    height: 120px;
    margin-bottom: 26px;
    img {
      position: absolute;
      width: 100%;
      &:nth-child(1) {
        top: 0;
        left: 0;
      }
      &:nth-child(2) {
        width: 69px;
        height: 69px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .uploadBtn {
    display: flex;
    gap: 10px;
    align-items: center;
    color: var(--just-white, #fff);
    font-family: Montserrat arm;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 233.333% */
    border-radius: 3px;
    background: #00909e;
    box-shadow: 2px 2px 2px 0px rgba(25, 41, 59, 0.2);
    margin-bottom: 30px;
  }
  ${StyledForm}>div {
    width: 100%;
  }
  ${SignFooter} {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    button {
      border-radius: 5px;
      border: 1px solid #00909e;
      background: #00909e;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      color: #fff;
      font-family: Montserrat arm;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      padding: 12px 64px;
      line-height: normal;
      margin-bottom: 30px;
    }
  }
`;

export const AddressesCartContainer = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
`;
export const AddressesCart = styled.div<{ active: boolean }>`
  border-radius: 5px;
  border: 1px solid ${({ active }) => (active ? '#00909E' : '#d1d3d4')};
  background: #fff;
  height: 320px;
  padding: 20px 30px;
  position: relative;
  word-break: break-all;
  /* min-width: 150px; */

  .title {
    color: #19293b;
    font-family: Helvetica home;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    &:nth-child(1) {
      margin-bottom: 40px;
    }
  }
  .address {
    color: #19293b;
    font-family: Montserrat;
    word-break: break-all;
    font-size: 16px;
    font-style: normal;
    word-break: break-all;
    margin-top: 15px;
    width: 215px;
    font-weight: 200;
    line-height: 26px; /* 162.5% */
  }
  .makeDefBtn {
    color: #19293b;
    text-align: center;
    cursor: pointer;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    position: absolute;
    bottom: 21px;
    left: 30px;
  }
  .deleteBtn {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    svg {
      font-size: 16px;
    }
    &:hover {
      svg {
        fill: red;
      }
    }
  }
  .addNewAddress {
    text-align: center;
    width: max-content;
    cursor: pointer;
    display: flex;
    gap: 14px;
    align-items: center;
    flex-direction: column;
    svg {
      cursor: pointer;
    }
  }
  .newAddressText {
    color: #19293b;
    font-family: Helvetica home;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const StyledActionColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  background: none;
  border: none;
`;

export const StyledArithmetic = styled.button`
  cursor: pointer;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid #00909e;
  color: black;
  background-color: white;

  :hover {
    opacity: 0.7;
  }
`;

export const NumberInput = styled.input.attrs({
  min: 1,
  type: 'number'
})<NumberInputType>`
  color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  max-width: 100px;
  outline: none;
  border: 1px solid #d1d3d4;
  border-radius: 5px;
  padding: 2px 5px;
  font-size: 15px;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  :focus {
    border: 1px solid black;
    outline: none;
  }
  ${({ length }) =>
    length
      ? css`
          width: ${length}em;
        `
      : css`
          width: 40px;
        `}
`;

export const SuccessWrapper = styled.div`
  width: fit-content;
  margin: auto;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  flex-direction: column;
  gap: 52px;

  div {
    width: 204px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  p {
    font-size: 22px;
  }
`;

export const StyledTotalPrice = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  div {
    min-width: 200px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    p {
      :nth-child(1) {
        font-size: 18px;
        font-weight: 500;
      }
      :nth-child(2) {
        font-size: 18px;
        color: #00909e;
        font-weight: 500;
      }
    }
  }
`;
