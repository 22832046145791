import { ArrowLeftOutlined } from '@ant-design/icons';
import { Divider, Typography } from 'antd';
import styled from 'styled-components';

export const PageHeader = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
`;

export const LeftPage = styled(ArrowLeftOutlined)`
  font-size: 17px;
`;

export const PageTitle = styled(Typography.Title)`
  && {
    width: max-content;
    margin-bottom: 0;
    margin-inline: 20px;
  }
`;

export const HeaderDivider = styled(Divider)`
  margin: 5px 0 50px 0;
`;
export const ProductTitle = styled(Typography.Paragraph)`
  && {
    margin: 0;
  }
`;
