import styled, { css } from 'styled-components';

import { IStyledButton } from './types';

const widthStyles = {
  half: css`
    width: 110px;
    height: 45px;
    min-width: 110px;
  `,
  default: css`
    width: 192px;
  `,
  'fit-content': css`
    padding-inline: 32px;
    width: max-content;
  `,
  full: css`
    width: 100%;
  `
} as const;

const sizeStyles = {
  xs: css`
    height: 45px;
    font-size: var(--text-8);
  `,
  sm: css`
    padding: 8px;
    font-size: var(--text-8);
  `,
  md: css`
    padding: 10px;
    font-size: var(--text-3);
  `,
  lg: css`
    padding: 12px;
    font-size: var(--text-11);
  `
} as const;

const modeStyles = {
  primary: css`
    background-color: var(--primary);
    color: var(--white);
  `,
  default: css`
    background-color: var(--white);
    border: 1px solid var(--primary);
    color: var(--primary);
  `,
  link: css`
    background-color: var(--white);
  `,
  search: css`
    background-color: var(--primary);
    border-radius: 0 50px 50px 0;
    color: var(--white);
  `
} as const;

export const StyledButton = styled.button<IStyledButton>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 2px rgba(25, 41, 59, 0.2);
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: 0.3s;
  font-family: 'Roboto';
  &:active {
    box-shadow: inset 0px 4px 4px rgba(25, 41, 59, 0.3);
    opacity: 1;
    transition: 0.3s;
  }
  ${({ width, mode, size }) => css`
    ${modeStyles[mode]}
    ${sizeStyles[size]}
    ${widthStyles[width]}
  `}
`;

export const ViewButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin: 0;
  padding: 0;
`;
