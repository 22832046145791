import { BASE_URL, BASE_URL_BIG } from 'api/apiClient';

export const getFileUrl = (name: string, extension: string, direct: string) => {
  return `${BASE_URL}/uploads/${direct}/${name}.${extension}`;
};

export const getFileUrlBIG = (path: string) => {
  return `${BASE_URL_BIG}/public/${path}`;
};

export const getFileUrlSlider = (name: string, extension: string) => {
  return encodeURI(`${BASE_URL}/uploads/sliders/${name}.${extension}`);
};

export const getPDFUrl = (path: string) => {
  return `${BASE_URL_BIG}/public/${path}`;
};
