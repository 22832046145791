import {
  AppstoreOutlined,
  BookOutlined,
  HddOutlined,
  IdcardOutlined,
  OrderedListOutlined,
  QuestionCircleOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  SnippetsOutlined,
  UnorderedListOutlined,
  UserOutlined,
  WhatsAppOutlined,
  PlusCircleOutlined,
  UserSwitchOutlined,
  PercentageOutlined,
  UsergroupDeleteOutlined,
  DollarOutlined
} from '@ant-design/icons';
import { Menu } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ordersApi } from 'app/features/order/slice';
import { selectOrdersData } from 'app/features/order/selectors';
import { IOrder } from 'app/features/order/types';
import { selectUserInfo } from 'app/features/auth/selectors';

import adminPng from '../../assets/img/admin.png';
import { StyledOrderLink } from './styled';
import * as constant from './constant';

interface IMenu {
  key: string;
  icon: any;
  label: any;
  children?: any;
}

const MenuSider = () => {
  //state
  const [filteredOrderData, setFilteredOrderData] = useState<IOrder[]>([]);
  // location
  const { pathname } = useLocation();
  const navigate = useNavigate();
  //dispatch
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUserInfo());

  const menuItems: IMenu[] = [
    {
      key: 'CATEGORIES',
      icon: <AppstoreOutlined style={{ fontSize: 20 }} />,
      label: (
        <Link
          onClick={() => {
            dispatch(constant.clickMenuCategory());
          }}
          to="categories"
        >
          Categories
        </Link>
      )
    },
    {
      key: 'PRODUCTS',
      icon: <ShoppingOutlined style={{ fontSize: 20 }} />,
      label: (
        <Link
          onClick={() => {
            dispatch(constant.clickMenuProduct());
          }}
          to="products"
        >
          Products
        </Link>
      )
    },
    {
      key: 'DISCOUNTS',
      icon: <PercentageOutlined style={{ fontSize: 20 }} />,
      label: <Link to="discounts">Discounts</Link>
    },
    {
      key: 'SECTION',
      icon: <UnorderedListOutlined style={{ fontSize: 20 }} />,
      label: (
        <Link
          onClick={() => {
            dispatch(constant.clickMenuSection());
          }}
          to="section"
        >
          Sections
        </Link>
      )
    },
    {
      key: 'ROLE_MANAGMENT',
      icon: <OrderedListOutlined style={{ fontSize: 20 }} />,
      label: 'Role Managment',
      children: [
        user?.permision_menu.find(i => i === 'ROLES') && {
          key: 'ROLES',
          icon: <UserSwitchOutlined style={{ fontSize: 20 }} />,
          label: <Link to="roles">Roles</Link>
        },
        user?.permision_menu.find(i => i === 'USERS') && {
          key: 'USERS',
          icon: <UserOutlined style={{ fontSize: 20 }} />,
          label: (
            <Link
              onClick={() => {
                dispatch(constant.clickMenuUser());
              }}
              to="users"
            >
              Users
            </Link>
          )
        },
        user?.permision_menu.find(i => i === 'ADMINS') && {
          key: 'ADMINS',
          icon: <img src={adminPng} style={{ width: '20px', height: '20px' }} />,
          label: (
            <Link
              onClick={() => {
                dispatch(constant.clickMenuAdmin());
              }}
              to="admins"
            >
              Admins
            </Link>
          )
        },
        user?.permision_menu.find(i => i === 'SPECIALIST') && {
          key: 'SPECIALIST',
          icon: <IdcardOutlined style={{ fontSize: 20 }} />,
          label: (
            <Link
              onClick={() => {
                dispatch(constant.clickMenuSpecialist());
              }}
              to="specialist"
            >
              Specialist
            </Link>
          )
        }
      ]
    },
    {
      key: 'PARTNERS',
      icon: <UsergroupDeleteOutlined style={{ fontSize: 20 }} />,
      label: (
        <Link
          onClick={() => {
            dispatch(constant.clickMenuPartner());
          }}
          to="partners"
        >
          Partner
        </Link>
      )
    },
    {
      key: 'CUSTOM_PAGE',
      icon: <HddOutlined style={{ fontSize: 20 }} />,
      label: <Link to="custom_page">Custom Page</Link>
    },
    {
      key: 'SERVICES',
      icon: <OrderedListOutlined style={{ fontSize: 20 }} />,
      label: 'Services',
      children: [
        {
          key: 'educational',
          icon: <BookOutlined style={{ fontSize: 20 }} />,
          label: (
            <Link
              onClick={() => {
                dispatch(constant.clickMenuEducational());
              }}
              to="educational"
            >
              Educational
            </Link>
          )
        },
        {
          key: 'allservices',
          icon: <PlusCircleOutlined style={{ fontSize: 20 }} />,
          label: (
            <Link
              onClick={() => {
                dispatch(constant.clickMenuCreateService());
              }}
              to="services"
            >
              All Services
            </Link>
          )
        }
      ]
    },
    {
      key: 'BLOG',
      icon: <SnippetsOutlined style={{ fontSize: 20 }} />,
      label: (
        <Link
          onClick={() => {
            dispatch(constant.clickMenuBlog());
          }}
          to="blog"
        >
          Blog
        </Link>
      )
    },
    {
      key: 'CONTACT',
      icon: <WhatsAppOutlined style={{ fontSize: 20 }} />,
      label: (
        <Link
          onClick={() => {
            dispatch(constant.clickMenuContact());
          }}
          to="contact"
        >
          Contact
        </Link>
      )
    },
    {
      key: 'ORDERS',
      icon: <ShoppingCartOutlined style={{ fontSize: 20 }} />,
      label: (
        <StyledOrderLink onClick={() => navigate('/orders')}>
          <Link
            onClick={() => {
              dispatch(constant.clickMenuOrder());
            }}
            to="orders"
            style={{ position: 'relative' }}
          >
            {filteredOrderData.length > 0 && <div className="orderCount">{filteredOrderData.length}</div>}
            Orders
          </Link>
        </StyledOrderLink>
      )
    },
    {
      key: 'QUESTIONS',
      icon: <QuestionCircleOutlined style={{ fontSize: 20 }} />,
      label: <Link to="questions">Questions</Link>
    },
    {
      key: 'CURRENCY',
      icon: <DollarOutlined style={{ fontSize: 20 }} />,
      label: <Link to="currency">Currency</Link>
    }
  ];

  const [menuByUser, setMenuByUser] = useState<IMenu[]>([]);

  useEffect(() => {
    if (user) {
      user.permision_menu?.map(key => {
        if (key === 'DISCOUNTS' && !menuByUser.find(item => item.key === 'CATEGORIES') && !menuByUser.find(item => item.key === 'PRODUCTS')) {
          setMenuByUser(prev => [...prev, menuItems[0], menuItems[1]]);
        }

        if (key === 'USERS' || key === 'ADMINS' || key === 'ROLES' || key === 'SPECIALIST') {
          const f = menuItems.find(item => item.key === 'ROLE_MANAGMENT');

          if (f) {
            setMenuByUser(prev => [...prev, f]);
          }
        }

        const f = menuItems.find(item => item.key === key);
        const currency = menuItems.find(item => item.key === 'CURRENCY');

        if (f) {
          setMenuByUser(prev => [...prev, f]);
        }

        if (currency) {
          setMenuByUser(prev => [...prev, currency]);
        }
      });
    }
  }, [user]);

  function removeDuplicates(arr: IMenu[]) {
    const seen = new Map();

    return arr.filter(item => {
      if (!seen.has(item)) {
        seen.set(item, true);

        return true;
      }

      return false;
    });
  }

  const orderData = useAppSelector(selectOrdersData());

  useEffect(() => {
    dispatch(ordersApi());
  }, []);

  useEffect(() => {
    if (orderData && orderData.length > 0) {
      setFilteredOrderData(orderData.filter(item => item.status === 1));
    }
  }, [orderData]);

  return (
    <Menu
      theme="dark"
      mode="inline"
      className="scroll"
      selectedKeys={[pathname.split('/')[1].toUpperCase()]}
      defaultSelectedKeys={[pathname.split('/')[1].toUpperCase()]}
      items={removeDuplicates(menuByUser)}
    />
  );
};

export default MenuSider;
