import { Button, Drawer, Typography, Upload, UploadFile, UploadProps, message } from 'antd';
import { FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectImagesSearch } from 'app/features/images/selectors';
import { imagesApi, setPage, setSearch } from 'app/features/images/slice';
import useCustomRequest from 'Hook/useCustomRequest';
import { UploadOutlined } from '@ant-design/icons';

import { IImagePageProps } from './types';
import { AddButtonWrraper, ContentTopTitle, ContentTopWrapper, DrawerStyled, SearchStyled, UploadWrraper } from './styled';
import Images from './components/Images';

const { Dragger } = Upload;
const { Title } = Typography;

const ImagePage: FC<IImagePageProps> = ({ open, setOpen }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [openAddImage, setOpenAddImage] = useState(false);
  //selectors
  const search = useAppSelector(selectImagesSearch());

  //dispatch
  const dispatch = useAppDispatch();

  const onChange: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
    setFileList(newFileList);

    const isAllUploaded = newFileList.every(file => file.status === 'done');

    if (isAllUploaded) {
      setFileList([]);
      await message.success('Pictures uploaded successfully!', 2);
      setOpenAddImage(false);
      dispatch(setPage(1));
      dispatch(setSearch(''));
      await dispatch(imagesApi());
    }
  };

  const customRequest = useCustomRequest({ url: 'upload_file' });

  return (
    <>
      <Drawer title="Add Image" placement="right" onClose={() => setOpenAddImage(false)} open={openAddImage} width={700}>
        <UploadWrraper>
          <Dragger onChange={onChange} accept="image/*" listType="picture" multiple fileList={fileList} customRequest={customRequest}>
            <Button icon={<UploadOutlined />}>Upload Image</Button>
          </Dragger>
        </UploadWrraper>
      </Drawer>
      <DrawerStyled
        title={
          <ContentTopWrapper>
            <ContentTopTitle>
              <Title level={3}>Images</Title>
              <SearchStyled
                onChange={e => {
                  dispatch(setSearch(e.target.value));
                }}
                size="middle"
                value={search}
                placeholder="Search Image"
                enterButton
              />
            </ContentTopTitle>
            <AddButtonWrraper>
              <Button type="primary" onClick={() => setOpenAddImage(true)}>
                Add Image
              </Button>
            </AddButtonWrraper>
          </ContentTopWrapper>
        }
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
        width={1200}
      >
        <Images />
      </DrawerStyled>
    </>
  );
};

export default ImagePage;
