export const filterOnlyNumber = (input: string) => {
  const floatNumberRegex = /^[0-9]*\.?[0-9]*$/g;

  const isFloatNumber = floatNumberRegex.test(input);

  const onlyNumbers = input.replace(/[^0-9.]/g, '');

  if (input[0] === '0') {
    return String(Number(onlyNumbers));
  }

  return isFloatNumber ? input : onlyNumbers;
};

export function formatDate(input: string) {
  // Split the input string into an array based on the position of characters
  const day = input.slice(0, 2);
  const month = input.slice(2, 4);
  const year = input.slice(4, 6);

  // Reassemble the date in the desired format
  return `${day}.${month}.20${year}`;
}
