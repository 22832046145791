import React from 'react';
import { Divider, Modal } from 'antd';

import { hcSyncRes } from 'pages/ProductDetails/components/ProductPrices/types';
import { CountryName, StatCategory, StatValue } from '../styled';

interface Props {
  open: boolean;
  onClose: () => void;
  hcRes?: hcSyncRes;
}

const HcModal: React.FC<Props> = ({ open, onClose, hcRes }) => {
  if (!hcRes) return null;

  return (
    <Modal title="Country Stats" width={'25%'} open={open} onCancel={onClose} footer={null}>
      {Object.entries(hcRes).map(([country, stats], index) => (
        <div key={country}>
          <CountryName>{country}</CountryName>
          <div>
            <StatCategory>To HC:</StatCategory>
            <StatValue zeroValue={stats.toHC.added === 0}>Added: {stats.toHC.added}</StatValue>
            <StatValue zeroValue={stats.toHC.updated === 0}>Updated: {stats.toHC.updated}</StatValue>
          </div>
          <div>
            <StatCategory>From HC:</StatCategory>
            <StatValue zeroValue={stats.fromHC.added === 0}>Added: {stats.fromHC.added}</StatValue>
            <StatValue zeroValue={stats.fromHC.updated === 0}>Updated: {stats.fromHC.updated}</StatValue>
          </div>
          {index !== Object.entries(hcRes).length - 1 && <Divider />}
        </div>
      ))}
    </Modal>
  );
};

export default HcModal;
