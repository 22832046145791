import React, { useEffect, useState } from 'react';
import { Table, Button, Image, Typography } from 'antd';
import { IProdByUser } from 'app/features/products/types';
import { getFileUrlBIG } from 'helpers/file';
import { ColumnsType } from 'antd/es/table';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { addBasketUserApi, userByIdApi } from 'app/features/users/slice';
import { selectUserById, selectUserProductsLoading } from 'app/features/users/selectors';
import { useParams } from 'react-router-dom';
import { TablePaginationConfig } from 'antd/lib/table';
import { findCountryCode } from 'helpers/product';

import noImg from '../../../../assets/img/noimage.png';
import { ImgTableWrapper } from 'pages/Products/styled';
import { NumberInput, StyledActionColumn, StyledArithmetic } from '../styled';

interface Props {
  products: IProdByUser[];
  pagination: TablePaginationConfig;
}

const ProductTable: React.FC<Props> = ({ products, pagination }) => {
  const [data, setData] = useState<IProdByUser[]>(products);
  const dispatch = useAppDispatch();
  const userById = useAppSelector(selectUserById());
  const loading = useAppSelector(selectUserProductsLoading());

  const { id } = useParams();
  const { Paragraph } = Typography;

  useEffect(() => {
    setData(products);
  }, [products]);

  const handleIncrement = (record: IProdByUser) => {
    const updatedData = data.map(item => (item.id === record.id ? { ...item, count: item.count + 1 } : item));

    setData(updatedData);
  };

  const handleChange = (record: IProdByUser, value: number) => {
    const updatedData = data.map(item => (item.id === record.id ? { ...item, count: value } : item));

    setData(updatedData);
  };

  const handleDecrement = (record: IProdByUser) => {
    if (record.count > 0) {
      const updatedData = data.map(item => (item.id === record.id && item.count > 1 ? { ...item, count: item.count - 1 } : item));

      setData(updatedData);
    }
  };

  const columns: ColumnsType<IProdByUser> = [
    {
      title: 'Title',
      dataIndex: 'title_en',
      key: 'title',
      width: 220
    },
    {
      title: 'Article number',
      dataIndex: 'articul_number',
      key: 'articul_number'
    },
    {
      title: 'Default Price',
      dataIndex: 'pricingInfo',
      key: 'price',
      align: 'center',
      width: 200,
      render: value => {
        const pricingInfoCount = value?.length;
        const firstPriceElement = value?.[0];
        if (!pricingInfoCount || !firstPriceElement) return 'No Country Price';

        const countryCodes = findCountryCode(firstPriceElement.countryId);
        const standardPrice = Number(firstPriceElement.fullNavyPrice) || 0;
        const expressPrice = Number(firstPriceElement.fullPlanePrice) || 0;

        return (
          <div>
            <Paragraph>
              <strong>ST: {standardPrice <= 0 ? '---' : `${standardPrice.toFixed(2)}$, ${countryCodes}`}</strong>
            </Paragraph>
            <Paragraph>
              <strong>EX: {expressPrice <= 0 ? '---' : `${expressPrice.toFixed(2)}$, ${countryCodes}`}</strong>
            </Paragraph>
          </div>
        );
      }
    },
    {
      title: 'Price for User',
      dataIndex: 'pricingInfo',
      key: 'price_for_user',
      align: 'center',
      width: 200,
      render: value => {
        const pricingInfoCount = value?.length;
        const firstPriceElement = value?.[0];
        if (!pricingInfoCount || !firstPriceElement) return 'No Country Price';

        const countryCodes = findCountryCode(firstPriceElement.countryId);
        const standardPrice = Number(firstPriceElement.finalNavyPrice) || 0;
        const expressPrice = Number(firstPriceElement.finalPlanePrice) || 0;

        return (
          <div>
            <Paragraph>
              <strong
                style={{
                  color: '#00c11f'
                }}
              >
                ST: {standardPrice <= 0 ? '---' : `${standardPrice.toFixed(2)}$, ${countryCodes}`}
              </strong>
            </Paragraph>
            <Paragraph>
              <strong
                style={{
                  color: '#00c11f'
                }}
              >
                EX: {expressPrice <= 0 ? '---' : `${expressPrice.toFixed(2)}$, ${countryCodes}`}
              </strong>
            </Paragraph>
          </div>
        );
      }
    },
    {
      title: 'Image',
      dataIndex: 'files',
      key: 'image',
      width: 100,
      render: files => {
        const thumbnailImageFile = files?.length > 0 ? { ...files[0], path: getFileUrlBIG(files[0].path) } : null;

        return (
          <ImgTableWrapper>
            {thumbnailImageFile ? (
              <Image
                height={55}
                width={70}
                alt={thumbnailImageFile.name}
                src={thumbnailImageFile.path}
                preview={{
                  src: thumbnailImageFile.path.replace('108x88', '574x438')
                }}
              />
            ) : (
              <Image preview={false} width={70} src={noImg} alt="No Image" />
            )}{' '}
          </ImgTableWrapper>
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'addBasket',
      key: 'addBasket',
      width: 100,
      render: (_, record) => {
        return (
          <StyledActionColumn>
            <StyledArithmetic
              onClick={e => {
                handleDecrement(record), e.preventDefault();
              }}
            >
              -
            </StyledArithmetic>

            <NumberInput
              length={String(record.count).length}
              value={record.count}
              onChange={e => {
                e.preventDefault();
                handleChange(record, +e.target.value);
              }}
            />

            <StyledArithmetic
              onClick={e => {
                handleIncrement(record), e.preventDefault();
              }}
            >
              +
            </StyledArithmetic>
            <Button
              onClick={() => {
                if (id) {
                  dispatch(
                    addBasketUserApi({
                      id: record.id,
                      count: record.count,
                      user_id: id
                    })
                  ).then(res => {
                    if (res && !userById?.have_basket) {
                      dispatch(userByIdApi(id));
                    }
                  });
                }
              }}
            >
              Add to basket
            </Button>
          </StyledActionColumn>
        );
      }
    }
  ];

  return <Table columns={columns} loading={loading} bordered key={id} dataSource={data} pagination={pagination} rowKey="id" />;
};

export default ProductTable;
