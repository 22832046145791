import styled from 'styled-components';

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 48px;
  background-color: var(--white);
  border-radius: 10px 10px 0 0;
  gap: 10px;
  margin-bottom: 5px;
  p {
    color: var(--primary);
  }

  img {
    height: 22px;
  }
`;

export const LiTitle = styled.div`
  display: block;
  flex: 1;
  button {
    background: none;
    border: none;
    color: #19293b;
    font-size: 14px;
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const OpenDiv = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledSpan = styled.div<{ selected: boolean }>`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 5px 10px 5px 5px;
  border-bottom: 1px solid #19293b1a;
  color: ${({ selected }) => (selected ? '#00909e' : 'black')};
  button {
    color: ${({ selected }) => (selected ? '#00909e' : 'black')};
  }
  img {
    right: 0;
    width: 6px;
    height: 10px;
  }
`;

export const StyledAside = styled.aside<{ isActive: boolean }>`
  max-width: 315px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid gray;
  z-index: 2;
  background-color: white;
  margin-bottom: 5px;
  position: ${({ isActive }) => (isActive ? 'absolute' : 'relative')};
`;

export const StyledDropDown = styled.div<{ isActive: boolean }>`
  background-color: white;
  width: 100%;
  transform: ${({ isActive }) => (isActive ? 'scaleY(1)' : 'scaleY(0)')};
  height: ${({ isActive }) => (isActive ? 'auto' : '0')};
  transition: 0.1s;
  transform-origin: top;
  li {
    list-style-type: none;
  }
`;

export const StyledParentContainer = styled.div`
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledDiv = styled.div<{ isSelectCategoryOpen: boolean }>`
  margin-bottom: ${({ isSelectCategoryOpen }) => (isSelectCategoryOpen ? '62px' : '0px')};
`;
