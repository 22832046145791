import { ArrowLeftOutlined } from '@ant-design/icons';
import { Card, Divider, Typography } from 'antd';
import styled from 'styled-components';

export const PageHeader = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
`;

export const LeftPage = styled(ArrowLeftOutlined)`
  font-size: 17px;
`;

export const PageTitle = styled(Typography.Title)`
  && {
    margin-bottom: 0;
    width: max-content;
    margin-inline: 20px;
  }
`;
export const HeaderDivider = styled(Divider)`
  margin: 5px 0 50px 0;
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const GridStyle = styled(Card.Grid)`
  width: 25%;
  text-align: center;
`;

export const CardContentWrraper = styled.div`
  display: flex;
  flex-wrap: wrap;
  .ant-card-grid {
    width: 50%;
  }
  .ant-card-grid-hoverable:hover {
    font-weight: 700;
  }
`;

export const Img = styled.img`
  width: 95%;
  height: 95%;
  object-fit: contain;
`;

export const ImgWrraper = styled.div`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 290px;
  }
`;

export const DescriptionItemWrraper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed black;
  margin-bottom: 10px;
  height: 32px;
`;

export const TotalPriceWrraper = styled.div`
  padding-top: 38px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Total = styled.span`
  font-size: 35px;
  font-weight: 700;
  margin-right: 15px;
`;

export const Price = styled.span`
  font-size: 25px;
  font-weight: 700;
  display: flex;
  align-items: center;
`;

export const ProductContainer = styled(Card)`
  .ant-card-meta-description {
    :hover {
      font-weight: 700;
    }
  }
`;
