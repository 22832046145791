import { Typography } from 'antd';
import styled from 'styled-components';

export const Text = styled(Typography.Text)`
  &&& {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
`;
