export const status = [
  { title: 'PENDING', id: 1 },
  { title: 'PROCESSING', id: 2 },
  { title: 'SHIPPED', id: 3 },
  { title: 'COMPLETED', id: 4 },
  { title: 'CANCELED', id: 5 }
];

export const statusFilter = (id: number) => {
  const filterStatus = status.find(item => item.id === id);

  return filterStatus?.title;
};
