import { Button, Card } from 'antd';
import Meta from 'antd/es/card/Meta';
import styled from 'styled-components';

export const Img = styled.img`
  width: 100%;
  height: 98%;
  object-fit: contain;
`;

export const ImgWrraper = styled.div`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 290px;
  }
`;

export const CardImg = styled(Card)`
  .ant-card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    padding: 0;
    border-top: 1px solid #f0f0f0;
    text-align: center;
  }
  .ant-card-actions > li {
    margin: 0;
  }
`;

export const ImagesPagination = styled.div`
  padding-block: 24px;
  display: flex;
  justify-content: flex-end;
`;

export const ImagesMeta = styled(Meta)`
  width: 100%;
  padding-inline: 24px;
  .ant-card-meta-title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    word-wrap: break-word;
    white-space: normal;
    font-weight: 500;
  }
`;

export const ButtonDelete = styled(Button)`
  .ant-btn {
    border-radius: 0px 0px 6px 6px;
  }
`;
