import React, { FC, memo } from 'react';
import { Modal } from 'antd';

import { IVideoModal } from './types';

const VideoModal: FC<IVideoModal> = ({ onCancel, visible, videoUrl }) => {
  return (
    <Modal width={650} open={visible} onCancel={onCancel} footer={null}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '350px'
        }}
      >
        <iframe width="560" height="315" src={videoUrl} frameBorder="0" allowFullScreen />
      </div>
    </Modal>
  );
};

export default memo(VideoModal);
