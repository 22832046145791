import { RootState } from 'app/store';

export const selectQuestionData = () => (state: RootState) => state.question.questions;
export const selectQuestionLoading = () => (state: RootState) => state.question.loading;
export const selectQuestionCount = () => (state: RootState) => state.question.count;
export const selectQuestionById = () => (state: RootState) => state.question.questionById;
export const selectQuestionLoadingById = () => (state: RootState) => state.question.loadingById;
export const selectQuestionLoadingAction = () => (state: RootState) => state.question.loadingAction;

export const selectQuestionActive = () => (state: RootState) => state.question.active;
