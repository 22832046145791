import { Modal, Button, Input, Form } from 'antd';
import { selectSectionById, selectSectionLoadingAction } from 'app/features/sections/selectors';
import { postSection, sectionEdit } from 'app/features/sections/slice';
import { ISectionById } from 'app/features/sections/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FC, memo, useEffect } from 'react';

import { ISectionModalProps } from './types';

const SectionModal: FC<ISectionModalProps> = ({ isOpen, onClose }) => {
  // form
  const [form] = Form.useForm();
  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  const sectionById = useAppSelector(selectSectionById());
  const loadingAction = useAppSelector(selectSectionLoadingAction());
  useEffect(() => {
    if (sectionById) {
      form.setFieldsValue({
        title_en: sectionById.title_en
        // title_ru: sectionById.title_ru,
        // title_hy: sectionById.title_hy
      });
    }
  }, [sectionById, form]);

  const handleCloseModal = () => {
    form.resetFields();
    onClose();
  };

  const onSave = async (values: ISectionById) => {
    const data = {
      title_en: values.title_en
      // title_ru: values.title_ru,
      // title_hy: values.title_hy
    };

    if (sectionById) {
      await dispatch(
        sectionEdit({
          ...data,
          id: sectionById.id
        })
      ).then(() => handleCloseModal());
    } else {
      await dispatch(postSection(data)).then(() => handleCloseModal());
    }
  };

  const modalTitle = () => {
    if (sectionById) {
      return 'Edit Section';
    } else {
      return 'Add Section';
    }
  };

  return (
    <Modal
      title={modalTitle()}
      open={isOpen}
      onCancel={handleCloseModal}
      footer={[
        <Button onClick={handleCloseModal} key="Cancel">
          Cancel
        </Button>,
        <Button type="primary" onClick={form.submit} loading={loadingAction} key="Save">
          Save
        </Button>
      ]}
    >
      <Form
        scrollToFirstError={{
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        }}
        form={form}
        onFinish={onSave}
        layout="vertical"
        size="large"
      >
        <Form.Item name="title_en" label="English Title" rules={[{ required: true }]}>
          <Input placeholder="Enter title in English" />
        </Form.Item>
        {/* <Form.Item
          name="title_ru"
          label="Russian Title"
          rules={[{ required: true }]}
        >
          <Input placeholder="Enter title in Russian" />
        </Form.Item>
        <Form.Item
          name="title_hy"
          label="Armenian Title"
          rules={[{ required: true }]}
        >
          <Input placeholder="Enter title in Armenian" />
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default memo(SectionModal);
