import { ArrowLeftOutlined, CopyOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const LeftPage = styled(ArrowLeftOutlined)`
  font-size: 20px;
  margin-right: 25px;
  margin-bottom: 23px;
`;
export const CopyStyled = styled(CopyOutlined)`
  font-size: 25px;
  cursor: pointer;
  transition: 0.2s;
  :hover {
    color: #1677ff;
  }
`;

export const HeaderWrraper = styled.div`
  display: flex;
  margin-bottom: 24px;
`;
