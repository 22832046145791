import { Input, Modal, Typography } from 'antd';
import React, { Dispatch } from 'react';

const CancelOrderModal: React.FC<{
  open: boolean;
  confirmLoading: boolean;
  onOk: () => void;
  onCancel: () => void;
  setMessage: Dispatch<React.SetStateAction<string>>;
}> = ({ open, confirmLoading, onOk, onCancel, setMessage }) => {
  return (
    <Modal open={open} title="Confirm" confirmLoading={confirmLoading} onOk={onOk} onCancel={onCancel}>
      <Typography.Paragraph>Are you sure?</Typography.Paragraph>
      <Input placeholder="Enter Message" onChange={e => setMessage(e.target.value)} />
    </Modal>
  );
};

export default CancelOrderModal;
