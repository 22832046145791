import { Modal, Button, Input, Form, Row, Col, Select, message } from 'antd';
import { FC, memo, useEffect, useState } from 'react';
import { PostProductDiscountValues } from 'app/features/products/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { postProductDiscountApi, getProductDiscount } from 'app/features/products/slice';
import { selectProductDiscounts, selectProductById } from 'app/features/products/selectors';
import { selectDiscountAmountByType } from 'app/features/category/selectors';
import { getDiscountAmountsByType } from 'app/features/category/slice';
import { transformArray } from 'helpers/discount';
import { PostDiscountValues } from 'app/features/discounts/types';
import { handleBackendValidationError } from 'helpers/errors';
import { sortingArrayByKey } from 'helpers/product';
import { selectSelectedCurrency } from 'app/features/currency/selectedcurrencyslice';

interface IProductDiscountModalProps {
  editValue: { isEditable: boolean; type: string; countryIndex: number | undefined };
  clearEditValue: () => void;
  isOpen: boolean;
  onClose: () => void;
  productId?: number | undefined;
  selectedCountryId?: number;
}

const DiscountModalProduct: FC<IProductDiscountModalProps> = ({ editValue, clearEditValue, isOpen, onClose, productId, selectedCountryId }) => {
  const accountTypes = [{ title: 'BASIC' }, { title: 'SILVER' }, { title: 'GOLD' }, { title: 'PREMIUM' }];

  const discountByProduct = useAppSelector(selectProductDiscounts());
  const { type, countryIndex = 0 } = editValue;
  const discountsForCountry = discountByProduct[Number(countryIndex)]?.product_discounts || [];
  const selectedDiscount = discountsForCountry.filter(item => item.type === editValue.type);
  const sortedSelectedDiscount = sortingArrayByKey(selectedDiscount, 'type_number');
  const selectedDiscountPercents = sortedSelectedDiscount.map(item => item.percent);
  const [selectedAccType, setSelectedAccType] = useState<string>('');
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    form.resetFields();
    onClose();
  };

  const product = useAppSelector(selectProductById());
  const currencyCode = useAppSelector(selectSelectedCurrency);
  const discountAmountByType = useAppSelector(selectDiscountAmountByType());
  const [amounts, setAmounts] = useState<PostDiscountValues[]>([]);

  useEffect(() => {
    if (discountAmountByType) {
      setAmounts(transformArray(discountAmountByType));
    }
  }, [discountAmountByType]);

  useEffect(() => {
    if (amounts && amounts.length > 0) {
      form.setFieldsValue({
        amount_min1: amounts[0].amount_min1,
        amount_min2: amounts[0].amount_min2,
        amount_min3: amounts[0].amount_min3,
        amount_min4: amounts[0].amount_min4,
        amount_max1: amounts[0].amount_max1,
        amount_max2: amounts[0].amount_max2,
        amount_max3: amounts[0].amount_max3,
        amount_max4: amounts[0].amount_max4
      });
    }
  }, [form, amounts]);

  useEffect(() => {
    if (selectedDiscount && type) {
      selectedDiscountPercents.forEach((percent, index) => {
        form.setFieldsValue({ [`percent${index + 1}`]: percent });
      });
    }

    if (type) {
      dispatch(getDiscountAmountsByType(type));
    }

    if (!isOpen) {
      form.resetFields();
      setSelectedAccType('');
      clearEditValue();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!type && selectedAccType.length > 0) {
      dispatch(getDiscountAmountsByType(selectedAccType));
    }
  }, [selectedAccType]);

  useEffect(() => {
    if (selectedAccType === 'BASIC') {
      form.setFieldsValue({ percent1: 0 });
    }
  }, [selectedAccType]);

  const onSave = async (values: PostProductDiscountValues) => {
    const data = {
      margin: 0,
      country_id: selectedCountryId,
      name: type || selectedAccType,
      percent1: +values.percent1,
      percent2: +values.percent2,
      percent3: +values.percent3,
      percent4: +values.percent4,
      product_id: productId || 0
    };

    const res = (await dispatch(postProductDiscountApi(data))) as { meta: { requestStatus: string }; payload: { message: string } };

    if (res.meta.requestStatus === 'rejected' && res.payload.message === 'Request failed with status code 409') {
      form.setFields([{ name: 'key', errors: ['Such a key already exists. Please enter a different key.'] }]);
    } else if (res.meta.requestStatus === 'fulfilled' && res.payload) {
      message.success('Updated successfully');
      handleCloseModal();
      if (currencyCode) {
        dispatch(getProductDiscount({ id: product?.id, currencyCode }));
      } else {
        dispatch(getProductDiscount({ id: product?.id }));
      }
    } else {
      handleBackendValidationError(res.payload.message, res);
    }
  };

  return (
    <Modal
      width={900}
      title={type ? `Edit Discount: ${type}` : 'Add Discount'}
      open={isOpen}
      onCancel={handleCloseModal}
      footer={[
        <Button onClick={handleCloseModal} key="Cancel">
          Cancel
        </Button>,
        <Button type="primary" onClick={form.submit} key="Save">
          Save
        </Button>
      ]}
    >
      <Form scrollToFirstError={{ behavior: 'smooth', block: 'center', inline: 'center' }} form={form} onFinish={onSave} layout="vertical" size="large">
        <br />
        <br />
        {!type && (
          <Form.Item label="Account Type" name="accType" rules={[{ required: true }]}>
            <Select
              value={selectedAccType || null}
              placeholder="Select Account Type"
              style={{ width: '200px' }}
              onChange={setSelectedAccType}
              options={accountTypes.map(item => ({ value: item.title, label: item.title }))}
            />
          </Form.Item>
        )}
        <Row style={{ borderBottom: '1px solid lightgray' }} gutter={[24, 24]}>
          <Col span={8}>
            <Form.Item name="amount_min1" label="Min Price 1">
              <Input type="number" disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="amount_max1" label="Max Price 1">
              <Input disabled type="number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="percent1" label="Percent 1" rules={[{ required: true }]}>
              <Input disabled={type === 'BASIC' || selectedAccType === 'BASIC'} placeholder="Enter Percent 1" type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ borderBottom: '1px solid lightgray' }} gutter={[24, 24]}>
          <Col span={8}>
            <Form.Item name="amount_min2" label="Min Price 2">
              <Input disabled type="number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="amount_max2" label="Max Price 2">
              <Input disabled type="number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="percent2" label="Percent 2" rules={[{ required: true }]}>
              <Input placeholder="Enter Percent 2" type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ borderBottom: '1px solid lightgray' }} gutter={[24, 24]}>
          <Col span={8}>
            <Form.Item name="amount_min3" label="Min Price 3">
              <Input disabled type="number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="amount_max3" label="Max Price 3">
              <Input disabled type="number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="percent3" label="Percent 3" rules={[{ required: true }]}>
              <Input placeholder="Enter Percent 3" type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={8}>
            <Form.Item name="amount_min4" label="Min Price 4">
              <Input disabled type="number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="amount_max4" label="Max Price 4">
              <Input disabled type="number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="percent4" label="Percent 4" rules={[{ required: true }]}>
              <Input placeholder="Enter Percent 4" type="number" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default memo(DiscountModalProduct);
