import { selectBlogById, selectBlogLoadingAction, selectBlogLoadingById } from 'app/features/blog/selectors';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { blogByIdApi, blogEdit, postBlog } from 'app/features/blog/slice';
import { Button, Col, Form, Input, Row, Upload, UploadFile, UploadProps } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { IBlogByIdValues } from 'app/features/blog/types';
import { BASE_URL } from 'api/apiClient';
import useCustomRequest from 'Hook/useCustomRequest';

import Loading from 'components/Loading';
import DetailsHead from 'components/DetailsHead';
import Editor from 'components/Editor';

const validateMessages = {
  required: "'${label}' is Required!"
};

const { TextArea } = Input;
const { Dragger } = Upload;

const BlogDetails = () => {
  // states
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [body, setBody] = useState('');

  const { id } = useParams();

  // navigate
  const navigate = useNavigate();

  // dispatch
  const dispatch = useAppDispatch();

  // form
  const [form] = Form.useForm();

  //selectors
  const loadingById = useAppSelector(selectBlogLoadingById());
  const blogById = useAppSelector(selectBlogById());
  const loadingAction = useAppSelector(selectBlogLoadingAction());

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  useEffect(() => {
    if (id) {
      dispatch(blogByIdApi(Number(id)));
    }
  }, [dispatch, id]);

  const onClose = () => {
    form.resetFields();
    navigate(-1);
  };

  const onSave = async (values: IBlogByIdValues) => {
    const files = fileList.map(item => item.response?.data?.data?.id || item.uid).filter(item => !!item);

    const data = {
      title_en: values.title_en,
      description_en: values.description_en,
      // title_ru: values.title_ru,
      // description_ru: values.description_ru,
      // title_hy: values.title_hy,
      // description_hy: values.description_hy,
      body_en: body,
      // body_ru: values.body_ru,
      // body_hy: values.body_hy,
      files
    };

    if (id) {
      await dispatch(
        blogEdit({
          id: Number(id),
          ...data
        })
      ).then(() => onClose());
    } else {
      await dispatch(postBlog(data)).then(() => onClose());
    }
  };

  useEffect(() => {
    if (id && blogById) {
      form.setFieldsValue({
        title_en: blogById.title_en,
        description_en: blogById.description_en
        // title_ru: blogById.title_ru,
        // description_ru: blogById.description_ru,
        // title_hy: blogById.title_hy,
        // description_hy: blogById.description_hy,
        // body_ru: blogById.body_ru,
        // body_hy: blogById.body_hy
      });
      setBody(blogById.body_en);
      setFileList(
        blogById.files?.map(item => {
          const url = `${BASE_URL}/uploads/${item.name}.${item.extension}`;

          return {
            uid: item.id,
            name: `${item.name}.${item.extension}`,
            status: 'done',
            url
          };
        }) || []
      );
    }
  }, [blogById, form, id]);
  const customRequest = useCustomRequest({ url: 'file' });

  return (
    <>
      {loadingById ? (
        <Loading size="large" />
      ) : (
        <>
          <Form
            onFinish={onSave}
            validateMessages={validateMessages}
            size="large"
            form={form}
            layout="vertical"
            scrollToFirstError={{
              behavior: 'smooth',
              block: 'center',
              inline: 'center'
            }}
          >
            <DetailsHead titleItem={blogById?.title_en} onClose={onClose} id={id} headTitle={'Blog'} form={form} loadingAction={loadingAction} />
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Form.Item rules={[{ required: true }]} label="English Title" name="title_en">
                  <Input placeholder="Enter Title in English" />
                </Form.Item>
                <Form.Item name="description_en" label="English Description" rules={[{ required: true }]}>
                  <TextArea style={{ height: '150px' }} showCount placeholder="Enter description in English" />
                </Form.Item>
                {/* <Form.Item
                  rules={[{ required: true }]}
                  label="Russian Title"
                  name="title_ru"
                >
                  <Input placeholder="Enter Title in Russian" />
                </Form.Item>
                <Form.Item
                  name="description_ru"
                  label="Russian Description"
                  rules={[{ required: true }]}
                >
                  <TextArea
                    style={{ height: '150px' }}
                    showCount
                    placeholder="Enter description in Russian"
                  />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true }]}
                  label="Armenian Title"
                  name="title_hy"
                >
                  <Input placeholder="Enter Title in Armenian" />
                </Form.Item>
                <Form.Item
                  name="description_hy"
                  label="Armenian Description"
                  rules={[{ required: true }]}
                >
                  <TextArea
                    style={{ height: '150px' }}
                    showCount
                    placeholder="Enter description in Armenian"
                  />
                </Form.Item> */}
                <Col span={24}>
                  <Form.Item name="files">
                    <Dragger onChange={onChange} fileList={fileList} accept="image/*" customRequest={customRequest} listType="picture" multiple>
                      <Button icon={<UploadOutlined />}>Upload</Button>
                    </Dragger>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="English Text">
                    <Editor setContent={setBody} content={body} />
                  </Form.Item>
                </Col>
                {/* <Col span={24}>
                  <Form.Item
                    name="body_ru"
                    label="Russian Text"
                    rules={[{ required: true }]}
                  >
                    <ReactEditor
                      modules={modules}
                      formats={formats}
                      style={{ height: 200, marginBottom: 20 }}
                      placeholder="Enter text in Russian"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="body_hy"
                    label="Armenian Text"
                    rules={[{ required: true }]}
                  >
                    <ReactEditor
                      modules={modules}
                      formats={formats}
                      style={{ height: 200, marginBottom: 20 }}
                      placeholder="Enter text in Armenian"
                    />
                  </Form.Item>
                </Col> */}
              </Col>
            </Row>
          </Form>
        </>
      )}
    </>
  );
};

export default BlogDetails;
