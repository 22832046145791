import { Modal, Button, Input, Form } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FC, memo } from 'react';
import { selectQuestionLoadingAction } from 'app/features/questions/selectors';
import { QuestionCreate } from 'app/features/questions/types';
import { createQuestion } from 'app/features/questions/slice';

import { IQuestionModalProps } from './types';
import { pageArr } from 'pages/Questions/constant';

const { TextArea } = Input;

const QuestionModal: FC<IQuestionModalProps> = ({ isOpen, onClose, tabs }) => {
  // form
  const [form] = Form.useForm();
  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  const loadingAction = useAppSelector(selectQuestionLoadingAction());

  const handleCloseModal = () => {
    form.resetFields();
    onClose();
  };

  const onSave = async (values: QuestionCreate) => {
    const data = {
      question: values.question,
      answer: values.answer,
      types: tabs
    };

    await dispatch(createQuestion(data)).then(() => handleCloseModal());
  };

  return (
    <Modal
      title={`Add ${pageArr.find(item => item.id === tabs)?.label} Question and Answer`}
      open={isOpen}
      width={800}
      onCancel={handleCloseModal}
      footer={[
        <Button onClick={handleCloseModal} key="Cancel">
          Cancel
        </Button>,
        <Button type="primary" onClick={form.submit} loading={loadingAction} key="Save">
          Save
        </Button>
      ]}
    >
      <Form form={form} onFinish={onSave} layout="vertical" size="large">
        <Form.Item name="question" label="Question" rules={[{ required: true }]}>
          <TextArea style={{ height: 100 }} placeholder="Enter question" />
        </Form.Item>
        <Form.Item name="answer" label="Answer" rules={[{ required: true }]}>
          <TextArea style={{ height: 200 }} placeholder="Enter answer" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default memo(QuestionModal);
