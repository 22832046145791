import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api/apiClient';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';

import { selectBlogLimit, selectBlogPage, selectBlogSearch } from './selectors';
import { BlogState, IBlogByIdValues } from './types';

const initialState: BlogState = {
  loading: false,
  loadingAction: false,
  loadingById: false,
  blogById: null,
  search: '',
  page: 1,
  limit: 10,
  count: 0,
  blog: []
};

export const blogApi = createAsyncThunk('blog/blogApi', async (_, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState;
    const page = selectBlogPage()(state);
    const search = selectBlogSearch()(state);
    const limit = selectBlogLimit()(state);
    const res = await client.get('/blog', {
      params: {
        search,
        page,
        limit
      }
    });

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const blogByIdApi = createAsyncThunk('blog/blogByIdApi', async (id: number, { rejectWithValue }) => {
  try {
    const res = await client.get(`/blog/blog/${id}`);

    return res.data.data;
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const deleteBlog = createAsyncThunk('blog/deleteBlog', async (id: number, { dispatch, rejectWithValue }) => {
  try {
    await client.delete(`/blog/${id}`);
    await dispatch(blogApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const postBlog = createAsyncThunk('blog/postBlog', async (data: IBlogByIdValues, { dispatch, rejectWithValue }) => {
  try {
    await client.post('/blog', {
      ...data
    });
    await dispatch(blogApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const blogEdit = createAsyncThunk('blog/blogEdit', async ({ id, ...data }: IBlogByIdValues, { dispatch, rejectWithValue }) => {
  try {
    await client.patch(`/blog/${id}`, {
      ...data
    });

    await dispatch(blogApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});
const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    clickMenuBlog: state => {
      (state.limit = 10), (state.page = 1), (state.search = '');
    }
  },
  extraReducers(builder) {
    builder
      .addCase(blogApi.pending, state => {
        state.loading = true;
      })
      .addCase(blogApi.fulfilled, (state, action) => {
        state.blog = action.payload.rows;
        state.count = action.payload.count;
        state.loading = false;
      })
      .addCase(blogApi.rejected, state => {
        state.loading = false;
      })
      .addCase(blogByIdApi.pending, state => {
        state.loadingById = true;
      })
      .addCase(blogByIdApi.fulfilled, (state, action) => {
        state.blogById = action.payload;
        state.loadingById = false;
      })
      .addCase(blogByIdApi.rejected, state => {
        state.loadingById = false;
      })
      .addCase(deleteBlog.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteBlog.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteBlog.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(postBlog.pending, state => {
        state.loadingAction = true;
      })
      .addCase(postBlog.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(postBlog.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(blogEdit.pending, state => {
        state.loadingAction = true;
      })
      .addCase(blogEdit.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(blogEdit.rejected, state => {
        state.loadingAction = false;
      });
  }
});

export const { setPage, setSearch, setLimit, clickMenuBlog } = blogSlice.actions;
export default blogSlice.reducer;
