import React, { FC, useState } from 'react';
import { Button, Drawer, Typography, Upload, UploadFile, UploadProps, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import useCustomRequest from 'Hook/useCustomRequest';
import { useAppDispatch } from 'app/hooks';
import { sliderApi } from 'app/features/slider/slice';

import { ISliderPageProps } from './types';
import { ContentTopTitle, ContentTopWrapper, DrawerStyled, UploadWrraper } from './styled';
import Slider from './components/Slider';

const { Dragger } = Upload;
const { Title } = Typography;

const SliderPage: FC<ISliderPageProps> = ({ openSlider, setOpenSlider }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [openAddImage, setOpenAddImage] = useState(false);

  //dispatch
  const dispatch = useAppDispatch();

  const onChange: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
    setFileList(newFileList);

    const isAllUploaded = newFileList.every(file => file.status === 'done');

    if (isAllUploaded) {
      setFileList([]);
      await message.success('Pictures uploaded successfully!', 2);
      setOpenAddImage(false);
      await dispatch(sliderApi());
    }
  };

  const customRequest = useCustomRequest({
    url: 'slider_upload/storeSlider',
    name: true
  });

  return (
    <>
      <Drawer title="Add Slider Image" placement="right" onClose={() => setOpenAddImage(false)} open={openAddImage} width={700}>
        <UploadWrraper>
          <Dragger onChange={onChange} accept="image/*" listType="picture" multiple fileList={fileList} customRequest={customRequest}>
            <Button icon={<UploadOutlined />}>Upload Slider Image</Button>
          </Dragger>
        </UploadWrraper>
      </Drawer>
      <DrawerStyled
        title={
          <ContentTopWrapper>
            <ContentTopTitle>
              <Title level={3}>Slider</Title>
            </ContentTopTitle>
            <Button style={{ marginTop: '6px' }} type="primary" onClick={() => setOpenAddImage(true)}>
              Add Slider Image
            </Button>
          </ContentTopWrapper>
        }
        placement="right"
        onClose={() => setOpenSlider(false)}
        open={openSlider}
        width={1200}
      >
        <Slider />
      </DrawerStyled>
    </>
  );
};

export default SliderPage;
