import { DeleteOutlined, EditTwoTone } from '@ant-design/icons';
import { Button } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import {
  selectSpecialistCount,
  selectSpecialistData,
  selectSpecialistLimit,
  selectSpecialistLoading,
  selectSpecialistLoadingAction,
  selectSpecialistPage,
  selectSpecialistSearch
} from 'app/features/specialist/selectors';
import { deleteSpecialist, setLimit, setPage, setSearch, specialistApi } from 'app/features/specialist/slice';
import { ISpecialist } from 'app/features/specialist/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { _debounce } from 'helpers/debounce';

import DeleteModal from 'components/DeleteModal';
import { ButtonsWrapper } from './styled';
import ContentTop from 'components/ContentTop';

const debounce = _debounce();

const Specialist = () => {
  //states
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [specialistId, setSpecialistId] = useState<number>(0);

  //dispatch
  const dispatch = useAppDispatch();

  //selectors
  const loadingAction = useAppSelector(selectSpecialistLoadingAction());
  const loading = useAppSelector(selectSpecialistLoading());
  const specialistData = useAppSelector(selectSpecialistData());
  const page = useAppSelector(selectSpecialistPage());
  const limit = useAppSelector(selectSpecialistLimit());
  const count = useAppSelector(selectSpecialistCount());
  const search = useAppSelector(selectSpecialistSearch());
  //navigate
  const navigate = useNavigate();

  useEffect(() => {
    debounce(() => dispatch(specialistApi()));
  }, [dispatch, search, page, limit]);

  const columns: ColumnsType<ISpecialist> = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text: string) => {
        return (
          <>
            <a href={`mailto: ${text}`}>{text}</a>
          </>
        );
      }
    },
    {
      title: 'Profession',
      dataIndex: 'profession',
      key: 'profession'
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone'
    },

    {
      title: 'Action',
      dataIndex: 'action',
      width: 220,
      key: 'Action',
      render: (_, record) => {
        return (
          <ButtonsWrapper>
            <Link to={`details/${record.id}`}>
              <Button icon={<EditTwoTone />}>Edit</Button>
            </Link>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                setSpecialistId(record.id);
                setOpenDeleteModal(true);
              }}
            >
              Delete
            </Button>
          </ButtonsWrapper>
        );
      }
    }
  ];

  return (
    <>
      <ContentTop
        title="Specialist"
        placeholder="Search Specialist"
        buttonText="Add Specialist"
        onClickAdd={() => navigate('details')}
        onChange={e => {
          dispatch(setSearch(e.target.value));
        }}
        searchComponent
        search={search}
        add
      />
      <Table
        bordered
        columns={columns}
        dataSource={specialistData}
        loading={loading}
        rowKey="id"
        pagination={{
          current: page,
          onChange: (page, pageSize) => {
            dispatch(setPage(page));
            dispatch(setLimit(pageSize));
          },
          pageSize: limit,
          total: count
        }}
      />
      <DeleteModal
        open={openDeleteModal}
        confirmLoading={loadingAction}
        onOk={() => {
          dispatch(deleteSpecialist(specialistId)).then(() => {
            setOpenDeleteModal(false);
            setSpecialistId(0);
          });
        }}
        onCancel={() => {
          setOpenDeleteModal(false);
          setSpecialistId(0);
        }}
      />
    </>
  );
};

export default Specialist;
