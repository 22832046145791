import { Button, Col, Image, Row, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IProduct } from 'app/features/products/types';
import { getFileUrlBIG } from 'helpers/file';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectBasketQtyObj, selectUserBasket, selectUserBasketLoading, selectUserOrderLoading, selectUserOrderSuccess } from 'app/features/users/selectors';
import { addBasketUserApi, deleteBasketUserApi, orderSuccess } from 'app/features/users/slice';
import { useParams } from 'react-router-dom';
import { FC, Dispatch, useEffect, useState } from 'react';

import noImg from '../../../../assets/img/noimage.png';
import successImg from '../../../../assets/img/success.png';
import { ImgTableWrapper } from 'pages/Products/styled';
import { NumberInput, StyledActionColumn, StyledArithmetic, StyledTotalPrice, SuccessWrapper } from '../styled';
import Loading from 'components/Loading';
import MakeOrderModal from './makeOrderModal';

interface IUserPage2 {
  showAddressesModal: boolean;
  setShowAddressesModal: Dispatch<React.SetStateAction<boolean>>;
}

const UserPage2: FC<IUserPage2> = ({ showAddressesModal, setShowAddressesModal }) => {
  const loading = useAppSelector(selectUserBasketLoading());
  const basketState = useAppSelector(selectUserBasket());
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const success = useAppSelector(selectUserOrderSuccess());
  const loadingOrder = useAppSelector(selectUserOrderLoading());
  const qtyObj = useAppSelector(selectBasketQtyObj());
  const [totalPrice, setTotalPrice] = useState(0);
  const [primaryPrice, setPrimaryPrice] = useState(0);
  const [showMakeOrderModal, setShowMakeOrderModal] = useState<boolean>(false);
  const [showBlackBack, setShowBlackBack] = useState<boolean>(false);

  const columns: ColumnsType<IProduct> = [
    {
      title: 'Title',
      dataIndex: 'title_en',
      key: 'title_en'
    },
    {
      title: 'Articul number',
      dataIndex: 'articul_number',
      key: 'articul_number'
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (_, record) => {
        const prodDisc = record.product_discounts[0];

        return (
          <div>
            {prodDisc && prodDisc?.price && prodDisc?.percent && (
              <>
                <p
                  style={{
                    fontSize: '18px',
                    fontWeight: 500,
                    color: '#d1d3d4',
                    textDecoration: 'line-through'
                  }}
                >
                  {prodDisc.price} AMD
                </p>
                <p
                  style={{
                    color: '#00c11f',
                    fontSize: '20px',
                    fontWeight: 400
                  }}
                >
                  {prodDisc.price - (prodDisc?.price * prodDisc?.percent) / 100} AMD
                </p>
              </>
            )}
          </div>
        );
      }
    },
    {
      title: 'Image',
      dataIndex: 'Files',
      key: 'image',
      width: 200,
      render: (_, item) => {
        return (
          <ImgTableWrapper style={{ height: '100px', width: '100px', margin: 'auto' }}>
            {item.files?.length ? (
              <Image height={100} width={100} alt={item.files[0].name} src={getFileUrlBIG(item.files[0]?.path)} />
            ) : (
              <Image preview={false} width={70} src={noImg} alt="No Image" />
            )}
          </ImgTableWrapper>
        );
      }
    },
    {
      title: 'Count',
      dataIndex: 'count',
      key: 'count',
      render: (_, record) => {
        const prod = qtyObj?.find(item => item.id === record.id);
        const count = prod?.count;

        return (
          <StyledActionColumn>
            <StyledArithmetic
              onClick={e => {
                e.preventDefault();
                dispatch(
                  addBasketUserApi({
                    id: record.id,
                    count: count ? count - 1 : 1,
                    user_id: id ? id : ''
                  })
                );
              }}
            >
              -
            </StyledArithmetic>

            <NumberInput
              length={String(count).length}
              value={count}
              onChange={e => {
                e.preventDefault();
                dispatch(
                  addBasketUserApi({
                    id: record.id,
                    count: +e.target.value,
                    user_id: id ? id : ''
                  })
                );
              }}
            />

            <StyledArithmetic
              onClick={e => {
                e.preventDefault();
                dispatch(
                  addBasketUserApi({
                    id: record.id,
                    count: count ? count + 1 : 1,
                    user_id: id ? id : ''
                  })
                );
              }}
            >
              +
            </StyledArithmetic>
          </StyledActionColumn>
        );
      }
    },
    {
      title: 'Remove',
      dataIndex: 'remove',
      key: 'remove',
      render: (_, record) => {
        return (
          <Button
            onClick={() => {
              if (id) {
                dispatch(deleteBasketUserApi({ id: record.id, user_id: id }));
              }
            }}
          >
            Remove
          </Button>
        );
      }
    }
  ];

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        dispatch(orderSuccess(false));
      }, 1000);
    }
  }, [dispatch, success]);

  useEffect(() => {
    const totalPriceMap = basketState.reduce((num, sum) => {
      const price = sum.product_discounts[0]?.price;
      const percent = sum.product_discounts[0]?.percent;
      const count = qtyObj?.find(item => item.id === sum.id)?.count;

      if (price && percent && count) {
        const discPrice = price - (percent * price) / 100;

        return num + Number(discPrice * count);
      } else {
        return 0;
      }
    }, 0);

    const primaryPriceMap = basketState.reduce((num, sum) => {
      const price = sum.product_discounts[0]?.price;
      const count = qtyObj?.find(item => item.id === sum.id)?.count;

      if (price && count) {
        return num + Number(price * count);
      } else {
        return 0;
      }
    }, 0);

    setPrimaryPrice(primaryPriceMap);
    setTotalPrice(totalPriceMap);
  }, [qtyObj, basketState]);

  useEffect(() => {
    if (showAddressesModal || showMakeOrderModal) {
      setShowBlackBack(true);
    } else {
      setShowBlackBack(false);
    }
  }, [showAddressesModal, showMakeOrderModal]);

  return (
    <Row gutter={[24, 24]}>
      {success ? (
        <SuccessWrapper>
          <div>
            <img src={successImg} alt="success" />
          </div>
          <p>Order Success</p>
        </SuccessWrapper>
      ) : loadingOrder ? (
        <Loading size="large" />
      ) : (
        <>
          {showBlackBack && (
            <p
              style={{
                position: 'fixed',
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                background: 'rgba(25, 41, 59, 0.20)',
                zIndex: 2
              }}
            ></p>
          )}
          {basketState && basketState.length > 0 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '98%'
              }}
            >
              <Button onClick={() => setShowMakeOrderModal(true)} type="primary">
                Order
              </Button>
            </div>
          )}
          <Col span={24}>
            {basketState && basketState.length > 0 && (
              <StyledTotalPrice>
                <div>
                  <p>Primary Price:</p>
                  <p>{primaryPrice} AMD</p>
                </div>
                <div>
                  <p>Total Price:</p>
                  <p>{totalPrice} AMD</p>
                </div>
              </StyledTotalPrice>
            )}
            <Table style={{ height: '700px' }} loading={loading} pagination={false} bordered columns={columns} dataSource={basketState} rowKey="id" />
          </Col>
        </>
      )}
      <MakeOrderModal open={showMakeOrderModal} handleModal={setShowMakeOrderModal} setShowAddressesModal={setShowAddressesModal} />
    </Row>
  );
};

export default UserPage2;
