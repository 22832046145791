import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api/apiClient';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';

import { selectPartnersLimit, selectPartnersPage, selectPartnersSearch } from './selectors';
import { IPartner, IPartnerPost, PartnerState } from './types';

const initialState: PartnerState = {
  loading: false,
  loadingAction: false,
  loadingByID: false,
  search: '',
  page: 1,
  limit: 10,
  count: 0,
  partner: [],
  partnerById: null
};

export const partnerApi = createAsyncThunk('partner/partnerApi', async (_, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState;
    const search = selectPartnersSearch()(state);
    const page = selectPartnersPage()(state);
    const limit = selectPartnersLimit()(state);
    const res = await client.get('/partner', {
      params: {
        search,
        page,
        limit
      }
    });

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});
export const partnerProductApi = createAsyncThunk('partner/partnerProductApi', async (_, { rejectWithValue }) => {
  try {
    const res = await client.get('/partner');

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const getPartnerById = createAsyncThunk('partner/getPartnerById', async (id: number, { rejectWithValue }) => {
  try {
    const response = await client.get(`/partner/${id}`);

    return response.data.data;
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const deletePartner = createAsyncThunk('partner/deletePartner', async (id: number, { dispatch, rejectWithValue }) => {
  try {
    await client.delete(`/partner/${id}`);
    await dispatch(partnerApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const postPartner = createAsyncThunk('partner/postPartner', async ({ ...data }: IPartnerPost, { dispatch, rejectWithValue }) => {
  try {
    await client.post('/partner', {
      ...data
    });
    await dispatch(partnerApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const partnerEdit = createAsyncThunk('partner/partnerEdit', async ({ id, ...data }: IPartner, { dispatch, rejectWithValue }) => {
  try {
    await client.patch(`/partner/${id}`, {
      ...data
    });

    await dispatch(partnerApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});
const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    clickMenuPartner: state => {
      (state.limit = 10), (state.page = 1), (state.search = '');
    }
  },
  extraReducers(builder) {
    builder

      .addCase(partnerProductApi.pending, state => {
        state.loading = true;
      })
      .addCase(partnerProductApi.fulfilled, (state, action) => {
        state.partner = action.payload.rows;
        state.loading = false;
      })
      .addCase(partnerProductApi.rejected, state => {
        state.loading = false;
      })
      .addCase(partnerApi.pending, state => {
        state.loading = true;
      })
      .addCase(partnerApi.fulfilled, (state, action) => {
        state.partner = action.payload.rows;
        state.count = action.payload.count;
        state.loading = false;
      })
      .addCase(partnerApi.rejected, state => {
        state.loading = false;
      })
      .addCase(getPartnerById.pending, state => {
        state.loadingByID = true;
      })
      .addCase(getPartnerById.fulfilled, (state, action) => {
        state.partnerById = action.payload;
        state.loadingByID = false;
      })
      .addCase(getPartnerById.rejected, state => {
        state.loadingByID = false;
      })
      .addCase(postPartner.pending, state => {
        state.loadingAction = true;
      })
      .addCase(postPartner.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(postPartner.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(partnerEdit.pending, state => {
        state.loadingAction = true;
      })
      .addCase(partnerEdit.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(partnerEdit.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(deletePartner.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deletePartner.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deletePartner.rejected, state => {
        state.loadingAction = false;
      });
  }
});

export const { setPage, setSearch, setLimit, clickMenuPartner } = partnerSlice.actions;
export default partnerSlice.reducer;
