import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api/apiClient';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { AxiosError } from 'axios';

import { DiscountState, PostDiscountValues } from './types';

const initialState: DiscountState = {
  discounts: [],
  loading: false
};

export const getDiscountsApi = createAsyncThunk('discounts/getDiscountsApi', async (_, { rejectWithValue }) => {
  try {
    const res = await client.get('/discount_panel/amounts/ALL');

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});
export const postDiscountsApi = createAsyncThunk('discounts/postDiscountsApi', async (data: PostDiscountValues, { dispatch, rejectWithValue }) => {
  try {
    const res = await client.post('/discount_panel/amounts', { ...data });
    dispatch(getDiscountsApi());

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const deleteDiscount = createAsyncThunk('discounts/deleteDiscount', async (name: string, { dispatch, rejectWithValue }) => {
  try {
    client.delete(`/discount_panel/amounts/${name}`);

    dispatch(getDiscountsApi());
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

const discountSlice = createSlice({
  name: 'discounts',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getDiscountsApi.pending, state => {
        state.loading = true;
      })
      .addCase(getDiscountsApi.fulfilled, (state, action) => {
        state.discounts = action.payload;
        state.loading = false;
      });
  }
});

// export const { clickMenuDiscounts } = discountSlice.actions;
export default discountSlice.reducer;
