import styled, { css } from 'styled-components';

import { IInputProps } from './types';

export const WrapperInput = styled.div<{ filled: boolean }>`
  max-width: inherit;
  height: 45px;
  display: flex;
  align-items: center;
  position: relative;
  font-size: var(--text-8);
  font-family: 'Roboto';
  label {
    position: absolute;
    transition: transform 0.2s cubic-bezier(0.59, 0.63, 1, 0.96);
    cursor: text;
    padding-inline: 3px;
    color: black;
  }

  input:focus-visible + label {
    transform: translateY(-120%);
    color: #00909e;
    opacity: 1;
    @media only screen and (max-width: 1450px) {
      transform: translateY(-120%);
    }
  }
  ${({ filled }) =>
    filled
      ? css`
          label {
            transform: translateY(-120%);
            color: #00909e;
            opacity: 1;
            @media only screen and (max-width: 1450px) {
              transform: translateY(-120%);
            }
          }
        `
      : null}
`;
export const StyledInput = styled.input<IInputProps>`
  border: 1px solid gray;
  padding: 12px 15px;
  border-radius: 5px;
  width: 100%;
  padding-inline: 15px;
  font-size: var(--text-7);
  height: 100%;
  :focus {
    outline: none;
    border: 1px solid #00909e;
  }

  &[type='email'] {
    :focus {
      outline: none;
      border: 1px solid #00909e;
    }
    ${({ value }) => css`
      + label {
        transform: ${value ? 'translateY(-120%)' : ''};
        color: ${value ? '#00909E' : ''};
      }
    `}
  }
`;
export const StyledLabel = styled.label<IInputProps>`
  left: 11px;
  width: fit-content;
  background-color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #19293b;
  opacity: 0.98;
`;
export const Icon = styled.img`
  position: absolute;
  right: 15px;
`;

export const Error = styled.p`
  color: #ff0000;
`;
