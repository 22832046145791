import { Collapse, Input } from 'antd';
import styled from 'styled-components';

export const TextArea = styled(Input.TextArea)`
  height: 120px;
`;
export const CollapseStyled = styled(Collapse)`
  background-color: white;
`;

export const VideosContainer = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, minmax(200px, 350px));
  .item {
    position: relative;
    padding: 10px;
    .delete {
      position: absolute;
      right: -5px;
      top: -5px;
      cursor: pointer;
      background-color: #00000070;
      color: white;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      border-radius: 50%;
      &:hover {
        background-color: red;
      }
    }
  }
`;

export const StyledConnSolution = styled.div`
  position: relative;
  width: 100%;
  .circle {
    position: absolute;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.314);
    width: 32px;
    height: 32px;
    display: block;
    border-radius: 50%;
    .iconsCont {
      position: absolute;
      left: 50%;
      top: 50%;
      .icon {
        width: 20px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .modal {
    cursor: default;
    position: absolute;
    position: absolute;
    background-color: white;
    z-index: 2;
    padding: 30px 20px;
    transform: translate(-50%, 20%);
    border-radius: 5px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    .close {
      position: absolute;
      top: 16px;
      right: 16px;
      line-height: 12px;
      font-size: 22px;
      cursor: pointer;
    }
    .modal-content {
      padding: 5px;
      display: flex;
      align-items: center;
      gap: 10px;
      img {
        width: 100px;
        height: 100px;
      }
      .title {
        width: max-content;
        margin-bottom: 0;
        a {
          font-size: 14px;
          cursor: pointer;
          line-height: 22px;
          font-weight: 500;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          scroll-margin-block-end: 40px;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
`;
