import { RootState } from 'app/store';

export const selectUsersLoading = () => (state: RootState) => state.users.loading;

export const selectUsersPage = () => (state: RootState) => state.users.page;

export const selectUsersCount = () => (state: RootState) => state.users.count;

export const selectUsersData = () => (state: RootState) => state.users.users;

export const selectUsersSearch = () => (state: RootState) => state.users.search;

export const selectUserLimit = () => (state: RootState) => state.users.limit;

export const selectSubscribePage = () => (state: RootState) => state.users.subscribePage;

export const selectSubscribeCount = () => (state: RootState) => state.users.subscribeCount;

export const selectSubscribeData = () => (state: RootState) => state.users.subscribeUsers;

export const selectSubscribeLimit = () => (state: RootState) => state.users.subscribeLimit;

export const selectUserBasket = () => (state: RootState) => state.users.userBasket.basketState;
export const selectUserBasketTotalMarginPrice = () => (state: RootState) => state.users.userBasket.total_margin_price;
export const selectUserBasketTotalPrice = () => (state: RootState) => state.users.userBasket.total_price;
export const selectUserBasketLoading = () => (state: RootState) => state.users.userBasket.loading;
export const selectBasketQtyObj = () => (state: RootState) => state.users.userBasketQtyObj;

export const selectUserFavoriteProducts = () => (state: RootState) => state.users.userFavoriteProducts;

export const selectUserOrders = () => (state: RootState) => state.users.userOrders.orders;

export const selectUserOrdersCount = () => (state: RootState) => state.users.userOrders.count;

export const selectUserOrdersPage = () => (state: RootState) => state.users.userOrders.page;

export const selectUserOrdersLimit = () => (state: RootState) => state.users.userOrders.limit;

export const selectUserProducts = () => (state: RootState) => state.users.userProducts.products;

export const selectUserProductsSearch = () => (state: RootState) => state.users.userProducts.search;

export const selectUserProductsLoading = () => (state: RootState) => state.users.userProducts.loading;

export const selectUserProductsPage = () => (state: RootState) => state.users.userProducts.page;

export const selectUserProductsLimit = () => (state: RootState) => state.users.userProducts.limit;

export const selectUserProductsCount = () => (state: RootState) => state.users.userProducts.count;

export const selectUserById = () => (state: RootState) => state.users.userById;

export const selectUserOrderAddresses = () => (state: RootState) => state.users.userOrderAddresses;
export const selectUserOrderSuccess = () => (state: RootState) => state.users.orderSuccessState;
export const selectUserOrderLoading = () => (state: RootState) => state.users.orderLoadingState;
