import React, { useEffect, useReducer, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectProductById, selectProductLoadingByID } from 'app/features/products/selectors';
import { Button, message, Switch, Table, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { selectUserInfo } from 'app/features/auth/selectors';
import { selectSelectedCurrency } from 'app/features/currency/selectedcurrencyslice';

import { ButtonsWrapper } from 'pages/Products/styled';
import { AvailabilityWrapper, StyledContainer, StyledCountryPriceDrop, StyledMarginInputs } from './styled';
import Loading from 'components/Loading';
import { ProductDataUpdate, ProductJournal, ProductPricingCountry } from './types';
import { pricingReducer, ProductPriceInitialData } from './reducer';
import { deepEqual, getUpdatedFields } from '../../../../helpers/object';
import { calculateFinalPrice, getProductPriceJournals, getProductPrices, updateProductPrices } from '../../../../api/services/productServices';
import { filterOnlyNumber, formatDate } from '../../../../helpers/string';
import { _debounce } from '../../../../helpers/debounce';

type currencyListType = {
  [key: string]: string;
};

const currencyList: currencyListType = {
  RUB: 'RUB',
  AMD: 'AMD',
  GEL: 'GEL',
  USD: 'USD',
  ALL: 'ALL'
};

const debounce = _debounce(500);

const LoadingText = 'Calculating...';

interface ProductPricesProps {
  onSuccess?: () => void;
}

const ProductJournalColumns: ColumnsType<ProductJournal> = [
  {
    title: 'Product count',
    dataIndex: 'count',
    key: 'count',
    align: 'right'
  },
  {
    title: 'Arriving date',
    dataIndex: 'date',
    key: 'date',
    align: 'left',
    render: (_, field) => {
      if (!field?.date) {
        return 'N/A';
      }

      return <span>{formatDate(field?.date)}</span>;
    }
  },
  {
    title: 'Document ID',
    dataIndex: 'document_i_s_n',
    key: 'document_i_s_n',
    align: 'left'
  }
];

const ProductPrices = ({ onSuccess }: ProductPricesProps) => {
  const [opened, setOpened] = useState<string[]>([]);

  const productData = useAppSelector(selectProductById());
  const productDataLoading = useAppSelector(selectProductLoadingByID());

  const selectedCurrency = useAppSelector(selectSelectedCurrency);

  const selectedCurrencyName = currencyList[selectedCurrency];

  const userInfo = useAppSelector(selectUserInfo());

  const [{ changeableProductPrice, productPrice, isLoading: isProductPricesLoading, productJournals }, pricingDispatch] = useReducer(pricingReducer, ProductPriceInitialData);

  const dispatch = useAppDispatch();
  const isSuperAdmin = userInfo?.role === 'SUPER_ADMIN';

  const columns: ColumnsType<ProductPricingCountry> = [
    {
      title: 'Original Price',
      dataIndex: 'basePrice',
      width: 100,
      key: 'basePrice',
      align: 'left',
      render: (_, record) => {
        const navyPricing = record?.pricing?.navy;
        const planePricing = record?.pricing?.plane;

        return (
          <StyledMarginInputs>
            {navyPricing && (
              <div className="navyPriceWrapper">
                <b className="b">St.</b> : {navyPricing?.basePrice} {navyPricing?.currency}
              </div>
            )}

            {planePricing && (
              <div className="planePriceWrapper">
                <b className="b">Ex.</b> : {planePricing?.basePrice} {planePricing?.currency}
              </div>
            )}
          </StyledMarginInputs>
        );
      }
    },
    {
      title: 'Margin (%)',
      dataIndex: 'margin',
      key: 'margin',
      width: 100,
      align: 'left',
      render: (_, record) => {
        const navyPricing = record?.pricing?.navy;
        const planePricing = record?.pricing?.plane;

        const onChangeNavyPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
          if (!navyPricing?.fixed) {
            const filteredMargin = filterOnlyNumber(e.target.value);

            pricingDispatch({ countryId: record?.countryId, type: 'updateNavyData', data: { fullPrice: LoadingText, fullPriceNoVat: LoadingText, margin: filteredMargin } });

            debounce(async () => {
              const response = await calculateFinalPrice({ countryId: record.countryId, basePrice: navyPricing?.basePrice, margin: filteredMargin });
              const { result, resultNoVat } = response ?? {};

              pricingDispatch({ countryId: record?.countryId, type: 'updateNavyData', data: { fullPrice: result, fullPriceNoVat: resultNoVat, currency: selectedCurrencyName } });
            });
          }
        };

        const onChangePlanePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
          if (!planePricing?.fixed) {
            const filteredMargin = filterOnlyNumber(e.target.value);

            pricingDispatch({ countryId: record?.countryId, type: 'updatePLaneData', data: { fullPrice: LoadingText, fullPriceNoVat: LoadingText, margin: filteredMargin } });

            debounce(async () => {
              const response = await calculateFinalPrice({ countryId: record?.countryId, basePrice: planePricing?.basePrice, margin: filteredMargin });
              const { result, resultNoVat } = response ?? {};

              pricingDispatch({ countryId: record?.countryId, type: 'updatePLaneData', data: { fullPrice: result, fullPriceNoVat: resultNoVat, currency: selectedCurrencyName } });
            });
          }
        };

        return (
          <StyledMarginInputs>
            <div>
              <Tooltip placement="bottomLeft" title={navyPricing?.margin}>
                <input type="text" disabled={navyPricing?.fixed} value={navyPricing?.margin ?? 0} onChange={onChangeNavyPrice} />
              </Tooltip>
            </div>

            <div>
              <Tooltip placement="bottomLeft" title={planePricing?.margin}>
                <input type="text" disabled={planePricing?.fixed} value={planePricing?.margin ?? 0} onChange={onChangePlanePrice} />
              </Tooltip>
            </div>
          </StyledMarginInputs>
        );
      }
    },
    {
      title: 'Price Type',
      dataIndex: 'fixed',
      align: 'center',
      width: 100,
      key: 'fixed',
      render: (_, record) => {
        const navyPriceData = record?.pricing?.navy;
        const planePricing = record?.pricing?.plane;

        const changePriceType = ({ actionType, checked, record }: { actionType: 'updateNavyData' | 'updatePLaneData'; checked: boolean; record: ProductPricingCountry }) => {
          const priceData = actionType === 'updateNavyData' ? record.pricing.navy : record.pricing.plane;

          const optionalData = checked ? { fullPrice: priceData.fullPrice } : {};

          pricingDispatch({ type: actionType, countryId: record?.countryId, data: { fixed: checked, ...optionalData } });

          if (!checked) {
            pricingDispatch({
              countryId: record?.countryId,
              type: actionType,
              data: { fullPrice: LoadingText, fullPriceNoVat: LoadingText }
            });

            debounce(async () => {
              const response = await calculateFinalPrice({
                countryId: record.countryId,
                basePrice: priceData?.basePrice,
                margin: priceData?.margin
              });

              const { result, resultNoVat } = response ?? {};

              pricingDispatch({
                countryId: record?.countryId,
                type: actionType,
                data: { fullPrice: result, fullPriceNoVat: resultNoVat, currency: selectedCurrencyName }
              });
            });
          }
        };

        return (
          <ButtonsWrapper>
            {navyPriceData && (
              <div className="navyPriceWrapper">
                <Switch onChange={checked => changePriceType({ record, checked, actionType: 'updateNavyData' })} checked={navyPriceData.fixed} checkedChildren="Fixed" unCheckedChildren="Margin" />
              </div>
            )}

            {planePricing && (
              <div className="planePriceWrapper">
                <Switch onChange={checked => changePriceType({ record, checked, actionType: 'updatePLaneData' })} checked={planePricing.fixed} checkedChildren="Fixed" unCheckedChildren="Margin" />
              </div>
            )}
          </ButtonsWrapper>
        );
      }
    },
    {
      title: 'Final Price (with VAT)',
      dataIndex: 'fullPrice',
      key: 'fullPrice',
      width: 100,
      align: 'left',
      render: (_, record) => {
        const navyPricing = record?.pricing?.navy;
        const planePricing = record?.pricing?.plane;

        const onChangeNavyPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
          if (navyPricing?.fixed) pricingDispatch({ countryId: record?.countryId, type: 'updateNavyData', data: { fullPrice: filterOnlyNumber(e.target.value), currency: selectedCurrencyName } });
        };

        const onChangePlanePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
          if (planePricing?.fixed) pricingDispatch({ countryId: record?.countryId, type: 'updatePLaneData', data: { fullPrice: filterOnlyNumber(e.target.value), currency: selectedCurrencyName } });
        };

        return (
          <StyledMarginInputs>
            {navyPricing && (
              <div>
                <Tooltip placement="bottomLeft" title={navyPricing?.fullPrice}>
                  <input onChange={onChangeNavyPrice} type="text" disabled={!navyPricing?.fixed} value={navyPricing?.fullPrice ?? 0} />
                </Tooltip>
                <span>{navyPricing.currency}</span>
              </div>
            )}

            {planePricing && (
              <div>
                <Tooltip placement="bottomLeft" title={planePricing?.fullPrice}>
                  <input onChange={onChangePlanePrice} type="text" disabled={!planePricing?.fixed} value={planePricing?.fullPrice ?? 0} />
                </Tooltip>
                <span>{planePricing.currency}</span>
              </div>
            )}
          </StyledMarginInputs>
        );
      }
    },
    {
      title: 'Final Price (no VAT)',
      dataIndex: 'fullPriceNoVat',
      key: 'fullPriceNoVat',
      width: 100,
      align: 'left',
      render: (_, record) => {
        const navyPricing = record?.pricing?.navy;
        const planePricing = record?.pricing?.plane;

        return (
          <StyledMarginInputs>
            {navyPricing && (
              <div className="priceInfoCombine">
                <div className="navyPriceWrapper">
                  <Tooltip placement="bottomLeft" title={navyPricing?.fullPriceNoVat}>
                    <span>{navyPricing?.fullPriceNoVat ?? 0}</span>
                  </Tooltip>
                </div>
                <span>{navyPricing.currency}</span>
              </div>
            )}

            {planePricing && (
              <div className="priceInfoCombine">
                <div className="planePriceWrapper">
                  <Tooltip placement="bottomLeft" title={planePricing?.fullPriceNoVat}>
                    <span>{planePricing?.fullPriceNoVat ?? 0}</span>
                  </Tooltip>
                </div>

                <span>{planePricing.currency}</span>
              </div>
            )}
          </StyledMarginInputs>
        );
      }
    },
    {
      title: `Stocks (${productData?.unit})`,
      dataIndex: 'stock',
      key: 'fullPrice',
      width: 100,
      align: 'left',
      render: (_, record) => {
        const mainStock = record?.stock?.main ?? 0;
        const showRoomStock = record?.stock?.showroom ?? 0;

        return (
          <StyledMarginInputs>
            <div className="flexRow">
              <div className="priceInfoCombineVertical">
                <p>Main:</p>
                <p>Showroom:</p>
              </div>

              <div className="priceInfoCombineVertical">
                <Tag color="default">{mainStock}</Tag>
                <Tag color="default">{showRoomStock}</Tag>
              </div>
            </div>
          </StyledMarginInputs>
        );
      }
    },
    {
      title: 'Available',
      dataIndex: 'availability',
      align: 'center',
      width: 60,
      key: 'availability',
      render: (_, record) => {
        return (
          <AvailabilityWrapper>
            <div>
              <Switch
                onChange={checked => {
                  pricingDispatch({ type: 'updateData', countryId: record.countryId, data: { availability: checked } });
                }}
                checked={record.availability}
              />
            </div>
          </AvailabilityWrapper>
        );
      }
    }
  ];

  useEffect(() => {
    if (productData) {
      pricingDispatch({ data: { isLoading: true }, type: 'setLoading' });

      getProductPrices(productData.id, selectedCurrencyName).then(async productPriceResponse => {
        const countryIds = productPriceResponse?.countries?.map(countryData => countryData.countryId) ?? [];

        const productJournals = await getProductPriceJournals({ productCode: productData.code, countryIds });

        pricingDispatch({ data: { productJournals, productPrice: productPriceResponse }, type: 'setData' });
      });
    }
  }, [dispatch, isSuperAdmin, productData, selectedCurrencyName]);

  const handleOpen = (id: string) => {
    if (opened.includes(id)) {
      const filtered = opened.filter(i => i !== id);
      setOpened(filtered);
    } else {
      setOpened(prev => [...prev, id]);
    }
  };

  const handleSave = async (originalPricing: ProductPricingCountry, changedPricing: ProductPricingCountry) => {
    const changedFields = getUpdatedFields(originalPricing, changedPricing, ['currency', 'fixed', 'fullPrice']);

    const updateData: ProductDataUpdate = {
      productId: productData?.id as number,
      countries: [{ ...changedFields, countryId: changedPricing?.countryId }]
    };

    const response = await updateProductPrices(updateData);

    if (response) {
      message.success('Updated successfully');
      onSuccess?.();

      return;
    }

    message.error('Something went wrong');
  };

  if (isProductPricesLoading || !productData || productDataLoading) return <Loading size="large" />;

  return (
    <div>
      {changeableProductPrice?.countries?.map((changeableCountryPriceData, index) => {
        const countryPriceData = productPrice?.countries?.[index] as ProductPricingCountry;

        const productJournalData: ProductJournal[] = productJournals?.[index] ?? [];

        const isSaveButtonEnabled = deepEqual(changeableCountryPriceData, countryPriceData);

        const navyPriceData = changeableCountryPriceData?.pricing?.navy;
        const planePriceData = changeableCountryPriceData?.pricing?.plane;

        return (
          <StyledContainer key={changeableCountryPriceData.countryId}>
            <div className="header" onClick={() => handleOpen(String(changeableCountryPriceData.countryId))}>
              <div>
                <span>
                  Country: <strong> {changeableCountryPriceData?.countryName}</strong>
                </span>
              </div>

              <div>
                <div className="headPrice">
                  {navyPriceData && (
                    <>
                      <p>Standard:</p>
                      <span>
                        {navyPriceData.fullPrice} {selectedCurrencyName}
                      </span>
                    </>
                  )}
                  {planePriceData && (
                    <>
                      <p>/ Express:</p>
                      <span>
                        {planePriceData.fullPrice} {selectedCurrencyName}
                      </span>
                    </>
                  )}
                </div>
                <Button
                  htmlType="submit"
                  style={{ marginLeft: '16px' }}
                  disabled={isSaveButtonEnabled}
                  loading={isProductPricesLoading}
                  type="primary"
                  onClick={async e => {
                    e.preventDefault();
                    await handleSave(countryPriceData, changeableCountryPriceData);
                  }}
                >
                  Save
                </Button>
              </div>
            </div>

            <StyledCountryPriceDrop open>
              <Table pagination={false} bordered columns={columns} dataSource={[changeableCountryPriceData]} scroll={{ y: 200 }} rowKey="id" />
            </StyledCountryPriceDrop>

            {Boolean(productJournalData?.length) && (
              <div className="journalTableWrapper">
                <p className="journalTableTitle">Incoming stocks</p>

                <Table pagination={false} bordered columns={ProductJournalColumns} dataSource={productJournalData ?? []} />
              </div>
            )}
          </StyledContainer>
        );
      })}
    </div>
  );
};

export default ProductPrices;
