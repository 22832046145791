import { Col, Pagination, Row, message } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectImages, selectImagesCount, selectImagesLimit, selectImagesLoading, selectImagesLoadingAction, selectImagesPage, selectImagesSearch } from 'app/features/images/selectors';
import { deleteImage, imagesApi, setLimit, setPage } from 'app/features/images/slice';
import { DeleteOutlined } from '@ant-design/icons';
import { _debounce } from 'helpers/debounce';

import { ButtonDelete, CardImg, ImagesMeta, ImagesPagination, Img, ImgWrraper } from './styled';
import DeleteModal from 'components/DeleteModal';
import Loading from 'components/Loading';

const debounce = _debounce();

const Images = () => {
  const [itemOpacity, setItemOpacity] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [imageId, setImageId] = useState<string>('');
  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  const imagesData = useAppSelector(selectImages());
  const loading = useAppSelector(selectImagesLoading());
  const loadingAction = useAppSelector(selectImagesLoadingAction());
  const page = useAppSelector(selectImagesPage());
  const limit = useAppSelector(selectImagesLimit());
  const count = useAppSelector(selectImagesCount());
  const search = useAppSelector(selectImagesSearch());

  useEffect(() => {
    debounce(() => dispatch(imagesApi()));
  }, [dispatch, search, page, limit]);

  const handleCopy = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, src: string) => {
    event.preventDefault();
    navigator.clipboard.writeText(src);
    message.success(`${src} copied`);
    setItemOpacity(src);
    setTimeout(() => {
      setItemOpacity('');
    }, 2000);
  };

  return (
    <>
      {loading ? (
        <Loading size="large" />
      ) : (
        <>
          <Row gutter={[24, 24]}>
            {imagesData?.map((item, index) => {
              return (
                <Col style={itemOpacity === item.url ? { opacity: 0.5 } : { opacity: 1 }} key={item.url + index} span={8}>
                  <CardImg
                    hoverable
                    actions={[
                      <ButtonDelete
                        key="delete"
                        style={{ width: '100%', backgroundColor: 'white' }}
                        block
                        icon={<DeleteOutlined />}
                        danger
                        onClick={() => {
                          setImageId(item.uid);
                          setOpenDeleteModal(true);
                        }}
                      >
                        Delete
                      </ButtonDelete>
                    ]}
                    cover={
                      <ImgWrraper onClick={event => handleCopy(event, item.url)}>
                        <Img src={item.url} />
                      </ImgWrraper>
                    }
                  >
                    <div onClick={event => handleCopy(event, item.url)}>
                      <ImagesMeta title={item.name} />
                    </div>
                  </CardImg>
                </Col>
              );
            })}
          </Row>
          <ImagesPagination>
            <Pagination
              total={count}
              current={page}
              pageSize={limit}
              onChange={(page, pageSize) => {
                dispatch(setPage(page));
                dispatch(setLimit(pageSize));
              }}
            />
          </ImagesPagination>
          <DeleteModal
            open={openDeleteModal}
            confirmLoading={loadingAction}
            onOk={() => {
              dispatch(deleteImage(imageId)).then(() => {
                setOpenDeleteModal(false);
                setImageId('');
              });
            }}
            onCancel={() => {
              setOpenDeleteModal(false);
              setImageId('');
            }}
          />
        </>
      )}
    </>
  );
};

export default Images;
