import { Table, Button, Col, Form, Input, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getProductDiscount, deleteProductDiscountBulk } from 'app/features/products/slice';
import { selectProductById, selectProductDiscounts } from 'app/features/products/selectors';
import { transformArrayProducts } from 'helpers/product';
import { ProductDiscountType } from 'app/features/products/types';
import DeleteModal from 'components/DeleteModal';
import DiscountModalProduct from './DiscountModalProduct';
import { selectSelectedCurrency } from 'app/features/currency/selectedcurrencyslice';

export type ProductDiscountWithIdType = ProductDiscountType & { id: string; index?: number; source?: string; special?: boolean; discountCategory?: string };

const initialEditValue = { isEditable: false, type: '', countryIndex: undefined };

const ProductPage2 = () => {
  const dispatch = useAppDispatch();
  const currencyCode = useAppSelector(selectSelectedCurrency);
  const product = useAppSelector(selectProductById());
  const productDiscounts = useAppSelector(selectProductDiscounts());
  const [editValue, setEditValue] = useState<{ isEditable: boolean; type: string; countryIndex: number | undefined }>(initialEditValue);
  const [showDiscountModal, setShowDiscountModal] = useState<boolean>(false);
  const [ids, setIds] = useState<(string | undefined)[]>([]);
  const [selectedCountryId, setSelectedCountryId] = useState<number | undefined>(undefined);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  useEffect(() => {
    const optionalData = product && currencyCode ? { currencyCode } : {};

    dispatch(getProductDiscount({ id: product?.id, ...optionalData }));
  }, [dispatch, product, currencyCode]);

  const clickDiscount = (title: string, index: number | undefined) => {
    setShowDiscountModal(true);
    setEditValue({ isEditable: true, type: title, countryIndex: index });
  };

  const deleteHandler = (ids: (string | undefined)[]) => {
    dispatch(deleteProductDiscountBulk(ids)).then(() => {
      const optionalData = currencyCode ? { currencyCode } : {};

      dispatch(getProductDiscount({ id: product?.id, ...optionalData }));
    });
  };

  const DiscountsColumns: ColumnsType<ProductDiscountWithIdType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: 200,
      render: (_, record) => {
        if (record.discountCategory && !record.special) {
          return (
            <>
              <p style={{ marginTop: '20px' }}>{record.name}</p>
              <Tag color="green">{record.discountCategory}</Tag>
            </>
          );
        } else if (record.discountCategory) {
          return (
            <>
              <p style={{ marginTop: '20px' }}>{record.name}</p>
              <Tag style={{ color: 'grey' }}>{record.discountCategory}</Tag>
            </>
          );
        } else {
          return (
            <>
              <p style={{ marginTop: '40px' }}>{record.name}</p>
            </>
          );
        }
      }
    },
    {
      title: 'Percent 1 (%)',
      dataIndex: 'percent1',
      key: 'percent1',
      align: 'center',
      render: (_, record) => {
        return (
          <div style={{ margin: '3px', padding: '3px' }}>
            <p>
              {record.percent1}
              {' %'}
            </p>
            <br />
            <p>
              {' '}
              Standard: {record.navy_price1} {currencyCode}
            </p>
            <p>
              {' '}
              Express: {record.plane_price1} {currencyCode}
            </p>
          </div>
        );
      }
    },
    {
      title: 'Percent 2 (%)',
      dataIndex: 'percent2',
      key: 'percent2',
      align: 'center',
      render: (_, record) => {
        return (
          <div style={{ margin: '3px', padding: '3px' }}>
            <p>
              {record.percent2} {' %'}
            </p>
            <br />
            <p>
              {' '}
              Standard: {record.navy_price2} {currencyCode}
            </p>
            <p>
              {' '}
              Express: {record.plane_price2} {currencyCode}
            </p>
          </div>
        );
      }
    },
    {
      title: 'Percent 3 (%)',
      dataIndex: 'percent3',
      key: 'percent3',
      align: 'center',
      render: (_, record) => {
        return (
          <div style={{ margin: '1px', padding: '1px' }}>
            <p>
              {record.percent3} {' %'}
            </p>
            <br />
            <p>
              {' '}
              Standard: {record.navy_price3} {currencyCode}
            </p>
            <p>
              {' '}
              Express: {record.plane_price3} {currencyCode}
            </p>
          </div>
        );
      }
    },
    {
      title: 'Percent 4 (%)',
      dataIndex: 'percent4',
      key: 'percent4',
      align: 'center',
      render: (_, record) => {
        return (
          <div style={{ margin: '3px', padding: '3px' }}>
            <p>
              {record.percent4} {' %'}
            </p>
            <br />
            <p>
              {' '}
              Standard: {record.navy_price4} {currencyCode}
            </p>
            <p>
              {' '}
              Express: {record.plane_price4} {currencyCode}
            </p>
          </div>
        );
      }
    },

    {
      title: 'Action',
      dataIndex: 'action',
      width: 120,
      key: 'Action',
      align: 'center',
      render: (_, record) => {
        return (
          <>
            <Button icon={<EditOutlined />} onClick={() => clickDiscount(record.name, record?.index)}></Button>
            <Button
              disabled={!record.special && Boolean(record?.source)}
              style={{ marginLeft: '3px' }}
              icon={<DeleteOutlined />}
              onClick={() => {
                const currentProduct = productDiscounts?.[record?.index ?? 0];
                const discountIds = currentProduct.product_discounts.filter(discount => discount.type === record.name).map(discount => discount.id);
                setIds(discountIds);
                setOpenDeleteModal(true);
              }}
            ></Button>
          </>
        );
      }
    }
  ];

  return (
    <>
      <Col span={12}>
        <Form.Item label="Status" name="status" rules={[{ required: false }]}>
          <Input placeholder="Enter Status" />
        </Form.Item>
      </Col>
      {productDiscounts?.map((item, index) => {
        const discounts = transformArrayProducts(item.product_discounts, index) || [];

        return (
          <div key={item.id}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '50px 0 20px 0',
                alignItems: 'center'
              }}
            >
              <p style={{ fontSize: '22px' }}>
                <b>{item.name}</b> Discounts
              </p>
              {discounts?.length < 4 && (
                <Button
                  icon={<PlusOutlined />}
                  color="primary"
                  onClick={() => {
                    setSelectedCountryId(item.country_id);
                    setShowDiscountModal(true);
                  }}
                >
                  Add Discount
                </Button>
              )}
            </div>
            <Table loading={false} bordered rowKey="id" pagination={false} columns={DiscountsColumns as any} dataSource={discounts} />
          </div>
        );
      })}
      <DiscountModalProduct
        selectedCountryId={selectedCountryId}
        editValue={editValue}
        clearEditValue={() => setEditValue(initialEditValue)}
        isOpen={showDiscountModal}
        onClose={() => setShowDiscountModal(false)}
        productId={product?.id}
      />
      <DeleteModal
        open={openDeleteModal}
        confirmLoading={false}
        onOk={() => {
          if (productDiscounts.length > 0) {
            deleteHandler(ids);
            setOpenDeleteModal(false);
          }
        }}
        onCancel={() => {
          setOpenDeleteModal(false);
        }}
      />
    </>
  );
};

export default ProductPage2;
