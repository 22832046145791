import { RootState } from 'app/store';

export const selectSliderData = () => (state: RootState) => state.slider.images;

// export const selectSlider = () => (state: RootState) => {
//   const imagesData = selectSliderData()(state);

//   return (
//     imagesData?.map((item: ISliderFile) => {
//       const url = `${BASE_URL}/sliders/${item.name}`;

//       return {
//         id: item.id,
//         name: `${item.name}`,
//         status: 'done',
//         url,
//         file: {
//           extension: item.file.extension,
//           name: `${item.file.name}`
//         }
//       };
//     }) || []
//   );
// };

export const selectSliderLoading = () => (state: RootState) => state.slider.loading;

export const selectSliderLoadingAction = () => (state: RootState) => state.slider.loadingAction;
