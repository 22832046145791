import { _debounce } from 'helpers/debounce';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IService } from 'app/features/services/types';
import { setLimit, setPage } from 'app/features/educational/slice';
import { DeleteOutlined, EditTwoTone } from '@ant-design/icons';
import { deleteService, serviceApi, setSearch } from 'app/features/services/slice';
import { selectServiceCount, selectServiceData, selectServiceLimit, selectServiceLoading, selectServicePage, selectServiceSearch } from 'app/features/services/selectors';

import { ButtonsWrapper } from 'pages/Contacts/styled';
import { Text } from './styled';
import ContentTop from 'components/ContentTop';
import DeleteModal from 'components/DeleteModal';

const debounce = _debounce();

const Services = () => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [serviceId, setServiceId] = useState<number>(0);
  const navigate = useNavigate();
  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  const serviceData = useAppSelector(selectServiceData());
  const loading = useAppSelector(selectServiceLoading());
  const page = useAppSelector(selectServicePage());
  const limit = useAppSelector(selectServiceLimit());
  const count = useAppSelector(selectServiceCount());
  const search = useAppSelector(selectServiceSearch());
  // const loadingAction = useAppSelector(selectEducationalLoadingAction());
  useEffect(() => {
    debounce(() => dispatch(serviceApi()));
  }, [dispatch, search, page, limit]);

  const columns: ColumnsType<IService> = [
    {
      title: 'Title',
      dataIndex: 'title_en',
      key: 'title_en',
      width: 180
    },
    {
      title: 'Text',
      dataIndex: 'body_text',
      key: 'body_text',
      render: (text: string) => {
        return <Text>{text}</Text>;
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 220,
      key: 'Action',
      render: (_, record) => {
        return (
          <ButtonsWrapper>
            <Link to={`details/${record.id}`}>
              <Button icon={<EditTwoTone />}>Edit</Button>
            </Link>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                setServiceId(record.id);
                setOpenDeleteModal(true);
              }}
            >
              Delete
            </Button>
          </ButtonsWrapper>
        );
      }
    }
  ];

  return (
    <>
      <ContentTop
        title="All Services"
        placeholder="Search Service"
        buttonText="Create Service"
        onClickAdd={() => navigate('details')}
        onChange={e => {
          dispatch(setSearch(e.target.value));
        }}
        searchComponent
        search={search}
        add
      />
      <Table
        bordered
        columns={columns}
        dataSource={serviceData}
        loading={loading}
        rowKey="id"
        pagination={{
          current: page,
          onChange: (page, pageSize) => {
            dispatch(setPage(page));
            dispatch(setLimit(pageSize));
          },
          pageSize: limit,
          total: count
        }}
      />
      <DeleteModal
        open={openDeleteModal}
        confirmLoading={loading}
        onOk={() => {
          dispatch(deleteService(serviceId)).then(() => {
            setOpenDeleteModal(false);
            setServiceId(0);
          });
        }}
        onCancel={() => {
          setOpenDeleteModal(false);
          setServiceId(0);
        }}
      />
    </>
  );
};

export default Services;
