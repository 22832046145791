// import { IGlobalFilesType } from 'app/features/product/types';
import { getFileUrl, getFileUrlBIG } from 'helpers/file';
import { FC } from 'react';
import Slider from 'react-slick';
import { Image } from 'antd';

import { ImgSlider } from './styled';
import { IGlobalFilesType } from './types';
import noImg from '../../../../../assets/img/noimage.png';

interface ISlider {
  files?: IGlobalFilesType[];
  folder: string;
  BigFiles: boolean;
}

const SyncSlider: FC<ISlider> = ({ files, folder, BigFiles }) => {
  //   const [nav1, setNav1] = useState<Slider | undefined>();
  //   const [nav2, setNav2] = useState<Slider | undefined>();

  return (
    <div>
      <ImgSlider>
        <Slider
          arrows={false}
          infinite
          //   asNavFor={nav2}
          //   ref={(slider1: any) => slider1 && setNav1(slider1)}
        >
          {files?.length ? (
            files.map(item => {
              const thumbnailImageFile = { ...item, path: getFileUrlBIG(files[0].path.replace('108x88', '574x438')) };

              return <Image key={item.id} alt={thumbnailImageFile.name} src={BigFiles ? thumbnailImageFile.path : getFileUrl(item.name, item.extension, folder)} />;
            })
          ) : (
            <Image preview={false} src={noImg} alt="No Image" />
          )}
        </Slider>
      </ImgSlider>
    </div>
  );
};

export default SyncSlider;
