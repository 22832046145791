import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api/apiClient';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';

import { CustomPageState, ICustomPageById } from './types';

const initialState: CustomPageState = {
  customPage: [],
  customPageById: null,
  loadingById: false,
  loading: false,
  loadingAction: false,
  count: 0,
  aboutFiles: []
};

export const customPageApi = createAsyncThunk('customPage/customPageApi', async (_, { rejectWithValue }) => {
  try {
    const res = await client.get('/custom_page');

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const customPageEdit = createAsyncThunk('customPage/customPageEdit', async ({ id, ...data }: ICustomPageById, { dispatch, rejectWithValue }) => {
  try {
    await client.patch(`custom_page/${id}`, {
      ...data
    });
    await dispatch(customPageApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const getCustomPageById = createAsyncThunk('customPage/getCustomPageById', async (id: number, { rejectWithValue }) => {
  try {
    const response = await client.get(`/custom_page/${id}`);

    return response.data.data;
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const deleteFileApi = createAsyncThunk('customPage/deleteFileApi', async (id: string, { dispatch, rejectWithValue }) => {
  try {
    const response = await client.delete(`/file/${id}`);

    dispatch(getCustomPageById(1));

    return response.data.data;
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});
const customPageSlice = createSlice({
  name: 'customPage',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(customPageApi.pending, state => {
        state.loading = true;
      })
      .addCase(customPageApi.fulfilled, (state, action) => {
        state.customPage = action.payload.rows;
        state.count = action.payload.count;
        state.loading = false;
      })
      .addCase(customPageApi.rejected, state => {
        state.loading = false;
      })
      .addCase(getCustomPageById.pending, state => {
        state.loadingById = true;
      })
      .addCase(getCustomPageById.fulfilled, (state, action) => {
        state.customPageById = action.payload.rows;
        state.aboutFiles = action.payload.files;
        state.loadingById = false;
      })
      .addCase(getCustomPageById.rejected, state => {
        state.loadingById = false;
      })
      .addCase(customPageEdit.pending, state => {
        state.loadingAction = true;
      })
      .addCase(customPageEdit.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(customPageEdit.rejected, state => {
        state.loadingAction = false;
      });
  }
});

export default customPageSlice.reducer;
