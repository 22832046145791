import styled, { css } from 'styled-components';

export const StyledListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  user-select: none;
`;

export const StyledListChild = styled.div<{ active: boolean }>`
  position: relative;
  padding: 10px;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 20px;
  border-radius: 5px;
  ${({ active }) =>
    active
      ? css`
          background-color: #00c11f;
          box-shadow: 0px 4px 10px 0px #00808d;
        `
      : css`
          background-color: #00c11f30;
          box-shadow: none;
        `}
  cursor: pointer;
  input {
    width: 20px;
    height: 20px;
    accent-color: green;
  }
`;
