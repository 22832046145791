import { RootState } from 'app/store';

export const selectPartnerData = () => (state: RootState) => state.partner.partner;

export const selectPartnerLoading = () => (state: RootState) => state.partner.loading;

export const selectPartnersSearch = () => (state: RootState) => state.partner.search;

export const selectPartnersPage = () => (state: RootState) => state.partner.page;

export const selectPartnersLimit = () => (state: RootState) => state.partner.limit;

export const selectPartnersCount = () => (state: RootState) => state.partner.count;

export const selectPartnerById = () => (state: RootState) => state.partner.partnerById;

export const selectPartnerLoadingAction = () => (state: RootState) => state.partner.loadingAction;

export const selectPartnerLoadingById = () => (state: RootState) => state.partner.loadingByID;
