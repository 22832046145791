import { IPerimission } from 'pages/Roles/types';

export const requestsArr: IPerimission[] = [
  { key: 'GET', checked: false },
  { key: 'CREATE', checked: false },
  { key: 'UPDATE', checked: false },
  { key: 'DELETE', checked: false }
];

export const menuListArr: IPerimission[] = [
  { key: 'CATEGORIES', checked: false },
  { key: 'PRODUCTS', checked: false },
  { key: 'DISCOUNTS', checked: false },
  { key: 'SECTION', checked: false },
  { key: 'USERS', checked: false },
  { key: 'ADMINS', checked: false },
  { key: 'ROLES', checked: false },
  { key: 'PARTNERS', checked: false },
  { key: 'ROLE_MANAGMENT', checked: false },
  { key: 'SPECIALIST', checked: false },
  { key: 'ATTRIBUTES', checked: false },
  { key: 'CUSTOM_PAGE', checked: false },
  { key: 'SERVICES', checked: false },
  { key: 'BLOG', checked: false },
  { key: 'CONTACT', checked: false },
  { key: 'ORDERS', checked: false },
  { key: 'QUESTIONS', checked: false }
];

export const getPermission = (arr: string[] | undefined, key: string) => {
  return !!arr?.find(item => item === key);
};
