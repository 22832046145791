import { DeleteOutlined, EditTwoTone } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { IAttributes } from 'app/features/atributes/types';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { _debounce } from 'helpers/debounce';
import { attributeApi, deleteAttribute, handleUploadAttr, setLimit, setPage, setSearch } from 'app/features/atributes/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectAttributeCount,
  selectAttributeData,
  selectAttributeLimit,
  selectAttributeLoading,
  selectAttributeLoadingAction,
  selectAttributePage,
  selectAttributeSearch
} from 'app/features/atributes/selectors';

import { ButtonsWrapper } from './styled';
import ContentTop from 'components/ContentTop';
import DeleteModal from 'components/DeleteModal';

const debounce = _debounce();

const Attributes = () => {
  //state
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [attributeId, setAttributeId] = useState<number>(0);
  //navigate
  const navigate = useNavigate();
  //dispatch
  const dispatch = useAppDispatch();
  // selectors
  const attributeData = useAppSelector(selectAttributeData());
  const page = useAppSelector(selectAttributePage());
  const search = useAppSelector(selectAttributeSearch());
  const limit = useAppSelector(selectAttributeLimit());
  const loading = useAppSelector(selectAttributeLoading());
  const count = useAppSelector(selectAttributeCount());
  const loadingAction = useAppSelector(selectAttributeLoadingAction());

  useEffect(() => {
    debounce(() => dispatch(attributeApi()));
  }, [dispatch, search, page, limit]);

  const columns: ColumnsType<IAttributes> = [
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 220,
      key: 'Action',
      render: (_, record) => {
        return (
          <ButtonsWrapper>
            <Link to={`details/${record.id}`}>
              <Button icon={<EditTwoTone />}>Edit</Button>
            </Link>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                setAttributeId(record.id);
                setOpenDeleteModal(true);
              }}
            >
              Delete
            </Button>
          </ButtonsWrapper>
        );
      }
    }
  ];

  return (
    <>
      <ContentTop
        title="Attributes"
        placeholder="Search Attribute"
        buttonText="Add Attribute"
        upload
        onChangeUpload={(info: { file: File }) => dispatch(handleUploadAttr(info.file))}
        onClickAdd={() => navigate('details')}
        onChange={e => {
          dispatch(setSearch(e.target.value));
        }}
        add
        searchComponent
        search={search}
      />
      <Table
        loading={loading}
        pagination={{
          current: page,
          onChange: (page, pageSize) => {
            dispatch(setPage(page));
            dispatch(setLimit(pageSize));
          },
          pageSize: limit,
          total: count
        }}
        bordered
        rowKey="id"
        columns={columns}
        dataSource={attributeData}
      />
      <DeleteModal
        open={openDeleteModal}
        confirmLoading={loadingAction}
        onOk={() => {
          dispatch(deleteAttribute(attributeId)).then(() => {
            setOpenDeleteModal(false);
            setAttributeId(0);
          });
        }}
        onCancel={() => {
          setOpenDeleteModal(false);
          setAttributeId(0);
        }}
      />
    </>
  );
};

export default Attributes;
