import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api/apiClient';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { RootState } from 'app/store';

import { ImagesState } from './types';
import { selectImagesLimit, selectImagesPage, selectImagesSearch } from './selectors';

const initialState: ImagesState = {
  loading: false,
  loadingAction: false,
  images: [],
  search: '',
  page: 1,
  limit: 12,
  count: 0
};

export const imagesApi = createAsyncThunk('images/imagesApi', async (_, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState;
    const search = selectImagesSearch()(state);
    const page = selectImagesPage()(state);
    const limit = selectImagesLimit()(state);
    const res = await client.get('/upload_file', {
      params: {
        search,
        page,
        limit
      }
    });

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const deleteImage = createAsyncThunk('images/deleteImage', async (id: string, { dispatch, rejectWithValue }) => {
  try {
    await client.delete(`/upload_file/${id}`);
    await dispatch(imagesApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

const imagesSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(imagesApi.pending, state => {
        state.loading = true;
      })
      .addCase(imagesApi.fulfilled, (state, action) => {
        state.loading = false;
        state.images = action.payload.rows;
        state.count = action.payload.count;
      })
      .addCase(imagesApi.rejected, state => {
        state.loading = false;
      })
      .addCase(deleteImage.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteImage.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteImage.rejected, state => {
        state.loadingAction = false;
      });
  }
});

export const { setPage, setSearch, setLimit } = imagesSlice.actions;
export default imagesSlice.reducer;
