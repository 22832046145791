import { Col, Form, Input, Row, Select } from 'antd';
import { FC, useState, Dispatch, useEffect } from 'react';
import { CountryById } from 'app/features/country/types';
import { useAppSelector } from 'app/hooks';
import { selectUserById } from 'app/features/users/selectors';
import moment from 'moment';
import { selectPartnerData } from 'app/features/partner/selectors';
import { selectUserInfo } from 'app/features/auth/selectors';
import { IPartner } from 'app/features/partner/types';
import MuiPhoneNumber from 'material-ui-phone-number-2';
import { getCountryData } from 'assets/countries';

import { IPerimission, IRole } from 'pages/Roles/types';
import { StyledListChild, StyledListContainer } from 'pages/Roles/details/styled';
import { Checkbox } from 'components/ProductsListModal/styled';
import { StyledMobileNumber } from '../styled';

interface IUserPage1 {
  id?: string;
  isSalesAdmin: boolean;
  userRoles: IRole[];
  accountTypes?: { title: string }[];
  discountTypes?: { type: number }[];
  setShowAddressesModal: Dispatch<React.SetStateAction<boolean>>;
  requestTypes: IPerimission[];
  setRequestTypes: Dispatch<React.SetStateAction<IPerimission[]>>;
  pagesList: IPerimission[];
  setPagesList: Dispatch<React.SetStateAction<IPerimission[]>>;
  phoneNumber: string | undefined;
  setPhoneNumber: Dispatch<React.SetStateAction<string | undefined>>;
}

const AdminPage1: FC<IUserPage1> = ({ id, isSalesAdmin, userRoles, requestTypes, setRequestTypes, pagesList, setPagesList, phoneNumber, setPhoneNumber }) => {
  const [userCountry] = useState<CountryById>();
  const countryData = getCountryData();
  const userById = useAppSelector(selectUserById());
  const partners = useAppSelector(selectPartnerData());
  const myInfo = useAppSelector(selectUserInfo());

  const filteredUserRoles = userRoles?.filter(item => item.name !== 'SUPER_ADMIN');

  const handleCheckRequest = (item: IPerimission) => {
    const updatedRequestTypes = requestTypes.map(requestType => {
      if (requestType.key === item.key) {
        return {
          ...requestType,
          checked: !requestType.checked
        };
      }

      return requestType;
    });

    setRequestTypes(updatedRequestTypes);
  };

  const handleCheckMenuList = (item: IPerimission) => {
    const updatedMenuList = pagesList.map(menuItem => {
      if (item.key === menuItem.key) {
        return {
          ...menuItem,
          checked: !menuItem.checked
        };
      }

      return menuItem;
    });

    setPagesList(updatedMenuList);
  };

  const filterMenu = (arg: string) => {
    if (arg === 'role') {
      return pagesList
        .map(item => {
          if (item.key == 'USERS' || item.key == 'SPECIALIST' || item.key == 'ADMINS' || item.key == 'ROLES') {
            return item ? item : null;
          }
        })
        .filter(i => i !== undefined);
    } else {
      return pagesList
        .map(item => {
          if (item.key !== 'USERS' && item.key !== 'SPECIALIST' && item.key !== 'ADMINS' && item.key !== 'ROLES' && item.key !== 'ROLE_MANAGMENT') {
            return item ? item : null;
          }
        })
        .filter(i => i !== undefined);
    }
  };

  const [selectedRole, setSelectedRole] = useState<IRole | null>();
  const [selectedPartner, setSelectedPartner] = useState<IPartner | null>();

  useEffect(() => {
    if (userById) {
      if (userRoles) {
        setSelectedRole(userRoles.find(item => item.name === userById.role) || null);
      }

      if (partners) {
        setSelectedPartner(partners.find(item => item.id === userById.partner_id) || null);
      }
    }
  }, [partners, userRoles, userById]);

  useEffect(() => {
    const updateLists = (listToUpdate: IPerimission[], permissions: string[]) => {
      return listToUpdate.map(item => ({
        ...item,
        checked: permissions.includes(item.key)
      }));
    };

    if (selectedRole?.name !== userById?.role) {
      if (selectedRole?.permission_request) {
        setRequestTypes(updateLists(requestTypes, selectedRole.permission_request));
      }

      if (selectedRole?.permision_menu) {
        setPagesList(updateLists(pagesList, selectedRole.permision_menu));
      }
    } else {
      if (userById?.permission_request) {
        setRequestTypes(updateLists(requestTypes, userById.permission_request));
      }

      if (userById?.permision_menu) {
        setPagesList(updateLists(pagesList, userById.permision_menu));
      }
    }
  }, [selectedRole]);

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <Form.Item rules={[{ required: true }]} label="First Name" name="firstName">
            <Input placeholder="Enter First Name" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item rules={[{ required: true }]} label="Last Name" name="lastName">
            <Input placeholder="Enter Last Name" />
          </Form.Item>
        </Col>
        {!id && (
          <Col span={8}>
            <Form.Item rules={[{ required: true }]} label="Password" name="password">
              <Input placeholder="Enter Password" />
            </Form.Item>
          </Col>
        )}
        <Col span={8}>
          <Form.Item
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!'
              },
              {
                required: true,
                message: 'Please input E-mail!'
              }
            ]}
            label="Email"
            name="email"
          >
            <Input size="large" placeholder="Enter email"></Input>
          </Form.Item>
        </Col>
        {myInfo?.partner_id === null && (
          <Col span={4}>
            <Form.Item label="Partner" name="partner_id">
              <Select
                value={selectedPartner}
                onChange={e => {
                  const partner = partners.find(item => item.id === +e);
                  setSelectedPartner(partner);
                }}
                placeholder="Select Partner"
                options={partners.map(item => {
                  return {
                    value: item.id,
                    label: item.name
                  };
                })}
              />
            </Form.Item>
          </Col>
        )}
        <Col span={4}>
          <Form.Item rules={[{ required: true }]} label="Role" name="role">
            <Select
              placeholder="Select User Role"
              onChange={e => setSelectedRole(userRoles.find(item => item.name === e) || null)}
              options={filteredUserRoles.map(item => {
                return {
                  value: item.name,
                  label: item.name
                };
              })}
            />
          </Form.Item>
        </Col>

        {!selectedPartner && (
          <Col span={7}>
            <Form.Item label="Country" name="countryId">
              <Select
                disabled={isSalesAdmin}
                filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                filterSort={(optionA, optionB) => optionA?.label.toLowerCase().localeCompare(optionB?.label.toLowerCase())}
                showSearch
                value={userCountry}
                placeholder="Country"
                options={countryData.map(country => {
                  return {
                    value: country.id,
                    label: country.name
                  };
                })}
              />
            </Form.Item>
          </Col>
        )}
        <Col span={7}>
          <Form.Item label="Phone" name="phone">
            <StyledMobileNumber>
              <MuiPhoneNumber
                disableAreaCodes={true}
                className="mobileNumberSelect"
                sx={{ '& svg': { height: '1em' } }}
                value={phoneNumber}
                inputClass="phoneNumberInput"
                defaultCountry={'am'}
                variant="outlined"
                onChange={(e: any) => setPhoneNumber(e)}
              />
            </StyledMobileNumber>
          </Form.Item>
        </Col>
        {id && (
          <Col span={7} style={{ marginTop: '12px' }}>
            <p style={{ marginBottom: '15px' }}>Created at</p>
            <Input readOnly value={userById ? moment(userById?.createdAt).format('DD/MMM/YYYY HH:mm') : ''} />
          </Col>
        )}
        <Col span={24}>
          {selectedRole && selectedRole.name !== 'USER' && (
            <>
              <p
                style={{
                  fontSize: '22px',
                  fontWeight: 500,
                  marginBottom: '50px'
                }}
              >
                Permissions
              </p>
              <p
                style={{
                  fontSize: '18px',
                  fontWeight: 500,
                  marginBottom: '20px'
                }}
              >
                Requests
              </p>
              <StyledListContainer>
                {requestTypes.map((item, index) => {
                  return (
                    <StyledListChild active={item.checked} key={index} onClick={() => handleCheckRequest(item)}>
                      <p>{item.key}</p>
                      <Checkbox readOnly checked={item.checked} />
                    </StyledListChild>
                  );
                })}
              </StyledListContainer>
              <p
                style={{
                  fontSize: '18px',
                  fontWeight: 500,
                  margin: '20px 0'
                }}
              >
                Pages
              </p>
              <StyledListContainer>
                {filterMenu('other').length > 0 &&
                  filterMenu('other').map((item, index) => {
                    if (item) {
                      return (
                        <StyledListChild active={item.checked} key={index} onClick={() => handleCheckMenuList(item)}>
                          <p style={{ textTransform: 'capitalize' }}>{item.key.toLowerCase()}</p>
                          <Checkbox readOnly checked={item.checked} />
                        </StyledListChild>
                      );
                    }
                  })}
              </StyledListContainer>
              <br />
              <br />
              <StyledListContainer>
                {filterMenu('role').length > 0 &&
                  filterMenu('role').map((item, index) => {
                    if (item) {
                      return (
                        <StyledListChild className={index == 0 ? 'firstChild' : ''} active={item.checked} key={index} onClick={() => handleCheckMenuList(item)}>
                          <p style={{ textTransform: 'capitalize' }}>{item.key.toLowerCase()}</p>
                          <Checkbox readOnly checked={item.checked} />
                        </StyledListChild>
                      );
                    }
                  })}
              </StyledListContainer>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default AdminPage1;
