import { RootState } from 'app/store';

export const selectContactData = () => (state: RootState) => state.contact.contact;

export const selectContactLoading = () => (state: RootState) => state.contact.loading;

export const selectContactSearch = () => (state: RootState) => state.contact.search;

export const selectContactPage = () => (state: RootState) => state.contact.page;

export const selectContactLimit = () => (state: RootState) => state.contact.limit;

export const selectContactCount = () => (state: RootState) => state.contact.count;

export const selectContactById = () => (state: RootState) => state.contact.contactById;

export const selectContactLoadingAction = () => (state: RootState) => state.contact.loadingAction;

export const selectContactLoadingById = () => (state: RootState) => state.contact.loadingByID;
