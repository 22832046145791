export function deepEqual(obj1: any, obj2: any): boolean {
  const isNumberItem1 = !Number.isNaN(Number(obj1));
  const isNumberItem2 = !Number.isNaN(Number(obj2));

  if (isNumberItem1 && isNumberItem2) {
    return Number(obj1) === Number(obj2);
  }

  if (obj1 === obj2) {
    return true;
  }

  if (obj1 === null || obj2 === null) {
    return obj1 === obj2;
  }

  const type1 = typeof obj1;
  const type2 = typeof obj2;

  if (type1 !== 'object' || type2 !== 'object') {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = new Set(Object.keys(obj2));

  if (keys1.length !== keys2.size) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.has(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export function getUpdatedFields(originalData: any, updatedData: any, exceptionalKeys: string[]) {
  const updatedFields: any = {};

  const keys = new Set([...Object.keys(originalData), ...Object.keys(updatedData)]);

  keys.forEach(key => {
    if (exceptionalKeys.includes(key)) {
      updatedFields[key] = updatedData[key];
    } else {
      // eslint-disable-next-line no-prototype-builtins
      if (!originalData.hasOwnProperty(key) && updatedData.hasOwnProperty(key)) {
        updatedFields[key] = updatedData[key];
      } else if (originalData[key] !== updatedData[key]) {
        if (Array.isArray(originalData[key]) && Array.isArray(updatedData[key])) {
          const arrayChanges = getUpdatedArrayItems(originalData[key], updatedData[key], exceptionalKeys);

          if (arrayChanges.length > 0) {
            updatedFields[key] = arrayChanges;
          }
        } else if (typeof originalData[key] === 'object' && typeof updatedData[key] === 'object' && originalData[key] !== null && updatedData[key] !== null) {
          const nestedChanges = getUpdatedFields(originalData[key], updatedData[key], exceptionalKeys);

          if (Object.keys(nestedChanges).length > 0) {
            updatedFields[key] = nestedChanges;
          }
        } else {
          updatedFields[key] = updatedData[key];
        }
      }
    }
  });

  return updatedFields;
}

export function getUpdatedArrayItems(originalData: any, updatedData: any, exceptionalKeys: string[]) {
  const maxLength = Math.max(originalData.length, updatedData.length);
  const updatedArrayItems = [];

  for (let i = 0; i < maxLength; i++) {
    if (originalData[i] !== updatedData[i]) {
      if (typeof originalData[i] === 'object' && typeof updatedData[i] === 'object' && originalData[i] !== null && updatedData[i] !== null) {
        const nestedChanges = getUpdatedFields(originalData[i], updatedData[i], exceptionalKeys);

        if (Object.keys(nestedChanges).length > 0) {
          updatedArrayItems[i] = nestedChanges;
        }
      } else {
        updatedArrayItems[i] = updatedData[i];
      }
    } else if (i < originalData.length || i < updatedData.length) {
      updatedArrayItems[i] = updatedData[i];
    }
  }

  return updatedArrayItems.filter(item => item !== undefined);
}

export const getQueryString = (obj: { [key: string]: any }) => {
  const filteredObj = Object.fromEntries(Object.entries(obj).filter(([, value]) => value));

  const params = new URLSearchParams(filteredObj).toString();

  return params ? `?${params}` : '';
};
