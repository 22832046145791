import { Typography } from 'antd';
import styled from 'styled-components';

export const Text = styled(Typography.Text)`
  &&& {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
`;
export const ScrollContainer = styled.div`
  height: 600px;
  overflow-y: scroll;
  margin: 0;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
`;
export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  cursor: pointer;
  height: 20px;
  width: 20px;
  accent-color: green;
`;
