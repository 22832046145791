import { ExportOutlined } from '@ant-design/icons';
import { Button, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IOrder } from 'app/features/order/types';
import { selectUserOrders, selectUserOrdersCount, selectUserOrdersLimit, selectUserOrdersPage } from 'app/features/users/selectors';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Link } from 'react-router-dom';
import { setUserOrderLimit, setUserOrderPage } from 'app/features/users/slice';

import { statusFilter } from 'pages/Orders/constant';

const UserPage4 = () => {
  const userOrderData = useAppSelector(selectUserOrders());
  const limit = useAppSelector(selectUserOrdersLimit());
  const count = useAppSelector(selectUserOrdersCount());
  const page = useAppSelector(selectUserOrdersPage());

  const dispatch = useAppDispatch();
  const columns: ColumnsType<IOrder> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Surname',
      dataIndex: 'surname',
      key: 'surname'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text: string) => {
        return (
          <>
            <a href={`mailto:${text}`} rel="noreferrer">
              {text}
            </a>
          </>
        );
      }
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'Created_at',
      dataIndex: 'createdAt',
      key: 'created_at',
      render: text => {
        return (
          <>
            <Typography.Text style={{ marginRight: 10 }}>{new Date(text).toLocaleDateString('en-GB')}</Typography.Text>
            <Typography.Text>
              {new Date(text).toLocaleTimeString('it-IT', {
                hour: '2-digit',
                minute: '2-digit'
              })}
            </Typography.Text>
          </>
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: id => {
        return <Typography.Text>{statusFilter(id)}</Typography.Text>;
      }
    },
    {
      title: '',
      dataIndex: 'order_content',
      key: 'order_content',
      width: 50,
      render: (_, record) => {
        return (
          <Link to={`/orders/details/${record.id}`}>
            <Button style={{ width: 60 }} icon={<ExportOutlined style={{ fontSize: 18, marginTop: 2 }} />}></Button>
          </Link>
        );
      }
    }
  ];

  return (
    <Table
      bordered
      columns={columns}
      dataSource={userOrderData}
      rowKey="id"
      pagination={{
        current: page,
        onChange: (page, pageSize) => {
          dispatch(setUserOrderPage(page));
          dispatch(setUserOrderLimit(pageSize));
        },
        pageSize: limit,
        total: count
      }}
    />
  );
};

export default UserPage4;
