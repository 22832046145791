import { Button, Form, Space, Tabs, Tag } from 'antd';
import { selectProductById, selectProductLoadingAction, selectProductLoadingByID } from 'app/features/products/selectors';
import { clearProdById, getProductById } from 'app/features/products/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { selectUserInfo } from 'app/features/auth/selectors';
import { getPermission } from 'helpers/userParmissions';

import { productProcesses } from '../../helpers/product-processes';
import Loading from 'components/Loading';
import { HeaderDivider, LeftPage, PageHeader, PageTitle, ProductTitle } from 'components/DetailsHead/styled';
import ProductPage1 from './components/ProductPage1';
import ProductPage2 from './components/ProductPage2';
import ProductPrices from './components/ProductPrices';

const validateMessages = {
  required: "'${label}' is Required!"
};

const ProductDetails = () => {
  const { opened_articul } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const productById = useAppSelector(selectProductById());
  const loadingById = useAppSelector(selectProductLoadingByID());
  const loadingAction = useAppSelector(selectProductLoadingAction());
  const userInfo = useAppSelector(selectUserInfo());
  const [form] = Form.useForm();

  useEffect(() => {
    return () => {
      clearProdById();
    };
  }, []);

  useEffect(() => {
    if (opened_articul) {
      dispatch(getProductById(opened_articul));
    }
  }, [dispatch, opened_articul]);

  const onClose = () => {
    form.resetFields();
    navigate(-1);
  };

  useEffect(() => {
    if (opened_articul && productById) {
      form.setFieldsValue({
        status: productById.status
      });
    } else {
      form.setFieldValue('attributes', [
        {
          description: '',
          value: ''
        }
      ]);
    }
  }, [productById, form, opened_articul]);

  const submitHandler = () => {
    if (getPermission(userInfo?.permision_menu, 'PRODUCTS')) {
      form.submit();
    }
  };

  const [tabs, setTabs] = useState('Product page 1');

  const onFinish = async () => {
    if (opened_articul && productById) {
      onClose();
    }
  };

  const tabItems = [
    {
      label: 'General Info',
      key: 'Product page 1',
      children: <ProductPage1 />
    },
    {
      label: 'Discounts',
      key: 'Product page 2',
      children: <ProductPage2 />
    },
    {
      label: 'Prices',
      key: 'Product page 3',
      children: <ProductPrices />
    }
  ];

  return (
    <>
      {loadingById ? (
        <Loading size="large" />
      ) : (
        <>
          <Form
            validateMessages={validateMessages}
            size="large"
            form={form}
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError={{
              behavior: 'smooth',
              block: 'center',
              inline: 'center'
            }}
          >
            <PageHeader
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Space>
                <LeftPage onClick={onClose} />
                <PageTitle level={3}>{opened_articul ? `Edit Product` : `Add Product`}</PageTitle>
                {opened_articul && (
                  <Tag color="green">
                    <ProductTitle strong>{productById?.title_en}</ProductTitle>
                  </Tag>
                )}
                {productById?.process === productProcesses.discontinued.value && <Tag>{productProcesses.discontinued.label}</Tag>}
              </Space>
              {!tabs.includes('Product page') && getPermission(userInfo?.permision_menu, 'PRODUCTS') && (
                <Button style={{ width: '200px' }} loading={loadingAction} type="primary" onClick={submitHandler}>
                  Save
                </Button>
              )}
            </PageHeader>
            <HeaderDivider />
            <Tabs activeKey={tabs} onChange={e => setTabs(e)} type="card" items={tabItems} />
          </Form>
        </>
      )}
    </>
  );
};

export default ProductDetails;
