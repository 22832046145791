import { _debounce } from 'helpers/debounce';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Table, { ColumnsType } from 'antd/es/table';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectEducationalCount,
  selectEducationalData,
  selectEducationalLimit,
  selectEducationalLoading,
  selectEducationalLoadingAction,
  selectEducationalPage,
  selectEducationalSearch
} from 'app/features/educational/selectors';
import { deleteEducational, educationalApi, setLimit, setPage, setSearch } from 'app/features/educational/slice';
import { IEducational } from 'app/features/educational/types';
import { Button } from 'antd';
import { DeleteOutlined, EditTwoTone, PlayCircleOutlined } from '@ant-design/icons';

import { ButtonsWrapper } from 'pages/Categories/styled';
import ContentTop from 'components/ContentTop';
import DeleteModal from 'components/DeleteModal';
import { Text } from './styled';
import VideoModal from 'components/VideoModal';

const debounce = _debounce();

const Educational = () => {
  //state
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [educationalId, setEducationalId] = useState<number>(0);
  const [visible, setVisible] = useState(false);
  const [video, setVideo] = useState('');
  const navigate = useNavigate();
  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  const educationalData = useAppSelector(selectEducationalData());
  const loading = useAppSelector(selectEducationalLoading());
  const page = useAppSelector(selectEducationalPage());
  const limit = useAppSelector(selectEducationalLimit());
  const count = useAppSelector(selectEducationalCount());
  const search = useAppSelector(selectEducationalSearch());
  const loadingAction = useAppSelector(selectEducationalLoadingAction());
  useEffect(() => {
    debounce(() => dispatch(educationalApi()));
  }, [dispatch, search, page, limit]);

  const columns: ColumnsType<IEducational> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text: string) => {
        return <Text>{text}</Text>;
      }
    },
    {
      title: 'Video',
      dataIndex: 'File',
      key: 'Video',
      width: 100,
      render: (_, item) => {
        if (item.url) {
          return (
            <>
              <Button
                onClick={() => {
                  setVideo(item.url);
                  setVisible(true);
                }}
                style={{ width: 60 }}
                icon={<PlayCircleOutlined style={{ fontSize: 18, marginTop: 2 }} />}
              ></Button>

              <VideoModal
                onCancel={() => {
                  setVideo('');
                  setVisible(false);
                }}
                visible={visible}
                videoUrl={video}
              />
            </>
          );
        }
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 220,
      key: 'Action',
      render: (_, record) => {
        return (
          <ButtonsWrapper>
            <Link to={`details/${record.id}`}>
              <Button icon={<EditTwoTone />}>Edit</Button>
            </Link>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                setEducationalId(record.id);
                setOpenDeleteModal(true);
              }}
            >
              Delete
            </Button>
          </ButtonsWrapper>
        );
      }
    }
  ];

  return (
    <>
      <ContentTop
        title="Educational"
        placeholder="Search Educational"
        buttonText="Add Educational"
        onClickAdd={() => navigate('details')}
        onChange={e => {
          dispatch(setSearch(e.target.value));
        }}
        searchComponent
        search={search}
        add
      />
      <Table
        bordered
        columns={columns}
        dataSource={educationalData}
        loading={loading}
        rowKey="id"
        pagination={{
          current: page,
          onChange: (page, pageSize) => {
            dispatch(setPage(page));
            dispatch(setLimit(pageSize));
          },
          pageSize: limit,
          total: count
        }}
      />
      <DeleteModal
        open={openDeleteModal}
        confirmLoading={loadingAction}
        onOk={() => {
          dispatch(deleteEducational(educationalId)).then(() => {
            setOpenDeleteModal(false);
            setEducationalId(0);
          });
        }}
        onCancel={() => {
          setOpenDeleteModal(false);
          setEducationalId(0);
        }}
      />
    </>
  );
};

export default Educational;
