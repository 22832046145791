import { selectOrdersCount, selectOrdersData, selectOrdersLimit, selectOrdersLoading, selectOrdersPage, selectOrdersSearch } from 'app/features/order/selectors';
import { ordersApi, setLimit, setPage, setSearch } from 'app/features/order/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { _debounce } from 'helpers/debounce';
import { useEffect } from 'react';
import { Button, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IOrder } from 'app/features/order/types';
import { ExportOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import ContentTop from 'components/ContentTop';
import { statusFilter } from './constant';

const debounce = _debounce();

const Orders = () => {
  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  const orderData = useAppSelector(selectOrdersData());
  const loading = useAppSelector(selectOrdersLoading());
  const page = useAppSelector(selectOrdersPage());
  const limit = useAppSelector(selectOrdersLimit());
  const count = useAppSelector(selectOrdersCount());
  const search = useAppSelector(selectOrdersSearch());

  const columns: ColumnsType<IOrder> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Surname',
      dataIndex: 'surname',
      key: 'surname'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text: string) => {
        return (
          <>
            <a href={`mailto:${text}`} rel="noreferrer">
              {text}
            </a>
          </>
        );
      }
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'Created_at',
      dataIndex: 'createdAt',
      key: 'created_at',
      render: text => {
        return (
          <>
            <Typography.Text style={{ marginRight: 10 }}>{new Date(text).toLocaleDateString('en-GB')}</Typography.Text>
            <Typography.Text>
              {new Date(text).toLocaleTimeString('it-IT', {
                hour: '2-digit',
                minute: '2-digit'
              })}
            </Typography.Text>
          </>
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: id => {
        return <Typography.Text style={id === 5 ? { color: 'red' } : id === 4 ? { color: 'green' } : {}}>{statusFilter(id)}</Typography.Text>;
      }
    },
    {
      title: '',
      dataIndex: 'order_content',
      key: 'order_content',
      width: 50,
      render: (_, record) => {
        return (
          <Link to={`details/${record.id}`}>
            <Button style={{ width: 60 }} icon={<ExportOutlined style={{ fontSize: 18, marginTop: 2 }} />}></Button>
          </Link>
        );
      }
    }
  ];

  useEffect(() => {
    debounce(() => dispatch(ordersApi()));
  }, [dispatch, search, page, limit]);

  return (
    <>
      <ContentTop
        title="Orders"
        placeholder="Search Order"
        onChange={e => {
          dispatch(setSearch(e.target.value));
        }}
        searchComponent
        search={search}
      />
      <Table
        bordered
        columns={columns}
        dataSource={orderData || []}
        loading={loading}
        rowKey="id"
        pagination={{
          current: page,
          onChange: (page, pageSize) => {
            dispatch(setPage(page));
            dispatch(setLimit(pageSize));
          },
          pageSize: limit,
          total: count
        }}
      />
    </>
  );
};

export default Orders;
