import { Modal, Button, Input, Form, Row, Col, Select } from 'antd';
import { FC, memo, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getCategoryById, getDiscountAmountsByType, postDiscountByCategory } from 'app/features/category/slice';
import { PostCategoryDiscountValues } from 'app/features/category/types';
import { selectDiscountAmountByType, selectDiscountByCategory } from 'app/features/category/selectors';
import { PostDiscountValues } from 'app/features/discounts/types';
import { transformArray } from 'helpers/discount';

interface IProductDiscountModalProps {
  editValue: [boolean, string];
  clearEditValue: () => void;
  isOpen: boolean;
  onClose: () => void;
  categoryId?: number | undefined;
}

const CategoryDiscountModal: FC<IProductDiscountModalProps> = ({ editValue, clearEditValue, isOpen, onClose, categoryId }) => {
  const accountTypes = [
    {
      title: 'BASIC'
    },
    {
      title: 'SILVER'
    },
    {
      title: 'GOLD'
    },
    {
      title: 'PREMIUM'
    }
  ];

  const discountByCategory = useAppSelector(selectDiscountByCategory());
  const discountAmountByType = useAppSelector(selectDiscountAmountByType());
  const [amounts, setAmounts] = useState<PostDiscountValues[]>([]);
  const selectedDiscount = discountByCategory.filter(item => item.name === editValue[1])[0];

  const [selectedAccType, setSelectedAccType] = useState<string>('');
  useEffect(() => {
    if (discountAmountByType) {
      setAmounts(transformArray(discountAmountByType));
    }
  }, [discountAmountByType]);
  useEffect(() => {
    if (selectedAccType === 'BASIC') {
      form.setFieldsValue({ percent1: 0 });
    }
  }, [selectedAccType]);
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    if (selectedDiscount && editValue[1]) {
      form.setFieldsValue({
        percent1: selectedDiscount.percent1,
        percent2: selectedDiscount.percent2,
        percent3: selectedDiscount.percent3,
        percent4: selectedDiscount.percent4
      });
    }

    if (editValue[1]) {
      dispatch(getDiscountAmountsByType(editValue[1]));
    }

    if (!isOpen) {
      form.resetFields();
      setSelectedAccType('');
      clearEditValue();
    }
  }, [isOpen]);

  useEffect(() => {
    if (amounts && amounts.length > 0) {
      form.setFieldsValue({
        amount_min1: amounts[0].amount_min1,
        amount_min2: amounts[0].amount_min2,
        amount_min3: amounts[0].amount_min3,
        amount_min4: amounts[0].amount_min4,
        amount_max1: amounts[0].amount_max1,
        amount_max2: amounts[0].amount_max2,
        amount_max3: amounts[0].amount_max3,
        amount_max4: amounts[0].amount_max4
      });
    }
  }, [amounts]);

  useEffect(() => {
    if (!editValue[1] && selectedAccType.length > 0) {
      dispatch(getDiscountAmountsByType(selectedAccType));
    }
  }, [selectedAccType]);

  const onSave = async (values: PostCategoryDiscountValues) => {
    const data = {
      name: editValue[1] ? editValue[1] : selectedAccType,
      percent1: +values.percent1,
      percent2: +values.percent2,
      percent3: +values.percent3,
      percent4: +values.percent4,
      category_id: categoryId ? +categoryId : 0
    };

    if (categoryId) {
      const res = (await dispatch(postDiscountByCategory(data))) as {
        meta: { requestStatus: string };
        payload: { message: string };
      };

      if (res.meta.requestStatus === 'rejected' && res.payload.message === 'Request failed with status code 409') {
        form.setFields([
          {
            name: 'key',
            errors: ['Such a key already exists. Please enter a different key.']
          }
        ]);
      } else {
        handleCloseModal();
        dispatch(getCategoryById(categoryId));
      }
    }
  };

  return (
    <Modal
      width={900}
      title={editValue[1] ? `Edit Discount: ${editValue[1]}` : `Add Discount`}
      open={isOpen}
      onCancel={handleCloseModal}
      footer={[
        <Button onClick={handleCloseModal} key="Cancel">
          Cancel
        </Button>,
        <Button type="primary" onClick={form.submit} key="Save">
          Save
        </Button>
      ]}
    >
      <Form
        scrollToFirstError={{
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        }}
        form={form}
        onFinish={onSave}
        layout="vertical"
        size="large"
      >
        <br />
        <br />
        {!editValue[1] && (
          <Form.Item label="Account Type" name="accType" rules={[{ required: true }]}>
            <Select
              value={selectedAccType.length > 0 ? selectedAccType : null}
              placeholder="Select Account Type"
              style={{ width: '200px' }}
              onChange={(e: any) => setSelectedAccType(e)}
              options={accountTypes.map(item => {
                return {
                  value: item.title,
                  label: item.title
                };
              })}
            />
          </Form.Item>
        )}
        <Row style={{ borderBottom: '1px solid lightgray' }} gutter={[24, 24]}>
          <Col span={8}>
            <Form.Item name="amount_min1" label="Min Price 1">
              <Input type="number" disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="amount_max1" label="Max Price 1">
              <Input disabled type="number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="percent1" label="Percent 1" rules={[{ required: true }]}>
              <Input disabled={editValue[1] === 'BASIC' || selectedAccType === 'BASIC'} placeholder="Enter Percent 1" type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ borderBottom: '1px solid lightgray' }} gutter={[24, 24]}>
          <Col span={8}>
            <Form.Item name="amount_min2" label="Min Price 2">
              <Input disabled type="number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="amount_max2" label="Max Price 2">
              <Input disabled type="number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="percent2" label="Percent 2" rules={[{ required: true }]}>
              <Input placeholder="Enter Percent 2" type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ borderBottom: '1px solid lightgray' }} gutter={[24, 24]}>
          <Col span={8}>
            <Form.Item name="amount_min3" label="Min Price 3">
              <Input disabled type="number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="amount_max3" label="Max Price 3">
              <Input disabled type="number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="percent3" label="Percent 3" rules={[{ required: true }]}>
              <Input placeholder="Enter Percent 3" type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={8}>
            <Form.Item name="amount_min4" label="Min Price 4">
              <Input disabled type="number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="amount_max4" label="Max Price 4">
              <Input disabled type="number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="percent4" label="Percent 4" rules={[{ required: true }]}>
              <Input placeholder="Enter Percent 4" type="number" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default memo(CategoryDiscountModal);
