import { FC, useEffect } from 'react';
import { Image, Modal, Table } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectProductCount, selectProductData, selectProductLoading, selectProductPage, selectProductSearch } from 'app/features/products/selectors';
import { productApi, setPage, setSearch } from 'app/features/products/slice';
import { getFileUrl } from 'helpers/file';
import { ColumnsType } from 'antd/es/table';
import { IProduct } from 'app/features/products/types';

import { ButtonsWrapper, FalseIcon, IconWrapper, ImgTableWrapper, TrueIcon } from 'pages/Products/styled';
import { IProductsModal } from './types';
import noImg from '../../assets/img/noimage.png';
import { AddButton, SearchStyled } from 'components/ContentTop/styled';
import { Checkbox, ScrollContainer } from './styled';
import { SearchContainer } from 'pages/ProductDetails/components/ProductPrices/styled';
import { selectSelectedCurrency } from '../../app/features/currency/selectedcurrencyslice';

const ProductsModal: FC<IProductsModal> = ({ onCancel, visible, setSelected, selected, serviceByID }) => {
  const dispatch = useAppDispatch();

  const loading = useAppSelector(selectProductLoading());
  const page = useAppSelector(selectProductPage());
  const productsData = useAppSelector(selectProductData());
  const count = useAppSelector(selectProductCount());
  const search = useAppSelector(selectProductSearch());

  const selectedCurrencyCode = useAppSelector(selectSelectedCurrency);

  const checkboxEvent = (record: IProduct) => {
    if (!selected.includes(record.id)) {
      setSelected(prev => [...prev, record.id]);
    } else {
      const filteredArr = selected.filter(id => id !== record.id);
      setSelected(filteredArr);
    }
  };

  const columns: ColumnsType<IProduct> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Articul number',
      dataIndex: 'articul_number',
      key: 'articul_number'
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price'
    },
    {
      title: 'Availability',
      dataIndex: 'availability',
      width: 40,
      align: 'center',
      key: 'availability',
      render: value => {
        const color = value ? 'green' : 'red';
        const icon = value ? <TrueIcon style={{ fontSize: 20 }} /> : <FalseIcon style={{ fontSize: 20 }} />;

        return <IconWrapper style={{ width: 40, height: '100%' }} icon={icon} key={value} color={color} />;
      }
    },
    {
      title: 'Image',
      dataIndex: 'Files',
      key: 'image',
      width: 100,
      render: (_, item) => {
        return (
          <ImgTableWrapper>
            {item.files?.length ? (
              <Image height={55} width={70} alt={item.files[0].name} src={getFileUrl(item.files[0]?.name, item.files[0]?.extension, 'product')} />
            ) : (
              <Image preview={false} width={70} src={noImg} alt="No Image" />
            )}
          </ImgTableWrapper>
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 60,
      key: 'Action',
      render: (_, record: any) => {
        return (
          <ButtonsWrapper>
            <Checkbox type="checkbox" checked={selected.includes(record.id)} onChange={() => checkboxEvent(record)} />
          </ButtonsWrapper>
        );
      }
    }
  ];

  useEffect(() => {
    if (visible) {
      dispatch(productApi(selectedCurrencyCode));

      if (serviceByID) {
        serviceByID.products.map((item: IProduct) => {
          setSelected(prev => [...prev, item.id]);
        });
      }
    } else {
      dispatch(setPage(1));
      dispatch(setSearch(''));
    }
  }, [visible, page, search]);

  return (
    <Modal width={700} open={visible} onCancel={onCancel} footer={null}>
      <SearchContainer>
        <SearchStyled
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(setSearch(e.target.value));
          }}
          placeholder="Search Product"
          enterButton
          size="large"
        />
      </SearchContainer>
      <ScrollContainer>
        <Table
          style={{ height: '700px' }}
          loading={loading}
          pagination={{
            current: page,
            onChange: page => {
              dispatch(setPage(page));
            },
            pageSize: 10,
            total: count
          }}
          bordered
          columns={columns}
          dataSource={productsData}
          rowKey="id"
        />
      </ScrollContainer>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '20px'
        }}
      >
        <AddButton size="large" onClick={() => onCancel()} type="primary">
          Done
        </AddButton>
      </div>
    </Modal>
  );
};

export default ProductsModal;
