import { Col, Tabs } from 'antd';
import styled from 'styled-components';

export const TabsStyled = styled(Tabs)`
  .ant-tabs-tab {
    min-width: 165px;
    display: flex;
    justify-content: center;
  }
  .ant-tabs-tab-active {
    min-width: 165px;
    display: flex;
    justify-content: center;
  }
`;

export const CollapseStyled = styled.div`
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: 100%;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  min-height: 500px;
`;

export const QuestionWrraper = styled(Col)`
  &&&&&& {
    height: 100%;
    min-height: 500px;
    padding: 18px 24px;
    overflow: auto;
    border-radius: 5px;
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  }
`;

export const QuestionItemStyled = styled.div<{ active: boolean }>`
  padding: 18px 24px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  border-radius: 5px;
  margin-bottom: 12px;
  background: ${props => (props.active ? '#ffffff' : 'rgba(0, 0, 0, 0.02)')};
  color: ${props => (props.active ? '#1677ff' : 'rgba(0, 0, 0, 0.88)')};
  box-shadow: ${props =>
    props.active
      ? '0 1px 2px -2px rgba(0, 0, 0, 0.16),0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)'
      : '1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0,1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset'};
  transition: all 0.2s;
  cursor: pointer;
  &:hover {
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
    color: #000000;
    color: ${props => (props.active ? '#1677ff' : '#000000')};
    background: #ffffff;

    text-shadow: 0 0 0.25px currentcolor;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const QuestionItemAnswer = styled.div`
  padding: 18px 24px;
  border-radius: 5px;
  font-size: 16px;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  transition: all 0.2s;
  overflow: auto;
  height: 500px;
  width: 100%;
  position: sticky;
  top: 90px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 50px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #a0a0a0;
    border-radius: 50px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #7a7a7a;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const QuestionText = styled.div`
  width: calc(100% - 92px);
  border-right: 1px solid rgba(5, 5, 5, 0.06);
`;
