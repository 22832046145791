import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api/apiClient';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';

import { selectContactLimit, selectContactPage, selectContactSearch } from './selectors';
import { ContactState, IContactById } from './type';

const initialState: ContactState = {
  contact: [],
  loading: false,
  loadingAction: false,
  loadingByID: false,
  search: '',
  page: 1,
  limit: 10,
  count: 0,
  contactById: null
};

export const contactApi = createAsyncThunk('contact/contactApi', async (_, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState;
    const search = selectContactSearch()(state);
    const page = selectContactPage()(state);
    const limit = selectContactLimit()(state);
    const res = await client.get('/contact', {
      params: {
        search,
        page,
        limit
      }
    });

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const getContactById = createAsyncThunk('contact/getContactById', async (id: number, { rejectWithValue }) => {
  try {
    const response = await client.get(`/contact/contact/${id}`);

    return response.data.data;
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const deleteContact = createAsyncThunk('contact/deleteContact', async (id: number, { dispatch, rejectWithValue }) => {
  try {
    await client.delete(`/contact/${id}`);
    await dispatch(contactApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const postContact = createAsyncThunk('contact/postContact', async ({ ...data }: IContactById, { dispatch, rejectWithValue }) => {
  try {
    await client.post('/contact', {
      ...data
    });
    await dispatch(contactApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const contactEdit = createAsyncThunk('contact/contactEdit', async ({ id, ...data }: IContactById, { dispatch, rejectWithValue }) => {
  try {
    await client.patch(`/contact/${id}`, {
      ...data
    });

    await dispatch(contactApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    clickMenuContact: state => {
      (state.limit = 10), (state.page = 1), (state.search = '');
    }
  },
  extraReducers(builder) {
    builder
      .addCase(contactApi.pending, state => {
        state.loading = true;
      })
      .addCase(contactApi.fulfilled, (state, action) => {
        state.loading = false;
        state.contact = action.payload.rows;
        state.count = action.payload.count;
      })
      .addCase(contactApi.rejected, state => {
        state.loading = false;
      })
      .addCase(deleteContact.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteContact.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteContact.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(getContactById.pending, state => {
        state.loadingByID = true;
      })
      .addCase(getContactById.fulfilled, (state, action) => {
        state.contactById = action.payload;
        state.loadingByID = false;
      })
      .addCase(getContactById.rejected, state => {
        state.loadingByID = false;
      })
      .addCase(postContact.pending, state => {
        state.loadingAction = true;
      })
      .addCase(postContact.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(postContact.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(contactEdit.pending, state => {
        state.loadingAction = true;
      })
      .addCase(contactEdit.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(contactEdit.rejected, state => {
        state.loadingAction = false;
      });
  }
});

export const { setPage, setSearch, setLimit, clickMenuContact } = contactSlice.actions;
export default contactSlice.reducer;
