import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api/apiClient';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';

import { SliderState } from './types';

const initialState: SliderState = {
  loading: false,
  loadingAction: false,
  images: []
};

export const sliderApi = createAsyncThunk('slider/sliderApi', async (_, { rejectWithValue }) => {
  try {
    const res = await client.get('/slider');

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const deleteSliderImage = createAsyncThunk('slider/deleteSliderImage', async (id: number, { dispatch, rejectWithValue }) => {
  try {
    await client.delete(`/slider/${id}`);
    await dispatch(sliderApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

const sliderSlice = createSlice({
  name: 'slider',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(sliderApi.pending, state => {
        state.loading = true;
      })
      .addCase(sliderApi.fulfilled, (state, action) => {
        state.loading = false;
        state.images = action.payload;
      })
      .addCase(sliderApi.rejected, state => {
        state.loading = false;
      })
      .addCase(deleteSliderImage.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteSliderImage.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteSliderImage.rejected, state => {
        state.loadingAction = false;
      });
  }
});

export default sliderSlice.reducer;
