import { selectBlogCount, selectBlogData, selectBlogLimit, selectBlogLoading, selectBlogLoadingAction, selectBlogPage, selectBlogSearch } from 'app/features/blog/selectors';
import { ColumnsType } from 'antd/lib/table';
import { blogApi, deleteBlog, setLimit, setPage, setSearch } from 'app/features/blog/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { _debounce } from 'helpers/debounce';
import { Button, Image, Table } from 'antd';
import { IBlog } from 'app/features/blog/types';
import { DeleteOutlined, EditTwoTone } from '@ant-design/icons';
import { getFileUrl } from 'helpers/file';

import { ButtonsWrapper, ImgTableWrraper, Text } from './styled';
import ContentTop from 'components/ContentTop';
import DeleteModal from 'components/DeleteModal';
import noImg from '../../assets/img/noimage.png';

const debounce = _debounce();

const Blog = () => {
  // state
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [blogId, setBlogId] = useState<number>(0);

  // dispatch
  const dispatch = useAppDispatch();

  // navigate
  const navigate = useNavigate();

  // selectors
  const blogData = useAppSelector(selectBlogData());
  const loading = useAppSelector(selectBlogLoading());
  const page = useAppSelector(selectBlogPage());
  const limit = useAppSelector(selectBlogLimit());
  const count = useAppSelector(selectBlogCount());
  const search = useAppSelector(selectBlogSearch());
  const loadingAction = useAppSelector(selectBlogLoadingAction());

  useEffect(() => {
    debounce(() => dispatch(blogApi()));
  }, [dispatch, search, page, limit]);

  const columns: ColumnsType<IBlog> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text: string) => {
        return <Text>{text}</Text>;
      }
    },
    {
      title: 'Image',
      dataIndex: 'Files',
      key: 'image',
      width: 100,
      render: (_, item) => {
        return (
          <ImgTableWrraper>
            {item.files?.length ? (
              <Image height={55} width={70} alt={item.files[0].name} src={getFileUrl(item.files[0]?.name, item.files[0]?.extension, '')} />
            ) : (
              <Image preview={false} width={70} src={noImg} alt="No Image" />
            )}
          </ImgTableWrraper>
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 220,
      key: 'Action',
      render: (_, record) => {
        return (
          <ButtonsWrapper>
            <Link to={`details/${record.id}`}>
              <Button icon={<EditTwoTone />}>Edit</Button>
            </Link>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                setBlogId(record.id);
                setOpenDeleteModal(true);
              }}
            >
              Delete
            </Button>
          </ButtonsWrapper>
        );
      }
    }
  ];

  return (
    <>
      <ContentTop
        title="Blog"
        placeholder="Search Blog"
        buttonText="Add Blog"
        onClickAdd={() => navigate('details')}
        onChange={e => {
          dispatch(setSearch(e.target.value));
        }}
        searchComponent
        search={search}
        add
      />
      <Table
        bordered
        columns={columns}
        dataSource={blogData}
        loading={loading}
        rowKey="id"
        pagination={{
          current: page,
          onChange: (page, pageSize) => {
            dispatch(setPage(page));
            dispatch(setLimit(pageSize));
          },
          pageSize: limit,
          total: count
        }}
      />
      <DeleteModal
        open={openDeleteModal}
        confirmLoading={loadingAction}
        onOk={() => {
          dispatch(deleteBlog(blogId)).then(() => {
            setOpenDeleteModal(false);
            setBlogId(0);
          });
        }}
        onCancel={() => {
          setOpenDeleteModal(false);
          setBlogId(0);
        }}
      />
    </>
  );
};

export default Blog;
