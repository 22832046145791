import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import authSlice from './features/auth/slice';
import categorySlice from './features/category/slice';
import productsSlice from './features/products/slice';
import usersSlice from './features/users/slice';
import countrySlice from './features/country/slice';
import partnerSlice from './features/partner/slice';
import attributeSlice from './features/atributes/slice';
import sectionSlice from './features/sections/slice';
import customPageSlice from './features/customPage/slice';
import blogSlice from './features/blog/slice';
import specialistSlice from './features/specialist/slice';
import ordersSlice from './features/order/slice';
import contactSlice from './features/contact/slice';
import sliderSlice from './features/slider/slice';
import educationalSlice from './features/educational/slice';
import imagesSlice from './features/images/slice';
import questionsSlice from './features/questions/slice';
import servicesSlice from './features/services/slice';
import discountSlice from './features/discounts/slice';
import rolesSlice from './features/roles/slice';
import adminsSlice from './features/admins/slice';
import currencySlice from './features/currency/slice';
import selectedcurrencyslice from './features/currency/selectedcurrencyslice';
export const store = configureStore({
  reducer: {
    auth: authSlice,
    category: categorySlice,
    users: usersSlice,
    products: productsSlice,
    country: countrySlice,
    partner: partnerSlice,
    attribute: attributeSlice,
    section: sectionSlice,
    customPage: customPageSlice,
    blog: blogSlice,
    specialist: specialistSlice,
    orders: ordersSlice,
    contact: contactSlice,
    slider: sliderSlice,
    educational: educationalSlice,
    images: imagesSlice,
    question: questionsSlice,
    services: servicesSlice,
    discounts: discountSlice,
    roles: rolesSlice,
    admins: adminsSlice,
    currency: currencySlice,
    selectedcurrencyslice: selectedcurrencyslice
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
