import { useAppDispatch } from 'app/hooks';
import { iso1A2Code } from '@rapideditor/country-coder';
import MuiPhoneNumber from 'material-ui-phone-number-2';
import { Dispatch, FC, useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { postUserOrderAddress } from 'app/features/users/slice';
import { getCountryData } from 'assets/countries';

import { IOrderAddressPostValues } from 'pages/Users/UserDetails/types';
import { SignFooter, StyledCountrySelect, StyledForm, StyledMobileNumber, StyledModal, SubForm } from 'pages/Users/UserDetails/styled';
import Input from 'components/Input';

interface IModalProps {
  open: boolean;
  handleModal: Dispatch<React.SetStateAction<boolean>>;
}

const AddressesModal: FC<IModalProps> = ({ open, handleModal }) => {
  const countries = getCountryData();
  const [phone_number, setPhoneNumber] = useState<string>('');
  const modalRef = useRef<HTMLDivElement>(null);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [localCode, setLocalCode] = useState('am');
  const [location, setLocation] = useState<number[]>([]);
  const [first_name, setFirst_name] = useState<string | null>(null);
  const [last_name, setLast_name] = useState<string | null>(null);
  const [country, setCountry] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [address, setAddress] = useState<string | null>(null);
  const [zip_code, setZip_code] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [disabledBtn, setDisableBtn] = useState(true);

  useEffect(() => {
    if (first_name && last_name && country && city && address && zip_code && email && phone_number.length > 0) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [first_name, last_name, country, city, address, zip_code, email, phone_number]);

  useEffect(() => {
    if (location.length > 0) {
      const local = iso1A2Code([location[1], location[0]]);

      if (local) {
        setLocalCode(local.toLowerCase());
      }
    } else {
      setLocalCode('us');
    }
  }, [location]);

  function resetForm() {
    setFirst_name(null);
    setLast_name(null);
    setCountry(null);
    setCity(null);
    setAddress(null);
    setZip_code(null);
    setEmail(null);
  }

  useEffect(() => {
    window.navigator.geolocation.getCurrentPosition(e => setLocation([e.coords.latitude, e.coords.longitude]));

    return () => {
      resetForm();
    };
  }, [open]);

  function Submit(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    if (id) {
      const data: IOrderAddressPostValues = {
        phone_number,
        user_id: id,
        first_name,
        last_name,
        address,
        country,
        city,
        email,
        zip_code
      };

      dispatch(postUserOrderAddress({ id, data }));
      handleModal(false);
      resetForm();
    }
  }

  return (
    <StyledModal ref={modalRef} active={open}>
      <div className="modalContent">
        <div className="title" style={{ marginBottom: '55px' }}>
          <span>Add Address</span>
          <div className="closeIcon" onClick={() => handleModal(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
              <circle cx="16.5" cy="16.5" r="16" stroke="#19293B" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
              <g clipPath="url(#clip0_778_27332)">
                <path
                  d="M6.22656 7.001L0.160172 0.934579C-0.0534105 0.720998 -0.0534105 0.374717 0.160172 0.161163C0.373754 -0.0523915 0.720035 -0.0524188 0.933589 0.161163L7.00001 6.22758L13.0664 0.161163C13.28 -0.0524188 13.6263 -0.0524188 13.8398 0.161163C14.0534 0.374744 14.0534 0.721025 13.8398 0.934579L7.77342 7.00097L13.8398 13.0674C14.0534 13.281 14.0534 13.6273 13.8398 13.8408C13.733 13.9476 13.5931 14.001 13.4531 14.001C13.3131 14.001 13.1732 13.9476 13.0664 13.8408L7.00001 7.77441L0.933616 13.8408C0.826838 13.9476 0.686867 14.001 0.546894 14.001C0.406921 14.001 0.266976 13.9476 0.160172 13.8408C-0.0534105 13.6272 -0.0534105 13.2809 0.160172 13.0674L6.22656 7.001Z"
                  fill="#19293B"
                />
              </g>
              <defs>
                <clipPath id="clip0_778_27332">
                  <rect width="14" height="14" fill="white" transform="matrix(-1 0 0 1 14 0)" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <StyledForm style={{ gap: 0 }}>
          <SubForm>
            <Input value={first_name ?? ''} onChange={e => setFirst_name(e.target.value)} name="first_name" label="First Name" />
            <Input value={last_name ?? ''} onChange={e => setLast_name(e.target.value)} name="last_name" label="Last Name" />
            {!!countries?.length && (
              <div>
                <StyledCountrySelect open={!!country} value={String(country)} className="countrySelect">
                  <select onChange={e => setCountry(e.target.value)} id="country" value={String(country)} style={{ height: 45 }}>
                    <option value="" defaultChecked disabled>
                      Choose
                    </option>
                    {countries?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="country">Country</label>
                </StyledCountrySelect>
              </div>
            )}
            <Input value={city ?? ''} onChange={e => setCity(e.target.value)} name="city" type="text" label="City" />
            <Input value={address ?? ''} onChange={e => setAddress(e.target.value)} name="address" type="text" label="Address (Street. ap.)" />
            <Input value={zip_code ?? ''} onChange={e => setZip_code(e.target.value)} name="zip_code" type="number" label="Zip Code" />
            <StyledMobileNumber>
              <MuiPhoneNumber
                disableAreaCodes={true}
                className="mobileNumberSelect"
                sx={{ '& svg': { height: '1em' } }}
                value={phone_number}
                inputClass="phoneNumberInput"
                defaultCountry={localCode}
                variant="outlined"
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => setPhoneNumber(e.toString())}
              />
            </StyledMobileNumber>
            <Input value={email ?? ''} onChange={e => setEmail(e.target.value)} name="email" type="email" label="E-mail address" />
          </SubForm>
          <SignFooter>
            <button style={{ cursor: 'pointer' }} disabled={disabledBtn} onClick={Submit}>
              Save
            </button>
          </SignFooter>
        </StyledForm>
      </div>
    </StyledModal>
  );
};

export default AddressesModal;
