import { attributesEdit, getAttributeById, postAttributes, setAtributeById } from 'app/features/atributes/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { selectAttributeById, selectAttributeLoadingAction, selectAttributeLoadingById } from 'app/features/atributes/selectors';
import { Button, Col, Form, Input, InputNumber, Row, Select, Switch, TreeSelect } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { IAttributeEditValues, IAttributePostValues, IAttributeValues, IAttributes } from 'app/features/atributes/types';
import { categoryProductApi } from 'app/features/category/slice';
import { flattenCategories } from 'helpers/product';

import DetailsHead from 'components/DetailsHead';
import Loading from 'components/Loading';

const validateMessages = {
  required: "'${label}' is Required!"
};

const AttributesDetails = () => {
  //states
  const [isChecked, setIsChecked] = useState(true);
  //form
  const [form] = Form.useForm();

  const attributeType: IAttributes['type'] = Form.useWatch('type', form);
  const attributeUnit: string = Form.useWatch('unit_en', form);

  const { id } = useParams();
  const navigate = useNavigate();
  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  const attributeById = useAppSelector(selectAttributeById());
  const loadingById = useAppSelector(selectAttributeLoadingById());
  const loadingAction = useAppSelector(selectAttributeLoadingAction());

  useEffect(() => {
    dispatch(categoryProductApi());

    if (id) {
      dispatch(getAttributeById(Number(id)));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id && attributeById) {
      const data = {
        description_en: attributeById.description_en,
        categories: attributeById.categories.map((item: any) => ({
          label: item.title,
          value: item.id
        })),
        unit_en: attributeById.unit_en,
        // description_ru: attributeById.description_ru,
        // unit_ru: attributeById.unit_ru,
        // description_hy: attributeById.description_hy,
        // unit_hy: attributeById.unit_hy,
        filter: attributeById.filter,
        key: attributeById.key,
        type: attributeById.type
      };

      setIsChecked(attributeById.filter);

      if (attributeById.type === 'number') {
        form.setFieldsValue({
          ...data,
          min: attributeById.values_en.min,
          max: attributeById.values_en.max
        });
      } else {
        // if (attributeById.values_ru && attributeById.values_hy) {
        //   const value_ru = attributeById.values_ru.values.map(e => e);
        //   const value_hy = attributeById.values_hy.values.map(e => e);
        //   form.setFieldsValue({
        //     ...data,
        //     values: attributeById.values_en.values.map((e, index) => ({
        //       value_en: e,
        //       value_ru: value_ru[index],
        //       value_hy: value_hy[index]
        //     }))
        //   });
        // } else {
        form.setFieldsValue({
          ...data,
          values: attributeById.values_en.values.map((e: any) => ({
            value_en: e
          }))
        });
        // }
      }
    }
  }, [attributeById, form, id]);

  const onClose = () => {
    form.resetFields();
    dispatch(setAtributeById(null));
    navigate(-1);
  };

  const onSave = async (data: IAttributeValues) => {
    const payload = {
      description_en: data.description_en,
      unit_en: data.unit_en,
      // description_ru: data.description_ru,
      // unit_ru: data.unit_ru,
      // description_hy: data.description_hy,
      // unit_hy: data.unit_hy,
      filter: isChecked,
      type: data.type,
      key: data.key,
      categories: data.categories?.map(item => Number(item.value)),
      values_en:
        data.type === 'enum'
          ? {
              values: form
                .getFieldValue('values')
                .map((item: { value_en: string }) => {
                  return {
                    value: item.value_en
                  };
                })
                .map((item: { value: string }) => item.value) as string[]
            }
          : {
              min: `${form.getFieldValue('min')}`,
              max: `${form.getFieldValue('max')}`
            }
      //       ,
      // values_ru:
      //   data.type === 'enum'
      //     ? {
      //         values: form
      //           .getFieldValue('values')
      //           .map((item: { value_ru: string }) => {
      //             return {
      //               value: item.value_ru
      //             };
      //           })
      //           .map((item: { value: string }) => item.value) as string[]
      //       }
      //     : {
      //         min: `${form.getFieldValue('min')}`,
      //         max: `${form.getFieldValue('max')}`
      //       },
      // values_hy:
      //   data.type === 'enum'
      //     ? {
      //         values: form
      //           .getFieldValue('values')
      //           .map((item: { value_hy: string }) => {
      //             return {
      //               value: item.value_hy
      //             };
      //           })
      //           .map((item: { value: string }) => item.value) as string[]
      //       }
      //     : {
      //         min: `${form.getFieldValue('min')}`,
      //         max: `${form.getFieldValue('max')}`
      //       }
    };

    if (attributeById) {
      await dispatch(attributesEdit({ id: Number(id), ...payload } as IAttributeEditValues)).then(() => {
        onClose();
      });
    } else {
      const res = (await dispatch(postAttributes({ ...payload } as IAttributePostValues))) as { meta: { requestStatus: string }; payload: { message: string } };

      if (res.meta.requestStatus === 'rejected' && res.payload.message === 'Request failed with status code 409') {
        form.setFields([
          {
            name: 'key',
            errors: ['Such a key already exists. Please enter a different key.']
          }
        ]);
      } else {
        onClose();
      }
    }
  };

  return (
    <>
      {loadingById ? (
        <Loading size="large" />
      ) : (
        <>
          <Form
            validateMessages={validateMessages}
            size="large"
            form={form}
            layout="vertical"
            onFinish={onSave}
            scrollToFirstError={{
              behavior: 'smooth',
              block: 'center',
              inline: 'center'
            }}
          >
            <DetailsHead titleItem={`Key ${attributeById?.key}`} onClose={onClose} id={id} form={form} loadingAction={loadingAction} headTitle={'Attribute'} />
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <Form.Item name="filter" label="Filter">
                  <Switch checked={isChecked} onChange={e => setIsChecked(e)} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="key" label="Key" rules={[{ required: true }]}>
                  <InputNumber disabled={id ? true : false} style={{ width: '100%' }} placeholder="Enter attribute key" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Category" name="categories" rules={[{ required: true }]}>
                  <TreeSelect treeData={flattenCategories(attributeById?.categories || [])} placeholder="Please select Category" multiple showSearch={false} treeCheckable treeCheckStrictly />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="description_en" label="English Description" rules={[{ required: true }]}>
                  <Input placeholder="Enter description in English" />
                </Form.Item>
              </Col>
              {/* <Col span={24}>
                <Form.Item
                  name="description_ru"
                  label="Russian Description"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Enter description in Russian" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="description_hy"
                  label="Armenian Description"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Enter description in Armenian" />
                </Form.Item>
              </Col> */}
              <Col span={12}>
                <Form.Item rules={[{ required: true }]} name="type" label="Type">
                  <Select
                    placeholder="Please input a Type"
                    options={[
                      {
                        value: 'enum',
                        label: 'Enum'
                      },
                      {
                        value: 'number',
                        label: 'Number'
                      }
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="unit_en" label="English Unit">
                  <Input placeholder="Enter unit in English" />
                </Form.Item>
              </Col>
              {/* <Col span={8}>
                <Form.Item name="unit_ru" label="Russian Unit">
                  <Input placeholder="Enter unit in Russian" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="unit_hy" label="Armenian Unit">
                  <Input placeholder="Enter unit in Armenian" />
                </Form.Item>
              </Col> */}
              {attributeType ? (
                attributeType === 'number' ? (
                  <>
                    <Col span={12}>
                      <Form.Item name="min" rules={[{ required: true }]} label="Min Value">
                        <InputNumber placeholder="Please input a Min Value" style={{ width: '100%' }} addonBefore={attributeUnit} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="max" rules={[{ required: true }]} label="Max Value">
                        <InputNumber placeholder="Please input a Max Value" style={{ width: '100%' }} addonBefore={attributeUnit} />
                      </Form.Item>
                    </Col>
                  </>
                ) : (
                  <Col span={24}>
                    <Row gutter={[24, 24]}>
                      <Form.List
                        initialValue={[
                          { value_en: '' }
                          // , value_ru: '', value_hy: ''
                        ]}
                        name="values"
                      >
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name }, index, arr) => (
                              <Col key={key} span={8}>
                                <Form.Item rules={[{ required: true }]} label="English Attribute Value" style={{ marginBottom: 0 }} name={[name, 'value_en']}>
                                  <Input addonBefore={attributeUnit} placeholder="Enter Attribute Value in English" />
                                </Form.Item>
                                <Row style={{ marginTop: '8px' }} gutter={[12, 0]}>
                                  <Col span={12}>
                                    {arr.length > 1 && (
                                      <Button
                                        size="small"
                                        danger
                                        block
                                        onClick={() => {
                                          remove(name);
                                        }}
                                      >
                                        <MinusCircleOutlined />
                                      </Button>
                                    )}
                                  </Col>
                                  <Col span={12}>
                                    {index === arr.length - 1 && (
                                      <Button
                                        size="small"
                                        block
                                        onClick={() => {
                                          add();
                                        }}
                                      >
                                        <PlusOutlined />
                                      </Button>
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                              //   <Col span={8}>
                              //   <Form.Item
                              //     rules={[{ required: true }]}
                              //     label="Russian Attribute Value"
                              //     style={{ marginBottom: 0 }}
                              //     name={[name, 'value_ru']}
                              //   >
                              //     <Input
                              //       addonBefore={attributeUnit}
                              //       placeholder="Enter Attribute Value in Russian"
                              //     />
                              //   </Form.Item>
                              // </Col>
                              // <Col span={8}>
                              //   <Form.Item
                              //     rules={[{ required: true }]}
                              //     label="Armenian Attribute Value"
                              //     style={{ marginBottom: 0 }}
                              //     name={[name, 'value_hy']}
                              //   >
                              //     <Input
                              //       addonBefore={attributeUnit}
                              //       placeholder="Enter Attribute Value in Armenian"
                              //     />
                              //   </Form.Item>
                              // </Col>
                            ))}
                          </>
                        )}
                      </Form.List>
                    </Row>
                  </Col>
                )
              ) : null}
            </Row>
          </Form>
        </>
      )}
    </>
  );
};

export default AttributesDetails;
