import { Image, Space, Table } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { IProduct } from 'app/features/products/types';
import { selectProductCount, selectProductData, selectProductLoading, selectProductPage, selectProductSearch } from 'app/features/products/selectors';
import { productApi, setPage, setSearch } from 'app/features/products/slice';
import { useNavigate } from 'react-router-dom';
import { _debounce } from 'helpers/debounce';
import { sectionApi } from 'app/features/sections/slice';
import { selectSectionData } from 'app/features/sections/selectors';
import { getFileUrlBIG } from 'helpers/file';

import noImg from '../../../../assets/img/noimage.png';
import { HeaderContainer, HeaderDivider, ImgTableWrraper, LeftPage, PageHeader, PageTitle, SearchStyled } from './styled';
import SectionSwitch from '../SectionSwitch';
import { selectSelectedCurrency } from '../../../../app/features/currency/selectedcurrencyslice';

const debounce = _debounce();

const SectionsAddProduct = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const productData = useAppSelector(selectProductData());
  const page = useAppSelector(selectProductPage());
  const count = useAppSelector(selectProductCount());
  const loading = useAppSelector(selectProductLoading());
  const search = useAppSelector(selectProductSearch());
  const sectionData = useAppSelector(selectSectionData());

  const selectedCurrencyCode = useAppSelector(selectSelectedCurrency);

  useEffect(() => {
    debounce(() => dispatch(productApi(selectedCurrencyCode)));
  }, [dispatch, search, page]);

  useEffect(() => {
    dispatch(sectionApi());
  }, [dispatch]);

  const columns: ColumnsType<IProduct> = [
    {
      title: 'Title',
      dataIndex: 'title_en',
      width: 25,
      key: 'title_en'
    },
    {
      title: 'Articul number',
      dataIndex: 'articul_number',
      width: 25,
      key: 'articul_number'
    },
    {
      title: 'Image',
      dataIndex: 'Files',
      key: 'image',
      width: 25,
      render: (_, item) => {
        return (
          <ImgTableWrraper>
            {item.files?.length ? <Image height={55} width={70} alt={item.files[0].name} src={getFileUrlBIG(item.files[0]?.path)} /> : <Image preview={false} width={70} src={noImg} alt="No Image" />}
          </ImgTableWrraper>
        );
      }
    },
    {
      title: 'Action',
      width: 25,
      children: sectionData.map(section => {
        return {
          title: section.title,
          width: 50,
          dataIndex: section.title,
          key: section.title + '' + section.id,
          render: (_, record) => {
            return <SectionSwitch product_id={record.id} section_id={section.id} productsList={section.products} />;
          }
        };
      })
    }
  ];

  return (
    <>
      <PageHeader>
        <HeaderContainer>
          <Space>
            <LeftPage
              onClick={() => {
                navigate(-1);
              }}
            />
            <PageTitle level={2}>Product</PageTitle>
          </Space>
          <SearchStyled
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(setSearch(e.target.value));
            }}
            placeholder="Search Product"
            enterButton
          />
        </HeaderContainer>
      </PageHeader>
      <HeaderDivider />
      <Table
        columns={columns}
        loading={loading}
        pagination={{
          current: page,
          onChange: page => {
            dispatch(setPage(page));
          },
          pageSize: 10,
          total: count
        }}
        scroll={{ x: 1100 }}
        bordered
        dataSource={productData}
        rowKey="id"
      />
    </>
  );
};

export default SectionsAddProduct;
