import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api/apiClient';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';

import { selectSpecialistLimit, selectSpecialistPage, selectSpecialistSearch } from './selectors';
import { ISpecialistById, SpecialistState } from './types';

const initialState: SpecialistState = {
  loading: false,
  loadingAction: false,
  loadingByID: false,
  search: '',
  page: 1,
  limit: 10,
  count: 0,
  specialist: [],
  specialistById: null
};

export const specialistApi = createAsyncThunk('specialist/specialistApi', async (_, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState;
    const search = selectSpecialistSearch()(state);
    const page = selectSpecialistPage()(state);
    const limit = selectSpecialistLimit()(state);
    const res = await client.get('/specialists', {
      params: {
        search,
        page,
        limit
      }
    });

    return res.data.data;
  } catch (error) {
    return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
  }
});

export const getSpecialistById = createAsyncThunk('specialist/getSpecialistById', async (id: number, { rejectWithValue }) => {
  try {
    const response = await client.get(`/specialists/specialists/${id}`);

    return response.data.data;
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const deleteSpecialist = createAsyncThunk('specialist/deleteSpecialist', async (id: number, { dispatch, rejectWithValue }) => {
  try {
    await client.delete(`/specialists/${id}`);
    await dispatch(specialistApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const postSpecialist = createAsyncThunk('specialist/postSpecialist', async ({ ...data }: ISpecialistById, { dispatch, rejectWithValue }) => {
  try {
    await client.post('/specialists', {
      ...data
    });
    await dispatch(specialistApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});

export const specialistEdit = createAsyncThunk('specialist/specialistEdit', async ({ id, ...data }: ISpecialistById, { dispatch, rejectWithValue }) => {
  try {
    await client.patch(`/specialists/${id}`, {
      ...data
    });

    await dispatch(specialistApi());
  } catch (err) {
    rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
  }
});
const specialistSlice = createSlice({
  name: 'specialist',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    clickMenuSpecialist: state => {
      (state.limit = 10), (state.page = 1), (state.search = '');
    }
  },
  extraReducers(builder) {
    builder
      .addCase(specialistApi.pending, state => {
        state.loading = true;
      })
      .addCase(specialistApi.fulfilled, (state, action) => {
        state.specialist = action.payload.rows;
        state.count = action.payload.count;
        state.loading = false;
      })
      .addCase(specialistApi.rejected, state => {
        state.loading = false;
      })
      .addCase(getSpecialistById.pending, state => {
        state.loadingByID = true;
      })
      .addCase(getSpecialistById.fulfilled, (state, action) => {
        state.specialistById = action.payload;
        state.loadingByID = false;
      })
      .addCase(getSpecialistById.rejected, state => {
        state.loadingByID = false;
      })
      .addCase(postSpecialist.pending, state => {
        state.loadingAction = true;
      })
      .addCase(postSpecialist.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(postSpecialist.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(specialistEdit.pending, state => {
        state.loadingAction = true;
      })
      .addCase(specialistEdit.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(specialistEdit.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(deleteSpecialist.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteSpecialist.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteSpecialist.rejected, state => {
        state.loadingAction = false;
      });
  }
});

export const { setPage, setSearch, setLimit, clickMenuSpecialist } = specialistSlice.actions;
export default specialistSlice.reducer;
