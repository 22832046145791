import { RootState } from 'app/store';

export const selectProductPage = () => (state: RootState) => state.products.page;

export const selectProductData = () => (state: RootState) => state.products.products;

export const selectProductCount = () => (state: RootState) => state.products.count;

export const selectProductLoading = () => (state: RootState) => state.products.loading;

export const selectProductLoadingAction = () => (state: RootState) => state.products.loadingAction;

export const selectProductLoadingByID = () => (state: RootState) => state.products.loadingByID;

export const selectProductDiscounts = () => (state: RootState) => state.products.productDiscounts;

export const selectProductSearch = () => (state: RootState) => state.products.search;

export const selectProductById = () => (state: RootState) => state.products.productById;

export const selectProductStock = () => (state: RootState) => state.products.productStock;
