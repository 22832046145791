import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, EditTwoTone } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { deleteRole, getRoleById, getRoles } from 'app/features/roles/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectAllRoles, selectRolesLoading } from 'app/features/roles/selectors';

import { ButtonsWrapper } from 'pages/Categories/styled';
import { IRole } from './types';
import { StyledRolesPage } from './styled';
import ContentTop from 'components/ContentTop';

const Roles = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectRolesLoading());

  const columns: ColumnsType<IRole> = [
    {
      title: 'Role',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 220,
      key: 'Action',
      render: (_, record) => {
        return (
          <ButtonsWrapper>
            <Link to={`details/${record.id}`}>
              <Button icon={<EditTwoTone />} onClick={() => dispatch(getRoleById(record.id))}>
                Edit
              </Button>
            </Link>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                dispatch(deleteRole(record.id));
              }}
            >
              Delete
            </Button>
          </ButtonsWrapper>
        );
      }
    }
  ];

  const rolesData = useAppSelector(selectAllRoles());

  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);

  return (
    <StyledRolesPage>
      <ContentTop title="Roles" onClickAdd={() => (location.pathname = 'roles/details')} buttonText="Add Role" add />
      <Table bordered columns={columns} dataSource={rolesData} rowKey="id" loading={loading} pagination={false} />
    </StyledRolesPage>
  );
};

export default Roles;
